import { Moment } from 'moment';
import { BaseModel, TimeBasedObjectModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';
import { AbsenceProhibition } from '../types/api';

class AbsenceProhibitionModelClass
   extends BaseModel<AbsenceProhibition>
   implements TimeBasedObjectModel<AbsenceProhibition>
{
   public getName(): string {
      return 'absence_prohibition';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<AbsenceProhibition[]> {
      return ModelExtension.listTimeRange<AbsenceProhibition>(this.getName(), from, to);
   }
}
export const AbsenceProhibitionModel = new AbsenceProhibitionModelClass();
