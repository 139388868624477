import { Badge, Button, Col, ListGroup, Modal, Placeholder, Row } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiStar } from '@mdi/js';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { MiniProfile } from '../molecules';
import { Colors, formatRange } from '../utils';
import {
   AbsenceType,
   CoachAvailabilityModel,
   CoachesAvailabilityModel,
   CoachingClass,
   ROLE_COACH,
} from '../types/api';
import { CoachingClassModel } from '../models';
import { useCache } from '../hooks';

interface Props {
   show: boolean;
   onClose: () => void;
   coachingClass: CoachingClass;
   onSelectCoach: (coachId: number) => Promise<void>;
}

const AVAILABILITY_MISSING_ROLE_COACH = 'MISSING_ROLE_COACH';
const AVAILABILITY_ABSENCE = 'ABSENCE';
const AVAILABILITY_DO_NOT_WANT_TO_COACH = 'DO_NOT_WANT_TO_COACH';
const AVAILABILITY_CLASS_LIMIT_REACHED = 'CLASS_LIMIT_REACHED';

export const SelectCoachDialog = (props: Props) => {
   const [availabilityStatus, setAvailabilityStatus] = useState<CoachesAvailabilityModel>();

   useEffect(() => {
      (async () => {
         if (!props.show) return;

         const calcClassesPercentage = (a: CoachAvailabilityModel) => {
            if (a.classes_month === 0) a.classes_month_percentage = 1;
            else a.classes_month_percentage = a.classes_done / a.classes_month;
         };

         setAvailabilityStatus(undefined);
         const result = await CoachingClassModel.getAvailableCoaches(
            props.coachingClass.start,
            props.coachingClass.id
         );
         result.available.forEach(calcClassesPercentage);
         result.not_available.forEach(calcClassesPercentage);
         setAvailabilityStatus(result);
      })();
   }, [props.coachingClass, props.show]);

   const handleSelect = useCallback(
      async (coachInfo: CoachAvailabilityModel) => {
         await props.onSelectCoach(coachInfo.coach_id);
      },
      [props]
   );

   return (
      <Modal show={props.show} onHide={props.onClose} size="lg">
         <Modal.Header closeButton>
            <Modal.Title>Coach auswählen</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Row>
               <Col xs={12} lg={6}>
                  <ListGroup className="flex-fill my-1">
                     <ListGroup.Item className="bg-light text-center fw-bold">
                        Verfügbare Coaches
                     </ListGroup.Item>
                     {!availabilityStatus && <PlaceholderItem />}
                     {orderBy(
                        availabilityStatus?.available,
                        ['wantToCoach', 'classes_month_percentage'],
                        ['desc', 'asc']
                     )?.map(c => (
                        <CoachItem key={c.coach_id} coachInfo={c} onClick={handleSelect} />
                     ))}
                  </ListGroup>
               </Col>
               <Col xs={12} lg={6}>
                  <ListGroup className="flex-fill my-1">
                     <ListGroup.Item className="bg-light text-center fw-bold">
                        Nicht verfügbare Coaches
                     </ListGroup.Item>
                     {!availabilityStatus && <PlaceholderItem />}
                     {availabilityStatus?.not_available?.map(c => (
                        <CoachItem key={c.coach_id} coachInfo={c} onClick={handleSelect} />
                     ))}
                  </ListGroup>
               </Col>
            </Row>
         </Modal.Body>
         <Modal.Footer className="bg-light">
            <Button variant="secondary" onClick={props.onClose}>
               Schließen
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

interface CoachItemProps {
   coachInfo: CoachAvailabilityModel;
   onClick: (coachInfo: CoachAvailabilityModel) => Promise<void>;
}

const CoachItem = ({ coachInfo, onClick }: CoachItemProps) => {
   const { users } = useCache();

   const getReasonText = () => {
      switch (coachInfo.reason) {
         case AVAILABILITY_MISSING_ROLE_COACH:
            return (
               <>
                  Rolle <strong>Coach</strong> fehlt.
               </>
            );
         case AVAILABILITY_ABSENCE:
            return (
               <>
                  Abwesenheit{' '}
                  <strong>{AbsenceType.find(t => t.value === coachInfo.data?.type)?.label}</strong>{' '}
                  vom{' '}
                  <strong>{`${formatRange(
                     coachInfo.data?.start as string,
                     coachInfo.data?.end as string,
                     'simple-date-only'
                  )}`}</strong>
                  .
               </>
            );
         case AVAILABILITY_DO_NOT_WANT_TO_COACH:
            return <>Möchte zu dieser Zeit nicht coachen.</>;
         case AVAILABILITY_CLASS_LIMIT_REACHED:
            return (
               <>
                  Anzahl der vertraglich definierten Klassen von{' '}
                  <strong>{coachInfo.classes_max}</strong> in diesem Monat ist erreicht.
               </>
            );
         default:
            return null;
      }
   };

   return (
      <ListGroup.Item action onClick={() => onClick(coachInfo)}>
         <div className="d-flex justify-content-between">
            <span className="d-flex align-items-center">
               {!!coachInfo.wantToCoach && (
                  <>
                     <Icon
                        id={`sc-dialog-want-to-coach-${coachInfo.coach_id}`}
                        path={mdiStar}
                        size={1}
                        color={Colors.yellow}
                        className="me-2"
                     />
                     <Tooltip
                        anchorSelect={`#sc-dialog-want-to-coach-${coachInfo.coach_id}`}
                        content="Der Coach gibt an, zu dieser Zeit bevorzugt coachen zu möchten."
                        place="bottom"
                     />
                  </>
               )}
               <MiniProfile user={coachInfo.coach_id} />
            </span>
            <span>
               {users.find(u => u.id === coachInfo.coach_id)?.roles.includes(ROLE_COACH) &&
                  coachInfo.coached_every_week >= 0 &&
                  coachInfo.coached_every_week < moment().week() && (
                     <Badge bg="warning" className="me-1">{`${
                        coachInfo.coached_every_week
                     } / ${moment().week()}`}</Badge>
                  )}
               <Badge>{`${coachInfo.classes_done} / ${coachInfo.classes_month}`}</Badge>
            </span>
         </div>
         {coachInfo.reason && (
            <small className="mt-2 fst-italic text-muted">{getReasonText()}</small>
         )}
      </ListGroup.Item>
   );
};

const PlaceholderItem = () => (
   <>
      {[...Array(4).keys()].map(i => (
         <ListGroup.Item key={i}>
            <Placeholder
               aria-hidden="true"
               animation="wave"
               className="d-flex justify-content-between"
            >
               <span className="flex-fill">
                  <Placeholder
                     className="me-2"
                     style={{ width: '1.5rem', aspectRatio: '1', borderRadius: '50%' }}
                  />
                  <Placeholder xs={6} />
               </span>
               <div>
                  <Placeholder style={{ width: '3rem' }} />
               </div>
            </Placeholder>
         </ListGroup.Item>
      ))}
   </>
);
