import React, { useEffect, useMemo, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import moment from 'moment';
import { Alert, Badge, Button, Container, Form, ListGroup, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiWhatsapp } from '@mdi/js';
import Icon from '@mdi/react';
import { LinkContainer } from 'react-router-bootstrap';
import { orderBy } from 'lodash';
import { isMobile } from 'react-device-detect';
import { FloatingPanel } from '../../atoms';
import { Headline, LoadingButton, MiniProfile } from '../../molecules';
import { Colors, formatRange, notEmpty } from '../../utils';
import {
   Absence,
   AbsenceType,
   CoachingClass,
   RepresentationRequest,
   ROLE_COACH,
   User,
} from '../../types/api';
import { AbsenceModel, CoachingClassModel, RepresentationRequestModel } from '../../models';
import { useCache, useSession } from '../../hooks';
import { GenericControl, SubmitButton, TextareaControl } from '../../forms';
import { CoachingBackup } from '../../types/api/CoachingBackup';
import { CoachingBackupModel } from '../../models/CoachingBackupModel';

interface FormModel extends Omit<RepresentationRequest, 'coaching_classes'> {
   coaching_classes: {
      class_id: number;
      coach_id?: number | null;
   }[];
}

export const CreateRepresentationRequestPage = () => {
   const { classTypes, rooms } = useCache();
   const { sessionUser } = useSession();
   const { date, coachingClassId } = useParams();
   const navigate = useNavigate();
   const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([]);
   const [coachingBackups, setCoachingBackups] = useState<CoachingBackup[]>([]);
   const [existingRequest, setExistingRequest] = useState<RepresentationRequest>();
   const [absences, setAbsences] = useState<Absence[]>([]);

   const classIsInPast = moment(date).isBefore(moment(), 'day');

   useEffect(() => {
      (async () => {
         setCoachingBackups(
            await CoachingBackupModel.list({
               day_of_week: moment(date).weekday(),
            })
         );

         const classes = (
            await CoachingClassModel.listTimeRange(moment(date), moment(date).add(1, 'day'))
         )
            .filter(
               cc =>
                  cc.coach_id === sessionUser?.id ||
                  cc.coach_second_id === sessionUser?.id ||
                  cc.coach_other.includes(sessionUser?.id ?? 0)
            )
            .filter(cc => !cc.canceled);
         setCoachingClasses(classes);

         setExistingRequest(
            (
               await RepresentationRequestModel.list({ user_id: sessionUser?.id, status: 'open' })
            ).find(r => !!r.coaching_classes.find(cc => !!classes.find(c => c.id === cc)))
         );
         setAbsences(await AbsenceModel.listTimeRange(moment(date), moment(date)));
      })();
   }, [date, coachingClassId, sessionUser?.id]);

   const handleSubmitForm = async (values: Partial<FormModel>) => {
      const request = await RepresentationRequestModel.insert({
         ...values,
         coaching_classes: values.coaching_classes?.map(cc => cc.class_id) ?? [],
      });

      navigate(`/representationRequest/${request.id}`, { replace: true });
   };

   const handleSelectBackupCoach = async (classId: number, coachId: number): Promise<void> => {
      const updatedCoachingClass = await CoachingClassModel.replaceCoachWithBackup(
         classId,
         coachId
      );
      setCoachingClasses(v => [...v.filter(cc => cc.id !== updatedCoachingClass.id)]);
   };

   return (
      <Container className="py-2">
         <Headline title="Vertretung anfragen" />
         {existingRequest && (
            <Alert variant="danger">
               <Alert.Heading>Es existiert bereits eine Anfrage für diesen Tag.</Alert.Heading>
               <p>
                  Du musst die bestehende Anfrage erst schließen ehe du eine neue erstellen kannst.
               </p>
               <p>
                  <LinkContainer to={`/representationRequest/${existingRequest.id}`}>
                     <Alert.Link as={Button} variant="link" className="p-0 border-0 align-top">
                        Klicke hier
                     </Alert.Link>
                  </LinkContainer>{' '}
                  um zu der Anfrage zu gelangen
               </p>
            </Alert>
         )}
         {classIsInPast && (
            <>
               <Alert variant="danger">
                  <Alert.Heading>
                     Der <strong>{moment(date).format('L')}</strong> is in der Vergangenheit.
                  </Alert.Heading>
                  <p>
                     Der gewählte Tag befindet sich in der Vergangenheit. Es kann daher keine
                     Anfrage erstellt werden.
                  </p>
               </Alert>
               <Button variant="primary" className="me-3 mt-3" onClick={() => navigate(-1)}>
                  Zurück
               </Button>
            </>
         )}
         {coachingClasses.length === 0 && (
            <>
               <Alert variant="danger">
                  <Alert.Heading>
                     Keine Klassen am <strong>{moment(date).format('L')}</strong>
                  </Alert.Heading>

                  <p>Für den gewählte Tag hast du keine Klasse zum Coachen.</p>
               </Alert>
               <Button variant="primary" className="me-3 mt-3" onClick={() => navigate(-1)}>
                  Zurück
               </Button>
            </>
         )}
         {!classIsInPast && coachingClasses.length > 0 && (
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={{
                  id: 0,
                  user_id: sessionUser?.id,
                  status: 'open',
                  date: coachingClasses.find(cc => cc.id === Number(coachingClassId))?.start,
                  coaching_classes: [
                     ...(coachingClasses.find(cc => cc.id === Number(coachingClassId))
                        ? [{ class_id: Number(coachingClassId) }]
                        : []),
                  ],
               }}
               enableReinitialize
               validateOnMount
               validate={values => {
                  const errors: FormikErrors<Partial<RepresentationRequest>> = {};

                  if (!values.coaching_classes || values.coaching_classes?.length === 0)
                     errors.coaching_classes = 'Bitte wähle mindestens eine Klasse aus.';

                  if (!values.coaching_classes?.every(cc => cc.coach_id !== undefined))
                     errors.coaching_classes =
                        'Bitte gebe an, ob ein Backup-Coach deine Klassen übernehmen kann.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <fieldset
                        disabled={!!existingRequest || moment(date).isBefore(moment(), 'day')}
                     >
                        <FloatingPanel>
                           <GenericControl
                              formik={formik}
                              name="coaching_classes"
                              label="Deine Klassen"
                              type="responsive"
                           >
                              <ListGroup className="flex-fill">
                                 {coachingClasses
                                    ?.sort((a, b) => {
                                       const timeDiff = a.start.getTime() - b.start.getTime();
                                       return timeDiff !== 0 ? timeDiff : a.id - b.id;
                                    })
                                    .map(cc => (
                                       <>
                                          <ListGroup.Item
                                             key={cc.id}
                                             action
                                             variant={
                                                formik.values.coaching_classes?.find(
                                                   i => i.class_id === cc.id
                                                )
                                                   ? 'primary'
                                                   : undefined
                                             }
                                             className="d-flex align-items-center"
                                             onClick={e => {
                                                e.preventDefault();
                                                if (
                                                   formik.values.coaching_classes?.find(
                                                      i => i.class_id === cc.id
                                                   )
                                                ) {
                                                   // Klasse ist aktiviert, also entfernen
                                                   formik.setFieldValue('coaching_classes', [
                                                      ...(
                                                         formik.values.coaching_classes ?? []
                                                      ).filter(i => i.class_id !== cc.id),
                                                   ]);
                                                } else {
                                                   // Klasse ist nicht aktiviert, also hinzufügen
                                                   formik.setFieldValue('coaching_classes', [
                                                      ...(formik.values.coaching_classes ?? []),
                                                      { class_id: cc.id },
                                                   ]);
                                                }
                                             }}
                                          >
                                             {formik.values.coaching_classes?.find(
                                                i => i.class_id === cc.id
                                             ) ? (
                                                <Icon path={mdiCheckboxMarked} size={1} />
                                             ) : (
                                                <Icon path={mdiCheckboxBlankOutline} size={1} />
                                             )}
                                             <div className="ms-3 d-flex flex-column">
                                                <h5>
                                                   {formatRange(
                                                      cc.start,
                                                      cc.end,
                                                      'full-date-with-time'
                                                   )}
                                                </h5>
                                                <div>
                                                   <span>
                                                      {
                                                         classTypes.find(ct => ct.id === cc.type_id)
                                                            ?.name
                                                      }
                                                   </span>
                                                   <span className="mx-1">·</span>
                                                   <span>
                                                      {rooms.find(r => r.id === cc.room_id)?.name}
                                                   </span>
                                                </div>
                                             </div>
                                          </ListGroup.Item>
                                          <SelectBackupCoachItem
                                             coachingBackups={coachingBackups}
                                             coachingClass={cc}
                                             absences={absences}
                                             isSelectedForRepresentation={
                                                !!formik.values.coaching_classes?.find(
                                                   i => i.class_id === cc.id
                                                )
                                             }
                                             selectedCoachId={
                                                formik.values.coaching_classes?.find(
                                                   i => i.class_id === cc.id
                                                )?.coach_id
                                             }
                                             onSelectCoach={async id => {
                                                if (id !== undefined && id != null)
                                                   await handleSelectBackupCoach(cc.id, id);
                                                else {
                                                   formik.setFieldValue('coaching_classes', [
                                                      ...(
                                                         formik.values.coaching_classes ?? []
                                                      ).filter(e => e.class_id !== cc.id),
                                                      {
                                                         class_id: cc.id,
                                                         coach_id: id,
                                                      },
                                                   ]);
                                                }
                                             }}
                                          />
                                       </>
                                    ))}
                              </ListGroup>
                           </GenericControl>
                           <TextareaControl
                              formik={formik}
                              name="comment"
                              label="Kommentar"
                              placeholder="Kommentar…"
                              type="responsive"
                           />
                        </FloatingPanel>
                        <div className="d-flex justify-content-end">
                           <Button
                              variant="outline-link"
                              className="me-3 mt-3"
                              onClick={() => navigate(-1)}
                           >
                              Zurück
                           </Button>
                           <SubmitButton formik={formik} className="floating-button">
                              Anfrage erstellen
                           </SubmitButton>
                        </div>
                     </fieldset>
                  </Form>
               )}
            </Formik>
         )}
      </Container>
   );
};

interface SelectBackupCoachItemProps {
   coachingBackups: CoachingBackup[];
   coachingClass: CoachingClass;
   absences: Absence[];
   isSelectedForRepresentation: boolean;
   selectedCoachId?: number | null;
   onSelectCoach: (coachId: number | null | undefined) => Promise<void>;
}

const SelectBackupCoachItem = ({
   coachingBackups,
   coachingClass,
   absences,
   isSelectedForRepresentation,
   selectedCoachId,
   onSelectCoach,
}: SelectBackupCoachItemProps) => {
   const { users } = useCache();
   const { sessionUser } = useSession();
   const [selectedCoach, setSelectedCoach] = useState<number | null>(null);
   const [isLoading, setLoading] = useState(false);

   const selectedUser = useMemo(
      () => users.find(u => u.id === selectedCoach),
      [users, selectedCoach]
   );

   const backupCoaches = useMemo(() => {
      const rawData: { id: number; sortKey: string }[] = [];
      const startTime = moment(coachingClass.start).format('HH:mm');
      const endTime = moment(coachingClass.end).format('HH:mm');

      coachingBackups
         .filter(cb => cb.start_time <= startTime && cb.end_time >= endTime)
         .forEach((cb, cbi) => {
            cb.coaches.forEach((cid, i) => {
               if (rawData.find(d => d.id === cid)) return;

               rawData.push({ id: cid, sortKey: `${i}-${cbi}` });
            });
         });

      return orderBy(rawData, 'sortKey').map(d => d.id);
   }, [coachingBackups, coachingClass.end, coachingClass.start]);

   const renderRow = (coach: User) => {
      const absence = absences.find(a => a.user_id === coach.id);

      return (
         <div className="d-flex gap-2 align-items-center">
            <Button size="sm" onClick={() => setSelectedCoach(coach.id)} disabled={!!absence}>
               Auswählen
            </Button>
            <MiniProfile user={coach} />
            {absence && (
               <Badge bg="secondary">
                  {AbsenceType.find(at => at.value === absence.type)?.label}
               </Badge>
            )}
            {!absence && coach.phone && (
               <a href={`https://wa.me/${coach.phone}`} target="_blank" rel="noopener noreferrer">
                  <Icon path={mdiWhatsapp} color={Colors.secondary} size={1} />
               </a>
            )}
         </div>
      );
   };

   // Wenn Klasse für Vertretung nicht ausgewählt ist, wird nichts angezeigt
   if (!isSelectedForRepresentation) return null;
   // Wenn keine Backup-Coaches existieren, wird nichts angezeigt
   if (backupCoaches.length === 0 && selectedCoachId !== null) {
      onSelectCoach(null).then();
      return null;
   }

   // Wenn kein Coach kann, dann dies anzeigen
   if (backupCoaches.length > 0 && selectedCoachId === null) {
      return (
         <ListGroup.Item className="d-flex flex-row gap-2 align-items-center">
            <span>Kein Backup-Coach kann übernehmen.</span>
            <Button size="sm" variant="link" onClick={() => onSelectCoach(undefined)}>
               Ändern
            </Button>
         </ListGroup.Item>
      );
   }

   // Wenn kein Coach ausgewählt ist, dann Liste der Coaches anzeigen
   if (selectedCoachId === undefined) {
      return (
         <>
            <ListGroup.Item className="d-flex flex-column gap-2">
               {backupCoaches
                  .map(cid => users.find(u => u.id === cid))
                  .filter(notEmpty)
                  .filter(
                     u => u.id !== sessionUser?.id && u.enabled && u.roles.includes(ROLE_COACH)
                  )
                  .map(renderRow)}
               <Button
                  size="sm"
                  variant="outline-secondary"
                  className="align-self-start"
                  onClick={() => onSelectCoach(null)}
               >
                  Kein Coach kann übernehmen
               </Button>
            </ListGroup.Item>
            <Modal show={!!selectedUser} onHide={() => setSelectedCoach(null)} centered={isMobile}>
               <Modal.Header closeButton>
                  <Modal.Title>Coach bestätigen</Modal.Title>
               </Modal.Header>

               <Modal.Body>
                  Möchtest du{' '}
                  <strong>{`${selectedUser?.first_name} ${selectedUser?.last_name}`}</strong> als
                  neuen Coach hinterlegen?
               </Modal.Body>

               <Modal.Footer className="bg-light">
                  <Button
                     variant="outline-link"
                     onClick={() => setSelectedCoach(null)}
                     disabled={isLoading}
                  >
                     Abbrechen
                  </Button>

                  <LoadingButton
                     onClick={async () => {
                        if (selectedCoach === null) return;

                        setLoading(true);
                        await onSelectCoach(selectedCoach);
                        setLoading(false);
                        setSelectedCoach(null);
                     }}
                     isLoading={isLoading}
                  >
                     Bestätigen
                  </LoadingButton>
               </Modal.Footer>
            </Modal>
         </>
      );
   }

   return null;
};
