/* eslint-disable no-bitwise */
import { Col, Form, InputGroup } from 'react-bootstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormikErrors, FormikProps } from 'formik';
import moment from 'moment';
import { Box, ROLE_ADMIN } from '../types/api';
import { CountrySelect, CountryStateSelect, LogoControl } from '../forms/controls';
import { ShowIfRole } from '../molecules';
import {
   CheckControl,
   FormFieldError,
   FormRow,
   FormUtils,
   GenericControl,
   TextControl,
} from '../forms';

export interface BoxFormProps
   extends Pick<
      FormikProps<Partial<Box>>,
      | 'values'
      | 'handleChange'
      | 'handleBlur'
      | 'errors'
      | 'touched'
      | 'isValid'
      | 'isSubmitting'
      | 'setFieldValue'
      | 'submitCount'
   > {
   /**
    * Gets called when the user changed the box logo.
    * @param file The selected file from the local file system.
    */
   setLogoFile: (file: File) => void;
}

/**
 * Provides a form to edit a CrossFit box.
 */
export const BoxForm = ({ setLogoFile, ...formik }: BoxFormProps) => {
   const { values, handleChange, handleBlur, isSubmitting, setFieldValue } = formik;

   return (
      <>
         <TextControl
            label="Name"
            name="name"
            placeholder="Name der CrossFit Box…"
            disabled={values.is_demo}
            formik={formik}
            type="responsive"
         />
         <ShowIfRole roles={[ROLE_ADMIN]}>
            <CheckControl label="Ist Demo-Box" name="is_demo" formik={formik} type="responsive" />
         </ShowIfRole>
         <FormRow label="Logo" type="responsive">
            <Col xs={12} md={8}>
               <LogoControl
                  boxId={values.id}
                  onFileSelect={setLogoFile}
                  disabled={isSubmitting || values.is_demo}
               />
            </Col>
         </FormRow>
         <GenericControl label="Land" name="country_id" formik={formik} type="responsive">
            <CountrySelect
               value={values.country_id ?? 0}
               onChange={v => {
                  setFieldValue('country_id', v);
                  setFieldValue('state_id', 0);
               }}
               onBlur={() => {
                  handleBlur('country_id');
                  handleBlur('state_id');
               }}
               isInvalid={FormUtils.isInvalid(formik, 'country_id')}
               disabled={isSubmitting || values.is_demo}
            />
         </GenericControl>
         <GenericControl label="Bundesland" name="state_id" formik={formik} type="responsive">
            <CountryStateSelect
               value={values.state_id ?? 0}
               countryId={values.country_id ?? 0}
               onChange={v => setFieldValue('state_id', v)}
               onBlur={() => handleBlur('state_id')}
               isInvalid={FormUtils.isInvalid(formik, 'state_id')}
               disabled={isSubmitting || values.is_demo}
            />
         </GenericControl>
         <FormRow label="Adresse" type="responsive">
            <Form.Control
               type="text"
               name="street_address"
               placeholder="Straße & Hausnummer…"
               value={values.street_address ?? ''}
               onChange={handleChange}
               onBlur={handleBlur}
               isInvalid={FormUtils.isInvalid(formik, 'street_address')}
               disabled={isSubmitting || values.is_demo}
            />
            <FormFieldError name="street_address" formik={formik} />
            <div className="mt-3 d-flex align-items-center">
               <Form.Control
                  className="me-2"
                  style={{ flex: 1 }}
                  type="text"
                  name="postal_code"
                  placeholder="Postleitzahl…"
                  value={values.postal_code ?? ''}
                  onChange={handleChange}
                  isInvalid={FormUtils.isInvalid(formik, 'postal_code')}
                  disabled={isSubmitting || values.is_demo}
               />
               <Form.Control
                  style={{ flex: 2 }}
                  type="text"
                  name="city"
                  placeholder="Stadt…"
                  value={values.city ?? ''}
                  onChange={handleChange}
                  isInvalid={FormUtils.isInvalid(formik, 'city')}
                  disabled={isSubmitting || values.is_demo}
               />
            </div>
            <FormFieldError name="postal_code" formik={formik} />
            <FormFieldError name="city" formik={formik} />
         </FormRow>
         <GenericControl label="Arbeitstage" name="working_days" formik={formik} type="responsive">
            <WorkingDaysControl
               value={values.working_days ?? 0}
               onChange={v => setFieldValue('working_days', v)}
               disabled={isSubmitting}
            />
         </GenericControl>
         <GenericControl
            label="Urlaub verfällt nach"
            description="Das Datum gibt an, bis zu welchen Tag der Resturlaub vom letzten Jahr verwendet werden kann."
            name="vacation_forfeiture_until"
            formik={formik}
            type="responsive"
         >
            <InputGroup>
               <Form.Control
                  type="number"
                  name="vacation_forfeiture_until"
                  placeholder="Anzahl Tage…"
                  value={values.vacation_forfeiture_until}
                  onChange={handleChange}
                  isInvalid={FormUtils.isInvalid(formik, 'vacation_forfeiture_until')}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
               />
               <InputGroup.Text>Tage</InputGroup.Text>
            </InputGroup>
            <Form.Text className="text-muted">
               Urlaub von <strong>{moment().year() - 1}</strong> ist gültig bis{' '}
               <strong>
                  {moment(`${moment().year()}-01-01`)
                     .add(values.vacation_forfeiture_until, 'day')
                     .format('L')}
               </strong>
               .
            </Form.Text>
         </GenericControl>
         <GenericControl
            name="simultaneous_absences"
            label="Gleichzeitige Urlaube"
            description="Gibt an, wie viele Coaches gleichzeitig im Urlaub sein dürfen. Ein Wert von 0 bedeutet unbegrenzt."
            formik={formik}
            type="responsive"
         >
            <InputGroup>
               <Form.Control
                  type="number"
                  name="simultaneous_absences"
                  placeholder="Anzahl Coaches…"
                  value={values.simultaneous_absences}
                  min={0}
                  onChange={handleChange}
                  isInvalid={FormUtils.isInvalid(formik, 'simultaneous_absences')}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
               />
               <InputGroup.Text>Tage</InputGroup.Text>
            </InputGroup>
         </GenericControl>
         <GenericControl
            name="rr_number_of_votes_required"
            label="Min. Anzahl Votes"
            description="Gibt an, wie viele Coaches mindestens für eine Vertretungsanfrage abgestimmt haben müssen, ehe der Antragssteller einen der Coaches auswählen kann. Ein Wert von 0 deaktiviert dabei die Funktion."
            formik={formik}
            type="responsive"
         >
            <InputGroup>
               <Form.Control
                  type="number"
                  name="rr_number_of_votes_required"
                  placeholder="Anzahl Votes…"
                  value={values.rr_number_of_votes_required ?? 0}
                  min={0}
                  onChange={handleChange}
                  isInvalid={FormUtils.isInvalid(formik, 'rr_number_of_votes_required')}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
               />
               <InputGroup.Text>Votes</InputGroup.Text>
            </InputGroup>
            {(values.rr_number_of_votes_required ?? 0) === 0 && (
               <small className="text-muted">Der Wert von 0 deaktiviert die Funktion.</small>
            )}
         </GenericControl>

         <GenericControl
            name="rr_locked_until_days_before"
            label="Sperrung bis vor"
            description="Gibt an, wie viele Tage vor der zu benötigten Vertretung der Antragsteller einer der abgestimmten Coaches auswählen kann. Ein Wert von 0 deaktiviert dabei die Funktion."
            formik={formik}
            type="responsive"
         >
            <InputGroup>
               <Form.Control
                  type="number"
                  name="rr_locked_until_days_before"
                  placeholder="Anzahl Tage…"
                  value={values.rr_locked_until_days_before ?? 0}
                  min={0}
                  onChange={handleChange}
                  isInvalid={FormUtils.isInvalid(formik, 'rr_locked_until_days_before')}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
               />
               <InputGroup.Text>Tage</InputGroup.Text>
            </InputGroup>
            {(values.rr_locked_until_days_before ?? 0) === 0 && (
               <small className="text-muted">Der Wert von 0 deaktiviert die Funktion.</small>
            )}
         </GenericControl>
      </>
   );
};

interface WorkingDaysControlProps {
   value: number;
   onChange: (value: number) => void;
   disabled: boolean;
}

export const WorkingDaysControl = (props: WorkingDaysControlProps) => {
   const [workingDays, setWorkingDays] = useState<boolean[]>(Array(7).fill(false));
   const counter = useRef(0);

   useEffect(() => {
      // Sonntag, Montag, ..., Freitag, Samstag
      setWorkingDays([
         (props.value & (1 << 0)) !== 0,
         (props.value & (1 << 1)) !== 0,
         (props.value & (1 << 2)) !== 0,
         (props.value & (1 << 3)) !== 0,
         (props.value & (1 << 4)) !== 0,
         (props.value & (1 << 5)) !== 0,
         (props.value & (1 << 6)) !== 0,
      ]);
   }, [props.value]);

   const calculateWorkingDayValue = (wd: boolean[]): number =>
      ((wd[0] ? 1 : 0) << 0) +
      ((wd[1] ? 1 : 0) << 1) +
      ((wd[2] ? 1 : 0) << 2) +
      ((wd[3] ? 1 : 0) << 3) +
      ((wd[4] ? 1 : 0) << 4) +
      ((wd[5] ? 1 : 0) << 5) +
      ((wd[6] ? 1 : 0) << 6);

   return (
      <Fragment key={counter.current}>
         {[...Array(7).keys()].map(i => (
            <Form.Check
               key={i}
               id={`weekday-${i}`}
               type="checkbox"
               label={moment.weekdays()[(i + 1) % 7]}
               checked={workingDays[(i + 1) % 7]}
               onChange={() => {
                  workingDays[(i + 1) % 7] = !workingDays[(i + 1) % 7];
                  setWorkingDays(workingDays);
                  props.onChange(calculateWorkingDayValue(workingDays));
                  counter.current += 1;
               }}
               disabled={props.disabled}
            />
         ))}
      </Fragment>
   );
};

export const handleValidate = (values: Partial<Box>) => {
   const errors: FormikErrors<Partial<Box>> = {};

   if (!values.name) errors.name = 'Bitte gib einen Namen für die CrossFit-Box an.';
   if (!values.country_id) errors.country_id = 'Bitte wähle ein Land aus.';
   if (!values.state_id) errors.state_id = 'Bitte wähle ein Bundesland aus.';

   if (values.street_address || values.postal_code || values.city) {
      if (!values.street_address)
         errors.street_address = 'Bitte gib die Straße inkl. Hausnummer an.';
      if (!values.postal_code) errors.postal_code = 'Bitte gib die Postleitzahl an.';
      if (!values.city) errors.city = 'Bitte gib die Stadt an.';
   }

   if ((values.simultaneous_absences ?? 0) < 0)
      errors.simultaneous_absences = 'Bitte gebe einen Wert größer als 0 an.';

   if ((values.rr_number_of_votes_required ?? 0) < 0)
      errors.rr_number_of_votes_required = 'Bitte gebe einen Wert größer als 0 an.';

   if ((values.rr_locked_until_days_before ?? 0) < 0)
      errors.rr_locked_until_days_before = 'Bitte gebe einen Wert größer als 0 an.';

   return errors;
};
