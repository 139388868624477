import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import { CoachesAvatarList } from './CoachesAvatarList';
import { MiniProfile } from '../molecules';
import { RepresentationRequestHasVoteModel } from '../models/RepresentationRequestHasVote';
import { RepresentationRequest } from '../types/api';

interface RequestRowProps {
   request: RepresentationRequest;
}
export const RepresentationRequestItem = ({ request }: RequestRowProps) => {
   const [votedCoaches, setVotedCoaches] = useState<number[]>([]);

   useEffect(() => {
      (async () => {
         const votes = await RepresentationRequestHasVoteModel.list({ request_id: request.id });
         setVotedCoaches(votes.map(v => v.user_id));
      })();
   }, [request]);

   return (
      <div className="d-flex align-items-center">
         <Container className="px-0 flex-fill">
            <Row>
               <Col
                  xs={7}
                  sm={8}
                  md={9}
                  xl={8}
                  className="d-flex justify-content-start flex-column flex-md-row align-items-md-center gap-md-3"
               >
                  <span>
                     <MiniProfile user={request.user_id} />
                  </span>
                  <span className="fst-italic text-muted">{moment(request.date).format('L')}</span>
               </Col>
               <Col className="d-flex align-items-center justify-content-end">
                  <CoachesAvatarList coaches={votedCoaches} />
               </Col>
            </Row>
         </Container>
         <Icon path={mdiChevronRight} size={0.75} className="mx-2" />
      </div>
   );
};
