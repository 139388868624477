import React, { useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Colors } from '../../utils';
import { RootState } from '../../store';
import { upsertBox } from '../../store/actions/adminActions';
import { Box, MODULE_PERSONAL_TRAINING } from '../../types/api';
import { BoxModel } from '../../models';

interface Props {
   boxId: number;
}

export const BoxModulesTab = ({ boxId }: Props) => (
   <div className="d-flex flex-column">
      <ModuleRow
         boxId={boxId}
         name="Basis-Module"
         description="Erlaubt das Verwalten von Coaching-Klassen und Abwesenheiten für Coaches. Des Weiteren wird ein Kalender zum Abonnieren der Coaching-Zeiten zur Verfügung gestellt."
      />
      <ModuleRow
         boxId={boxId}
         module={MODULE_PERSONAL_TRAINING}
         name="Personal Trainings"
         description="Erlaubt berechtigte Coaches das Einstellen von PT-Terminen im Coaching-Kalender. Neben den Trainings können noch weitere Dienstleistungen im Rahmen von Personal Trainings abgerechnet werden."
      />
   </div>
);

interface ModuleRowProps {
   boxId: number;
   module?: string;
   name: string;
   description: string;
}

const ModuleRow = (props: ModuleRowProps) => {
   const dispatch = useDispatch();
   const box = useSelector((s: RootState) => s.admin.boxes.find((b: Box) => b.id === props.boxId));
   const [checked, setChecked] = useState(false);

   useEffect(() => {
      if (!box) return;

      if (!props.module) setChecked(true);
      else setChecked(box.modules.includes(props.module));
   }, [box, props]);

   const handleOnChange = async () => {
      if (!props.module) return;

      setChecked(!checked);
      const updatedBox = await BoxModel.adminToggleModule(props.boxId, props.module, !checked);
      dispatch(upsertBox(updatedBox));
      toast.success(
         `Das Modul '${props.name}' wurde erfolgreich ${!checked ? 'aktiviert' : 'deaktiviert'}.`
      );
   };

   if (!box) return null;

   return (
      <div className="d-flex flex-column mb-3">
         <FormCheck
            type="switch"
            id={`module-switch-${props.module ?? 'Base'}`}
            label={props.name}
            className="me-2 cursor-pointer"
            color={Colors.success}
            checked={checked}
            disabled={!props.module}
            onChange={handleOnChange}
         />
         <div className="text-muted" style={{ marginLeft: '2.5rem' }}>
            {props.description}
         </div>
      </div>
   );
};
