import {
   INSERT_COACHING_CLASSES,
   REMOVE_ABSENCE,
   REMOVE_ABSENCE_PROHIBITION,
   REMOVE_COACHING_CLASS,
   REMOVE_MEETING,
   SET_ABSENCE_PROHIBITIONS,
   SET_ABSENCES,
   SET_API_ERROR,
   SET_APP_TITLE,
   SET_CACHE,
   SET_CACHE_BOX,
   SET_COACHING_CLASSES,
   SET_HAS_NOTIFICATIONS,
   SET_IS_LOGGED_IN,
   SET_LOGGED_OUT,
   SET_MEETINGS,
   SET_SHOW_BACK_BTN,
   UPDATE_SESSION_USER,
   UPSERT_ABSENCE,
   UPSERT_ABSENCE_PROHIBITION,
   UPSERT_COACHING_CLASS,
   UPSERT_MEETING,
} from '../reducers/appReducer';
import {
   Absence,
   AbsenceProhibition,
   Box,
   CoachingClass,
   Meeting,
   Role,
   User,
} from '../../types/api';
import { CachedData } from '../../types/CachedData';

export const setLoggedIn = (user: User, roles: Role[]) => ({
   type: SET_IS_LOGGED_IN,
   data: {
      user,
      roles,
   },
});

export const updateSessionUser = (user: User) => ({
   type: UPDATE_SESSION_USER,
   data: user,
});

export const setLoggedOut = () => ({
   type: SET_LOGGED_OUT,
});

export const setApiError = (error: Error) => ({
   type: SET_API_ERROR,
   data: error,
});

export const setAppTitle = (line1: string, line2: string | null = null) => ({
   type: SET_APP_TITLE,
   data: {
      line1,
      line2,
   },
});

export const showBackButton = (show: boolean) => ({
   type: SET_SHOW_BACK_BTN,
   data: show,
});

export const setCache = (cacheData: CachedData) => ({
   type: SET_CACHE,
   data: cacheData,
});

export const setBox = (box: Box) => ({
   type: SET_CACHE_BOX,
   data: box,
});

export const setCoachingClasses = (classes: CoachingClass[]) => ({
   type: SET_COACHING_CLASSES,
   data: classes,
});

export const upsertCoachingClass = (cc: CoachingClass) => ({
   type: UPSERT_COACHING_CLASS,
   data: cc,
});

export const insertCoachingClasses = (classes: CoachingClass[]) => ({
   type: INSERT_COACHING_CLASSES,
   data: classes,
});

export const removeCoachingClass = (cc: Partial<CoachingClass> | CoachingClass) => ({
   type: REMOVE_COACHING_CLASS,
   data: cc,
});

export const setAbsences = (absences: Absence[]) => ({
   type: SET_ABSENCES,
   data: absences,
});

export const upsertAbsence = (absence: Absence) => ({
   type: UPSERT_ABSENCE,
   data: absence,
});

export const removeAbsence = (absence: Partial<Absence> | Absence) => ({
   type: REMOVE_ABSENCE,
   data: absence,
});

export const setAbsenceProhibitions = (ap: AbsenceProhibition[]) => ({
   type: SET_ABSENCE_PROHIBITIONS,
   data: ap,
});

export const upsertAbsenceProhibition = (ap: AbsenceProhibition) => ({
   type: UPSERT_ABSENCE_PROHIBITION,
   data: ap,
});

export const removeAbsenceProhibition = (ap: Partial<AbsenceProhibition> | AbsenceProhibition) => ({
   type: REMOVE_ABSENCE_PROHIBITION,
   data: ap,
});

export const setHasNotifications = (hasNotifications: boolean) => ({
   type: SET_HAS_NOTIFICATIONS,
   data: hasNotifications,
});

export const setMeetings = (meetings: Meeting[]) => ({
   type: SET_MEETINGS,
   data: meetings,
});

export const upsertMeeting = (meeting: Meeting) => ({
   type: UPSERT_MEETING,
   data: meeting,
});

export const removeMeeting = (meeting: Partial<Meeting>) => ({
   type: REMOVE_MEETING,
   data: meeting,
});
