import { BaseModel } from './BaseModel';
import { ScheduleNotes } from '../types/api/ScheduleNotes';

class ScheduleNotesModelClass extends BaseModel<ScheduleNotes> {
   public getName(): string {
      return 'schedule_notes';
   }
}

export const ScheduleNotesModel = new ScheduleNotesModelClass();
