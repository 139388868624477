import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Dropdown, Nav, Navbar } from 'react-bootstrap';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight, mdiCog, mdiFilterOutline, mdiPlus } from '@mdi/js';
import WeekOfYearRow from './WeekOfYearRow';
import { RootState } from '../../store';
import { Colors, setTitle } from '../../utils';
import { setSelectedDate, upsertScheduleEntryInWeek } from '../../store/actions/scheduleActions';
import { EditClassDialog } from '../../dialogs/EditClassDialog';
import { BoxClosingTime, CoachingClass, Meeting } from '../../types/api';
import { BadgeIcon, FloatingPanel } from '../../atoms';
import { SelectDateDropDownButton } from '../../molecules';
import { ScheduleSettingsDialog } from './ScheduleSettingsDialog';
import { ScheduleFilterDialog } from './ScheduleFilterDialog';
import { EditMeetingDialog } from '../../dialogs/EditMeetingDialog';
import { EditBoxClosingTimeDialog } from '../../dialogs/EditBoxClosingTimeDialog';
import { useUserSettings } from '../../hooks';
import { ScheduleNotes } from '../../types/api/ScheduleNotes';
import { ScheduleNotesModel } from '../../models/ScheduleNotesModel';
import { NoteCard } from './NoteCard';

export const ManageSchedulePage = () => {
   const dispatch = useDispatch();
   const { selectedDate, settings: pageSettings } = useSelector((s: RootState) => s.schedule);
   const [userSettings] = useUserSettings();
   const [newCoachingClass, setNewCoachingClass] = useState<Partial<CoachingClass>>();
   const [newMeeting, setNewMeeting] = useState<Partial<Meeting>>();
   const [newBoxClosingTime, setNewBoxClosingTime] = useState<Partial<BoxClosingTime>>();
   const [showSettings, setShowSettings] = useState(false);
   const [showFilter, setShowFilter] = useState(false);
   const [coachesNotes, setCoachesNotes] = useState<ScheduleNotes[]>([]);

   useEffect(() => setTitle('Terminieren'), []);

   useEffect(() => {
      (async () => {
         const m = moment(selectedDate);
         setCoachesNotes(await ScheduleNotesModel.list({ year: m.year(), month: m.month() + 1 }));
      })();
   }, [selectedDate]);

   return (
      <div
         className="flex-grow-1"
         style={{
            marginBottom: -20,
         }}
      >
         <Navbar
            bg="light"
            expand="lg"
            fixed="top"
            className="justify-content-start"
            style={{ marginTop: '3.6rem', zIndex: 999 }}
         >
            <Nav className="me-auto flex-row align-items-center">
               <SelectDateDropDownButton
                  variant="light"
                  size="sm"
                  className="mx-2"
                  onDateChange={d => dispatch(setSelectedDate(moment(d).toISOString()))}
               />
               <ButtonGroup className="d-none d-sm-flex me-2">
                  <Button
                     variant="light"
                     size="sm"
                     onClick={() =>
                        dispatch(
                           setSelectedDate(moment(selectedDate).add(-1, 'week').toISOString())
                        )
                     }
                  >
                     <Icon path={mdiChevronLeft} size={0.75} />
                  </Button>
                  <Button
                     variant="light"
                     size="sm"
                     onClick={() =>
                        dispatch(setSelectedDate(moment(selectedDate).add(1, 'week').toISOString()))
                     }
                  >
                     <Icon path={mdiChevronRight} size={0.75} />
                  </Button>
               </ButtonGroup>
               <h4 className="m-0 fw-bold">{moment(selectedDate).format('MMMM YYYY')}</h4>
               <Dropdown>
                  <Dropdown.Toggle
                     variant="success"
                     size="sm"
                     className="ms-3 d-flex align-items-center"
                  >
                     <Icon path={mdiPlus} size={0.75} className="me-1" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="position-absolute">
                     <Dropdown.Item onClick={() => setNewCoachingClass({ id: 0 })}>
                        Neue Klasse
                     </Dropdown.Item>
                     <Dropdown.Item onClick={() => setNewMeeting({ id: 0 })}>
                        Neues Meeting
                     </Dropdown.Item>
                     <Dropdown.Item onClick={() => setNewBoxClosingTime({ id: 0 })}>
                        Neue Schließzeit
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
               <Button
                  variant="outline-secondary"
                  size="sm"
                  className="ms-2 d-flex align-items-center"
                  onClick={() => setShowFilter(true)}
               >
                  <BadgeIcon
                     icon={mdiFilterOutline}
                     badgeStyle={{
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: Colors.light,
                     }}
                     showBadge={pageSettings.hiddenEventTypes.length > 0}
                  />
               </Button>
            </Nav>

            <Nav className="navbar-right">
               <Button
                  variant="outline-secondary"
                  size="sm"
                  className="mx-2 d-flex align-items-center"
                  onClick={() => setShowSettings(true)}
               >
                  <Icon path={mdiCog} size={0.75} />
                  <span className="ms-2 d-none d-sm-inline">Einstellungen</span>
               </Button>
            </Nav>
         </Navbar>
         <div id="schedule-new" className="mt-5 p-1 pt-2 p-md-2 pt-md-3 flex-fill">
            <div className="d-flex m-2 ">
               <FloatingPanel className="m-0 me-1 p-0 border-0 flex-fill d-flex flex-column">
                  {[...Array(userSettings.schedule?.weeksBefore ?? 1).keys()].reverse().map(i => (
                     <WeekOfYearRow
                        key={i}
                        startOfWeek={moment(selectedDate)
                           .add(-1 * (i + 1), 'week')
                           .startOf('week')
                           .toISOString()}
                     />
                  ))}
                  <WeekOfYearRow
                     startOfWeek={moment(selectedDate).startOf('week').toISOString()}
                     scrollIntoView
                  />
                  {[...Array(userSettings.schedule?.weeksAfter ?? 1).keys()].map(i => (
                     <WeekOfYearRow
                        key={i}
                        startOfWeek={moment(selectedDate)
                           .add(i + 1, 'week')
                           .startOf('week')
                           .toISOString()}
                     />
                  ))}
               </FloatingPanel>

               <Card className="d-none d-xl-flex justify-content-center" style={{ width: '16rem' }}>
                  <Card.Header className="d-flex justify-content-center">
                     {`Notizen ${moment(selectedDate).format('MMMM YYYY')}`}
                  </Card.Header>
                  <Card.Body className="position-relative">
                     <div
                        style={{
                           position: 'absolute',
                           right: 0,
                           top: 0,
                           height: '100%',
                           width: '100%',
                           overflowY: 'scroll',

                           background: 'white',
                           borderLeftWidth: 0,
                           borderTopWidth: 0,
                           borderBottomWidth: 0,
                           borderRightWidth: 0,
                           borderStyle: 'solid',
                           borderColor: '#dee2e6',
                        }}
                     >
                        {coachesNotes.map(n => (
                           <NoteCard key={n.id} note={n} />
                        ))}
                     </div>
                  </Card.Body>
               </Card>
            </div>
         </div>

         <EditClassDialog
            show={!!newCoachingClass}
            coachingClass={newCoachingClass ?? {}}
            onClose={() => setNewCoachingClass(undefined)}
            afterSave={cc => {
               dispatch(
                  upsertScheduleEntryInWeek(moment(cc.start), {
                     id: cc.id,
                     type: 'CoachingClass',
                     obj: cc,
                     start: cc.start,
                  })
               );
               return Promise.resolve();
            }}
         />

         <EditMeetingDialog
            show={!!newMeeting}
            meeting={newMeeting ?? {}}
            onClose={() => setNewMeeting(undefined)}
            afterSave={m => {
               dispatch(
                  upsertScheduleEntryInWeek(moment(m.start), {
                     id: m.id,
                     type: 'Meeting',
                     obj: m,
                     start: m.start,
                  })
               );
               return Promise.resolve();
            }}
         />

         <EditBoxClosingTimeDialog
            show={!!newBoxClosingTime}
            boxClosingTime={newBoxClosingTime ?? {}}
            onClose={() => setNewBoxClosingTime(undefined)}
            afterSave={bct => {
               dispatch(
                  upsertScheduleEntryInWeek(moment(bct.start), {
                     id: bct.id,
                     type: 'BoxClosingTime',
                     obj: bct,
                     start: bct.start,
                  })
               );
               return Promise.resolve();
            }}
         />

         <ScheduleFilterDialog show={showFilter} onClose={() => setShowFilter(false)} />
         <ScheduleSettingsDialog show={showSettings} onClose={() => setShowSettings(false)} />
      </div>
   );
};

// ManageSchedulePage.whyDidYouRender = true;
