import { TimeBasedObject } from './BaseTypes';

export type AbsenceStatusType = 'pending' | 'granted' | 'rejected';
export type AbsenceTypeType = 'vacation' | 'illness' | 'other';

export interface Absence extends TimeBasedObject {
   user_id: number;
   type: AbsenceTypeType;
   status: AbsenceStatusType;
   draft: boolean;
   comment?: string;
   ignore_during_overlap_validation: boolean;
}

export const AbsenceType = [
   {
      label: 'Urlaub',
      value: 'vacation',
   },
   {
      label: 'Krank',
      value: 'illness',
   },
   {
      label: 'Sonstige',
      value: 'other',
   },
];

export const AbsenceStatus = [
   {
      label: 'Genehmigung ausstehend',
      value: 'pending',
   },
   {
      label: 'Genehmigt',
      value: 'granted',
   },
   {
      label: 'Abgelehnt',
      value: 'rejected',
   },
];
