import React, { memo } from 'react';
import Container from 'react-bootstrap/cjs/Container';
import Row from 'react-bootstrap/cjs/Row';
import Col from 'react-bootstrap/cjs/Col';
import { Link } from 'react-router-dom';

const PageFooter = () => (
   <footer id="footer">
      <Container className="pt-0">
         <Row>
            <Col className="pt-3" lg="6" md="6" xs="12">
               <h4>Fehler/Feature/Feedback melden</h4>
               <div className="ps-2">
                  <a href="mailto:feedback@coaching-zeitplaner.de">
                     feedback@coaching-zeitplaner.de
                  </a>
               </div>
            </Col>
            <Col className="pt-3" lg="6" md="6" xs="12">
               <h4>Links</h4>
               <div className="ps-2">
                  <Link to="/imprint">Impressum & Datenschutz</Link>
               </div>
               <div className="ps-2">
                  <Link to="/changelog">Changelog</Link>
                  &nbsp;-&nbsp;
                  <span className="fst-italic">{`v${process.env.REACT_APP_VERSION}`}</span>
               </div>
            </Col>
            {/* <Col className="pt-3" lg="2" md="6" xs="12">
                  <h4>Sprache</h4>
               </Col> */}
         </Row>
      </Container>
   </footer>
);

export default memo(PageFooter);
