import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import moment from 'moment';
import { ROLE_COACH } from '../../types/api';
import { MyCoachingClassesWidget } from './MyCoachingClassesWidget';
import { MyAbsencesWidget } from './MyAbsencesWidget';
import { Headline, ShowIfRole } from '../../molecules';
import { CoachingStatsWidget } from './CoachingStatsWidget';
import { useSession } from '../../hooks';
import { ScheduleNotesWidget } from './ScheduleNotesWidget';
import { MyRepresentationRequestsWidget } from './MyRepresentationRequestsWidget';

export const DashboardPage = () => {
   const { sessionUser } = useSession();

   const getGreeting = () => {
      const hourInDay = moment().hour();
      if (hourInDay >= 3 && hourInDay < 11) return 'Guten Morgen';
      if (hourInDay >= 3 && hourInDay < 18) return 'Guten Tag';
      return 'Guten Abend';
   };

   return (
      <Container className="py-2">
         <Headline
            title={`${getGreeting()}, ${sessionUser?.first_name}`}
            browserTitle={`Dashboard von ${sessionUser?.first_name}`}
         />
         <ShowIfRole roles={[ROLE_COACH]}>
            <Row>
               <Col sm={12} md={6} className="mt-3">
                  <ScheduleNotesWidget />
               </Col>
               <Col sm={12} md={6} className="mt-3">
                  <CoachingStatsWidget />
               </Col>
            </Row>
         </ShowIfRole>
         <Row>
            <Col sm={12} md={6}>
               <MyRepresentationRequestsWidget />
               <MyCoachingClassesWidget />
            </Col>
            <Col sm={12} md={6}>
               <MyAbsencesWidget />
            </Col>
         </Row>
      </Container>
   );
};
