import {
   Calendar as RBCalendar,
   EventProps,
   Messages,
   momentLocalizer,
   Navigate,
   NavigateAction,
   ToolbarProps,
   View,
} from 'react-big-calendar';
import { isMobile } from 'react-device-detect';
import moment from 'moment/moment';
import React, { ComponentProps, CSSProperties, useCallback } from 'react';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { Colors } from '../../utils';
import { useCache } from '../../hooks';
import {
   AbsenceEvent,
   CoachingBackupEvent,
   CoachingEvent,
   GenericEvent,
   MeetingEvent,
   PTEvent,
} from './EventTypes';
import { CoachingClassEventEntry } from './CoachingClassEventEntry';
import { MeetingEventEntry } from './MeetingEventEntry';
import { PTClassEventEntry } from './PTClassEventEntry';
import { AbsenceEventEntry } from './AbsenceEventEntry';
import { AbsenceProhibitionEventEntry } from './AbsenceProhibitionEventEntry';
import { OtherEventEntry } from './OtherEventEntry';
import { CoachingBackupEventEntry } from './CoachingBackupEventEntry';

const DragAndDropCalendar = withDragAndDrop<GenericEvent>(RBCalendar as any);

export const DEFAULT_VIEW_WEB = 'week';
export const DEFAULT_VIEW_MOBILE = 'day';

interface Props
   extends Pick<
      ComponentProps<typeof DragAndDropCalendar>,
      | 'events'
      | 'onNavigate'
      | 'onEventDrop'
      | 'resizable'
      | 'selectable'
      | 'onEventResize'
      | 'onSelectSlot'
      | 'views'
      | 'defaultView'
      | 'formats'
      | 'toolbar'
   > {
   showScheduleStatus?: boolean;
}

export const Calendar = ({
   events,
   onEventDrop,
   resizable,
   selectable,
   onEventResize,
   onSelectSlot,
   onNavigate,
   showScheduleStatus = false,
   views = ['month', 'week', 'day'],
   defaultView,
   formats,
   toolbar,
}: Props) => {
   const { box } = useCache();
   const [urlParams, setUrlParams] = useSearchParams();

   const updateRoute = useCallback(
      (view: string, start: Date) => {
         const v = (view || urlParams.get('view')) ?? DEFAULT_VIEW_WEB;

         setUrlParams({
            view: v,
            date: moment(start).format('YYYY-MM-DD'),
         });
      },
      [setUrlParams, urlParams]
   );

   return (
      <DragAndDropCalendar
         /* Data handling */
         events={events}
         onSelectEvent={(evt, elem) => {
            evt.popupTarget = elem.target as HTMLElement;
         }}
         startAccessor="start"
         endAccessor="end"
         /* Calender editing */
         resizable={resizable}
         onEventDrop={onEventDrop}
         onEventResize={onEventResize}
         selectable={selectable}
         onSelectSlot={onSelectSlot}
         /* External configurable props */
         views={views}
         defaultView={
            (urlParams.get('view') as View) ??
            defaultView ??
            (isMobile ? DEFAULT_VIEW_MOBILE : DEFAULT_VIEW_WEB)
         }
         defaultDate={
            urlParams.get('date') ? moment(urlParams.get('date'), 'YYYY-MM-DD').toDate() : undefined
         }
         toolbar={toolbar}
         formats={formats}
         /* General configuration */
         style={{ minHeight: 1000 }}
         culture="de"
         localizer={momentLocalizer(moment)}
         messages={calenderMessages}
         dayLayoutAlgorithm="no-overlap"
         popup
         step={15}
         timeslots={4}
         onRangeChange={(range, view) => {
            if (Array.isArray(range)) updateRoute(view ?? '', range[0]);
            else {
               const r = moment((range as { start: Date; end: Date }).start);
               // Der Start kann am Ende des letzten Monats sein. Daher addieren wir 7 Tage dazu,
               //    sodass der Start sicher im gewünschten Monat liegt und dann nehmen wir fix den Ersten
               //    des Monats.
               r.add(7, 'day');
               updateRoute(view ?? '', moment(r.format('YYYY-MM-01')).toDate());
            }
         }}
         onNavigate={(newDate: Date, view: View, action: NavigateAction) => {
            const start = moment(newDate);
            if (view === 'month') start.set('date', 1);

            return onNavigate?.(start.toDate(), view, action);
         }}
         components={{
            month: {
               event: EventEntry,
            },
            week: {
               event: EventEntry,
            },
            day: {
               event: EventEntry,
            },
            toolbar: CalendarTooBar,
         }}
         eventPropGetter={event => {
            const style: CSSProperties = {};
            const classNames: string[] = [];

            if (event.color) {
               style.backgroundColor = event.color;
               style.color = calculateColorBasedOnBGColor(event.color);
            }

            if (event.isPending) classNames.push('event-pending');
            if (event.isCanceled) classNames.push('text-line-through');

            return {
               style,
               className: classNames.join(' '),
            };
         }}
         dayPropGetter={(date: Date) => {
            if (!showScheduleStatus || !box?.sessions_scheduled_until) return {};

            const scheduledUntil = moment(box?.sessions_scheduled_until);
            const d = moment(date);
            let className = 'schedule-ok';
            if (d.isAfter(scheduledUntil, 'day')) className = 'schedule-nok';

            return {
               className: className,
            };
         }}
      />
   );
};

const EventEntry = ({ event }: EventProps<GenericEvent>) => {
   switch (event.type) {
      case 'CoachingClass':
         return <CoachingClassEventEntry event={event as CoachingEvent} />;
      case 'Meeting':
         return <MeetingEventEntry event={event as MeetingEvent} />;
      case 'PTClass':
         return <PTClassEventEntry event={event as PTEvent} />;
      case 'Absence':
         return <AbsenceEventEntry event={event as AbsenceEvent} />;
      case 'AbsenceProhibition':
         return <AbsenceProhibitionEventEntry event={event} />;
      case 'CoachingBackup':
         return <CoachingBackupEventEntry event={event as CoachingBackupEvent} />;
      case 'Other':
      default:
         return <OtherEventEntry event={event} />;
   }
};

const calenderMessages: Messages = {
   date: 'Datum',
   time: 'Zeit',
   event: 'Event',
   allDay: 'ganztägig',
   week: 'Woche',
   work_week: 'Arbeitswoche',
   day: 'Tag',
   month: 'Monat',
   previous: 'Vorheriger',
   next: 'Nächster',
   yesterday: 'Gestern',
   tomorrow: 'Morgen',
   today: 'Heute',
   agenda: 'Agenda',

   noEventsInRange: 'In diesem Bereich sind keine Ereignisse vorhanden.',

   showMore: (total: number) => `+${total} weitere`,
};

// see: https://stackoverflow.com/a/11868159
const calculateColorBasedOnBGColor = (bgColor?: string): string | undefined => {
   if (!bgColor?.startsWith('#')) return undefined;
   const rgb: number[] = [0, 0, 0];

   if (bgColor.length === 4) {
      rgb[0] = Number(`0x${bgColor.substring(1, 2)}${bgColor.substring(1, 1)}`);
      rgb[1] = Number(`0x${bgColor.substring(2, 3)}${bgColor.substring(2, 1)}`);
      rgb[2] = Number(`0x${bgColor.substring(3, 4)}${bgColor.substring(3, 1)}`);
   } else if (bgColor.length === 7) {
      rgb[0] = Number(`0x${bgColor.substring(1, 3)}`);
      rgb[1] = Number(`0x${bgColor.substring(3, 5)}`);
      rgb[2] = Number(`0x${bgColor.substring(5, 7)}`);
   } else {
      return undefined;
   }

   // http://www.w3.org/TR/AERT#color-contrast
   const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
   return brightness > 125 ? Colors.textBlack : Colors.textWhite;
};

const CalendarTooBar = ({
   localizer,
   label,
   onNavigate,
   views,
   view,
   onView,
}: ToolbarProps<GenericEvent>) => {
   const viewNamesGroup = (messages: Messages) => {
      const viewNames = views as View[];

      return (
         <Form.Select
            onChange={e => onView(e.target.value as View)}
            disabled={viewNames.length === 1}
         >
            {viewNames.map(name => (
               <option value={name} selected={view === name}>
                  {messages[name]}
               </option>
            ))}
         </Form.Select>
      );
   };

   return (
      <>
         <div className="rbc-toolbar d-none d-md-flex">
            <span className="rbc-btn-group">
               <button
                  id="calender-nav-previous"
                  type="button"
                  onClick={() => onNavigate(Navigate.PREVIOUS)}
               >
                  ←
               </button>
               <button
                  id="calender-nav-today"
                  type="button"
                  onClick={() => onNavigate(Navigate.TODAY)}
               >
                  ●
               </button>
               <button
                  id="calender-nav-next"
                  type="button"
                  onClick={() => onNavigate(Navigate.NEXT)}
               >
                  →
               </button>
            </span>

            <span className="rbc-toolbar-label">{label}</span>

            <span className="rbc-btn-group">{viewNamesGroup(localizer.messages)}</span>
         </div>

         <div className="rbc-toolbar d-flex d-md-none gap-2">
            <span className="d-flex flex-fill justify-content-between">
               <span className="rbc-btn-group">
                  <button
                     id="calender-nav-previous"
                     type="button"
                     onClick={() => onNavigate(Navigate.PREVIOUS)}
                  >
                     ←
                  </button>
                  <button
                     id="calender-nav-today"
                     type="button"
                     onClick={() => onNavigate(Navigate.TODAY)}
                  >
                     ●
                  </button>
                  <button
                     id="calender-nav-next"
                     type="button"
                     onClick={() => onNavigate(Navigate.NEXT)}
                  >
                     →
                  </button>
               </span>

               <span className="rbc-btn-group">{viewNamesGroup(localizer.messages)}</span>
            </span>

            <span className="rbc-toolbar-label">{label}</span>
         </div>
         <Tooltip
            anchorSelect="#calender-nav-previous"
            content={localizer.messages.previous}
            place="bottom"
         />
         <Tooltip
            anchorSelect="#calender-nav-today"
            content={localizer.messages.today}
            place="bottom"
         />
         <Tooltip
            anchorSelect="#calender-nav-next"
            content={localizer.messages.next}
            place="bottom"
         />
      </>
   );
};
