import { Badge } from 'react-bootstrap';
import React, { memo } from 'react';
import { AbsenceStatusType } from '../types/api';

interface Props {
   /**
    * The status of the absence.
    */
   status: AbsenceStatusType;
}

/**
 * Creates a badge based on the given status of an absence.
 */
export const AbsenceApprovalState = memo(({ status }: Props) => {
   switch (status) {
      case 'granted':
         return <Badge bg="success">Genehmigt</Badge>;

      case 'rejected':
         return <Badge bg="danger">Abgelehnt</Badge>;

      case 'pending':
         return <Badge bg="warning">Genehmigung ausstehend</Badge>;

      default:
         return null;
   }
});
