import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemList } from '../../organisms/ItemList';
import { RepresentationRequest } from '../../types/api';
import { RepresentationRequestModel } from '../../models';
import { useCache, useSession } from '../../hooks';
import { RepresentationRequestItem } from '../../organisms/RepresentationRequestItem';

export const MyRepresentationRequestsWidget = memo(() => {
   const { sessionUser } = useSession();
   const cachedData = useCache();
   const navigate = useNavigate();
   const [myOpenRequests, setMyOpenRequests] = useState<RepresentationRequest[]>();

   useEffect(() => {
      (async () => {
         const suid = sessionUser?.id ?? 0;

         setMyOpenRequests(
            await RepresentationRequestModel.list({ user_id: suid, status: 'open' })
         );
      })();
   }, [sessionUser?.id, cachedData]);

   return (
      <ItemList
         title="Meine Vertretungsanfragen"
         items={myOpenRequests}
         onClick={item => navigate(`/representationRequest/${item.id}`)}
         hideWhenEmpty
      >
         {({ item }) => <RepresentationRequestItem request={item} />}
      </ItemList>
   );
});
