import AsyncSelect from 'react-select/async';
import React, { useState } from 'react';
import { Box } from '../../types/api';
import { BoxModel } from '../../models';

interface Props {
   defaultValue: number;
   onSearchTextChange?: (searchText: string) => void;
   onSelect: (boxId: number) => void;
}

export const BoxSearchSelect = (props: Props) => {
   const [boxes, setBoxes] = useState<Box[]>([]);

   const loadOptions = async (searchText: string) => {
      props?.onSearchTextChange?.(searchText);

      const boxList = await BoxModel.adminList(searchText);
      setBoxes(boxList);
      return boxList.map(b => ({ label: b.name, value: `${b.id}` }));
   };

   return (
      <AsyncSelect
         className="flex-fill"
         loadOptions={loadOptions}
         onChange={v => props.onSelect(Number(v?.value))}
         defaultValue={
            boxes
               .filter(b => b.id === props.defaultValue)
               .map(b => ({ label: b.name, value: `${b.id}` }))[0]
         }
         isOptionSelected={v => v.value === `${props.defaultValue}`}
         cacheOptions
         defaultOptions
      />
   );
};
