import { BaseModel } from './BaseModel';
import ApiService from '../services/ApiService';
import { RepresentationRequestHasVote } from '../types/api';
import { IApiResponse } from '../types/IApiResponse';

class RepresentationRequestHasVoteModelClass extends BaseModel<RepresentationRequestHasVote> {
   public getName(): string {
      return 'representation_request_has_vote';
   }

   async markAsSelected(id: number) {
      try {
         const response = await ApiService.http.put<IApiResponse<RepresentationRequestHasVote>>(
            `data/${this.getName()}/${id}/markAsSelected`
         );

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
export const RepresentationRequestHasVoteModel = new RepresentationRequestHasVoteModelClass();
