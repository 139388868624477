import React, { memo } from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { mdiAccountGroup, mdiClockOutline, mdiIsland, mdiLogout, mdiWeightLifter } from '@mdi/js';
import Icon from '@mdi/react';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip } from 'react-tooltip';
import { RootState } from '../../store';
import { GoogleSignInButton } from '../GoogleSignInButton';
import { BoxLogo, ShowIfRole } from '../../molecules';
import { isImpersonateModeEnabled, setImpersonateMode } from '../../utils';
import { UserModel } from '../../models';
import { useCache } from '../../hooks';
import { ROLE_BOX_OWNER, ROLE_PERSONAL_TRAINER, ROLE_SCHEDULER } from '../../types/api';
import { NotificationButton } from './NotificationButton';
import { AvatarDropdownButton } from './AvatarDropdownButton';

export const NavBar = memo(() => {
   const { loginState } = useSelector((s: RootState) => s.app.session);
   const { box } = useCache();

   return (
      <Navbar bg="primary" className="navbar-dark px-3" fixed="top">
         <LinkContainer to="/">
            <Navbar.Brand className="d-flex align-items-center cursor-pointer">
               <BoxLogo key={box?.x_date_updated.getTime()} box={box} />
               <span className="d-none d-md-inline">Coaching Zeitplaner</span>
            </Navbar.Brand>
         </LinkContainer>
         {loginState !== 'loggedIn' ? (
            <Nav className="me-auto" style={{ flex: 1 }}>
               <Nav.Link as={NavLink} to="/demo" className="d-flex align-items-center">
                  <span>Demo-Zugang</span>
               </Nav.Link>
            </Nav>
         ) : (
            <Nav className="me-auto" style={{ flex: 1 }}>
               <Nav.Link
                  id="nav-calendar"
                  as={NavLink}
                  to="/calendar"
                  className="d-flex align-items-center"
               >
                  <Icon path={mdiAccountGroup} size={0.75} className="mx-1" />
                  <span className="d-none d-xl-inline">Coaching</span>
               </Nav.Link>
               <Tooltip anchorSelect="#nav-calendar" content="Coaching" place="bottom" />
               <Nav.Link
                  id="nav-absences"
                  as={NavLink}
                  to="/absences"
                  className="d-flex align-items-center"
               >
                  <Icon path={mdiIsland} size={0.75} className="mx-1" />
                  <span className="d-none d-xl-inline">Abwesenheiten</span>
               </Nav.Link>
               <Tooltip anchorSelect="#nav-absences" content="Abwesenheiten" place="bottom" />
               <ShowIfRole roles={[ROLE_SCHEDULER, ROLE_BOX_OWNER]}>
                  <Nav.Link
                     id="nav-schedule"
                     as={NavLink}
                     to="/schedule"
                     className="d-flex align-items-center"
                  >
                     <Icon path={mdiClockOutline} size={0.75} className="mx-1" />
                     <span className="d-none d-xl-inline">Terminieren</span>
                  </Nav.Link>
                  <Tooltip anchorSelect="#nav-schedule" content="Terminieren" place="bottom" />
               </ShowIfRole>
               <ShowIfRole roles={[ROLE_PERSONAL_TRAINER]}>
                  <Nav.Link id="nav-pt" as={NavLink} to="/pt" className="d-flex align-items-center">
                     <Icon path={mdiWeightLifter} size={0.75} className="mx-1" />
                     <span className="d-none d-xl-inline">Personal Trainings</span>
                  </Nav.Link>
                  <Tooltip anchorSelect="#nav-pt" content="Personal Trainings" place="bottom" />
               </ShowIfRole>
            </Nav>
         )}
         <Nav className="navbar-right">
            {isImpersonateModeEnabled() && (
               <Button
                  variant="danger"
                  className="me-2"
                  onClick={async () => {
                     await UserModel.logout();
                     setImpersonateMode(false);
                     window.location.reload();
                  }}
               >
                  <Icon path={mdiLogout} size={1} />
               </Button>
            )}
            {loginState !== 'loggedIn' ? (
               <GoogleSignInButton />
            ) : (
               <>
                  <NotificationButton />
                  <AvatarDropdownButton />
               </>
            )}
         </Nav>
      </Navbar>
   );
});
