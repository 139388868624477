import React, { useEffect, useState } from 'react';
import { Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   CoachingPreferenceOptions,
   DayOfWeekOptions,
   DayOfWeekType,
   PreferenceType,
   PreferredCoachingTimes,
   TimeOfDayType,
} from '../../types/api';
import { Headline } from '../../molecules';
import { PreferredCoachingTimesModel } from '../../models';
import { useSession } from '../../hooks';

export const CoachingTimesTab = () => {
   const { sessionUser } = useSession();
   const [preferredTimes, setPreferredTimes] = useState<PreferredCoachingTimes[]>([]);

   useEffect(() => {
      (async () => {
         const result = await PreferredCoachingTimesModel.list();
         setPreferredTimes(result);
      })();
   }, []);

   const handleChange = async (
      dayOfWeek: DayOfWeekType,
      timeOfDay: TimeOfDayType,
      preference?: PreferenceType
   ) => {
      if (!preference) return;

      // Aktuellen Eintrag nehmen oder neuen erstellen, wenn noch keiner existiert
      const preferredTime: Partial<PreferredCoachingTimes> = preferredTimes.find(
         t => t.day_of_week === dayOfWeek && t.time_of_day === timeOfDay
      ) ?? {
         id: 0,
         day_of_week: dayOfWeek,
         time_of_day: timeOfDay,
         user_id: sessionUser?.id ?? -1,
      };

      preferredTime.preference = preference;
      const newPreference =
         preferredTime.id === 0
            ? await PreferredCoachingTimesModel.insert(preferredTime)
            : await PreferredCoachingTimesModel.update(preferredTime);

      setPreferredTimes(pt => [...pt.filter(p => p.id !== newPreference.id), newPreference]);
   };

   return (
      <>
         <Headline title="Coaching-Zeiten" browserTitle="Mein Profil - Coaching-Zeiten">
            Hier kannst du angeben, an welchen Tagen du bevorzugt oder gar nicht coachen möchtest.
            Dies kann anschließend beim Erstellen des Coaching-Plans berücksichtigt werden
         </Headline>
         <ListGroup className="floating-panel">
            <ListGroup.Item className="bg-secondary fw-bold text-white">
               <Row className="align-items-center">
                  <Col xs={2} md={4} />
                  <Col xs={5} md={4} className="text-center">
                     Vormittag
                  </Col>
                  <Col xs={5} md={4} className="text-center">
                     Nachmittag
                  </Col>
               </Row>
            </ListGroup.Item>
            {DayOfWeekOptions.map(o => (
               <ListGroup.Item key={o.value}>
                  <Row className="align-items-center">
                     <Col xs={2} md={4}>
                        <span className="d-none d-md-inline">{o.label}</span>
                        <span className="d-inline d-md-none">{o.labelShort}</span>
                     </Col>
                     <Col xs={5} md={4} className="d-flex justify-content-center">
                        <OptionDropDown
                           preference={
                              preferredTimes.find(
                                 t => t.day_of_week === o.value && t.time_of_day === 'morning'
                              )?.preference
                           }
                           onChange={p => handleChange(o.value, 'morning', p)}
                        />
                     </Col>
                     <Col xs={5} md={4} className="d-flex justify-content-center">
                        <OptionDropDown
                           preference={
                              preferredTimes.find(
                                 t => t.day_of_week === o.value && t.time_of_day === 'afternoon'
                              )?.preference
                           }
                           onChange={p => handleChange(o.value, 'afternoon', p)}
                        />
                     </Col>
                  </Row>
               </ListGroup.Item>
            ))}
         </ListGroup>
      </>
   );
};

interface OptionDropDownProps {
   preference?: PreferenceType;
   onChange: (value?: PreferenceType) => Promise<void>;
}

const OptionDropDown = ({ preference = 'does_not_matter', onChange }: OptionDropDownProps) => {
   const [selectedOption, setSelectedOption] =
      useState<{ label: string; icon: string; variant: string; color: string; value: string }>();

   useEffect(() => {
      setSelectedOption(CoachingPreferenceOptions.find(o => o.value === preference));
   }, [preference]);

   return (
      <Dropdown>
         <Dropdown.Toggle
            variant={selectedOption?.variant}
            size="sm"
            className="d-flex align-items-center"
         >
            <Icon path={selectedOption?.icon ?? ''} size={0.75} />
            <span className="d-none d-md-inline ms-md-1">{selectedOption?.label}</span>
         </Dropdown.Toggle>
         <Dropdown.Menu>
            {CoachingPreferenceOptions.map(o => (
               <Dropdown.Item
                  key={o.value}
                  className="d-flex align-items-center"
                  active={o.value === preference}
                  onClick={() => onChange(o.value as PreferenceType)}
               >
                  <Icon
                     className="me-1"
                     path={selectedOption?.icon ?? ''}
                     size={1}
                     color={o.color}
                  />
                  {o.label}
               </Dropdown.Item>
            ))}
         </Dropdown.Menu>
      </Dropdown>
   );
};
