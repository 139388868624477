import DatePicker from 'react-datepicker';
import React, { CSSProperties, forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { FormikControl } from '../../types/FormikControl';
import { FormUtils } from '../FormUtils';

interface Props<T> extends FormikControl<T> {
   children?: (props: { inputElem: React.ReactNode }) => React.ReactNode;
   onChange: (date: Date | null) => void;
   style?: CSSProperties;
}

export const DateInput = <T,>({ formik, name, onChange, style, ...outerProps }: Props<T>) => {
   // eslint-disable-next-line react/no-unstable-nested-components
   const ExampleCustomInput = forwardRef((props: any, ref: React.Ref<HTMLButtonElement>) => {
      const inputElem = (
         <Form.Control
            ref={ref}
            type="text"
            {...props}
            isInvalid={FormUtils.isInvalid(formik, name)}
         />
      );

      if (!outerProps.children) {
         return inputElem;
      }
      return <>{outerProps.children({ inputElem: inputElem })}</>;
   });

   return (
      <div style={style}>
         <DatePicker
            selected={formik.values[name] as unknown as Date}
            dateFormat="dd.MM.yyyy"
            locale="de"
            onChange={onChange}
            onBlur={() => formik.handleBlur(name)}
            calendarStartDay={1}
            disabled={formik.isSubmitting}
            popperPlacement="bottom"
            placeholderText="__.__.____"
            customInput={<ExampleCustomInput />}
         />
      </div>
   );
};
