import { Button, Form, InputGroup } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight, mdiCalendarToday } from '@mdi/js';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '../../molecules';

interface Props {
   className?: string;
   onChange: (month: Moment) => void;
}

export const SelectMonthControl = ({ className, onChange }: Props) => {
   const currentMonth = moment(`${moment().year()}-${moment().month() + 1}-01`, 'YYYY-MM-DD');
   const [selectedMonth, setSelectedMonth] = useState(currentMonth);

   useEffect(() => {
      onChange(selectedMonth);
   }, [selectedMonth]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className={`d-flex flex-row align-items-center ${className}`}>
         <InputGroup className="w-auto justify-content-start">
            <LoadingButton
               variant="secondary"
               className="d-flex align-items-center"
               onClick={() => setSelectedMonth(v => moment(v).add(-1, 'month'))}
            >
               <Icon path={mdiArrowLeft} size={0.85} />
            </LoadingButton>
            <Form.Control
               as="select"
               className="px-1"
               style={{ maxWidth: '8rem' }}
               value={selectedMonth.month()}
               onChange={event =>
                  setSelectedMonth(v => moment(v).set('month', Number(event.target.value)))
               }
            >
               {moment.months().map((m, i) => (
                  <option key={m} value={i}>
                     {m}
                  </option>
               ))}
            </Form.Control>
            <Form.Control
               className="px-1 text-center"
               style={{ maxWidth: '4rem' }}
               value={selectedMonth.year()}
               onChange={event =>
                  setSelectedMonth(v => moment(v).set('year', Number(event.target.value)))
               }
            />
            <LoadingButton
               variant="secondary"
               className="d-flex align-items-center"
               onClick={() => setSelectedMonth(v => moment(v).add(1, 'month'))}
            >
               <Icon path={mdiArrowRight} size={0.85} />
            </LoadingButton>
         </InputGroup>
         <Button
            variant="outline-link"
            className="ms-1"
            onClick={() => setSelectedMonth(currentMonth)}
         >
            <span className="d-none d-sm-inline">Heute</span>
            <Icon path={mdiCalendarToday} className="d-inline d-sm-none" size={1} />
         </Button>
      </div>
   );
};
