import { SingleApiObject } from './BaseTypes';

export interface CoachingBackup extends SingleApiObject {
   day_of_week: number;
   start_time: string;
   end_time: string;
   coaches: number[];
}

export const Weekdays = [
   {
      label: 'Montag',
      value: '0',
   },
   {
      label: 'Dienstag',
      value: '1',
   },
   {
      label: 'Mittwoch',
      value: '2',
   },
   {
      label: 'Donnerstag',
      value: '3',
   },
   {
      label: 'Freitag',
      value: '4',
   },
   {
      label: 'Samstag',
      value: '5',
   },
   {
      label: 'Sonntag',
      value: '6',
   },
];
