import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, ListGroup, Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import { Headline, MiniProfile } from '../molecules';
import { Calendar } from '../types/api';
import { CalendarModel } from '../models';
import { SkeletonPlaceholder } from '../atoms';

export const AdminCalendarPage = () => {
   const [calendars, setCalendars] = useState<Calendar[]>();
   const [headers, setHeaders] = useState<{ [key: string]: string } | null>(null);
   const [error, setError] = useState<string | null>(null);

   const loadCalendars = useCallback(async () => {
      setCalendars(await CalendarModel.adminList());
   }, []);

   useEffect(() => {
      (async () => {
         await loadCalendars();
      })();
   }, [loadCalendars]);

   return (
      <Container className="py-2">
         <Headline title="Kalender-Abos" />
         <ListGroup className="floating-panel">
            {!calendars
               ? [...Array(6).keys()].map(i => (
                    <ListGroup.Item key={i}>
                       <SkeletonPlaceholder width="60%" />
                    </ListGroup.Item>
                 ))
               : calendars.map(c => (
                    <ListGroup.Item
                       key={c.id}
                       className="d-flex align-items-center justify-content-between"
                    >
                       <div>
                          <div className="d-flex align-items-center gap-2">
                             <MiniProfile user={c.user_id} badgeVariant="secondary" />
                             <span>{c.name}</span>
                          </div>
                          <div className="small fst-italic">
                             Letzter Anfrageversuch:{' '}
                             {c.last_request_time
                                ? moment(c.last_request_time).format('L [um] LTS')
                                : 'kein Abruf bisher'}
                          </div>
                          <div className="small fst-italic">
                             Letzter erfolgreicher Abruf:{' '}
                             {c.last_fetch
                                ? moment(c.last_fetch).format('L [um] LTS')
                                : 'kein Abruf bisher'}
                          </div>
                          <div>
                             {c.last_request_headers && (
                                <Button
                                   variant="link"
                                   className="m-0 p-0 me-2"
                                   onClick={() =>
                                      setHeaders(JSON.parse(c.last_request_headers ?? ''))
                                   }
                                >
                                   Request-Header
                                </Button>
                             )}
                             {c.last_request_error && (
                                <Button
                                   variant="link"
                                   className="m-0 p-0"
                                   onClick={() => setError(c.last_request_error)}
                                >
                                   Request-Fehler
                                </Button>
                             )}
                          </div>
                       </div>
                    </ListGroup.Item>
                 ))}
         </ListGroup>
         <Modal show={!!headers} onHide={() => setHeaders(null)} size="xl">
            <Modal.Header closeButton>
               <Modal.Title>Header des letzten Requests</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Table className="mt-0 mb-0" hover responsive striped>
                  <thead>
                     <tr className="bg-light">
                        <th className="w-75">Key</th>
                        <th>Value</th>
                     </tr>
                  </thead>
                  <tbody>
                     {Object.entries(headers ?? {}).map(kv => (
                        <tr key={kv[0]}>
                           <td className="text-nowrap">{kv[0]}</td>
                           <td>{kv[1]}</td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </Modal.Body>
            <Modal.Footer className="bg-light">
               <Button variant="secondary" onClick={() => setHeaders(null)}>
                  Schließen
               </Button>
            </Modal.Footer>
         </Modal>
         <Modal show={!!error} onHide={() => setError(null)} size="xl">
            <Modal.Header closeButton>
               <Modal.Title>Fehler des letzten Requests</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <pre>{error}</pre>
            </Modal.Body>
            <Modal.Footer className="bg-light">
               <Button variant="secondary" onClick={() => setError(null)}>
                  Schließen
               </Button>
            </Modal.Footer>
         </Modal>
      </Container>
   );
};
