import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, ListGroup, Modal, ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';
import sortBy from 'lodash/sortBy';
import { TimeBasedObjectModel } from '../models/BaseModel';
import { TimeBasedObject } from '../types/api';
import { formatRange } from '../utils';
import { ContentLoader } from '../atoms';

interface Props<T extends TimeBasedObject> {
   referenceEntry: T;
   entryModel: TimeBasedObjectModel<T>;
   show: boolean;
   onCancel?: (entry: T) => Promise<void> | void;
   onDelete?: (entry: T) => Promise<void> | void;
   onClose: () => Promise<void> | void;
   afterCancelOrDelete?: () => Promise<void> | void;
   isSimilar: (a: T, b: T) => boolean;
   additionalListContent?: (entry: T) => React.ReactNode;
}

interface SelectOptionType {
   label: string;
   value: string;
}

const SelectOptions: SelectOptionType[] = [
   {
      label: 'Gleiche Eigenschaften',
      value: 'same-props',
   },
   {
      label: 'Gleicher Wochentag',
      value: 'same-day-of-week',
   },
   {
      label: 'Gleicher Tag im Monat',
      value: 'same-day-in-month',
   },
   {
      label: 'Anfang/Ende Monat',
      value: 'start-or-end-of-month',
   },
];

type PageModel = TimeBasedObject & {
   isSelected: boolean;
};
export const CancelOrDeleteMultipleEventsDialog = <T extends TimeBasedObject>({
   referenceEntry,
   entryModel,
   show,
   onCancel,
   onDelete,
   onClose,
   afterCancelOrDelete,
   isSimilar,
   additionalListContent,
}: Props<T>) => {
   const [filterCriteria, setFilterCriteria] = useState<string>('same-props');
   const [loading, setLoading] = useState(true);
   const [progress, setProgress] = useState(-1);
   const [events, setEvents] = useState<PageModel[]>();

   const loadAndFilterEvents = useCallback(async () => {
      setLoading(true);
      const items = (await entryModel.listTimeRange(moment(referenceEntry.start))).map(
         item => ({ ...item, isSelected: false } as PageModel)
      );

      switch (filterCriteria) {
         case 'same-props':
            setEvents(
               items.filter(
                  i =>
                     moment(i.start).format('HH:mm') ===
                        moment(referenceEntry.start).format('HH:mm') &&
                     moment(i.end).format('HH:mm') === moment(referenceEntry.end).format('HH:mm') &&
                     isSimilar(referenceEntry, i as unknown as T)
               )
            );
            break;
         case 'same-day-of-week': {
            const dayOfWeek = moment(referenceEntry.start).weekday();
            setEvents(items.filter(i => moment(i.start).weekday() === dayOfWeek));
            break;
         }
         case 'same-day-in-month': {
            const dayInMonth = moment(referenceEntry.start).date();
            setEvents(items.filter(i => moment(i.start).date() === dayInMonth));
            break;
         }
         case 'start-or-end-of-month':
            setEvents(
               items.filter(i => {
                  const s = moment(i.start);
                  return (
                     s.date() === moment(s).startOf('month').date() ||
                     s.date() === moment(s).endOf('month').date()
                  );
               })
            );
            break;
         default:
            setEvents([]);
      }

      setLoading(false);
   }, [entryModel, referenceEntry, filterCriteria, isSimilar]);

   useEffect(() => {
      setProgress(-1);

      loadAndFilterEvents().then();
   }, [loadAndFilterEvents]);

   const handleOnCancel = async () => {
      if (!events || !onCancel) return;

      await Promise.all(
         events.filter(e => e.isSelected).map(async e => onCancel(e as unknown as T))
      );

      await afterCancelOrDelete?.();
      await onClose();
   };

   const handleOnDelete = async () => {
      if (!events || !onDelete) return;

      await Promise.all(
         events.filter(e => e.isSelected).map(async e => onDelete(e as unknown as T))
      );

      await afterCancelOrDelete?.();
      await onClose();
   };

   return (
      <Modal show={show} onHide={onClose} size="lg">
         <Modal.Header closeButton>
            <Modal.Title>Mehrere Termine absagen/löschen</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            {progress >= 0 && <ProgressBar className="mb-3" animated now={progress} />}
            <Form.Select
               onChange={e => {
                  setFilterCriteria(e.target.value);
               }}
            >
               {SelectOptions.map(o => (
                  <option key={o.value} value={o.value} selected={o.value === filterCriteria}>
                     {o.label}
                  </option>
               ))}
            </Form.Select>
            {loading ? (
               <ContentLoader />
            ) : (
               <div className=" mt-4">
                  <ButtonGroup>
                     <Button
                        variant="link"
                        onClick={() =>
                           setEvents(p =>
                              p?.map(e => ({
                                 ...e,
                                 isSelected: true,
                              }))
                           )
                        }
                     >
                        Alle auswählen
                     </Button>
                     <Button
                        variant="link"
                        onClick={() =>
                           setEvents(p =>
                              p?.map(e => ({
                                 ...e,
                                 isSelected: false,
                              }))
                           )
                        }
                     >
                        Keine auswählen
                     </Button>
                  </ButtonGroup>

                  <ListGroup className="flex-fill">
                     {sortBy(events, 'start')?.map(event => (
                        <ListGroup.Item
                           key={event.id}
                           action
                           variant={event.isSelected ? 'primary' : undefined}
                           className="d-flex align-items-center"
                           onClick={e => {
                              e.preventDefault();
                              setEvents(p => [
                                 ...(p?.filter(o => o.id !== event.id) ?? []),
                                 { ...event, isSelected: !event.isSelected } as PageModel,
                              ]);
                           }}
                        >
                           {event.isSelected ? (
                              <Icon path={mdiCheckboxMarked} size={1} />
                           ) : (
                              <Icon path={mdiCheckboxBlankOutline} size={1} />
                           )}
                           <div className="ms-3 d-flex flex-column">
                              <div>
                                 {formatRange(event.start, event.end, 'full-date-with-time')}
                              </div>
                              {additionalListContent && (
                                 <span className="small fst-italic">
                                    {additionalListContent(event as unknown as T)}
                                 </span>
                              )}
                           </div>
                        </ListGroup.Item>
                     ))}
                  </ListGroup>
               </div>
            )}
         </Modal.Body>
         <Modal.Footer className="bg-light">
            <Button variant="outline-link" onClick={onClose}>
               Schließen
            </Button>
            {onCancel && (
               <Button variant="outline-danger" onClick={handleOnCancel}>
                  Absagen
               </Button>
            )}
            {onDelete && (
               <Button variant="danger" onClick={handleOnDelete}>
                  Löschen
               </Button>
            )}
         </Modal.Footer>
      </Modal>
   );
};
