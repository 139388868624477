import React from 'react';
import Container from 'react-bootstrap/Container';
import { Headline } from '../molecules';

export const ImprintPage = () => (
   <Container className="p-2 imprint">
      <Headline title="Impressum & Datenschutz" />
      <br />
      <p>Angaben gemäß § 5 TMG</p>
      <p>
         Steffen Dumjahn
         <br />
         Veilchenstraße 19
         <br />
         76131 Karlsruhe
         <br />
         Deutschland
      </p>
      <h4>Vertreten durch:</h4>
      <p>Steffen Dumjahn</p>
      <h4>Kontakt:</h4>
      <p>
         Telefon: +49 (0)721 5007238
         <br />
         E-Mail: <a href="mailto:info@coaching-zeitplaner.de">info@coaching-zeitplaner.de</a>
      </p>
      <br />
      <h1>Haftungsausschluss:</h1>
      <h4>Haftung für Inhalte</h4>
      <p>
         Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
         Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
         Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
         allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
         jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
         überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
         Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
         allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
         ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
         von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      <br />
      <h4>Haftung für Links</h4>
      <p>
         Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
         Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
         Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
         Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
         mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
         nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
         ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
         Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
      <br />
      <h4>Urheberrecht</h4>
      <p>
         Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
         dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
         Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
         des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
         privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
         vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
         werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
         Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
         Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>
      <h4>Rechtlicher Hinweis zur Verwendung von Marken und Logos</h4>
      <p>
         Die Informationen in dieser Website werden ohne Rücksicht auf einen eventuellen
         Patentschutz veröffentlicht. Warennamen werden ohne Gewährleistung der freien
         Verwendbarkeit benutzt. Alle Firmennamen, Logos oder Produktbezeichnungen und sonstige
         Angaben, die auf dieser Website verwendet werden, sind möglicherweise als eingetragene
         Marken geschützt. Da es nicht möglich ist, in allen Fällen zeitnah zu ermitteln, ob ein
         Markenschutz besteht, wird das
         <sup>&reg;</sup> Symbol in dieser Website nicht vollständig verwendet. Der Nutzer soll
         bitte davon ausgehen, dass alle gezeigten Markennamen geschützt sind und Eigentum der
         jeweiligen Hersteller sind.
      </p>
      <br /> <br />
      <h1>Datenschutzerklärung</h1>
      <h3>1. Datenschutz auf einen Blick</h3>
      <h4>Allgemeine Hinweise</h4>
      <p>
         Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
         personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten
         sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche
         Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
         Datenschutzerklärung.
      </p>
      <br />
      <h4>Datenerfassung auf unserer Website</h4>
      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <p>
         Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
         Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
      </p>
      <br />
      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>
         Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
         sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
      </p>
      <p>
         Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst.
         Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
         Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
         betreten.
      </p>
      <br />
      <h4>Wofür nutzen wir Ihre Daten?</h4>
      <p>
         Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
         gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
      </p>
      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
      <p>
         Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck
         Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
         Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
         Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
         Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
         Aufsichtsbehörde zu.
      </p>
      <br />
      <br />
      <h3>2. Allgemeine Hinweise und Pflichtinformationen</h3>
      <h4>Datenschutz</h4>
      <p>
         Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
         behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
         Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </p>
      <p>
         Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
         Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können.
         Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
         nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
         Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation
         per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
         Zugriff durch Dritte ist nicht möglich.
      </p>
      <br />
      <h4>Hinweis zur verantwortlichen Stelle</h4>
      <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
      <p>
         Steffen Dumjahn
         <br />
         Veilchenstraße 19
         <br />
         76131 Karlsruhe
         <br />
         Deutschland
      </p>
      <p>
         Telefon: +49 (0)721 5007328
         <br />
         E-Mail: <a href="mailto:info@coaching-zeitplaner.de">info@coaching-zeitplaner.de</a>
      </p>
      <p>
         Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder
         gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen
         Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
      </p>
      <br />
      <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
      <p>
         Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich.
         Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
         formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
         Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <br />
      <h4>SSL- bzw. TLS-Verschlüsselung</h4>
      <p>
         Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
         Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
         senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
         daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem
         Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
         Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
         übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <br />
      <h4>Auskunft, Sperrung, Löschung</h4>
      <p>
         Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
         unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
         Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
         oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
         Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
      </p>
      <br />
      <br />
      <h1>3. Datenerfassung auf unserer Website</h1>
      <h4>Cookies</h4>
      <p>
         Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem
         Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
         nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die
         auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
      </p>
      <p>
         Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden
         nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät
         gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
         nächsten Besuch wiederzuerkennen.
      </p>
      <p>
         Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
         werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle
         oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
         Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalitätdieser Website
         eingeschränkt sein.
      </p>
      <p>
         Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur
         Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion)
         erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
         Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur
         technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies
         (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser
         Datenschutzerklärung gesondert behandelt.
      </p>
      <br />
      <h4>Server-Log-Dateien</h4>
      <p>
         Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
         Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
      </p>
      <ul>
         <li>Browsertyp und Browserversion</li>
         <li>verwendetes Betriebssystem</li>
         <li>Referrer URL</li>
         <li>Hostname des zugreifenden Rechners</li>
         <li>Uhrzeit der Serveranfrage</li>
         <li>IP-Adresse</li>
      </ul>
      <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
      <p>
         Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung
         von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      </p>
      <br />
      <h4>Registrierung auf dieser Website</h4>
      <p>
         Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der Seite
         zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des
         jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der
         Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls
         werden wir die Registrierung ablehnen.
      </p>
      <p>
         Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen
         nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
         informieren.
      </p>
      <p>
         Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer
         Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte Einwilligung
         jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
         Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <p>
         Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf
         unserer Website registriert sind und werden anschließend gelöscht. Gesetzliche
         Aufbewahrungsfristen bleiben unberührt.
      </p>
      <br />
      <h4>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h4>
      <p>
         Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die
         Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich
         sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
         Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
         gestattet. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
         (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um
         dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
      </p>
      <p>
         Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der
         Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
      </p>
      <br />
      <h4>Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und Warenversand</h4>
      <p>
         Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der
         Vertragsabwicklung notwendig ist, etwa an die mit der Lieferung der Ware betrauten
         Unternehmen oder das mit der Zahlungsabwicklung beauftragte Kreditinstitut. Eine
         weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung
         ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche
         Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
      </p>
      <p>
         Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung
         von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      </p>
      <br />
      <h4>Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte</h4>
      <p>
         Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der
         Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte
         Kreditinstitut.
      </p>
      <p>
         Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
         Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne
         ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
      </p>
      <p>
         Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung
         von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      </p>
   </Container>
);
