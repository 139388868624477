import AsyncSelect from 'react-select/async';
import React, { useState } from 'react';
import { MiniProfile } from '../../molecules';
import { User } from '../../types/api';
import { UserModel } from '../../models';

interface Props {
   onSearchTextChange?: (searchText: string) => void;
   onSelect: (user: User) => void;
}

export const UserSearchSelect = (props: Props) => {
   const [selectedUser, setSelectedUser] = useState<User>();

   const loadOptions = async (searchText: string) => {
      props?.onSearchTextChange?.(searchText);

      return UserModel.adminSearch(searchText);
   };

   return (
      <AsyncSelect
         className="flex-fill"
         placeholder="Benutzer auswählen…"
         loadOptions={loadOptions}
         getOptionValue={u => `${u.first_name} ${u.last_name}`}
         formatOptionLabel={u => <MiniProfile user={u} />}
         onChange={user => {
            if (user) {
               props.onSelect(user);
               setSelectedUser(user);
            }
         }}
         isOptionSelected={u => u.id === selectedUser?.id}
         cacheOptions
         defaultOptions
      />
   );
};
