import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateUpdateInfo } from '../../organisms/CreateUpdateInfo';
import { SubmitButton } from '../../forms';
import { upsertBox } from '../../store/actions/adminActions';
import { BoxForm, handleValidate } from '../../organisms';
import { Box } from '../../types/api';
import { BoxModel } from '../../models';

interface Props {
   box: Partial<Box>;
}

export const EditBoxTab = ({ box }: Props) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [logoFile, setLogoFile] = useState<File>();

   const handleSubmitForm = async (values: Partial<Box>) => {
      const r =
         values.id === 0 ? await BoxModel.adminInsert(values) : await BoxModel.adminUpdate(values);

      if (logoFile) await BoxModel.uploadLogo(r.id, logoFile);

      dispatch(upsertBox(r));
      navigate(`/admin/box/${r.id}`, { replace: true });
   };

   return (
      <div>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={box}
            enableReinitialize
            validate={handleValidate}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <BoxForm setLogoFile={setLogoFile} {...formik} />
                  <div className="d-flex flex-row justify-content-between align-items-center">
                     <CreateUpdateInfo hidden={box.id === 0} obj={box} />
                     <SubmitButton formik={formik} className="mx-1">
                        Speichern
                     </SubmitButton>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
};
