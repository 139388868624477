/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

declare type Props = {
   children?: React.ReactNode;
   roles: string[];
};

export const ShowIfRole = ({ children, roles }: Props) => {
   const sessionRoles = useSelector((s: RootState) => s.app.session.roles);

   // User hat min. einer der benötigten Rollen? -> Children anzeigen, sonst nicht
   if (children && sessionRoles.findIndex(r => roles.includes(r.key)) >= 0) return <>{children}</>;
   return null;
};
