import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Formik, FormikErrors } from 'formik';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
   FormFieldError,
   FormRow,
   FormUtils,
   GenericControl,
   SubmitButton,
   TextareaControl,
} from '../forms';
import { ContentLoader } from '../atoms';
import { getSelectStyles, mergeDateAndTime } from '../utils';
import { upsertPersonalTraining } from '../store/actions/ptActions';
import { RootState } from '../store';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { PTClassModel } from '../models';
import { PTClass } from '../types/api';
import { RoomSelect } from '../forms/controls';
import { DuplicateDialog } from './DuplicateDialog';
import { CancelOrDeleteMultipleEventsDialog } from './CancelOrDeleteMultipleEventsDialog';
import { useCache } from '../hooks';

declare type Props = {
   show: boolean;
   onClose: () => void;
   ptClass: Partial<PTClass>;
   afterSave?: (ptclass: PTClass) => Promise<void> | void;
};

interface FormModel {
   client_id: number;
   room_id: number;
   start_date: Date;
   start_time: string;
   end_time: string;
   canceled: boolean;
   comment: string;
}

export const EditPTClassDialog = ({ show, ptClass, onClose, afterSave }: Props) => {
   const dispatch = useDispatch();
   const { rooms } = useCache();
   const ptClients = useSelector((s: RootState) => s.pt.clients);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
   const [showCancelOrDeleteDialog, setShowCancelOrDeleteDialog] = useState(false);
   const [formData, setFormData] = useState<Partial<FormModel>>();

   useEffect(() => {
      (async () => {
         setFormData({
            client_id: ptClass?.client_id,
            room_id: ptClass?.room_id,
            start_date: ptClass?.start ?? new Date(),
            start_time: ptClass?.start
               ? moment(mergeDateAndTime(new Date(), ptClass?.start)).format('HH:mm')
               : undefined,
            end_time: ptClass?.end
               ? moment(mergeDateAndTime(new Date(), ptClass?.end)).format('HH:mm')
               : undefined,
            canceled: ptClass?.canceled ?? false,
            comment: ptClass?.comment,
         } as Partial<FormModel>);
      })();
   }, [ptClass]);

   const handleSubmitForm = async (values: Partial<FormModel>) => {
      // umwandeln in eine Klasse
      let pt = {
         ...ptClass,
         client_id: values.client_id,
         room_id: values.room_id,
         start: mergeDateAndTime(values.start_date, moment(values.start_time, 'HH:mm').toDate()),
         end: mergeDateAndTime(values.start_date, moment(values.end_time, 'HH:mm').toDate()),
         canceled: values.canceled,
         comment: values.comment,
      } as PTClass;

      if (pt.id === 0) {
         pt = await PTClassModel.insert(pt);
      } else {
         pt = await PTClassModel.update(pt);
      }

      dispatch(upsertPersonalTraining(pt));

      await afterSave?.(pt);

      onClose();
   };

   const handleDelete = async (values: Partial<PTClass>) => {
      values.canceled = true;
      const updatedClass = await PTClassModel.update(values);

      dispatch(upsertPersonalTraining(updatedClass));

      setShowDeleteDialog(false);
      onClose();
      toast.success('Das PT wurde erfolgreich abgesagt.');
   };

   const handleRestore = async () => {
      ptClass.canceled = false;
      ptClass.cancel_reason = null;
      const updatedClass = await PTClassModel.update(ptClass);

      dispatch(upsertPersonalTraining(updatedClass));
      setShowDeleteDialog(false);
      toast.success('Das PT wurde erfolgreich wiederhergestellt.');
   };

   const isSimilar = useCallback(
      (a: PTClass, b: PTClass) => a.room_id === b.room_id && a.client_id === b.client_id,
      []
   );

   return (
      <>
         <Modal
            show={show && !showDeleteDialog && !showCancelOrDeleteDialog}
            onHide={onClose}
            size="lg"
         >
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={formData ?? {}}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<FormModel>> = {};

                  if (!values.client_id) errors.client_id = 'Bitte wähle einen Kunden aus.';

                  if (!values.room_id) errors.room_id = 'Bitte wähle einen Raum aus.';

                  if (!values.start_date) errors.start_date = 'Bitte gib ein Datum an.';

                  if (!values.start_time) errors.start_time = 'Bitte gib eine Startzeit an.';

                  if (!values.end_time) errors.end_time = 'Bitte geb eine Endzeit an.';

                  // Differenz zwischen Start und Ende muss min. 1 Sekunde betragen (Wegen ungenauen Millisekunden)
                  if (
                     values.start_time &&
                     values.end_time &&
                     moment(values.start_time, 'HH:mm').isSameOrAfter(
                        moment(values.end_time, 'HH:mm'),
                        'minute'
                     )
                  )
                     errors.end_time = 'Das Ende muss nach dem Start sein.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title className="d-flex align-items-center">
                           {ptClass.id === 0
                              ? 'Neues Personal Training'
                              : 'Personal Training bearbeiten'}
                        </Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        {!formData ? (
                           <ContentLoader />
                        ) : (
                           <>
                              <Row>
                                 <Col sm={12} md={6}>
                                    <GenericControl
                                       formik={formik}
                                       name="client_id"
                                       label="Kunde"
                                       description="Der Kunde, mit dem das Personal Training durchgeführt wird."
                                    >
                                       <Select
                                          key={formik.values.client_id}
                                          options={ptClients}
                                          styles={getSelectStyles(
                                             FormUtils.isInvalid(formik, 'client_id')
                                          )}
                                          getOptionValue={c => c.name}
                                          getOptionLabel={c => c.name}
                                          placeholder="Kunde auswählen…"
                                          defaultValue={ptClients.find(
                                             c => c.id === formik.values.client_id
                                          )}
                                          onChange={value =>
                                             formik.setFieldValue('client_id', value?.id)
                                          }
                                          onBlur={() => formik.handleBlur('client_id')}
                                          isDisabled={formik.isSubmitting || ptClass.canceled}
                                       />
                                    </GenericControl>
                                 </Col>
                                 <Col sm={12} md={6}>
                                    <GenericControl
                                       formik={formik}
                                       name="room_id"
                                       label="Raum"
                                       description="Der Raum wo das Personal Training stattfindet."
                                    >
                                       <RoomSelect
                                          formik={formik}
                                          name="room_id"
                                          disabled={ptClass.canceled}
                                       />
                                    </GenericControl>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col sm={12} lg={6}>
                                    <GenericControl
                                       formik={formik}
                                       name="start_date"
                                       label="Datum"
                                       description="Datum, an dem das Personal Training stattfindet."
                                    >
                                       <div className="d-flex justify-content-center justify-content-lg-start">
                                          <DatePicker
                                             selected={formik.values.start_date}
                                             onChange={date =>
                                                formik.setFieldValue('start_date', date)
                                             }
                                             onBlur={() => formik.handleBlur('start_date')}
                                             inline
                                             calendarStartDay={1}
                                             disabled={formik.isSubmitting || ptClass.canceled}
                                             /* Workaround für disabled */
                                             minDate={
                                                formik.isSubmitting
                                                   ? formik.values.start_date
                                                   : undefined
                                             }
                                             maxDate={
                                                formik.isSubmitting
                                                   ? formik.values.start_date
                                                   : undefined
                                             }
                                          />
                                       </div>
                                    </GenericControl>
                                 </Col>
                                 <Col sm={12} lg={6}>
                                    <FormRow
                                       label="Zeitraum"
                                       description="Start und Ende des Personal Trainings."
                                    >
                                       <Row>
                                          <Col md={6} lg={12}>
                                             <Form.Group className="mb-3">
                                                <InputGroup>
                                                   <InputGroup.Text>Start</InputGroup.Text>
                                                   <Form.Control
                                                      type="time"
                                                      name="start_time"
                                                      className="text-end"
                                                      value={formik.values.start_time}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      isInvalid={FormUtils.isInvalid(
                                                         formik,
                                                         'start_time'
                                                      )}
                                                      disabled={formik.isSubmitting}
                                                   />
                                                </InputGroup>
                                                <FormFieldError formik={formik} name="start_time" />
                                             </Form.Group>
                                          </Col>
                                          <Col xs={6} lg={12}>
                                             <Form.Group className="mb-3">
                                                <InputGroup>
                                                   <InputGroup.Text>Ende</InputGroup.Text>
                                                   <Form.Control
                                                      type="time"
                                                      name="end_time"
                                                      className="text-end"
                                                      value={formik.values.end_time}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      isInvalid={FormUtils.isInvalid(
                                                         formik,
                                                         'end_time'
                                                      )}
                                                      disabled={formik.isSubmitting}
                                                   />
                                                </InputGroup>
                                                <FormFieldError formik={formik} name="end_time" />
                                             </Form.Group>
                                          </Col>
                                       </Row>
                                    </FormRow>
                                 </Col>
                              </Row>
                              <TextareaControl
                                 formik={formik}
                                 name="comment"
                                 label="Kommentar"
                                 placeholder="Kommentar…"
                              />
                           </>
                        )}
                     </Modal.Body>
                     <Modal.Footer className="bg-light justify-content-between">
                        <Dropdown autoClose="outside">
                           <Dropdown.Toggle variant="outline-secondary">Aktionen</Dropdown.Toggle>
                           <Dropdown.Menu>
                              <Dropdown.Item
                                 onClick={handleRestore}
                                 hidden={!ptClass.canceled || ptClass.id === 0}
                                 disabled={formik.isSubmitting}
                              >
                                 PT wiederherstellen
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() => setShowDeleteDialog(true)}
                                 hidden={ptClass.canceled || ptClass.id === 0}
                                 disabled={formik.isSubmitting}
                              >
                                 PT absagen
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() => setShowDuplicateDialog(true)}
                                 hidden={ptClass.id === 0}
                                 disabled={formik.isSubmitting}
                              >
                                 PT-Termin duplizieren
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() => setShowCancelOrDeleteDialog(true)}
                                 hidden={ptClass.id === 0}
                                 disabled={formik.isSubmitting}
                              >
                                 Ähnliche Termine absagen/löschen
                              </Dropdown.Item>
                           </Dropdown.Menu>
                        </Dropdown>
                        <span>
                           <CreateUpdateInfo hidden={ptClass.id === 0} obj={ptClass} />
                           <Button
                              variant="outline-link"
                              onClick={onClose}
                              disabled={formik.isSubmitting}
                           >
                              Schließen
                           </Button>
                           <SubmitButton formik={formik}>Speichern</SubmitButton>
                        </span>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
         <Modal
            show={showDeleteDialog}
            onHide={() => setShowDeleteDialog(false)}
            centered={isMobile}
         >
            <Formik
               onSubmit={handleDelete}
               initialValues={ptClass}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<PTClass>> = {};

                  if (!values.cancel_reason) errors.cancel_reason = 'Bitte gib einen Grund an.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>PT-Klasse absagen</Modal.Title>
                     </Modal.Header>

                     <Modal.Body>
                        <TextareaControl
                           formik={formik}
                           name="cancel_reason"
                           placeholder="Grund der Absage…"
                        />
                     </Modal.Body>

                     <Modal.Footer className="bg-light">
                        <Button
                           variant="outline-link"
                           onClick={() => setShowDeleteDialog(false)}
                           disabled={formik.isSubmitting}
                        >
                           Abbrechen
                        </Button>
                        <SubmitButton formik={formik} variant="danger">
                           Absagen
                        </SubmitButton>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
         <DuplicateDialog
            entry={ptClass as PTClass}
            entryModel={PTClassModel}
            show={showDuplicateDialog}
            onClose={async () => {
               setShowDuplicateDialog(false);
               onClose();
            }}
         />
         <CancelOrDeleteMultipleEventsDialog
            referenceEntry={ptClass as PTClass}
            entryModel={PTClassModel}
            show={showCancelOrDeleteDialog}
            onClose={() => setShowCancelOrDeleteDialog(false)}
            isSimilar={isSimilar}
            additionalListContent={e => (
               <>
                  <span>{ptClients.find(c => c.id === e.client_id)?.name}</span>
                  <span className="mx-1">·</span>
                  <span>{rooms.find(r => r.id === e.room_id)?.name}</span>
               </>
            )}
            onDelete={e => PTClassModel.delete(e)}
            afterCancelOrDelete={() => onClose()}
         />
      </>
   );
};
