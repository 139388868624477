import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash';
import { Headline } from '../../molecules';
import { ItemList } from '../../organisms/ItemList';
import { RepresentationRequest } from '../../types/api';
import { RepresentationRequestModel } from '../../models';
import { RepresentationRequestItem } from '../../organisms/RepresentationRequestItem';

export const RepresentationRequestOverviewPage = () => {
   const navigate = useNavigate();
   const [requests, setRequests] = useState<RepresentationRequest[]>();

   useEffect(() => {
      (async () => {
         setRequests(await RepresentationRequestModel.listOverview());
      })();
   }, []);

   return (
      <Container className="py-2">
         <Headline title="Vertretungsanfragen" />
         <Container>
            <Row>
               <Col sm={12} xl={6}>
                  <ItemList
                     title="Offene Anfragen"
                     emptyMessage="Es existieren keine offenen Anfragen"
                     items={requests?.filter(r => r.status === 'open')}
                     onClick={item => navigate(`/representationRequest/${item.id}`)}
                  >
                     {({ item }) => <RepresentationRequestItem request={item} />}
                  </ItemList>
               </Col>
               <Col sm={12} xl={6}>
                  <ItemList
                     title="Geschlossene Anfragen"
                     emptyMessage="Es existieren keine abgeschlossenen Anfragen"
                     items={orderBy(
                        requests?.filter(r => r.status === 'closed'),
                        'date',
                        'desc'
                     )}
                     onClick={item => navigate(`/representationRequest/${item.id}`)}
                  >
                     {({ item }) => <RepresentationRequestItem request={item} />}
                  </ItemList>
               </Col>
            </Row>
         </Container>
      </Container>
   );
};
