import React from 'react';
import { MultiSelect } from './MultiSelect';
import { useCache } from '../../hooks';

interface Props {
   values?: number[];
   onChange: (userIds: number[]) => void;
   isDisabled?: boolean;
}

export const ClassTypeMultiSelect = ({ values = [], onChange, isDisabled }: Props) => {
   const { classTypes } = useCache();

   return (
      <MultiSelect
         options={classTypes.map(ct => ({ label: ct.name, value: `${ct.id}` }))}
         values={classTypes
            .filter(ct => values.includes(ct.id))
            .map(ct => ({ label: ct.name, value: `${ct.id}` }))}
         onChange={selectedValues => onChange(selectedValues.map(v => Number(v.value)))}
         isDisabled={isDisabled}
      />
   );
};
