import React, { useCallback, useMemo } from 'react';
import { mdiAccountMultiple, mdiChartLine } from '@mdi/js';
import { useParams } from 'react-router-dom';
import { ManageEmployeeStatsTab } from './ManageEmployeeStatsTab';
import { MenuItem, PageWithSideMenu } from '../../templates';
import { useCache } from '../../hooks';
import { ManageEmployeeListTab } from './ManageEmployeeListTab';

export const ManageEmployeesPage = () => {
   const cachedData = useCache();
   const params = useParams();

   const menuItems: MenuItem[] = useMemo(
      () => [
         {
            title: 'Coaches',
            key: 'list',
            icon: mdiAccountMultiple,
            badgeValue: cachedData.users.length,
            badgeType: 'default',
            component: () => <ManageEmployeeListTab />,
         },
         {
            title: 'Statistiken',
            key: 'stats',
            icon: mdiChartLine,
            component: () => <ManageEmployeeStatsTab />,
         },
      ],

      [cachedData.users.length]
   );

   const handleBuildLink = useCallback(
      (tab: string) => {
         if (tab === 'openRequests' && params.id) return `/employees/${tab}/${params.id}`;
         return `/manage/employees/${tab}`;
      },
      [params.id]
   );

   return (
      <PageWithSideMenu
         breakpoint="lg"
         menuItems={menuItems}
         selectedMenuItem={params.tab}
         onBuildLink={handleBuildLink}
      />
   );
};
