import React, { memo, useState } from 'react';
import { Button, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InlineLoader } from '../atoms';

export type LoadingButtonProps = ButtonProps & {
   /**
    * When `true` then button will be disabled and a loading spinner is appended to its content.
    */
   isLoading?: boolean;
   /**
    * When `true` the content of the button will only be the loader when loading. Otherwise the loader is appended
    * to the content. Default is `false`.
    */
   hideContentWhenLoading?: boolean;
   /**
    * Tooltip text to be shown when hovering the button.
    */
   tooltip?: string;
   /**
    * The position of the tooltip relative to the button. The default is `bottom`.
    */
   tooltipPlacement?:
      | 'auto-start'
      | 'auto'
      | 'auto-end'
      | 'top-start'
      | 'top'
      | 'top-end'
      | 'right-start'
      | 'right'
      | 'right-end'
      | 'bottom-end'
      | 'bottom'
      | 'bottom-start'
      | 'left-end'
      | 'left'
      | 'left-start';
};

/**
 * Creates a button with an optional loading spinner.
 * If {@link LoadingButtonProps.isLoading} is set to `true` the button is disabled and
 * a loading spinner is appended to its content.
 */
export const LoadingButton = memo(
   ({
      variant = 'primary',
      children,
      disabled,
      isLoading = false,
      hideContentWhenLoading = false,
      tooltip,
      tooltipPlacement = 'bottom',
      onClick,
      ...props
   }: LoadingButtonProps) => {
      const [isExecuting, setExecuting] = useState(false);

      return (
         <OverlayTrigger
            trigger={!tooltip ? [] : ['hover', 'focus']}
            placement={tooltipPlacement}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
         >
            <Button
               {...props}
               variant={variant}
               disabled={disabled || isLoading || isExecuting}
               onClick={async e => {
                  setExecuting(true);
                  try {
                     await onClick?.(e);
                  } finally {
                     setExecuting(false);
                  }
               }}
            >
               <InlineLoader
                  show={isLoading || isExecuting}
                  className={!hideContentWhenLoading ? 'me-1' : ''}
               />
               {((!isLoading && !isExecuting) || !hideContentWhenLoading) && children}
            </Button>
         </OverlayTrigger>
      );
   }
);
