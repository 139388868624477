import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Formik, FormikErrors } from 'formik';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { mdiLinkVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import { CanceledCoachingClassAlert, LoadingButton, MiniProfile, ShowIfRole } from '../molecules';
import { RootState } from '../store';
import { ContentLoader } from '../atoms';
import { getSelectStyles, mergeDateAndTime } from '../utils';
import { removeCoachingClass, upsertCoachingClass } from '../store/actions/appActions';
import { removeScheduleEntryInWeek } from '../store/actions/scheduleActions';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { CancelRestoreCoachingClassDialog } from './CancelRestoreCoachingClassDialog';
import {
   CoachingClass,
   ROLE_ACCOUNTANT,
   ROLE_BOX_OWNER,
   ROLE_COACH,
   ROLE_SCHEDULER,
} from '../types/api';
import { CoachingClassModel } from '../models';
import { useCache } from '../hooks';
import { RoomSelect, UserSelect } from '../forms/controls';
import {
   FormFieldError,
   FormRow,
   FormUtils,
   GenericControl,
   SubmitButton,
   TextareaControl,
} from '../forms';

declare type Props = {
   show: boolean;
   onClose: () => void;
   coachingClass?: Partial<CoachingClass>;
   afterSave?: (cc: CoachingClass) => Promise<void> | void;
   afterDelete?: (cc: Partial<CoachingClass>) => Promise<void> | void;
};

interface FormModel {
   type_id: number;
   room_id: number;
   start_date: Date;
   start_time: string;
   end_time: string;
   canceled: boolean;
   coach_id: number;
   coach_second_id: number;
   coach_other: number[];
   comment: string;
}

export const EditClassDialog = ({
   show,
   coachingClass,
   onClose,
   afterSave,
   afterDelete,
}: Props) => {
   const dispatch = useDispatch();
   const sessionRoles = useSelector((s: RootState) => s.app.session.roles);
   const cachedData = useCache();
   const [isDeleting, setDeleting] = useState(false);
   const [formData, setFormData] = useState<Partial<FormModel>>();
   const [readOnlyMode, setReadOnlyMode] = useState(false);
   const [fullEditMode, setFullEditMode] = useState(false);
   const [classIsFinished, setClassIsFinished] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [showCancelRestoreDialog, setShowCancelRestoreDialog] = useState(false);

   useEffect(() => {
      (async () => {
         setFormData({
            type_id: coachingClass?.type_id,
            room_id: coachingClass?.room_id,
            start_date: coachingClass?.start ?? new Date(),
            start_time: coachingClass?.start
               ? moment(mergeDateAndTime(new Date(), coachingClass?.start)).format('HH:mm')
               : undefined,
            end_time: coachingClass?.end
               ? moment(mergeDateAndTime(new Date(), coachingClass?.end)).format('HH:mm')
               : undefined,
            canceled: coachingClass?.canceled ?? false,
            coach_id: coachingClass?.coach_id,
            coach_second_id: coachingClass?.coach_second_id,
            coach_other: coachingClass?.coach_other ?? [],
            comment: coachingClass?.comment,
         } as Partial<FormModel>);
      })();
   }, [coachingClass]);

   useEffect(() => {
      setReadOnlyMode(!sessionRoles.find(r => r.key === ROLE_COACH));
      setFullEditMode(
         !!sessionRoles.find(r => r.key === ROLE_SCHEDULER || r.key === ROLE_BOX_OWNER)
      );
      setClassIsFinished(!!coachingClass?.end && coachingClass.end.getTime() <= Date.now());
   }, [sessionRoles, coachingClass]);

   const handleSubmitForm = async (values: Partial<FormModel>) => {
      // umwandeln in eine Klasse
      let cc = {
         ...coachingClass,
         type_id: values.type_id,
         room_id: values.room_id,
         start: mergeDateAndTime(values.start_date, moment(values.start_time, 'HH:mm').toDate()),
         end: mergeDateAndTime(values.start_date, moment(values.end_time, 'HH:mm').toDate()),
         canceled: values.canceled,
         comment: values.comment,
         coach_id: values.coach_id,
         coach_second_id: values.coach_second_id,
         coach_other: [
            ...(coachingClass?.coach_other ?? []).filter(chc => values.coach_other?.includes(chc)),
            ...(values?.coach_other ?? []).filter(
               co => !(coachingClass?.coach_other ?? []).includes(co)
            ),
         ],
      } as CoachingClass;

      if (cc.id === 0) {
         cc = await CoachingClassModel.insert(cc);
      } else {
         cc = await CoachingClassModel.update(cc);
      }

      dispatch(upsertCoachingClass(cc));

      if (afterSave) await afterSave(cc);

      onClose();
   };

   const handleDelete = async () => {
      if (!coachingClass) return;

      setDeleting(true);
      try {
         await CoachingClassModel.delete(coachingClass);

         dispatch(removeCoachingClass(coachingClass));
         dispatch(
            removeScheduleEntryInWeek(moment(coachingClass.start), {
               id: coachingClass.id ?? 0,
               type: 'CoachingClass',
            })
         );

         await afterDelete?.(coachingClass);
         onClose();
      } catch (err) {
         /* ignore */
      }

      setDeleting(false);
      setShowDeleteDialog(false);
   };

   const disableCoachesFields = (): boolean => {
      // Admins und Buchhalter dürfen jederzeit die Coaches bearbeiten
      if (fullEditMode || sessionRoles.find(r => r.key === ROLE_ACCOUNTANT)) return false;

      return classIsFinished || readOnlyMode;
   };

   return (
      <>
         <Modal show={show} onHide={onClose} size="lg">
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={formData ?? {}}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<FormModel>> = {};

                  if (!values.type_id) errors.type_id = 'Bitte wähle ein Kurstyp aus.';

                  if (!values.room_id) errors.room_id = 'Bitte wähle einen Raum aus.';

                  if (!values.start_date) errors.start_date = 'Bitte gib eine Datum an.';

                  if (!values.start_time) errors.start_time = 'Bitte gib eine Startzeit an.';

                  if (!values.end_time) errors.end_time = 'Bitte gib eine Endzeit an.';

                  // Differenz zwischen Start und Ende muss min. 1 Sekunde betragen (Wegen ungenauen Millisekunden)
                  if (
                     values.start_time &&
                     values.end_time &&
                     moment(values.start_time, 'HH:mm').isSameOrAfter(
                        moment(values.end_time, 'HH:mm'),
                        'minute'
                     )
                  )
                     errors.end_time = 'Das Ende muss nach dem Start sein.';
                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title className="d-flex align-items-center">
                           {coachingClass?.id === 0 ? 'Neue Klasse' : 'Klasse bearbeiten'}
                           {coachingClass?.id !== 0 && (
                              <Button
                                 className="ms-1 d-flex align-items-center"
                                 variant="link"
                                 size="sm"
                                 onClick={async () => {
                                    await navigator.clipboard.writeText(
                                       `${window.location.origin}/event/${coachingClass?.id}`
                                    );
                                    toast.success('Der Link wurde in die Zwischenablage kopiert.');
                                 }}
                              >
                                 <Icon path={mdiLinkVariant} size={0.75} />
                              </Button>
                           )}
                        </Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        {!formData ? (
                           <ContentLoader />
                        ) : (
                           <>
                              {!!coachingClass?.id && (
                                 <Row>
                                    <Col>
                                       <CanceledCoachingClassAlert
                                          coachingClass={coachingClass ?? {}}
                                       />
                                       <Button
                                          variant={
                                             coachingClass?.canceled
                                                ? 'outline-success'
                                                : 'outline-danger'
                                          }
                                          className="mb-2"
                                          onClick={() => setShowCancelRestoreDialog(true)}
                                       >
                                          {coachingClass?.canceled
                                             ? 'Reaktivieren'
                                             : 'Klasse absagen'}
                                       </Button>
                                    </Col>
                                 </Row>
                              )}
                              <Row>
                                 <Col sm={12} lg={6}>
                                    <GenericControl
                                       label="Typ"
                                       formik={formik}
                                       name="type_id"
                                       description="Der Typ der Klassen."
                                    >
                                       <Select
                                          key={formik.values.type_id}
                                          options={cachedData.classTypes}
                                          styles={getSelectStyles(
                                             FormUtils.isInvalid(formik, 'type_id')
                                          )}
                                          getOptionValue={c => c.name}
                                          getOptionLabel={c => c.name}
                                          placeholder="Kurs-Typ auswählen…"
                                          defaultValue={cachedData.classTypes.find(
                                             t => t.id === formik.values.type_id
                                          )}
                                          onChange={value => {
                                             formik.setFieldValue('type_id', value?.id);
                                             if (
                                                !formik.values.room_id &&
                                                !!value?.preferred_room_id
                                             )
                                                formik.setFieldValue(
                                                   'room_id',
                                                   value?.preferred_room_id
                                                );
                                          }}
                                          onBlur={() => formik.handleBlur('type_id')}
                                          isDisabled={formik.isSubmitting || !fullEditMode}
                                       />
                                    </GenericControl>
                                 </Col>
                                 <Col sm={12} lg={6}>
                                    <GenericControl
                                       label="Raum"
                                       formik={formik}
                                       name="room_id"
                                       description="Der Raum wo die Klasse stattfindet."
                                    >
                                       <RoomSelect
                                          formik={formik}
                                          name="room_id"
                                          disabled={!fullEditMode}
                                       />
                                    </GenericControl>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col sm={12} lg={6}>
                                    <GenericControl
                                       label="Datum"
                                       formik={formik}
                                       name="start_date"
                                       description="Datum, an dem die Klasse stattfindet."
                                    >
                                       <div className="d-flex justify-content-center justify-content-lg-start">
                                          <DatePicker
                                             selected={formik.values.start_date}
                                             onChange={date =>
                                                formik.setFieldValue('start_date', date)
                                             }
                                             onBlur={() => formik.handleBlur('start_date')}
                                             inline
                                             calendarStartDay={1}
                                             disabled={formik.isSubmitting || !fullEditMode}
                                             /* Workaround für disabled */
                                             minDate={
                                                formik.isSubmitting || !fullEditMode
                                                   ? formik.values.start_date
                                                   : undefined
                                             }
                                             maxDate={
                                                formik.isSubmitting || !fullEditMode
                                                   ? formik.values.start_date
                                                   : undefined
                                             }
                                          />
                                       </div>
                                    </GenericControl>
                                 </Col>
                                 <Col sm={12} lg={6}>
                                    <FormRow
                                       label="Zeitraum"
                                       description="Start und Ende der Klasse."
                                    >
                                       <Row>
                                          <Col xs={6} lg={12}>
                                             <Form.Group className="mb-3">
                                                <InputGroup>
                                                   <InputGroup.Text>Start</InputGroup.Text>
                                                   <Form.Control
                                                      type="time"
                                                      name="start_time"
                                                      className="text-end"
                                                      value={formik.values.start_time}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      isInvalid={FormUtils.isInvalid(
                                                         formik,
                                                         'start_time'
                                                      )}
                                                      disabled={
                                                         formik.isSubmitting || !fullEditMode
                                                      }
                                                   />
                                                </InputGroup>
                                                <FormFieldError formik={formik} name="start_time" />
                                             </Form.Group>
                                          </Col>
                                          <Col xs={6} lg={12}>
                                             <Form.Group className="mb-3">
                                                <InputGroup>
                                                   <InputGroup.Text>Ende</InputGroup.Text>
                                                   <Form.Control
                                                      type="time"
                                                      name="end_time"
                                                      className="text-end"
                                                      value={formik.values.end_time}
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      isInvalid={FormUtils.isInvalid(
                                                         formik,
                                                         'end_time'
                                                      )}
                                                      disabled={
                                                         formik.isSubmitting || !fullEditMode
                                                      }
                                                   />
                                                </InputGroup>
                                                <FormFieldError formik={formik} name="end_time" />
                                             </Form.Group>
                                          </Col>
                                       </Row>
                                    </FormRow>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col sm={12} md={6}>
                                    <GenericControl
                                       label="1st Coach"
                                       formik={formik}
                                       name="coach_id"
                                       description="Der erste Coach für die Klasse."
                                    >
                                       <UserSelect
                                          key={formik.values.coach_id}
                                          formik={formik}
                                          name="coach_id"
                                          isOptionDisabled={c =>
                                             c.id === formik.values.coach_second_id ||
                                             !!formik.values.coach_other?.includes(c.id)
                                          }
                                          placeholder="1st Coach…"
                                          disabled={disableCoachesFields()}
                                          isClearable
                                       />
                                    </GenericControl>
                                 </Col>
                                 <Col sm={12} md={6}>
                                    <GenericControl
                                       label="2nd Coach"
                                       formik={formik}
                                       name="coach_second_id"
                                       description="Der zweite Coach für die Klasse."
                                    >
                                       <UserSelect
                                          key={formik.values.coach_second_id}
                                          formik={formik}
                                          name="coach_second_id"
                                          isOptionDisabled={c =>
                                             c.id === formik.values.coach_id ||
                                             !!formik.values.coach_other?.includes(c.id)
                                          }
                                          placeholder="2nd Coach…"
                                          disabled={disableCoachesFields()}
                                          isClearable
                                       />
                                    </GenericControl>
                                 </Col>
                              </Row>
                              <GenericControl
                                 label="Weitere Coaches"
                                 formik={formik}
                                 name="coach_other"
                                 description="Weitere Coaches oder Personen können hier hinterlegt werden."
                              >
                                 <Select
                                    options={cachedData.users.filter(u => u.enabled)}
                                    styles={getSelectStyles(
                                       FormUtils.isInvalid(formik, 'coach_other')
                                    )}
                                    isMulti
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={false}
                                    getOptionValue={c => `${c.first_name} ${c.last_name}`}
                                    formatOptionLabel={u => <MiniProfile user={u} />}
                                    isOptionDisabled={c =>
                                       c.id === formik.values.coach_id ||
                                       c.id === formik.values.coach_second_id
                                    }
                                    placeholder="Weitere Coaches…"
                                    defaultValue={cachedData.users.filter(c =>
                                       formik.values.coach_other?.includes(c.id)
                                    )}
                                    onChange={selectedValues =>
                                       formik.setFieldValue(
                                          'coach_other',
                                          selectedValues.map(v => v.id)
                                       )
                                    }
                                    onBlur={() => formik.handleBlur('coach_other')}
                                    isDisabled={formik.isSubmitting || disableCoachesFields()}
                                 />
                              </GenericControl>
                              <TextareaControl
                                 label="Kommentar"
                                 formik={formik}
                                 name="comment"
                                 disabled={!fullEditMode && (classIsFinished || readOnlyMode)}
                              />
                           </>
                        )}
                     </Modal.Body>
                     <Modal.Footer className="bg-light justify-content-between">
                        <span>
                           <ShowIfRole roles={[ROLE_SCHEDULER, ROLE_BOX_OWNER]}>
                              <LoadingButton
                                 variant="danger"
                                 onClick={() => setShowDeleteDialog(true)}
                                 hidden={coachingClass?.id === 0}
                                 disabled={formik.isSubmitting}
                                 isLoading={isDeleting}
                              >
                                 Löschen
                              </LoadingButton>
                           </ShowIfRole>
                        </span>
                        <span>
                           <CreateUpdateInfo hidden={coachingClass?.id === 0} obj={coachingClass} />
                           <Button
                              variant="outline-link"
                              onClick={onClose}
                              disabled={formik.isSubmitting}
                           >
                              Schließen
                           </Button>
                           <SubmitButton formik={formik}>Speichern</SubmitButton>
                        </span>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
         <CancelRestoreCoachingClassDialog
            show={showCancelRestoreDialog}
            coachingClass={coachingClass}
            onClose={() => setShowCancelRestoreDialog(false)}
            afterSave={cc => {
               dispatch(upsertCoachingClass(cc));
               onClose();
            }}
         />
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <span>Möchtest Du die Klasse wirklich löschen?</span>
         </ConfirmDeleteDialog>
      </>
   );
};
