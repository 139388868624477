import { AnyAction } from 'redux';
import { PTClass, PTClient, PTService } from '../../types/api';

export const SET_PERSONAL_TRAININGS = 'SET_PERSONAL_TRAININGS';
export const UPSERT_PERSONAL_TRAINING = 'UPSERT_PERSONAL_TRAINING';
export const REMOVE_PERSONAL_TRAINING = 'REMOVE_PERSONAL_TRAINING';
export const SET_CLIENTS = 'SET_CLIENTS';
export const UPSERT_CLIENT = 'UPSERT_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const SET_SERVICES = 'SET_SERVICES';
export const UPSERT_SERVICE = 'UPSERT_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';

interface IState {
   personalTrainings: PTClass[];
   clients: PTClient[];
   services: PTService[];
}

const initialState: IState = {
   personalTrainings: [],
   clients: [],
   services: [],
};

const PTReducer = (state = initialState, action: AnyAction = { type: '' }): IState => {
   switch (action.type) {
      case SET_PERSONAL_TRAININGS:
         return {
            ...state,
            personalTrainings: action.data,
         };
      case UPSERT_PERSONAL_TRAINING:
         return {
            ...state,
            personalTrainings: [
               ...state.personalTrainings.filter(c => c.id !== action.data.id),
               action.data,
            ],
         };
      case REMOVE_PERSONAL_TRAINING:
         return {
            ...state,
            personalTrainings: state.personalTrainings.filter(c => c.id !== action.data.id),
         };
      case SET_CLIENTS:
         return {
            ...state,
            clients: action.data,
         };
      case UPSERT_CLIENT:
         return {
            ...state,
            clients: [...state.clients.filter(c => c.id !== action.data.id), action.data],
         };
      case REMOVE_CLIENT:
         return {
            ...state,
            clients: [...state.clients.filter(c => c.id !== action.data.id)],
         };
      case SET_SERVICES:
         return {
            ...state,
            services: action.data,
         };
      case UPSERT_SERVICE:
         return {
            ...state,
            services: [...state.services.filter(c => c.id !== action.data.id), action.data],
         };
      case REMOVE_SERVICE:
         return {
            ...state,
            services: [...state.services.filter(c => c.id !== action.data.id)],
         };
      default:
         return state;
   }
};

export default PTReducer;
