import React from 'react';
import { MiniProfile } from '../../molecules';
import { MultiSelect } from './MultiSelect';
import { useCache } from '../../hooks';
import { User } from '../../types/api';

interface Props {
   className?: string;
   values?: number[];
   filter?: (u: User) => boolean;
   onChange: (userIds: number[]) => void;
   onBlur?: () => void;
   isDisabled?: boolean;
   showDisabledUsers?: boolean;
   allowAll?: boolean;
}

export const UserMultiSelect = ({
   values = [],
   filter = () => true,
   onChange,
   onBlur,
   isDisabled,
   className,
   showDisabledUsers = false,
   allowAll,
}: Props) => {
   const { users } = useCache();

   return (
      <MultiSelect
         className={className}
         options={users
            .filter(c => showDisabledUsers || c.enabled)
            .filter(filter)
            .map(u => ({ label: `${u.first_name} ${u.last_name}`, value: `${u.id}` }))}
         onBlur={onBlur}
         values={users
            .filter(c => values.includes(c.id))
            .filter(c => showDisabledUsers || c.enabled)
            .filter(filter)
            .map(u => ({ label: `${u.first_name} ${u.last_name}`, value: `${u.id}` }))}
         onChange={selectedValues => onChange(selectedValues.map(v => Number(v.value)))}
         formatOptionLabel={v => <MiniProfile user={Number(v.value)} />}
         isDisabled={isDisabled}
         allowAll={allowAll}
      />
   );
};
