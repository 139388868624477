import Select from 'react-select';
import React, { useRef } from 'react';
import { getSelectStyles } from '../../utils';

interface OptionType {
   value: string;
   label: string;
}

interface Props {
   className?: string;
   options: OptionType[];
   values: OptionType[];
   onChange: (values: OptionType[]) => void;
   onBlur?: () => void;
   formatOptionLabel?: (option: OptionType) => React.ReactNode;
   isDisabled?: boolean;
   allowAll?: boolean;
   isClearable?: boolean;
}

export const MultiSelect = ({ allowAll = true, ...props }: Props) => {
   // isOptionSelected sees previous props.value after onChange
   const valueRef = useRef(props.values);
   valueRef.current = props.values;

   const selectAllOption = {
      value: '<SELECT_ALL>',
      label: 'Alle',
   };

   const isSelectAllSelected = () => valueRef.current.length === 0;

   const isOptionSelected = (option: OptionType) =>
      valueRef.current.some(v => v.value === option.value) ||
      (isSelectAllSelected() && option.value === selectAllOption.value);

   const getOptions = () => [...(allowAll ? [selectAllOption] : []), ...props.options];

   const getValue = () => (isSelectAllSelected() && allowAll ? [selectAllOption] : props.values);

   const onChange = (newValue: any, actionMeta: any) => {
      const { action, option, removedValue } = actionMeta;

      if (action === 'select-option' && option.value === selectAllOption.value) {
         // Alle wurde selektiert
         props.onChange([]);
      } else if (
         (action === 'deselect-option' && option.value === selectAllOption.value) ||
         (action === 'remove-value' && removedValue.value === selectAllOption.value)
      ) {
         // Alle Option wurde entfernt, also alle Elemente aktivieren
         props.onChange(props.options);
      } else if (actionMeta.action === 'select-option' && isSelectAllSelected()) {
         // Alle ist aktiv und ein anderes Element wurde geklickt
         props.onChange(newValue.filter((v: any) => v.value !== selectAllOption.value) || []);
      } else {
         props.onChange(newValue || []);
      }
   };

   return (
      <Select
         className={props.className}
         isOptionSelected={isOptionSelected}
         options={getOptions()}
         value={getValue()}
         onChange={(o, m) => onChange(o, m)}
         onBlur={props.onBlur}
         formatOptionLabel={option => {
            if (option.value === selectAllOption.value) return option.label;
            if (props.formatOptionLabel) return props.formatOptionLabel(option);

            return option.label;
         }}
         hideSelectedOptions={false}
         closeMenuOnSelect={false}
         isMulti
         isClearable={props.isClearable}
         isDisabled={props.isDisabled}
         styles={getSelectStyles()}
         placeholder="Bitte auswählen…"
      />
   );
};
