import React, { CSSProperties, memo } from 'react';
import { Spinner } from 'react-bootstrap';

type InlineLoaderProps = {
   /**
    * If `true`, the loader will be shown.
    */
   show: boolean;
   /**
    * Additional styles.
    */
   style?: CSSProperties;
   /**
    * Additional classes.
    */
   className?: string;
};

/**
 * Creates a small loader to use in inline with text (e.g. Buttons).
 */
export const InlineLoader = memo(({ show, className, style }: InlineLoaderProps) => {
   if (!show) return null;

   return (
      <Spinner
         className={className}
         style={style}
         as="span"
         animation="border"
         size="sm"
         role="status"
         aria-hidden="true"
      />
   );
});
