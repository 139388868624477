import { AnyAction } from 'redux';
import { Box } from '../../types/api';

export const SET_BOXES = 'SET_BOXES';
export const UPSERT_BOX = 'UPSERT_BOX';

interface IState {
   boxes: Box[];
}

const initialState: IState = {
   boxes: [],
};

const AdminReducer = (state = initialState, action: AnyAction = { type: '' }): IState => {
   switch (action.type) {
      case SET_BOXES:
         return {
            ...state,
            boxes: action.data,
         };
      case UPSERT_BOX:
         return {
            ...state,
            boxes: [...state.boxes.filter(b => b.id !== action.data.id), action.data],
         };
      default:
         return state;
   }
};

export default AdminReducer;
