import {
   REMOVE_CLIENT,
   REMOVE_PERSONAL_TRAINING,
   REMOVE_SERVICE,
   SET_CLIENTS,
   SET_PERSONAL_TRAININGS,
   SET_SERVICES,
   UPSERT_CLIENT,
   UPSERT_PERSONAL_TRAINING,
   UPSERT_SERVICE,
} from '../reducers/ptReducer';
import { PTClass, PTClient, PTService } from '../../types/api';

export const setPersonalTrainings = (personalTrainings: PTClass[]) => ({
   type: SET_PERSONAL_TRAININGS,
   data: personalTrainings,
});

export const upsertPersonalTraining = (personalTraining: PTClass) => ({
   type: UPSERT_PERSONAL_TRAINING,
   data: personalTraining,
});

export const removePersonalTraining = (personalTraining: Partial<PTClass>) => ({
   type: REMOVE_PERSONAL_TRAINING,
   data: personalTraining,
});

export const setClients = (clients: PTClient[]) => ({
   type: SET_CLIENTS,
   data: clients,
});

export const upsertClient = (client: PTClient) => ({
   type: UPSERT_CLIENT,
   data: client,
});

export const removeClient = (client: Partial<PTClient>) => ({
   type: REMOVE_CLIENT,
   data: client,
});

export const setServices = (services: PTService[]) => ({
   type: SET_SERVICES,
   data: services,
});

export const upsertService = (service: PTService) => ({
   type: UPSERT_SERVICE,
   data: service,
});

export const removeService = (service: Partial<PTService>) => ({
   type: REMOVE_SERVICE,
   data: service,
});
