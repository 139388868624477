import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Badge, Button, Col, ListGroup, Modal, Row, Table } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import sortBy from 'lodash/sortBy';
import { Tooltip } from 'react-tooltip';
import { SelectMonthControl } from '../../forms/controls';
import { Headline, MiniProfile } from '../../molecules';
import { SkeletonPlaceholder } from '../../atoms';
import { CoachingClassModel } from '../../models';
import { CoachingClass, ROLE_COACH } from '../../types/api';
import { useCache } from '../../hooks';

interface Entry {
   coach_id: number;
   coachingClasses: CoachingClass[];
}

export const AccountingCoaches = () => {
   const { users, classTypes } = useCache();
   const [selectedMonth, setSelectedMonth] = useState(moment().startOf('month'));
   const [entries, setEntries] = useState<Entry[]>();
   const [detailEntry, setDetailEntry] = useState<Entry>();

   const loadData = useCallback(async () => {
      setEntries(undefined);
      const monthEnd = moment(selectedMonth).add(1, 'month');
      const classes = (await CoachingClassModel.listTimeRange(selectedMonth, monthEnd)).filter(
         cc => classTypes.find(ct => ct.id === cc.type_id)?.is_billable === true
      );

      const result: Entry[] = [];
      const addClassToEntry = (coach_id: number | undefined, cc: CoachingClass) => {
         if (!coach_id) return;

         const e = result.find(r => r.coach_id === coach_id);
         if (e) {
            if (!e.coachingClasses.some(c => c.id === cc.id)) e.coachingClasses.push(cc);
         } else {
            result.push({
               coach_id: coach_id,
               coachingClasses: [cc],
            });
         }
      };

      classes.forEach(cc => {
         addClassToEntry(cc.coach_id, cc);
         addClassToEntry(cc.coach_second_id, cc);
         cc.coach_other.forEach(c => addClassToEntry(c, cc));
      });

      setEntries(result);
   }, [classTypes, selectedMonth]);

   useEffect(() => {
      (async () => loadData())();
   }, [loadData]);

   return (
      <div className="py-2">
         <Headline title="Abrechnung Coaching">
            Zeigt alle Coaching-Stunden, welcher im ausgewählten Zeitraum geleistet wurden. Dabei
            werden nicht abzurechnende Kurse nicht mitgezählt und auch nicht dargestellt.
         </Headline>
         <SelectMonthControl className="mt-3" onChange={m => setSelectedMonth(m)} />
         <ListGroup className="floating-panel">
            <ListGroup.Item className="mb-0 bg-secondary text-white">
               <Row>
                  <Col xs={8} sm={9} md={9} lg={10}>
                     Coach
                  </Col>
                  <Col className="text-center">Kurse</Col>
               </Row>
            </ListGroup.Item>
            {!entries
               ? [...Array(6).keys()].map(i => (
                    <ListGroup.Item key={i}>
                       <Row>
                          <Col xs={8} sm={9} md={9} lg={10}>
                             <SkeletonPlaceholder width="10rem" />
                          </Col>
                          <Col className="text-center">
                             <SkeletonPlaceholder width="3rem" />
                          </Col>
                       </Row>
                    </ListGroup.Item>
                 ))
               : entries.map(e => (
                    <ListGroup.Item key={e.coach_id}>
                       <Row className="align-items-center">
                          <Col
                             xs={8}
                             sm={9}
                             md={9}
                             lg={10}
                             className="d-flex align-items-center flex-wrap"
                          >
                             <MiniProfile user={e.coach_id} className="me-2" />
                             {!users.find(u => u.id === e.coach_id)?.roles.includes(ROLE_COACH) && (
                                <Badge bg="info" className="my-1">
                                   Kein offizieller Coach!
                                </Badge>
                             )}
                          </Col>
                          <Col className="text-center">
                             <Button variant="link" size="sm" onClick={() => setDetailEntry(e)}>
                                {e.coachingClasses.filter(cc => !cc.canceled).length}
                             </Button>
                          </Col>
                       </Row>
                    </ListGroup.Item>
                 ))}
            {entries && entries.length === 0 && (
               <ListGroup.Item className="d-flex align-items-center justify-content-center">
                  <em>Keine Einträge vorhanden</em>
               </ListGroup.Item>
            )}
         </ListGroup>
         <DetailDialog
            entry={detailEntry}
            show={!!detailEntry}
            onClose={() => setDetailEntry(undefined)}
         />
      </div>
   );
};

interface DetailDialogProps {
   entry?: Entry;
   show: boolean;
   onClose: () => void;
}

const DetailDialog = ({ entry, show, onClose }: DetailDialogProps) => {
   const { classTypes, users } = useCache();
   // TODO: Coach-Name und Monat/Jahr in Dialog-Title
   if (!entry) return null;

   return (
      <Modal show={show} onHide={onClose} centered size="lg">
         <Modal.Header closeButton>
            <Modal.Title>Auflistung Coaching-Stunden</Modal.Title>
         </Modal.Header>
         <Modal.Body className="p-0">
            <Table className="mt-0 mb-0" hover responsive>
               <thead>
                  <tr className="bg-light">
                     <th className="w-75">Datum/Uhrzeit</th>
                     <th>Klasse</th>
                  </tr>
               </thead>
               <tbody>
                  {sortBy(entry.coachingClasses, 'start').map(cc => {
                     const cancelUser = users.find(u => u.id === cc.cancel_user_id);

                     return (
                        <tr
                           key={cc.id}
                           className={cc.canceled ? 'text-line-through text-muted' : ''}
                        >
                           <td className="overflow-ellipsis">
                              {cc.canceled && (
                                 <>
                                    <Icon
                                       id="cc-class-canceled"
                                       path={mdiInformationOutline}
                                       size={1}
                                       className="me-1"
                                    />
                                    <Tooltip
                                       anchorSelect="#cc-class-canceled"
                                       content={`Klasse wurde am ${moment(cc.cancel_time).format(
                                          'L [um] LT'
                                       )} von ${cancelUser?.first_name} ${
                                          cancelUser?.last_name
                                       } abgesagt. Grund: ${cc.cancel_reason}`}
                                       place="top"
                                    />
                                 </>
                              )}
                              {moment(cc.start).format('L [um] LT')}
                           </td>
                           <td className="overflow-ellipsis">
                              {classTypes.find(ct => ct.id === cc.type_id)?.name}
                           </td>
                        </tr>
                     );
                  })}
               </tbody>
            </Table>
         </Modal.Body>
         <Modal.Footer className="bg-light">
            <Button variant="secondary" onClick={onClose}>
               Schließen
            </Button>
         </Modal.Footer>
      </Modal>
   );
};
