import { Form } from 'react-bootstrap';
import React from 'react';
import { ControlProps, GenericControl } from './GenericControl';
import { Colors } from '../utils';
import { InfoTooltip } from '../molecules';

export interface CheckControlProps<T> extends ControlProps<T> {
   /**
    * The placeholder text for the form control.
    */
   placeholder?: string;
   /**
    * When `true` the component is disabled.
    */
   disabled?: boolean;
}

/**
 * Renders a responsive form control of type checkbox with validation errors.
 */
export const CheckControl = <T,>({
   placeholder,
   disabled,
   label,
   description,
   ...props
}: CheckControlProps<T>) => (
   <GenericControl {...props}>
      <span className="d-flex align-items-center">
         <Form.Check
            type="switch"
            id={`switch-${String(props.name)}`}
            label={label}
            className="me-2 cursor-pointer d-inline-block"
            color={Colors.success}
            checked={(props.formik.values[props.name] as unknown as boolean) ?? false}
            disabled={props.formik.isSubmitting || disabled}
            onChange={() =>
               props.formik.setFieldValue(
                  props.name as unknown as string,
                  !props.formik.values[props.name]
               )
            }
            onBlur={props.formik.handleBlur}
            isInvalid={
               !!props.formik.errors[props.name] &&
               ((!props.formik.isValid && props.formik.submitCount > 0) ||
                  !!props.formik.touched[props.name])
            }
         />
         {description && <InfoTooltip text={description} />}
      </span>
   </GenericControl>
);
