import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiArrowExpand,
   mdiCalendarOutline,
   mdiClockTimeFourOutline,
   mdiMapMarkerOutline,
} from '@mdi/js';
import moment from 'moment/moment';
import EventPopover from '../../molecules/EventPopover';
import { EditClassDialog } from '../../dialogs/EditClassDialog';
import { CoachingClass } from '../../types/api';
import { CoachingClassModel } from '../../models';
import { Colors, formatRange } from '../../utils';
import { MiniProfile } from '../../molecules';
import { CoachingEvent, EventEntryProps } from './EventTypes';

export const CoachingClassEventEntry = ({ event }: EventEntryProps<CoachingEvent>) => {
   const [classToEdit, setClassToEdit] = useState<Partial<CoachingClass>>();
   const [showOverlay, setShowOverlay] = useState(false);

   useEffect(() => {
      if (event.popupTarget) setShowOverlay(true);
   }, [event.popupTarget]);

   const handleExpandCoachingClass = async () => {
      const cc = await CoachingClassModel.get(event.id);
      if (!cc) return;

      setClassToEdit(cc);
      setShowOverlay(false);
   };

   return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <div
         role="tooltip"
         onClick={e => {
            if (event.popupTarget) e.stopPropagation();
         }}
      >
         <span className="d-flex align-items-center overflow-hidden">
            <strong className="text-nowrap small">{event.classType?.name}</strong>
            <small className="text-nowrap ms-2">
               {event.coach?.nickname || event.coach?.first_name}
            </small>
         </span>
         <EventPopover
            target={event.popupTarget}
            show={showOverlay}
            onHide={() => {
               setShowOverlay(false);
               event.popupTarget = undefined;
            }}
         >
            {coachingClassPopover(event, handleExpandCoachingClass)}
         </EventPopover>

         <EditClassDialog
            show={!!classToEdit}
            onClose={() => {
               setClassToEdit(undefined);
            }}
            coachingClass={classToEdit ?? {}}
         />
      </div>
   );
};

const coachingClassPopover = (event: CoachingEvent, onExpand: () => Promise<void>) => (
   <Popover style={{ maxWidth: 350 }}>
      <Popover.Body className="p-0">
         <div className="d-flex">
            <div className="ps-3 pt-2 pe-2 flex-fill">
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiCalendarOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <h3>{event.classType?.name}</h3>
               </div>
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiClockTimeFourOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <div>
                     <h5 className="mb-0">{moment(event.start).format('dddd, LL')}</h5>
                     <span>{formatRange(event.start, event.end, 'simple-date-with-time')}</span>
                  </div>
               </div>
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiMapMarkerOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <h5>{event.room?.name}</h5>
               </div>
            </div>
            <div>
               <Button variant="link" className="me-1 mt-1 btn p-0" onClick={onExpand}>
                  <Icon path={mdiArrowExpand} color={Colors.secondary} size={1} />
               </Button>
            </div>
         </div>
         {(event.coach || event.coachingClass.coach_second_id) && (
            <>
               <EventPopover.Divider />
               <div className="d-flex flex-column mx-3 mb-2">
                  {event.coach && <MiniProfile user={event.coach} type="large" className="my-1" />}
                  {event.coachingClass.coach_second_id && (
                     <MiniProfile
                        user={event.coachingClass.coach_second_id}
                        type="normal"
                        className="my-1"
                     />
                  )}
               </div>
            </>
         )}
         {(event.coachingClass.coach_other?.length ?? 0) > 0 && (
            <>
               <EventPopover.Divider />
               <div className="d-flex flex-row flex-wrap mx-3 mb-2">
                  {event.coachingClass.coach_other?.map(c => (
                     <MiniProfile key={c} user={c} type="normal" className="my-1 me-1" />
                  ))}
               </div>
            </>
         )}
         {event.coachingClass.comment && (
            <>
               <EventPopover.Divider />
               <div className="mx-3 mb-3">
                  <em>{event.coachingClass.comment}</em>
               </div>
            </>
         )}
      </Popover.Body>
   </Popover>
);
