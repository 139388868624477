import { isMobile } from 'react-device-detect';
import { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { Absence, AbsenceProhibition, CoachingClass, Meeting, Role, User } from '../../types/api';
import { CachedData } from '../../types/CachedData';

export const SET_APP_TITLE = 'SET_APP_TITLE';
export const SET_SHOW_BACK_BTN = 'SET_SHOW_BACK_BTN';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_LOGGED_OUT = 'SET_LOGGED_OUT';
export const UPDATE_SESSION_USER = 'UPDATE_SESSION_USER';
export const SET_API_ERROR = 'SET_API_ERROR';
export const SET_CACHE = 'SET_CACHE';
export const SET_CACHE_BOX = 'SET_CACHE_BOX';
export const SET_COACHING_CLASSES = 'SET_COACHING_CLASSES';
export const INSERT_COACHING_CLASSES = 'INSERT_COACHING_CLASSES';
export const UPSERT_COACHING_CLASS = 'UPSERT_COACHING_CLASS';
export const REMOVE_COACHING_CLASS = 'REMOVE_COACHING_CLASS';
export const SET_ABSENCES = 'SET_ABSENCES';
export const UPSERT_ABSENCE = 'UPSERT_ABSENCES';
export const REMOVE_ABSENCE = 'REMOVE_ABSENCES';
export const SET_ABSENCE_PROHIBITIONS = 'SET_ABSENCE_PROHIBITIONS';
export const UPSERT_ABSENCE_PROHIBITION = 'UPSERT_ABSENCE_PROHIBITIONS';
export const REMOVE_ABSENCE_PROHIBITION = 'REMOVE_ABSENCE_PROHIBITIONS';
export const SET_HAS_NOTIFICATIONS = 'SET_HAS_NOTIFICATIONS';
export const SET_MEETINGS = 'SET_MEETINGS';
export const UPSERT_MEETING = 'UPSERT_MEETING';
export const REMOVE_MEETING = 'REMOVE_MEETING';

interface IState {
   title: {
      line1: string;
      line2: string;
   };
   showBackButton: boolean;
   isMobile: boolean;
   session: {
      loginState: 'unknown' | 'loggedIn' | 'loggedOut';
      user: User | null;
      roles: Role[];
   };
   apiError: AxiosError | null;
   cache: CachedData;
   coachingClasses: CoachingClass[];
   meetings: Meeting[];
   absences: Absence[];
   absenceProhibitions: AbsenceProhibition[];
   hasNotifications: boolean;
}

const initialState: IState = {
   title: {
      line1: '',
      line2: '',
   },
   showBackButton: false,
   isMobile: isMobile,
   session: {
      loginState: 'unknown',
      user: null,
      roles: [],
   },
   apiError: null,
   cache: {
      allBoxes: [],
      box: null,
      users: [],
      rooms: [],
      classTypes: [],
      roles: [],
   },
   coachingClasses: [],
   meetings: [],
   absences: [],
   absenceProhibitions: [],
   hasNotifications: false,
};

const AppReducer = (state = initialState, action: AnyAction = { type: '' }): IState => {
   switch (action.type) {
      case SET_IS_LOGGED_IN:
         return {
            ...state,
            session: {
               loginState: 'loggedIn',
               user: action.data.user,
               roles: action.data.roles,
            },
         };
      case SET_LOGGED_OUT:
         return {
            ...state,
            session: {
               loginState: 'loggedOut',
               user: null,
               roles: [],
            },
         };
      case UPDATE_SESSION_USER:
         return {
            ...state,
            session: {
               ...state.session,
               user: action.data,
            },
         };
      case SET_API_ERROR:
         return {
            ...state,
            apiError: action.data,
         };
      case SET_APP_TITLE:
         return {
            ...state,
            title: action.data,
         };
      case SET_SHOW_BACK_BTN:
         return {
            ...state,
            showBackButton: action.data,
         };
      case SET_CACHE:
         return {
            ...state,
            cache: action.data,
         };
      case SET_CACHE_BOX:
         return {
            ...state,
            cache: {
               ...state.cache,
               box: action.data,
            },
         };
      case SET_COACHING_CLASSES:
         return {
            ...state,
            coachingClasses: action.data,
         };
      case INSERT_COACHING_CLASSES:
         return {
            ...state,
            coachingClasses: [...state.coachingClasses, ...action.data],
         };
      case UPSERT_COACHING_CLASS:
         return {
            ...state,
            coachingClasses: [
               ...state.coachingClasses.filter(c => c.id !== action.data.id),
               action.data,
            ],
         };
      case REMOVE_COACHING_CLASS:
         return {
            ...state,
            coachingClasses: [...state.coachingClasses.filter(c => c.id !== action.data.id)],
         };
      case SET_ABSENCES:
         return {
            ...state,
            absences: action.data,
         };
      case UPSERT_ABSENCE:
         return {
            ...state,
            absences: [...state.absences.filter(c => c.id !== action.data.id), action.data],
         };
      case REMOVE_ABSENCE:
         return {
            ...state,
            absences: [...state.absences.filter(c => c.id !== action.data.id)],
         };
      case SET_ABSENCE_PROHIBITIONS:
         return {
            ...state,
            absenceProhibitions: action.data,
         };
      case UPSERT_ABSENCE_PROHIBITION:
         return {
            ...state,
            absenceProhibitions: [
               ...state.absenceProhibitions.filter(c => c.id !== action.data.id),
               action.data,
            ],
         };
      case REMOVE_ABSENCE_PROHIBITION:
         return {
            ...state,
            absenceProhibitions: [
               ...state.absenceProhibitions.filter(c => c.id !== action.data.id),
            ],
         };
      case SET_HAS_NOTIFICATIONS:
         return {
            ...state,
            hasNotifications: action.data,
         };
      case SET_MEETINGS: {
         return {
            ...state,
            meetings: action.data,
         };
      }
      case UPSERT_MEETING:
         return {
            ...state,
            meetings: [...state.meetings.filter(m => m.id !== action.data.id), action.data],
         };
      case REMOVE_MEETING:
         return {
            ...state,
            meetings: [...state.meetings.filter(m => m.id !== action.data.id)],
         };
      default:
         return state;
   }
};

export default AppReducer;
