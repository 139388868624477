import React, { CSSProperties, memo } from 'react';

type Props = {
   /**
    * An optional id when used inside an iteration.
    */
   id?: string;
   /**
    * The content of the panel.
    */
   children: React.ReactNode;
   /**
    * Additional classes.
    */
   className?: string;
   style?: CSSProperties;
};

/**
 * Creates a panel with a visual floating effect (e.g. border with shadow).
 */
export const FloatingPanel = memo((props: Props) => (
   <div id={props.id} className={`${props.className ?? ''} floating-panel`} style={props.style}>
      {props.children}
   </div>
));
