import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, ListGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import { Formik, FormikErrors } from 'formik';
import sortBy from 'lodash/sortBy';
import { useDispatch } from 'react-redux';
import { Headline } from '../../molecules';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import { SkeletonPlaceholder } from '../../atoms';
import { ColorInput } from '../../forms/controls';
import { pickerColors } from '../../utils';
import { removeClient, upsertClient } from '../../store/actions/ptActions';
import { PTClient } from '../../types/api';
import { PTClientModel } from '../../models';
import { FormUtils, SubmitButton } from '../../forms';

export const PTClientsTab = () => {
   const dispatch = useDispatch();
   const [clients, setClients] = useState<PTClient[]>();

   useEffect(() => {
      (async () => {
         setClients(sortBy(await PTClientModel.list(), 'name'));
      })();
   }, []);

   const handleAfterSave = (client: PTClient, operation: 'insert' | 'update') => {
      if (operation === 'insert') setClients(v => [client, ...(v ?? [])]);
      else setClients(v => (v ?? []).map(c => (c.id === client.id ? client : c)));

      dispatch(upsertClient(client));
   };

   const handleAfterDelete = (client: Partial<PTClient>) => {
      setClients(v => (v ?? []).filter(c => c.id !== client.id));
      dispatch(removeClient(client));
   };

   return (
      <>
         <Headline title="Kunden" browserTitle="Personal Trainings - Kunden">
            Hier kannst du deine Personal Training Kunden verwalten. Gib ihnen verschiedene Farben,
            sodass du im Kalender schneller siehst, mit wem du das PT hast.
         </Headline>
         <ListGroup className="floating-panel">
            {!clients ? (
               [...Array(6).keys()].map(i => (
                  <ListGroup.Item key={i} className="d-flex justify-content-between">
                     <span className="d-flex">
                        <SkeletonPlaceholder width="1.5rem" className="me-2" />
                        <SkeletonPlaceholder width="20rem" />
                     </span>
                     <SkeletonPlaceholder width="5rem" />
                  </ListGroup.Item>
               ))
            ) : (
               <>
                  <ClientRow afterSave={handleAfterSave} />
                  {clients.map(c => (
                     <ClientRow
                        key={c.id}
                        client={c}
                        afterSave={handleAfterSave}
                        afterDelete={handleAfterDelete}
                     />
                  ))}
               </>
            )}
         </ListGroup>
      </>
   );
};

interface ClientRowProps {
   client?: Partial<PTClient>;
   afterSave: (client: PTClient, operation: 'insert' | 'update') => void;
   afterDelete?: (client: Partial<PTClient>) => void;
}

const ClientRow = (props: ClientRowProps) => {
   const [client, setClient] = useState<Partial<PTClient>>(
      props.client ?? { id: 0, color: pickerColors[0] }
   );
   const [isEditMode, setEditMode] = useState(client.id === 0);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);

   const handleSubmitForm = async (values: Partial<PTClient>) => {
      const newClient =
         values.id === 0 ? await PTClientModel.insert(values) : await PTClientModel.update(values);

      props.afterSave(newClient, client.id === 0 ? 'insert' : 'update');
      if (!props.client) {
         setClient({ id: 0, color: pickerColors[0], x_date_inserted: new Date() });
      } else {
         setEditMode(false);
         setClient(newClient);
      }
   };

   const handleDelete = async () => {
      if (!props.client) return;

      await PTClientModel.delete(props.client);
      props.afterDelete?.(props.client);
   };

   return (
      <>
         <ListGroup.Item className="ps-3">
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={client}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<PTClient>> = {};

                  if (!values.name) errors.name = 'Bitte gib einen Namen für den Kunden an.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     className="d-flex justify-content-between align-items-center"
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     {isEditMode ? (
                        <>
                           <span className="d-flex flex-fill align-items-center">
                              <span className="me-2" style={{ width: '2.5rem' }}>
                                 <ColorInput
                                    size="sm"
                                    value={formik.values.color}
                                    onChange={c => formik.setFieldValue('color', c)}
                                 />
                              </span>

                              <Form.Control
                                 type="text"
                                 name="name"
                                 size="sm"
                                 placeholder="Name des neuen Kunden"
                                 className="me-2"
                                 style={{ maxWidth: '20rem' }}
                                 value={formik.values.name ?? ''}
                                 onChange={formik.handleChange}
                                 isInvalid={FormUtils.isInvalid(formik, 'name')}
                                 disabled={formik.isSubmitting}
                              />
                           </span>

                           <div className="d-flex flex-row">
                              {client.id !== 0 && (
                                 <Button
                                    variant="outline-link"
                                    size="sm"
                                    className="d-flex align-items-center ms-2"
                                    onClick={() => setEditMode(false)}
                                    disabled={formik.isSubmitting}
                                 >
                                    <span className="d-none d-md-inline">Abbrechen</span>
                                    <Icon
                                       className="d-inline d-md-none"
                                       path={mdiClose}
                                       size={0.75}
                                    />
                                 </Button>
                              )}
                              <SubmitButton
                                 formik={formik}
                                 size="sm"
                                 className="d-flex align-items-center"
                              >
                                 <span className="d-none d-md-inline">
                                    {client.id !== 0 ? 'Speichern' : 'Anlegen'}
                                 </span>
                                 <Icon
                                    className="d-inline d-md-none"
                                    path={client.id !== 0 ? mdiCheck : mdiPlus}
                                    size={0.75}
                                 />
                              </SubmitButton>
                           </div>
                        </>
                     ) : (
                        <>
                           <span className="d-flex align-items-center">
                              <span className="me-2" style={{ width: '2.5rem' }}>
                                 <div
                                    className="me-2"
                                    style={{
                                       height: '1rem',
                                       width: '1rem',
                                       borderRadius: '0.25rem',
                                       backgroundColor: client.color,
                                    }}
                                 />
                              </span>

                              {client.name}
                           </span>
                           <ButtonGroup>
                              <Button
                                 variant="outline-secondary"
                                 size="sm"
                                 className="d-flex align-items-center"
                                 onClick={() => setEditMode(true)}
                              >
                                 <Icon path={mdiPencilOutline} size={0.75} />
                              </Button>
                              <Button
                                 variant="outline-danger"
                                 size="sm"
                                 className="d-flex align-items-center"
                                 onClick={() => setShowDeleteDialog(true)}
                              >
                                 <Icon path={mdiTrashCanOutline} size={0.75} />
                              </Button>
                           </ButtonGroup>
                        </>
                     )}
                  </Form>
               )}
            </Formik>
         </ListGroup.Item>
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest Du den Kunden <strong>{client.name}</strong> wirklich löschen?
            </span>
         </ConfirmDeleteDialog>
      </>
   );
};
