import { Button } from 'react-bootstrap';
import { mdiBell } from '@mdi/js';
import { LinkContainer } from 'react-router-bootstrap';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { BadgeIcon } from '../../atoms';
import { RootState } from '../../store';

export const NotificationButton = memo(() => {
   const { hasNotifications } = useSelector((s: RootState) => s.app);
   return (
      <LinkContainer to="/notifications">
         <Button
            variant="primary"
            className={`notification-btn ${hasNotifications ? 'has-notification' : ''}`}
         >
            <BadgeIcon icon={mdiBell} showBadge={hasNotifications} />
         </Button>
      </LinkContainer>
   );
});
