import { useParams } from 'react-router-dom';
import { Button, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import {
   mdiAccountCowboyHat,
   mdiCalendarMonth,
   mdiChevronLeft,
   mdiMapMarker,
   mdiText,
} from '@mdi/js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { LinkContainer } from 'react-router-bootstrap';
import { formatRange, getClockIcon, setTitle } from '../utils';
import { MiniProfile } from '../molecules';
import { SkeletonPlaceholder } from '../atoms';
import { PTClass, ROLE_PERSONAL_TRAINER } from '../types/api';
import { PTClassModel } from '../models';
import { useCache, useSession } from '../hooks';

export const EventPersonalTrainingPage = () => {
   const { eventId } = useParams();
   const cachedData = useCache();
   const { sessionRoles } = useSession();
   const [ptClass, setPtClass] = useState<PTClass>();
   const [paramIsInvalid, setParamIsInvalid] = useState(false);

   useEffect(() => setTitle('Coaching-Klasse'), []);

   useEffect(() => {
      (async () => {
         const id = Number(eventId);
         if (!Number.isNaN(id)) {
            try {
               setPtClass((await PTClassModel.get(id)) ?? undefined);
               return;
            } catch {
               /* ignore */
            }
         }
         toast.error('Die aufgerufene Coaching-Class existiert nicht.');
         setParamIsInvalid(true);
      })();
   }, [eventId]);

   return (
      <Container className="py-2">
         <Row>
            <Col xs={0} md={2} />
            <Col
               xs={12}
               md={8}
               className="d-flex flex-row justify-content-between align-items-center"
            >
               <LinkContainer
                  to={
                     sessionRoles.some(r => r.key === ROLE_PERSONAL_TRAINER)
                        ? {
                             pathname: '/pt',
                             search: `?view=day&date=${moment(ptClass?.start).format(
                                'YYYY-MM-DD'
                             )}`,
                          }
                        : {
                             pathname: '/calendar',
                             search: `?view=day&date=${moment(ptClass?.start).format(
                                'YYYY-MM-DD'
                             )}`,
                          }
                  }
               >
                  <Button variant="outline-link" className="d-flex align-items-center ps-1">
                     <Icon path={mdiChevronLeft} size={1} />
                     Kalender
                  </Button>
               </LinkContainer>
            </Col>
            <Col xs={0} md={2} />
         </Row>
         <Row>
            <Col xs={0} md={2} />
            <Col xs={12} md={8}>
               <ListGroup className="floating-panel">
                  <ListGroup.Item className="bg-secondary text-white">
                     <h2 className="mb-0">Personal-Training</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                     {paramIsInvalid && (
                        <div className="d-flex justify-content-center">
                           <em>Personal-Training existiert nicht.</em>
                        </div>
                     )}
                     {!ptClass && !paramIsInvalid && (
                        <Row>
                           <Col xs={12} lg={6}>
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="6rem" />
                           </Col>
                           <Col xs={12} lg={6}>
                              <SkeletonPlaceholder width="100%" height="2rem" />
                           </Col>
                        </Row>
                     )}
                     {ptClass && !paramIsInvalid && (
                        <Row>
                           <Col xs={12} lg={6}>
                              <FieldRow
                                 icon={mdiMapMarker}
                                 value={cachedData.rooms.find(r => r.id === ptClass?.room_id)?.name}
                              />
                              <FieldRow
                                 icon={mdiCalendarMonth}
                                 value={moment(ptClass?.start).format('L')}
                              />
                              <FieldRow
                                 icon={getClockIcon(ptClass.start)}
                                 value={`${formatRange(
                                    ptClass?.start,
                                    ptClass?.end,
                                    'simple-date-with-time'
                                 )}`}
                              />
                              <FieldRow icon={mdiText}>
                                 <Form.Control
                                    as="textarea"
                                    className="bg-white"
                                    rows={3}
                                    value={ptClass?.comment ?? ''}
                                    readOnly
                                 />
                              </FieldRow>
                           </Col>
                           <Col xs={12} lg={6}>
                              <FieldRow icon={mdiAccountCowboyHat}>
                                 <MiniProfile className="form-control" user={ptClass?.coach_id} />
                              </FieldRow>
                           </Col>
                        </Row>
                     )}
                  </ListGroup.Item>
               </ListGroup>
            </Col>
            <Col xs={0} md={2} />
         </Row>
      </Container>
   );
};

interface FieldRowProps {
   icon?: string;
   value?: string;
   children?: React.ReactNode;
}

// TODO: Code doppelt
const FieldRow = (props: FieldRowProps) => (
   <Row className="my-2 align-items-center">
      <Col>
         <InputGroup>
            {props.icon && (
               <InputGroup.Text>
                  <Icon path={props.icon} size={1} />
               </InputGroup.Text>
            )}
            {props.children || (
               <Form.Control className="bg-white" type="text" value={props.value} readOnly />
            )}
         </InputGroup>
      </Col>
   </Row>
);
