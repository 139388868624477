import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React from 'react';
import { Formik, FormikErrors } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FormUtils, GenericControl, SubmitButton, TextControl } from '../forms';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { BoxClosingTimeModel } from '../models/BoxClosingTime';
import { BoxClosingTime } from '../types/api';

declare type Props = {
   show: boolean;
   onClose: () => void;
   boxClosingTime?: Partial<BoxClosingTime>;
   afterSave?: (bct: BoxClosingTime) => Promise<void>;
};

export const EditBoxClosingTimeDialog = ({ show, onClose, boxClosingTime, afterSave }: Props) => {
   if (!boxClosingTime) return null;

   const handleSubmitForm = async (values: Partial<BoxClosingTime>) => {
      let bct;
      if (values.id === 0) {
         bct = await BoxClosingTimeModel.insert(values);
      } else {
         bct = await BoxClosingTimeModel.update(values);
      }

      if (afterSave) await afterSave(bct);

      onClose();
   };

   return (
      <Modal show={show} onHide={onClose}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={boxClosingTime}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<BoxClosingTime>> = {};

               if (!values.start) errors.start = 'Bitte gib ein Startdatum an.';

               if (!values.end) errors.end = 'Bitte gib ein Enddatum an.';

               if (values.start && values.end && moment(values.start).isAfter(values.end, 'minute'))
                  errors.end = 'Das Ende muss nach dem Start sein.';

               if (!values.reason) errors.reason = 'Bitte gib einen Grund an.';

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        {boxClosingTime.id === 0 ? 'Neue Schließzeit' : 'Schließzeit bearbeiten'}
                     </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <TextControl
                        formik={formik}
                        name="reason"
                        placeholder="Grund…"
                        label="Grund"
                        description="Der Grund sollte kurz und prägnant angeben, warum in dieser Zeit Training vor Ort möglich ist."
                     />
                     <Row>
                        <Col sm={6}>
                           <GenericControl formik={formik} name="start" label="Von">
                              <Form.Control
                                 as={DatePicker}
                                 wrapperClassName="d-flex"
                                 dateFormat="dd.MM.yyyy HH:mm"
                                 locale="de"
                                 calendarStartDay={1}
                                 showWeekNumbers
                                 showTimeInput
                                 timeInputLabel="Time:"
                                 placeholderText="__.__.____ __:__"
                                 selectsStart
                                 startDate={formik.values.start}
                                 endDate={formik.values.end}
                                 maxDate={formik.values.end}
                                 selected={formik.values.start}
                                 onChange={date => formik.setFieldValue('start', date)}
                                 onBlur={() => formik.handleBlur('start')}
                                 isInvalid={FormUtils.isInvalid(formik, 'start')}
                                 disabled={formik.isSubmitting}
                              />
                           </GenericControl>
                        </Col>
                        <Col sm={6}>
                           <GenericControl formik={formik} name="end" label="Bis">
                              <Form.Control
                                 as={DatePicker}
                                 wrapperClassName="d-flex"
                                 dateFormat="dd.MM.yyyy HH:mm"
                                 locale="de"
                                 calendarStartDay={1}
                                 showWeekNumbers
                                 showTimeInput
                                 timeInputLabel="Time:"
                                 placeholderText="__.__.____ __:__"
                                 selectsEnd
                                 startDate={formik.values.start}
                                 endDate={formik.values.end}
                                 minDate={formik.values.start}
                                 selected={formik.values.end}
                                 onChange={date => formik.setFieldValue('end', date)}
                                 onBlur={() => formik.handleBlur('end')}
                                 isInvalid={FormUtils.isInvalid(formik, 'end')}
                                 disabled={formik.isSubmitting}
                              />
                           </GenericControl>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                     <CreateUpdateInfo hidden={boxClosingTime.id === 0} obj={boxClosingTime} />
                     <Button
                        variant="outline-link"
                        onClick={onClose}
                        disabled={formik.isSubmitting}
                     >
                        Schließen
                     </Button>
                     <SubmitButton formik={formik}>Speichern</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
