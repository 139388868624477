import { Form } from 'react-bootstrap';
import React from 'react';
import { ControlProps, GenericControl } from './GenericControl';
import { FormUtils } from './FormUtils';

export interface TextareaControlProps<T> extends ControlProps<T> {
   /**
    * The placeholder text for the form control.
    */
   placeholder?: string;
   /**
    * When `true` the component is disabled.
    */
   disabled?: boolean;
   /**
    * The amount of rows to be visible.
    */
   rows?: number;
}

/**
 * Renders a responsive form control of type textarea with validation errors.
 */
export const TextareaControl = <T,>({
   placeholder,
   disabled,
   rows = 3,
   ...props
}: TextareaControlProps<T>) => (
   <GenericControl {...props}>
      <Form.Control
         as="textarea"
         rows={rows}
         name={props.name as string}
         placeholder={placeholder}
         value={(props.formik.values[props.name] as unknown as string) ?? ''}
         onChange={props.formik.handleChange}
         onBlur={props.formik.handleBlur}
         isInvalid={FormUtils.isInvalid(props.formik, props.name)}
         disabled={props.formik.isSubmitting || disabled}
      />
   </GenericControl>
);
