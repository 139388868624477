import { BaseModel } from './BaseModel';
import ApiService from '../services/ApiService';
import { User } from '../types/api';
import { IApiResponse } from '../types/IApiResponse';

class UserModelClass extends BaseModel<User> {
   public getName(): string {
      return 'user';
   }

   public async authenticate(token?: string): Promise<User> {
      const response = await ApiService.http.post<IApiResponse<User>>('auth', { token: token });
      return response.data.data[0];
   }

   public async authenticateDemoAccount(username: string, password: string): Promise<User> {
      const response = await ApiService.http.post<IApiResponse<User>>('auth/demo', {
         username: username,
         password: password,
      });
      return response.data.data[0];
   }

   public async logout(): Promise<void> {
      await ApiService.http.get<IApiResponse<void>>('auth/logout');
   }

   public async anonymize(user: User): Promise<User> {
      try {
         const response = await ApiService.http.put<IApiResponse<User>>(
            `data/${this.getName()}/${user.id}/anonymize`
         );

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async uploadAvatar(file: Blob): Promise<void> {
      try {
         const formData = new FormData();
         formData.append('image', file);

         await ApiService.http.put(`avatar/user`, formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
            },
         });
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async removeAvatar(): Promise<void> {
      try {
         await ApiService.http.delete(`avatar/user`);
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   // region admin functions

   public async impersonate(userId: number): Promise<void> {
      await ApiService.http.get(`auth/impersonate/${userId}`);
   }

   public async adminList(boxId: number): Promise<User[]> {
      try {
         const response = await ApiService.http.get<IApiResponse<User>>(`admin/box/${boxId}/user`);

         return response.data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async adminSearch(q: string): Promise<User[]> {
      try {
         const queryString = q.length === 0 ? '' : `?query=${encodeURIComponent(q)}`;
         const response = await ApiService.http.get<IApiResponse<User>>(
            `admin/box/user${queryString}`
         );

         return response.data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async adminAddToBox(
      userId: number,
      boxId: number,
      replaceExistingAssignments = false
   ): Promise<void> {
      try {
         await ApiService.http.post(`admin/box/user`, {
            user_id: userId,
            box_id: boxId,
            replaceExistingAssignments: replaceExistingAssignments,
         });
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async adminRemoveFromBox(userId: number, boxId: number): Promise<void> {
      try {
         await ApiService.http.delete(`admin/box/user`, {
            data: { user_id: userId, box_id: boxId },
         });
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   // endregion
}
export const UserModel = new UserModelClass();
