import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/cjs/Container';
import { setTitle } from '../utils';
import { RootState } from '../store';
import { ContentLoader } from '../atoms';

export const PageNotFoundPage = () => {
   const loginState = useSelector((s: RootState) => s.app.session.loginState);

   useEffect(() => {
      if (loginState === 'unknown') {
         setTitle('Bitte warten…');
      } else {
         setTitle('Seite nicht gefunden');
      }
   }, [loginState]);

   // Wir müssen den Login-Status prüfen, da die 404-Seite auch angezeigt wird, wenn die Seite existiert,
   // aber nur für angemeldete Benutzer angezeigt wird. Damit nicht kurzzeitig 404 angezeigt wird, während der Login
   // im Hintergrund durchgeführt wird.
   return (
      <Container className="py-2">
         {loginState === 'unknown' ? (
            <ContentLoader />
         ) : (
            <>
               <div className="text-center" style={{ fontSize: 150 }}>
                  404
               </div>
               <div className="text-center">Die aufgerufene Seite existiert nicht.</div>
            </>
         )}
      </Container>
   );
};
