import { Image } from 'react-bootstrap';
import React, { CSSProperties, useMemo } from 'react';
import { useImage } from 'react-image';
import NoAvatar from '../assets/img/no_avatar.png';

interface Props {
   url?: string;
   style?: CSSProperties;
}

export const Avatar = ({ url, style }: Props) => {
   const { src } = useImage({
      srcList: url ?? '',
      useSuspense: false,
   });

   const imageStyle: CSSProperties = useMemo(
      () => ({
         aspectRatio: '1',
         borderRadius: '50%',
         ...style,
      }),
      [style]
   );

   return <Image src={src ?? NoAvatar} style={imageStyle} roundedCircle />;
};
