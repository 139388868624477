import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Alert, Button, ButtonGroup, Col, ListGroup, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiEmailArrowRightOutline, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { formatRange } from '../../utils';
import { removeAbsence, upsertAbsence } from '../../store/actions/appActions';
import { AbsenceList } from '../../organisms';
import { LoadingButton } from '../../molecules';
import { EditAbsenceDialog } from '../../dialogs/EditAbsenceDialog';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import ApiService from '../../services/ApiService';
import { SelectYearControl } from '../../forms/controls';
import { Absence } from '../../types/api';
import { CoachStatsModel } from '../../types/CoachStatsModel';
import { AbsenceModel } from '../../models';
import { IApiResponse } from '../../types/IApiResponse';
import { useSession } from '../../hooks';

interface Props {
   activeTab: string | undefined;
}

export const MyAbsencesTab = (props: Props) => {
   const dispatch = useDispatch();
   const { sessionUser } = useSession();
   const [myAbsences, setMyAbsences] = useState<Absence[]>();
   const [showDialog, setShowDialog] = useState(false);
   const [absenceToEdit, setAbsenceToEdit] = useState<Partial<Absence>>();
   const [absenceToDelete, setAbsenceToDelete] = useState<Absence>();
   const [vacationStats, setVacationStats] = useState<CoachStatsModel>();
   const [selectedYear, setSelectedYear] = useState(moment().year());

   const loadData = useCallback(async () => {
      const data = await AbsenceModel.listTimeRange(
         moment(`${selectedYear}-01-01`, 'YYYY-MM-DD'),
         moment(`${selectedYear}-12-31`, 'YYYY-MM-DD')
      );
      setMyAbsences(data.filter(e => e.user_id === sessionUser?.id));
   }, [sessionUser, selectedYear]);

   useEffect(() => {
      (async () => loadData())();
   }, [loadData, props.activeTab]);

   useEffect(() => {
      (async () => {
         // Freelancer fallen nicht unter die Prüfung
         if (!sessionUser || sessionUser.type === 'freelancer') return;

         // TODO: Code doppelt mit CoachingStatsWidget. Auslagern.
         try {
            const result = await ApiService.http.get<IApiResponse<CoachStatsModel>>(
               `stats/user/${sessionUser.id}?month=${moment().format('YYYY-MM')}`
            );
            setVacationStats(result.data.data[0]);
         } catch (error) {
            ApiService.handleError(error);
            return Promise.reject(error);
         }
      })();
   }, [sessionUser, myAbsences]);

   const submitAbsenceToApproval = async (absence: Absence) => {
      absence.draft = false;
      const updatedAbsence = await AbsenceModel.submitForApproval(absence);
      dispatch(upsertAbsence(updatedAbsence));
      setMyAbsences(v => [...(v ?? []).filter(a => a.id !== updatedAbsence.id), updatedAbsence]);
      toast.success('Die Abwesenheit wurde erfolgreich zur Genehmigung eingereicht.');
   };

   const handleDelete = async () => {
      if (!absenceToDelete) return;

      try {
         await AbsenceModel.delete(absenceToDelete);
         dispatch(removeAbsence(absenceToDelete));
         setMyAbsences(v => v?.filter(a => a.id !== absenceToDelete.id));
         setAbsenceToDelete(undefined);
      } catch {
         /* ignore */
      }
   };

   return (
      <div className="px-0">
         <Row>
            <Col
               className="d-flex gap-2 justify-content-between justify-content-md-start"
               xs={12}
               md={6}
            >
               <Button
                  variant="outline-success"
                  className="text-nowrap"
                  onClick={() => {
                     setAbsenceToEdit({
                        id: 0,
                        user_id: sessionUser?.id,
                        draft: true,
                     });
                     setShowDialog(true);
                  }}
               >
                  Erstellen
               </Button>
               <SelectYearControl
                  className="flex-row w-auto"
                  year={selectedYear}
                  onChange={year => setSelectedYear(year)}
               />
            </Col>
            {sessionUser?.type !== 'freelancer' && (
               <Col
                  xs={12}
                  md={6}
                  className="mt-2 mt-md-0 d-flex justify-content-stretch justify-content-sm-end"
               >
                  <ListGroup horizontal className="flex-fill">
                     <ListGroup.Item className="py-0 px-2 flex-fill bg-light small d-flex flex-column align-items-center">
                        Gesamt
                        <strong>
                           {(vacationStats?.vacationUsed ?? 0) +
                              (vacationStats?.vacationPlanned ?? 0) +
                              (vacationStats?.vacationAvailable ?? 0)}
                        </strong>
                     </ListGroup.Item>
                     <ListGroup.Item className="py-0 px-2 flex-fill bg-light small d-flex flex-column align-items-center">
                        Genommen
                        <strong>{vacationStats?.vacationUsed}</strong>
                     </ListGroup.Item>
                     <ListGroup.Item className="py-0 px-2 flex-fill bg-light small d-flex flex-column align-items-center">
                        Geplant
                        <strong>{vacationStats?.vacationPlanned}</strong>
                     </ListGroup.Item>
                     <ListGroup.Item className="py-0 px-2 flex-fill bg-light small d-flex flex-column align-items-center">
                        Rest
                        <strong>{vacationStats?.vacationAvailable}</strong>
                     </ListGroup.Item>
                  </ListGroup>
               </Col>
            )}
            <Col xs={12}>
               <Alert variant="info" className="mb-0 mt-1 py-1 px-2 small">
                  Genehmigte Abwesenheiten in der Vergangenheit können nicht gelöscht werden.
               </Alert>
            </Col>
         </Row>

         <AbsenceList absences={myAbsences} className="mt-2">
            {({ item }) => (
               <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ minWidth: '6.5rem' }}
               >
                  <ButtonGroup>
                     {item.draft && (
                        <LoadingButton
                           variant="outline-primary"
                           size="sm"
                           className="d-flex align-items-center"
                           tooltip="Abwesenheit zur Genehmigung einreichen"
                           onClick={() => submitAbsenceToApproval(item)}
                           hideContentWhenLoading
                        >
                           <Icon path={mdiEmailArrowRightOutline} size={0.75} />
                        </LoadingButton>
                     )}
                     <LoadingButton
                        variant="outline-secondary"
                        size="sm"
                        className="d-flex align-items-center"
                        tooltip="Abwesenheit bearbeiten"
                        onClick={() => {
                           setAbsenceToEdit(item);
                           setShowDialog(true);
                        }}
                     >
                        <Icon path={mdiPencilOutline} size={0.75} />
                     </LoadingButton>
                     {(item.draft || moment(item.start).isSameOrAfter(moment())) && (
                        <LoadingButton
                           variant="outline-danger"
                           size="sm"
                           className="d-flex align-items-center"
                           tooltip={
                              !item.draft && moment(item.start).isBefore(moment())
                                 ? 'Abwesenheiten in der Vergangenheit können nur vom Box-Owner gelöscht werden'
                                 : 'Abwesenheit löschen'
                           }
                           onClick={() => setAbsenceToDelete(item)}
                        >
                           <Icon path={mdiTrashCanOutline} size={0.75} />
                        </LoadingButton>
                     )}
                  </ButtonGroup>
               </div>
            )}
         </AbsenceList>
         <EditAbsenceDialog
            show={showDialog}
            onClose={() => setShowDialog(false)}
            absence={absenceToEdit}
            afterSave={async absence => {
               setSelectedYear(moment(absence.start).year());
               await loadData();
            }}
            remainingVacationDays={vacationStats?.vacationAvailable}
         />
         <ConfirmDeleteDialog
            show={!!absenceToDelete}
            onClose={() => setAbsenceToDelete(undefined)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest du die Abwesenheit vom{' '}
               <strong>
                  {formatRange(absenceToDelete?.start, absenceToDelete?.end, 'full-date-only')}
               </strong>{' '}
               wirklich löschen?
            </span>
         </ConfirmDeleteDialog>
      </div>
   );
};
