import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import { Form, InputGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LoadingButton } from '../../molecules';

interface Props {
   year?: number;
   className?: string;
   onChange: (year: number) => void;
}

export const SelectYearControl = ({ onChange, className, year: inputYear }: Props) => {
   const [year, setYear] = useState(inputYear ?? moment().year());

   useEffect(() => {
      onChange(year);
   }, [year]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (inputYear && year !== inputYear) setYear(inputYear);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <InputGroup className={`${className}`}>
         <LoadingButton
            variant="secondary"
            className="d-flex align-items-center"
            onClick={() => setYear(v => v - 1)}
         >
            <Icon path={mdiArrowLeft} size={0.85} />
         </LoadingButton>
         <Form.Control
            className="text-center"
            style={{ maxWidth: '5rem' }}
            value={`${year}`}
            onChange={event => setYear(Number(event.target.value))}
         />
         <LoadingButton
            variant="secondary"
            className="d-flex align-items-center"
            onClick={() => setYear(v => v + 1)}
         >
            <Icon path={mdiArrowRight} size={0.85} />
         </LoadingButton>
      </InputGroup>
   );
};
