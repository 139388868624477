import { Card } from 'react-bootstrap';
import React, { useState } from 'react';
import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { MiniProfile } from '../../molecules';
import { ScheduleNotes } from '../../types/api/ScheduleNotes';

interface Props {
   note: ScheduleNotes;
}

export const NoteCard = ({ note }: Props) => {
   const [isExpanded, setExpanded] = useState(true);

   return (
      <Card className="m-1">
         <Card.Header
            className={`ps-1 d-flex align-items-center cursor-pointer ${
               !isExpanded ? 'border-bottom-0' : ''
            }`}
            onClick={() => setExpanded(v => !v)}
         >
            <Icon
               className="me-2"
               path={isExpanded ? mdiChevronDown : mdiChevronRight}
               size={0.75}
            />
            <MiniProfile user={note.user_id} />
         </Card.Header>
         {isExpanded && <Card.Body className="white-space-pre-wrap small">{note.notes}</Card.Body>}
      </Card>
   );
};
