import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-image-crop/src/ReactCrop.scss';
import 'react-tooltip/dist/react-tooltip.css';
import './index.css';
import { Provider } from 'react-redux';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from './store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { CLIENT_ID } from './utils/config';

registerLocale('de', de);

setDefaultLocale('de');

ReactDOM.render(
   <React.StrictMode>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
         <Provider store={store}>
            <BrowserRouter
               basename={process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL}
            >
               <App />
            </BrowserRouter>
         </Provider>
      </GoogleOAuthProvider>
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
