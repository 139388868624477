import { Button, ButtonGroup, Form, ListGroup, Modal } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Formik, FormikErrors } from 'formik';
import { ScheduleNotes } from '../types/api/ScheduleNotes';
import { useSession } from '../hooks';
import { ScheduleNotesModel } from '../models/ScheduleNotesModel';
import { SubmitButton, TextareaControl } from '../forms';
import { ConfirmDeleteDialog } from '../dialogs/ConfirmDeleteDialog';

interface Props {
   note: ScheduleNotes;
   onChange: (note: ScheduleNotes) => void;
}

export const CoachingNoteItem = ({ note, onChange }: Props) => {
   const currentMonth = moment(`${note.year}-${note.month <= 9 ? '0' : ''}${note.month}-01`);
   const { sessionUser } = useSession();
   const [isExpanded, setExpanded] = useState(false);
   const [showDialog, setShowDialog] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);

   const handleSubmitForm = async (values: ScheduleNotes) => {
      let n;
      if (!values.id) {
         n = await ScheduleNotesModel.insert(values);
      } else {
         n = await ScheduleNotesModel.update(values);
      }

      onChange(n);
      setShowDialog(false);
   };

   const handleDelete = useCallback(async () => {
      if (!note) return;
      await ScheduleNotesModel.delete(note);
      onChange({
         id: 0,
         user_id: sessionUser?.id ?? 0,
         year: note.year,
         month: note.month,
      } as ScheduleNotes);
      setShowDeleteDialog(false);
   }, [note, onChange, sessionUser?.id]);

   return (
      <>
         <ListGroup.Item className=" ps-0 d-flex flex-column">
            <div className="d-flex align-items-center">
               <div className="d-flex align-items-center text-nowrap">
                  {note.id ? (
                     <Button
                        variant="outline-link"
                        size="sm"
                        className="d-flex align-items-center justify-content-center"
                        onClick={() => setExpanded(v => !v)}
                     >
                        <Icon path={isExpanded ? mdiChevronDown : mdiChevronRight} size={0.75} />
                     </Button>
                  ) : (
                     <div className="ms-3" />
                  )}
                  {currentMonth.format('MMMM YYYY')}
               </div>
               {isExpanded ? (
                  <div className="flex-fill" />
               ) : (
                  <div className="mx-2 w-100 overflow-ellipsis text-muted fst-italic">
                     {note?.notes}
                  </div>
               )}
               {note.id ? (
                  <ButtonGroup>
                     <Button
                        variant="outline-primary"
                        size="sm"
                        className="d-flex align-items-center"
                        onClick={() => {
                           setShowDialog(true);
                        }}
                     >
                        <Icon path={mdiPencilOutline} size={0.75} />
                     </Button>
                     {!!note.id && (
                        <Button
                           variant="outline-danger"
                           size="sm"
                           className="d-flex align-items-center"
                           onClick={() => {
                              setShowDeleteDialog(true);
                           }}
                        >
                           <Icon path={mdiTrashCanOutline} size={0.75} />
                        </Button>
                     )}
                  </ButtonGroup>
               ) : (
                  <Button
                     variant="outline-primary"
                     size="sm"
                     className="fst-italic text-nowrap"
                     onClick={() => setShowDialog(true)}
                  >
                     Notiz hinzufügen
                  </Button>
               )}
            </div>
            {isExpanded && (
               <div className="ps-4 text-muted white-space-pre-wrap">{note?.notes}</div>
            )}
         </ListGroup.Item>
         <Modal show={showDialog} onHide={() => setShowDialog(false)} centered>
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={note}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<ScheduleNotes> = {};

                  if (!values.notes) errors.notes = 'Bitte gib eine Notiz an.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>
                           {note.id ? 'Notiz bearbeiten' : 'Notiz hinzufügen'}
                        </Modal.Title>
                     </Modal.Header>

                     <Modal.Body>
                        <TextareaControl
                           formik={formik}
                           name="notes"
                           placeholder="Notizen…"
                           rows={8}
                        />
                     </Modal.Body>

                     <Modal.Footer className="bg-light">
                        <Button
                           variant="outline-link"
                           onClick={() => {
                              setShowDialog(false);
                           }}
                           disabled={formik.isSubmitting}
                        >
                           Abbrechen
                        </Button>
                        <SubmitButton formik={formik} variant="primary">
                           Speichern
                        </SubmitButton>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest du deine Notiz für <strong>{currentMonth.format('MMMM YYYY')}</strong>{' '}
               wirklich löschen?
            </span>
         </ConfirmDeleteDialog>
      </>
   );
};
