import { Col, Container, Image, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setTitle } from '../utils';
import { FloatingPanel } from '../atoms';
import WelcomeDesktop from '../assets/img/welcome_desktop.png';
import WelcomeMobile from '../assets/img/welcome_mobile.png';
import WelcomeDashboard from '../assets/img/welcome_dashboard.png';
import WelcomeAbsences from '../assets/img/welcome_absences.png';
import WelcomeAccounting from '../assets/img/welcome_accounting.png';

export const WelcomePage = () => {
   useEffect(() => setTitle(''), []);

   return (
      <Container className="py-2">
         <FloatingPanel>
            <Col className="px-3 py-4">
               <h1 className="mb-4">Coaching-Zeitplaner</h1>
               <Row>
                  <Col md={6} className="d-flex flex-column justify-content-center">
                     <p className="lead">
                        Der Coaching-Zeitplaner stellt eine einfache Verwaltung deiner
                        Coaching-Klassen mit den dazugehörigen Coaches dar.
                     </p>
                     <p className="lead">
                        Jeder Coach erhält seinen eigenen Kalender um seine Termine im Überblick zu
                        behalten. Der Kalender kann dabei auch auf mobilen Geräten oder anderen
                        Kalender-Apps eingerichtet werden.
                     </p>
                     {/*
                        description="Der Kalender gibt dir einen schnellen Überblick über alle Klassen im gewählten Zeitraum. Verschiedene Typen von Klassen werden dabei farblich unterschiedlich dargestellt. Über Filter kann die Ansicht entsprechend angepasst werden."
                        description="Die Coaching-Termine können auch per Kalender-Abo auf allen Endgeräten wie z.B. iPhone angezeigt werden. Dabei werden Kalender-Features wie Benachrichtigungen ebenfalls unterstützt."
                     */}
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center">
                     <div className="position-relative">
                        <Image src={WelcomeDesktop} style={{ textAlign: 'center' }} width="80%" />
                        <Image
                           src={WelcomeMobile}
                           className="position-absolute"
                           style={{ bottom: 0, right: 0 }}
                           width="55%"
                        />
                     </div>
                  </Col>
               </Row>
               <p className="m-0 mt-3">
                  <Link className="btn btn-primary" to="/registration">
                     Klicke hier für die Registrierung
                  </Link>
               </p>
            </Col>
         </FloatingPanel>
         <FeatureRow
            title="Dashboard"
            description="Übersichtliche Darstellung von anstehenden Coaching-Termine und weiteren Aufgaben."
            image={WelcomeDashboard}
         />
         <FeatureRow
            title="Abwesenheiten"
            description="Die Pflege von Abwesenheiten ermöglicht eine einfachere Erstellung des Coaching-Plans, sowie der frühzeitigen Erkennung von Engpässen bei der Klassenbesetzung."
            image={WelcomeAbsences}
            inverted
         />
         <FeatureRow
            title="Abrechnung"
            description="Die erfassten Daten können direkt für die Abrechnung der geleisteten Stunden verwendet werden."
            image={WelcomeAccounting}
         />
         <p className="m-0 mt-5 mb-2 d-flex justify-content-center">
            <Link className="btn btn-primary" to="/registration">
               Interesse geweckt? Dann Klicke hier für die Registrierung!
            </Link>
         </p>
      </Container>
   );
};

interface FeatureRowProps {
   title: string;
   description: string;
   image: string;
   inverted?: boolean;
}

const FeatureRow = (props: FeatureRowProps) => (
   <div className="pt-5">
      <Row
         className={
            props.inverted
               ? 'flex-column-reverse flex-md-row-reverse'
               : 'flex-column-reverse flex-md-row'
         }
      >
         <Col xs={12} md={5} className="d-flex justify-content-center">
            <Image src={props.image} style={{ textAlign: 'center' }} width="100%" />
         </Col>
         <Col xs={12} md={7} className="d-flex flex-column justify-content-center">
            <h4>{props.title}</h4>
            <p className="lead">{props.description}</p>
         </Col>
      </Row>
   </div>
);
