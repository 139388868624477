import { useParams } from 'react-router-dom';
import { Button, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import {
   mdiAccountCowboyHat,
   mdiAccountMultiple,
   mdiCalendarMonth,
   mdiChevronLeft,
   mdiMapMarker,
   mdiText,
} from '@mdi/js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { LinkContainer } from 'react-router-bootstrap';
import { formatRange, getClockIcon, setTitle } from '../utils';
import { MiniProfile } from '../molecules';
import { SkeletonPlaceholder } from '../atoms';
import { Meeting } from '../types/api';
import { useCache } from '../hooks';
import { MeetingModel } from '../models/MeetingModel';
import { EditMeetingDialog } from '../dialogs/EditMeetingDialog';

export const EventMeetingPage = () => {
   const { eventId } = useParams();
   const cachedData = useCache();
   const [meeting, setMeeting] = useState<Meeting>();
   const [showEditDialog, setShowEditDialog] = useState(false);
   const [paramIsInvalid, setParamIsInvalid] = useState(false);

   useEffect(() => setTitle('Coaching-Klasse'), []);

   useEffect(() => {
      (async () => {
         const id = Number(eventId);
         if (!Number.isNaN(id)) {
            try {
               setMeeting((await MeetingModel.get(id)) ?? undefined);
               return;
            } catch {
               /* ignore */
            }
         }
         toast.error('Das aufgerufene Meeting existiert nicht.');
         setParamIsInvalid(true);
      })();
   }, [eventId]);

   const handleSave = (m: Meeting) => {
      setMeeting(m);
      return Promise.resolve();
   };

   return (
      <Container className="py-2">
         <Row>
            <Col xs={0} md={2} />
            <Col
               xs={12}
               md={8}
               className="d-flex flex-row justify-content-between align-items-center"
            >
               <LinkContainer
                  to={{
                     pathname: '/calendar',
                     search: `?view=day&date=${moment(meeting?.start).format('YYYY-MM-DD')}`,
                  }}
               >
                  <Button variant="outline-link" className="d-flex align-items-center ps-1">
                     <Icon path={mdiChevronLeft} size={1} />
                     Kalender
                  </Button>
               </LinkContainer>
            </Col>
            <Col xs={0} md={2} />
         </Row>
         <Row>
            <Col xs={0} md={2} />
            <Col xs={12} md={8}>
               <ListGroup className="floating-panel">
                  <ListGroup.Item className="bg-secondary text-white">
                     <h2 className="mb-0">Meeting</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                     {paramIsInvalid && (
                        <div className="d-flex justify-content-center">
                           <em>Coaching-Class existiert nicht.</em>
                        </div>
                     )}
                     {!meeting && !paramIsInvalid && (
                        <Row>
                           <Col xs={12} lg={6}>
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="6rem" />
                           </Col>
                           <Col xs={12} lg={6}>
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="6rem" />
                           </Col>
                        </Row>
                     )}
                     {meeting && !paramIsInvalid && (
                        <Row>
                           <Col xs={12} lg={6}>
                              <FieldRow
                                 icon={mdiMapMarker}
                                 value={cachedData.rooms.find(r => r.id === meeting?.room_id)?.name}
                              />
                              <FieldRow
                                 icon={mdiCalendarMonth}
                                 value={moment(meeting?.start).format('L')}
                              />
                              <FieldRow
                                 icon={getClockIcon(meeting.start)}
                                 value={`${formatRange(
                                    meeting?.start,
                                    meeting?.end,
                                    'simple-date-with-time'
                                 )}`}
                              />
                              <FieldRow icon={mdiText}>
                                 <Form.Control
                                    as="textarea"
                                    className="bg-white"
                                    rows={3}
                                    value={meeting?.comment ?? ''}
                                    readOnly
                                 />
                              </FieldRow>
                           </Col>
                           <Col xs={12} lg={6}>
                              <FieldRow icon={mdiAccountCowboyHat}>
                                 <MiniProfile
                                    className="form-control"
                                    user={meeting?.organizer_id}
                                 />
                              </FieldRow>

                              {(meeting?.participants?.length ?? 0) > 0 && (
                                 <FieldRow icon={mdiAccountMultiple}>
                                    <div className="form-control h-auto d-flex flex-column">
                                       {meeting?.participants.map(p => (
                                          <MiniProfile key={p} user={p} className="my-1" />
                                       ))}
                                    </div>
                                 </FieldRow>
                              )}
                           </Col>
                        </Row>
                     )}
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-light d-flex justify-content-end">
                     <Button
                        variant="primary"
                        onClick={() => setShowEditDialog(true)}
                        disabled={paramIsInvalid}
                     >
                        Bearbeiten
                     </Button>
                  </ListGroup.Item>
               </ListGroup>
               <EditMeetingDialog
                  meeting={meeting ?? {}}
                  show={showEditDialog}
                  onClose={() => setShowEditDialog(false)}
                  afterSave={handleSave}
               />
            </Col>
            <Col xs={0} md={2} />
         </Row>
      </Container>
   );
};

interface FieldRowProps {
   icon?: string;
   value?: string;
   children?: React.ReactNode;
}

// TODO: Code doppelt
const FieldRow = (props: FieldRowProps) => (
   <Row className="my-2 align-items-center">
      <Col>
         <InputGroup>
            {props.icon && (
               <InputGroup.Text>
                  <Icon path={props.icon} size={1} />
               </InputGroup.Text>
            )}
            {props.children || (
               <Form.Control className="bg-white" type="text" value={props.value} readOnly />
            )}
         </InputGroup>
      </Col>
   </Row>
);
