import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiArrowExpand,
   mdiCalendarOutline,
   mdiClockTimeFourOutline,
   mdiMapMarkerOutline,
} from '@mdi/js';
import moment from 'moment/moment';
import { EventEntryProps, MeetingEvent } from './EventTypes';
import EventPopover from '../../molecules/EventPopover';
import { EditMeetingDialog } from '../../dialogs/EditMeetingDialog';
import { MeetingModel } from '../../models/MeetingModel';
import { Meeting, ROLE_BOX_OWNER, ROLE_SCHEDULER } from '../../types/api';
import { Colors, formatRange } from '../../utils';
import { MiniProfile, ShowIfRole } from '../../molecules';

export const MeetingEventEntry = ({ event }: EventEntryProps<MeetingEvent>) => {
   const [showOverlay, setShowOverlay] = useState(false);
   const [meetingToEdit, setMeetingToEdit] = useState<Partial<Meeting>>();

   useEffect(() => {
      if (event.popupTarget) setShowOverlay(true);
   }, [event.popupTarget]);

   const handleExpandMeeting = async () => {
      const m = await MeetingModel.get(event.id);
      if (!m) return;

      setMeetingToEdit(m);
      setShowOverlay(false);
   };

   return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <div
         role="tooltip"
         onClick={e => {
            if (event.popupTarget) e.stopPropagation();
         }}
      >
         <strong className="small">{event.title}</strong>
         <EventPopover
            target={event.popupTarget}
            show={showOverlay}
            onHide={() => {
               setShowOverlay(false);
               event.popupTarget = undefined;
            }}
         >
            {meetingPopover(event as MeetingEvent, handleExpandMeeting)}
         </EventPopover>

         <EditMeetingDialog
            show={!!meetingToEdit}
            onClose={() => {
               setMeetingToEdit(undefined);
            }}
            meeting={meetingToEdit ?? {}}
         />
      </div>
   );
};

const meetingPopover = (event: MeetingEvent, onExpand: () => Promise<void>) => (
   <Popover style={{ maxWidth: 350 }}>
      <Popover.Body className="p-0">
         <div className="d-flex">
            <div className="ps-3 pt-2 pe-2 flex-fill">
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiCalendarOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <h3>{event.title}</h3>
               </div>
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiClockTimeFourOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <div>
                     <h5 className="mb-0">{moment(event.start).format('dddd, LL')}</h5>
                     <span>{formatRange(event.start, event.end, 'simple-date-with-time')}</span>
                  </div>
               </div>
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiMapMarkerOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <h5>{event.room?.name}</h5>
               </div>
            </div>
            <ShowIfRole roles={[ROLE_SCHEDULER, ROLE_BOX_OWNER]}>
               <div>
                  <Button variant="link" className="me-1 mt-1 btn p-0" onClick={onExpand}>
                     <Icon path={mdiArrowExpand} color={Colors.secondary} size={1} />
                  </Button>
               </div>
            </ShowIfRole>
         </div>
         {event.organizer && (
            <>
               <div
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', height: 1 }}
                  className="mx-3 my-2"
               >
                  &nbsp;
               </div>
               <div className="d-flex flex-column mx-3 mb-2">
                  <MiniProfile user={event.organizer} type="large" className="my-1" />
               </div>
            </>
         )}
         {(event.meeting.participants?.length ?? 0) > 0 && (
            <>
               <EventPopover.Divider />
               <div className="d-flex flex-row flex-wrap mx-3 mb-2">
                  {event.meeting.participants?.map(p => (
                     <MiniProfile key={p} user={p} type="normal" className="my-1 me-1" />
                  ))}
               </div>
            </>
         )}
         {event.meeting.comment && (
            <>
               <EventPopover.Divider />
               <div className="mx-3 mb-3">
                  <em>{event.meeting.comment}</em>
               </div>
            </>
         )}
      </Popover.Body>
   </Popover>
);
