import React, { useMemo } from 'react';
import { mdiAccountGroup, mdiWeightLifter } from '@mdi/js';
import { useParams } from 'react-router-dom';
import { MODULE_PERSONAL_TRAINING } from '../../types/api';
import { AccountingPersonalTrainings } from './AccountingPersonalTrainings';
import { AccountingCoaches } from './AccountingCoaches';
import { MenuItem, PageWithSideMenu } from '../../templates';
import { useModule } from '../../hooks';

export const AccountingPage = () => {
   const params = useParams();
   const ptModuleEnabled = useModule(MODULE_PERSONAL_TRAINING);

   const menuItems: MenuItem[] = useMemo(() => {
      const items = [
         {
            title: 'Coaches',
            key: 'coaches',
            icon: mdiAccountGroup,
            component: () => <AccountingCoaches />,
         },
      ];

      if (ptModuleEnabled) {
         items.push({
            title: 'Personal Trainings',
            key: 'personalTrainings',
            icon: mdiWeightLifter,
            component: () => <AccountingPersonalTrainings />,
         });
      }

      return items;
   }, [ptModuleEnabled]);

   return (
      <PageWithSideMenu
         menuItems={menuItems}
         selectedMenuItem={params.tab}
         onBuildLink={tab => `/accounting/${tab}`}
      />
   );
};
