import { FormikProps } from 'formik';
import { ButtonVariant } from 'react-bootstrap/types';
import React from 'react';
import { LoadingButton } from '../molecules';

export interface SubmitButtonProps<T> {
   /**
    * Some FormikProps which contains infos about the state of the form.
    */
   formik: Pick<FormikProps<T>, 'isValid' | 'isSubmitting' | 'submitCount'>;
   /**
    * The display variant for the button.
    */
   variant?: ButtonVariant;
   /**
    * Additional classes.
    */
   className?: string;
   /**
    * The content of the button.
    */
   children: React.ReactNode;
   /**
    * When `true` the component is disabled.
    */
   disabled?: boolean;
   /**
    * The size of the button.
    */
   size?: 'sm' | 'lg';
}

/**
 * Renders a {@link LoadingButton} for submitting a form.
 * Based on the state of the form the button is only clickable when no validation errors exists.
 */
export const SubmitButton = <T,>({
   formik,
   variant = 'primary',
   className,
   children,
   disabled,
   size,
}: SubmitButtonProps<T>) => (
   <LoadingButton
      variant={variant}
      className={className}
      type="submit"
      size={size}
      disabled={(!formik.isValid && formik.submitCount > 0) || formik.isSubmitting || disabled}
      isLoading={formik.isSubmitting}
   >
      {children}
   </LoadingButton>
);
