import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import AppReducer from './reducers/appReducer';
import ScheduleReducer from './reducers/scheduleReducer';
import AdminReducer from './reducers/adminReducer';
import PTReducer from './reducers/ptReducer';

const rootReducer = combineReducers({
   app: AppReducer,
   schedule: ScheduleReducer,
   admin: AdminReducer,
   pt: PTReducer,
});

const middleware = [reduxThunk];

// TODO: https://redux.js.org/introduction/getting-started#redux-toolkit-example
const store = createStore(
   rootReducer,
   process.env.NODE_ENV === 'development'
      ? composeWithDevTools(applyMiddleware(...middleware))
      : applyMiddleware(...middleware)
);

export default store;

export type RootState = ReturnType<typeof rootReducer>;
