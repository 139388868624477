import { ProgressBar } from 'react-bootstrap';
import React, { memo } from 'react';
import { Tooltip } from 'react-tooltip';

interface Props {
   /**
    * Number of classes already coached.
    */
   done: number;
   /**
    * Number of all classes to be coached.
    */
   all: number;
   /**
    * ID of the progress bar (for Tooltip)
    */
   id: string;
}

export const CoachingHoursProgressBar = memo(({ done, all, id }: Props) => (
   <ProgressBar className="w-100">
      {done > 0 && (
         <>
            <ProgressBar
               id={`coaching-hour-progress-done-${id}`}
               isChild
               variant="primary"
               now={all === 0 ? 100 : Math.floor((done / all) * 100)}
               label={done}
            />
            <Tooltip
               anchorSelect={`#coaching-hour-progress-done-${id}`}
               content={
                  done === 1
                     ? '1 Stunde wurde bereits gecoacht'
                     : `${done} Stunden wurden bereits gecoacht`
               }
               place="top"
            />
         </>
      )}
      {(all > 0 || done === 0) && (
         <>
            <ProgressBar
               id={`coaching-hour-progress-planned-${id}`}
               isChild
               className="bg-gray text-dark"
               now={all === 0 ? 100 : 100 - Math.floor((done / all) * 100)}
               label={all - done}
            />
            <Tooltip
               anchorSelect={`#coaching-hour-progress-planned-${id}`}
               content={
                  all - done === 1
                     ? 'Noch 1 Stunde als Coach eingeplant'
                     : `Noch ${all - done} Stunden als Coach eingeplant`
               }
               place="top"
            />
         </>
      )}
   </ProgressBar>
));
