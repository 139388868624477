import { isMobile } from 'react-device-detect';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import React from 'react';
import { BoxLogo, LoadingButton } from '../molecules';
import { Box } from '../types/api';
import ApiService from '../services/ApiService';
import { useCache } from '../hooks';

interface Props {
   show: boolean;
   onClose: () => void;
}

export const SwitchBoxDialog = (props: Props) => {
   const { allBoxes, box } = useCache();

   const handleChangeBox = async (changedBox: Box) => {
      try {
         await ApiService.http.get(`auth/switch?box=${changedBox.id}`);
         window.location.reload();
      } catch (error) {
         ApiService.handleError(error);
      }
   };
   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Modal.Header closeButton>
            <Modal.Title>Box wechseln</Modal.Title>
         </Modal.Header>

         <Modal.Body>
            <ListGroup>
               {allBoxes.map(b => (
                  <ListGroup.Item key={b.id} className="d-flex align-items-center">
                     <div className="d-flex align-items-center flex-fill">
                        <BoxLogo box={b} />
                        <div className="d-flex flex-fill">{b.name}</div>
                     </div>
                     {b.id === box?.id ? (
                        <em className="small">Ausgewählt</em>
                     ) : (
                        <Button variant="primary" size="sm" onClick={() => handleChangeBox(b)}>
                           Auswählen
                        </Button>
                     )}
                  </ListGroup.Item>
               ))}
            </ListGroup>
         </Modal.Body>

         <Modal.Footer className="bg-light">
            <LoadingButton variant="secondary" onClick={props.onClose}>
               Schließen
            </LoadingButton>
         </Modal.Footer>
      </Modal>
   );
};
