import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import {
   mdiContentDuplicate,
   mdiDotsVerticalCircleOutline,
   mdiPencilOutline,
   mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Colors, formatRange } from '../../utils';
import {
   forceSchedulePageReload,
   removeScheduleEntryInWeek,
   upsertScheduleEntryInWeek,
} from '../../store/actions/scheduleActions';
import { MiniProfile } from '../../molecules';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import { removeMeeting } from '../../store/actions/appActions';
import { Meeting, Room } from '../../types/api';
import { useCache } from '../../hooks';
import { CoachingScheduleEntry } from '../../types/CoachingScheduleEntry';
import { EditMeetingDialog } from '../../dialogs/EditMeetingDialog';
import { MeetingModel } from '../../models/MeetingModel';
import { DuplicateDialog } from '../../dialogs/DuplicateDialog';

type Props = {
   week: string;
   entry: CoachingScheduleEntry;
};
export const MeetingCard = ({ week, entry }: Props) => {
   const dispatch = useDispatch();
   const cachedData = useCache();
   const [room, setRoom] = useState<Room | null>();
   const [showDialog, setShowDialog] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
   const [coaches, setCoaches] = useState<number[]>();

   useEffect(() => {
      if (entry.type === 'Meeting') {
         const m = entry.obj as Meeting;
         setRoom(cachedData.rooms.find(r => r.id === m.room_id));

         setCoaches([m.organizer_id, ...m.participants]);
      }
   }, [entry, cachedData]);

   const handleAfterSave = (meeting: Meeting): Promise<void> => {
      dispatch(
         upsertScheduleEntryInWeek(moment(week), {
            id: meeting.id,
            type: 'Meeting',
            obj: meeting,
            start: meeting.start,
         })
      );
      return Promise.resolve();
   };

   const handleDelete = async () => {
      try {
         const m = entry.obj as Meeting;
         await MeetingModel.delete(m);

         dispatch(removeMeeting(m));
         dispatch(removeScheduleEntryInWeek(moment(week), entry));

         setShowDeleteDialog(false);
      } catch {
         /* ignore */
      }
   };

   if (entry.type !== 'Meeting') return null;

   const m = entry.obj as Meeting;
   return (
      <div className="d-flex flex-row align-items-center">
         <div
            className="coaching-class-card d-flex"
            style={{
               paddingLeft: '0.75rem',
               backgroundColor: '#FFFF00',
            }}
         >
            <span className="flex-fill">
               <div className="d-flex flex-wrap small">
                  <span className="">{`${moment(m.start).format('LT')} - ${moment(m.end).format(
                     'LT'
                  )}`}</span>
                  <span className="mx-1">·</span>
                  <span className="fst-italic">{room?.name}</span>
               </div>
               <div className="small fw-bold mb-0">{m?.name}</div>
               <span className="small coaches-list gap-1">
                  {coaches && coaches.length === 0 ? (
                     <Badge bg="warning" className="d-flex align-items-center">
                        kein Coach
                     </Badge>
                  ) : (
                     coaches?.map(c => <MiniProfile key={c} user={c} badgeVariant="secondary" />)
                  )}
               </span>
            </span>
            <span>
               <Dropdown>
                  <Dropdown.Toggle variant="link" className="no-caret p-0">
                     <Icon path={mdiDotsVerticalCircleOutline} size={1} color={Colors.secondary} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => setShowDialog(true)}
                     >
                        <Icon
                           path={mdiPencilOutline}
                           size={0.85}
                           color={Colors.secondary}
                           className="me-2"
                        />
                        Bearbeiten
                     </Dropdown.Item>
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => setShowDuplicateDialog(true)}
                     >
                        <Icon
                           path={mdiContentDuplicate}
                           size={0.85}
                           color={Colors.secondary}
                           className="me-2"
                        />
                        Duplizieren
                     </Dropdown.Item>
                     <Dropdown.Divider />
                     <Dropdown.Item
                        className="d-flex align-items-center text-danger"
                        onClick={() => setShowDeleteDialog(true)}
                     >
                        <Icon path={mdiTrashCanOutline} size={0.85} className="me-2" />
                        Löschen
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            </span>
         </div>
         <EditMeetingDialog
            meeting={m}
            show={showDialog}
            onClose={() => setShowDialog(false)}
            afterSave={handleAfterSave}
         />
         <DuplicateDialog
            entry={m}
            entryModel={MeetingModel}
            show={showDuplicateDialog}
            afterDuplicate={() => dispatch(forceSchedulePageReload())}
            onClose={async () => {
               setShowDuplicateDialog(false);
            }}
         />
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest du das Meeting vom{' '}
               <strong>{formatRange(m?.start, m?.end, 'full-date-with-time')}</strong> wirklich
               löschen?
            </span>
         </ConfirmDeleteDialog>
      </div>
   );
};
