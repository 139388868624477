import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import React from 'react';
import { Formik } from 'formik';
import { CheckControl, FormRow, FormUtils, SubmitButton } from '../../forms';
import { useUserSettings } from '../../hooks';
import { UserSettings } from '../../types/api';

interface Props {
   show: boolean;
   onClose: () => void;
}
export const ScheduleSettingsDialog = ({ show, onClose }: Props) => {
   const [settings, updateSettings] = useUserSettings();

   const handleSubmitForm = async (values: UserSettings['schedule']) => {
      await updateSettings(s => ({
         ...s,
         schedule: values,
      }));
      onClose();
   };

   return (
      <Modal show={show} onHide={onClose} centered={isMobile}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={settings.schedule ?? {}}
            enableReinitialize
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>Einstellungen</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                     <FormRow label="Anzahl Wochen">
                        <Row>
                           <InputGroup as={Col}>
                              <Form.Control
                                 type="number"
                                 name="weeksBefore"
                                 value={formik.values.weeksBefore}
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 min={0}
                                 isInvalid={FormUtils.isInvalid(formik, 'weeksBefore')}
                                 disabled={formik.isSubmitting}
                              />
                              <InputGroup.Text>
                                 {formik.values.weeksBefore === 1 ? 'Woche' : 'Wochen'} vorher
                              </InputGroup.Text>
                           </InputGroup>
                           <InputGroup as={Col}>
                              <Form.Control
                                 type="number"
                                 name="weeksAfter"
                                 value={formik.values.weeksAfter}
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 min={0}
                                 isInvalid={FormUtils.isInvalid(formik, 'weeksAfter')}
                                 disabled={formik.isSubmitting}
                              />
                              <InputGroup.Text>
                                 {formik.values.weeksAfter === 1 ? 'Woche' : 'Wochen'} nachher
                              </InputGroup.Text>
                           </InputGroup>
                        </Row>
                     </FormRow>
                     <CheckControl
                        label="Keine Dubletten erzeugen"
                        description="Wenn aktiviert, wird beim Einfügen geprüft, ob zu dem Zeitraum bereits ein gleichwertiger Termin existiert. Ist dies der Fall, so wird der neue Termin nicht gespeichert."
                        formik={formik}
                        name="preventDuplicates"
                     />
                     <CheckControl
                        label="Meetings nicht mitkopieren"
                        description="Gibt an, ob beim Kopieren einer ganzen Woche Meetings mitkopiert werden sollen."
                        formik={formik}
                        name="doNotCopyMeetings"
                     />

                     <CheckControl
                        label="Schließzeiten nicht mitkopieren"
                        description="Gibt an, ob beim Kopieren einer ganzen Woche Schließzeiten mitkopiert werden sollen."
                        formik={formik}
                        name="doNotCopyBoxClosingTimes"
                     />
                  </Modal.Body>

                  <Modal.Footer className="bg-light">
                     <Button variant="outline-link" onClick={onClose}>
                        Schließen
                     </Button>
                     <SubmitButton formik={formik}>Speichern</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
