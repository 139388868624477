import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Badge, ButtonGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiArrowExpand } from '@mdi/js';
import sortBy from 'lodash/sortBy';
import { AbsenceApprovalState, LoadingButton } from '../../molecules';
import { ItemList } from '../../organisms/ItemList';
import { Colors, formatRange } from '../../utils';
import { EditAbsenceDialog } from '../../dialogs/EditAbsenceDialog';
import { Absence } from '../../types/api';
import { AbsenceModel } from '../../models';
import { useSession } from '../../hooks';

export const MyAbsencesWidget = () => {
   const { sessionUser } = useSession();
   const [myEvents, setMyEvents] = useState<Absence[]>();
   const [absenceToShow, setAbsenceToShow] = useState<Absence>();

   useEffect(() => {
      (async () => {
         const absences = await AbsenceModel.listTimeRange(moment());
         const events = absences.filter(e => e.user_id === sessionUser?.id);
         setMyEvents(sortBy(events, 'start'));
      })();
   }, [sessionUser]);

   return (
      <>
         <ItemList
            title="Deine Abwesenheiten"
            emptyMessage="Keine Abwesenheiten vorhanden"
            items={myEvents}
         >
            {({ item }) => (
               <div className="d-flex align-items-start">
                  <div className="flex-fill w-75">
                     <div className="d-flex flex-row flex-wrap align-items-center">
                        <Badge bg="primary" className="me-2">
                           {formatRange(item.start, item.end, 'simple-date-only')}
                        </Badge>
                        <AbsenceApprovalState status={item.status} />
                     </div>
                     <div className="mt-1 w-100 overflow-ellipsis">
                        <span>
                           {item.type === 'vacation' && 'Urlaub'}
                           {item.type === 'other' && 'Sonstige'}
                        </span>
                        {item.comment && (
                           <>
                              <span className="mx-1">·</span>
                              <span className="text-muted fst-italic ">{item.comment}</span>
                           </>
                        )}
                     </div>
                  </div>
                  <ButtonGroup>
                     <LoadingButton
                        variant="outline-link"
                        size="sm"
                        className="d-flex align-items-center"
                        onClick={() => setAbsenceToShow(item)}
                        hideContentWhenLoading
                     >
                        <Icon path={mdiArrowExpand} size={0.75} color={Colors.secondary} />
                     </LoadingButton>
                  </ButtonGroup>
               </div>
            )}
         </ItemList>
         <EditAbsenceDialog
            absence={absenceToShow}
            show={!!absenceToShow}
            onClose={() => setAbsenceToShow(undefined)}
         />
      </>
   );
};
