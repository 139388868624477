import { BaseModel } from './BaseModel';
import ApiService from '../services/ApiService';
import { Box, BoxStatistic } from '../types/api';
import { IApiResponse } from '../types/IApiResponse';

class BoxModelClass extends BaseModel<Box> {
   public getName(): string {
      return 'box';
   }

   public async adminList(q = ''): Promise<Box[]> {
      try {
         const queryString = q.length === 0 ? '' : `?query=${encodeURIComponent(q)}`;
         const response = await ApiService.http.get<IApiResponse<Box>>(`admin/box${queryString}`);

         return response.data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async adminInsert(obj: Partial<Box>): Promise<Box> {
      try {
         const response = await ApiService.http.post<IApiResponse<Box>>(`admin/box`, obj);

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async adminUpdate(obj: Partial<Box>): Promise<Box> {
      try {
         const response = await ApiService.http.put<IApiResponse<Box>>(`admin/box/${obj.id}`, obj);

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async adminStats(boxId: number): Promise<BoxStatistic> {
      try {
         const response = await ApiService.http.get<IApiResponse<BoxStatistic>>(
            `admin/box/${boxId}/stats`
         );

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async uploadLogo(boxId: number, file: File): Promise<void> {
      try {
         const formData = new FormData();
         formData.append('image', file);

         await ApiService.http.put(`data/box/${boxId}/logo`, formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
            },
         });
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async adminToggleModule(boxId: number, module: string, enabled: boolean): Promise<Box> {
      try {
         const response = await ApiService.http.put<IApiResponse<Box>>(
            `admin/box/${boxId}/module`,
            {
               module: module,
               enabled: enabled,
            }
         );

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
export const BoxModel = new BoxModelClass();
