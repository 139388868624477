import moment, { Moment } from 'moment';
import { BaseModel, TimeBasedObjectModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';

import { Meeting } from '../types/api';

class MeetingModelClass extends BaseModel<Meeting> implements TimeBasedObjectModel<Meeting> {
   public getName(): string {
      return 'meeting';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<Meeting[]> {
      return ModelExtension.listTimeRange<Meeting>(this.getName(), from, to);
   }

   public async listPerWeek(startOfWeek: Moment): Promise<Meeting[]> {
      return ModelExtension.listPerWeek(this, startOfWeek);
   }

   public async copyWeek(
      srcWeek: Moment,
      destWeek: Moment,
      skipDuplicates: boolean
   ): Promise<Meeting[]> {
      return ModelExtension.copyWeek(MeetingModel, srcWeek, destWeek, skipDuplicates);
   }

   public override isDoublet(a: Meeting, b: Meeting): boolean {
      return (
         moment(a.start).isSame(b.start, 'second') &&
         moment(a.end).isSame(b.end, 'second') &&
         a.room_id === b.room_id &&
         a.organizer_id === b.organizer_id
      );
   }
}

export const MeetingModel = new MeetingModelClass();
