import React, { useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiDomain, mdiPencilOutline } from '@mdi/js';
import { Button, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useImage } from 'react-image';
import { Colors } from '../../utils';
import { API_ENDPOINT } from '../../utils/config';

interface Props {
   boxId?: number | null;
   onFileSelect: (file: File) => void;
   disabled?: boolean;
}

export const LogoControl = (props: Props) => {
   const [selectedFile, setSelectedFile] = useState<File>();
   const inputFile = useRef<HTMLInputElement>(null);

   const { src } = useImage({
      srcList: `${API_ENDPOINT}/data/box/${props.boxId ?? 0}/logo`,
      useSuspense: false,
   });

   const fileIsValid = (file: File): boolean => {
      const sizeInMB = file.size / 1024 / 1024;
      if (sizeInMB > 1) {
         toast.error('Die ausgewählte Datei darf maximal 1 MB groß sein.');
         return false;
      }

      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
      switch (fileExtension.toLowerCase()) {
         case '.jpg':
         case '.bmp':
         case '.png':
            return true;
         default:
            toast.error('Es dürfen nur Bilder (.jpg, .bmp, .png) ausgewählt werden.');
            return false;
      }
   };

   return (
      <div
         className="d-inline-block position-relative"
         style={{
            height: '7rem',
            width: '7rem',
         }}
      >
         <input
            ref={inputFile}
            type="file"
            id="file"
            style={{ display: 'none' }}
            onChange={async e => {
               const file = e.target.files?.[0];
               if (!file || !props.boxId) return;

               if (fileIsValid(file)) {
                  setSelectedFile(file);
                  props.onFileSelect(file);
               }
            }}
         />
         <div
            className="bg-light overflow-hidden"
            style={{
               borderRadius: '0.5rem',
            }}
         >
            {selectedFile && (
               <Image src={URL.createObjectURL(selectedFile)} width="100%" height="100%" />
            )}
            {!selectedFile && src ? (
               <Image src={src} width="100%" height="100%" />
            ) : (
               <Icon path={mdiDomain} size={4} color={Colors.secondary} className="m-2" />
            )}
         </div>
         <Button
            className="position-absolute p-1 text-white"
            variant="primary"
            style={{
               right: '-0.5rem',
               bottom: '-0.5rem',
               borderRadius: '50%',
               borderWidth: '0.25rem',
               borderStyle: 'solid',
            }}
            onClick={() => {
               inputFile.current?.click();
            }}
            disabled={props.disabled}
         >
            <Icon path={mdiPencilOutline} size={1} color={Colors.white} />
         </Button>
      </div>
   );
};
