import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiArrowExpand, mdiCalendarOutline, mdiClockTimeFourOutline } from '@mdi/js';
import { Colors } from '../../utils';
import { CoachingBackupEvent, EventEntryProps } from './EventTypes';
import EventPopover from '../../molecules/EventPopover';
import { MiniProfile } from '../../molecules';
import { EditCoachingBackupDialog } from '../../dialogs/EditCoachingBackupDialog';
import { CoachingBackup, Weekdays } from '../../types/api/CoachingBackup';

export const CoachingBackupEventEntry = ({ event }: EventEntryProps<CoachingBackupEvent>) => {
   const [eventToEdit, setEventToEdit] = useState<Partial<CoachingBackup>>();
   const [showOverlay, setShowOverlay] = useState(false);

   useEffect(() => {
      if (event.popupTarget) setShowOverlay(true);
   }, [event.popupTarget]);

   const handleExpand = async () => {
      setEventToEdit(event.coachingBackup);
      setShowOverlay(false);
   };

   return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <div
         role="tooltip"
         onClick={e => {
            if (event.popupTarget) e.stopPropagation();
         }}
      >
         <span className="d-flex align-items-center">
            <strong className="small">{event.title}</strong>
         </span>
         <div className="d-flex flex-wrap gap-1">
            {event.coachingBackup.coaches?.map(c => (
               <MiniProfile key={c} user={c} badgeVariant="secondary" />
            ))}
         </div>
         <EventPopover
            target={event.popupTarget}
            show={showOverlay}
            onHide={() => {
               setShowOverlay(false);
               event.popupTarget = undefined;
            }}
         >
            {popover(event, handleExpand)}
         </EventPopover>

         <EditCoachingBackupDialog
            show={!!eventToEdit}
            item={eventToEdit ?? {}}
            onClose={() => setEventToEdit(undefined)}
         />
      </div>
   );
};

const popover = (event: CoachingBackupEvent, onExpand: () => Promise<void>) => (
   <Popover style={{ maxWidth: 350 }}>
      <Popover.Body className="p-0">
         <div className="d-flex">
            <div className="ps-3 pt-2 pe-2 flex-fill">
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiCalendarOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <h3>Backup-Coaches</h3>
               </div>
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiClockTimeFourOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1"
                  />
                  <div>
                     <h5 className="mb-0">
                        {`${Weekdays[event.coachingBackup.day_of_week].label} ${
                           event.coachingBackup.start_time
                        } bis ${event.coachingBackup.end_time}`}
                     </h5>
                  </div>
               </div>
            </div>
            <div>
               <Button variant="link" className="me-1 mt-1 btn p-0" onClick={onExpand}>
                  <Icon path={mdiArrowExpand} color={Colors.secondary} size={1} />
               </Button>
            </div>
         </div>
         {(event.coachingBackup.coaches.length ?? 0) > 0 && (
            <>
               <EventPopover.Divider />
               <div className="d-flex flex-column flex-wrap mx-3 mb-2">
                  {event.coachingBackup.coaches?.map(c => (
                     <MiniProfile key={c} user={c} type="normal" className="my-1 me-1" />
                  ))}
               </div>
            </>
         )}
      </Popover.Body>
   </Popover>
);
