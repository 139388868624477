import { SingleApiObject } from './BaseTypes';

export const ROLE_COACH = 'coach';
export const ROLE_SCHEDULER = 'scheduler';
export const ROLE_BOX_OWNER = 'box_owner';
export const ROLE_ADMIN = 'admin';
export const ROLE_PERSONAL_TRAINER = 'personal_trainer';
export const ROLE_ACCOUNTANT = 'accountant';

export interface User extends SingleApiObject {
   sub: string;
   email: string;
   phone?: string;
   is_demo?: boolean;
   demo_password?: string;
   first_name: string;
   last_name: string;
   nickname?: string;
   profile_url?: string;
   has_avatar: boolean;
   enabled: boolean;
   type: 'full-time' | 'part-time' | 'mini-job' | 'freelancer';
   preferred_box_id: number;
   vacation_days_current_year: number;
   vacation_remaining_last_year: number;
   monthly_classes_count: number;
   has_to_coach_every_week: boolean;
   absence_color: string;
   settings: string;
   roles: string[];
}

export interface UserSettings {
   schedule: Partial<{
      weeksBefore: number;
      weeksAfter: number;
      preventDuplicates: boolean;
      doNotCopyMeetings: boolean;
      doNotCopyBoxClosingTimes: boolean;
   }>;
}

export const UserTypes = [
   {
      label: 'Vollzeit',
      value: 'full-time',
   },
   {
      label: 'Teilzeit',
      value: 'part-time',
   },
   {
      label: 'Mini-Jobber',
      value: 'mini-job',
   },
   {
      label: 'Freiberufler',
      value: 'freelancer',
   },
];
