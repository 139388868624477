import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { CSSProperties, memo } from 'react';
import { Variant } from 'react-bootstrap/types';
import { User } from '../types/api';
import { useCache } from '../hooks';
import { Avatar } from './Avatar';

interface Props {
   /**
    * The user object or the id of the user.
    */
   user?: User | number;
   /**
    * The display type for the profile.
    */
   type?: 'avatar' | 'normal' | 'large' | 'nameOnly';
   /**
    * When specified the user profile will be displayed as a badge with the given variant.
    */
   badgeVariant?: Variant;
   /**
    * The specific size of the avatar. Only available when using type `avatar`.
    */
   avatarSize?: string;
   /**
    * Additional classes.
    */
   className?: string;
   /**
    * Additional styling.
    */
   style?: CSSProperties;
}

/**
 * Creates a profile element for the given user based on the type of presentation.
 */
export const MiniProfile = memo(
   ({
      user,
      type = 'normal',
      badgeVariant,
      className = '',
      avatarSize = '1.5rem',
      style,
   }: Props) => {
      const { users } = useCache();

      const getUser = (): User | undefined => {
         if (typeof user === 'number') return users.find(u => u.id === user);
         return user;
      };

      if (!user) return null;

      if (badgeVariant) {
         const userObj = getUser();
         return (
            <Badge
               bg={badgeVariant}
               // Stellt sicher, dass der Text immer guten Kontrast hat
               text={['warning', 'light'].includes(badgeVariant) ? 'dark' : 'light'}
               className={className}
            >
               {userObj?.nickname || userObj?.first_name}
            </Badge>
         );
      }

      if (type === 'nameOnly') {
         const userObj = getUser();
         return (
            <div>
               {userObj?.first_name} {userObj?.last_name}
            </div>
         );
      }

      return (
         <div
            className={`d-flex align-items-center ${className}`}
            style={{ flexBasis: 0, ...style }}
         >
            {type === 'avatar' ? (
               <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                     <Tooltip id={`tooltip-user-${getUser()?.id}`}>
                        {`${getUser()?.first_name} ${getUser()?.last_name}`}
                     </Tooltip>
                  }
               >
                  <Avatar url={getUser()?.profile_url} style={{ width: avatarSize }} />
               </OverlayTrigger>
            ) : (
               <>
                  <Avatar
                     url={getUser()?.profile_url}
                     style={{ width: type === 'normal' ? '1.5rem' : '3.125rem' }}
                  />
                  <span className={`${type === 'large' ? 'h5' : ''} m-0 ms-2 overflow-ellipsis`}>
                     {getUser()?.first_name} {getUser()?.last_name}
                  </span>
               </>
            )}
         </div>
      );
   }
);
