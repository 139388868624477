import moment from 'moment';
import React from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import { Colors } from '../utils';

interface Props {
   obj?: { x_date_inserted?: Date; x_date_updated?: Date } | null;
   hidden?: boolean;
}

export const CreateUpdateInfo = ({ obj, hidden = false }: Props) => {
   const getContent = () => (
      <>
         <OverlayTrigger
            placement="top"
            overlay={
               <Tooltip id="tooltip-created">
                  {moment(obj?.x_date_inserted).format('L [um] LTS')}
               </Tooltip>
            }
         >
            <small className="text-muted fst-italic">
               Erstellt: {moment(obj?.x_date_inserted).fromNow()}
            </small>
         </OverlayTrigger>
         <OverlayTrigger
            placement="top"
            overlay={
               <Tooltip id="tooltip-created">
                  {moment(obj?.x_date_updated).format('L [um] LTS')}
               </Tooltip>
            }
         >
            <small className="text-muted fst-italic">
               Geändert: {moment(obj?.x_date_updated).fromNow()}
            </small>
         </OverlayTrigger>
      </>
   );

   if (hidden) return null;
   return (
      <span>
         <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={
               <Popover id="popover-meta">
                  <Popover.Body className="d-flex flex-column">{getContent()}</Popover.Body>
               </Popover>
            }
         >
            <Icon className="m-1" path={mdiInformationOutline} size={1} color={Colors.secondary} />
         </OverlayTrigger>
      </span>
   );
};
