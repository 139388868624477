import { NavigateAction, SlotInfo, View } from 'react-big-calendar';
import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { LoadingOverlay } from '../../organisms/LoadingOverlay';
import { EditAbsenceDialog } from '../../dialogs/EditAbsenceDialog';
import { Absence } from '../../types/api';
import { useSession } from '../../hooks';
import { Calendar } from '../../organisms/Calendar';
import { GenericEvent } from '../../organisms/Calendar/EventTypes';

interface AbsenceCalendarTabProps {
   events: GenericEvent[];
   isLoading: boolean;
   onReloadEvents: (month?: Moment) => Promise<void>;
}

export const AbsenceCalendarTab = ({
   events,
   isLoading,
   onReloadEvents,
}: AbsenceCalendarTabProps) => {
   const { sessionUser } = useSession();
   const [showDialog, setShowDialog] = useState(false);
   const [absenceToEdit, setAbsenceToEdit] = useState<Partial<Absence>>();

   const handleNavigate = async (newDate: Date, view: View, action: NavigateAction) => {
      switch (action) {
         case 'TODAY':
            await onReloadEvents();
            break;
         default:
            await onReloadEvents(moment(newDate).set('date', 1));
      }
   };

   const handleSelectSlot = (slotInfo: SlotInfo) => {
      setAbsenceToEdit({
         id: 0,
         user_id: sessionUser?.id,
         draft: true,
         start: new Date(slotInfo.start),
         // Ende geht bis Anfang des nächten Tages, daher hier einen Tag abziehen
         end: moment(slotInfo.end).add(-1, 'day').toDate(),
      });
      setShowDialog(true);
   };

   return (
      <LoadingOverlay loading={isLoading}>
         <div className="px-2">
            <Calendar
               events={events}
               views={['month']}
               defaultView="month"
               onNavigate={handleNavigate}
               onSelectSlot={handleSelectSlot}
            />
         </div>
         <EditAbsenceDialog
            show={showDialog}
            onClose={() => setShowDialog(false)}
            absence={absenceToEdit}
            afterSave={() => onReloadEvents()}
         />
      </LoadingOverlay>
   );
};
