import { isMobile } from 'react-device-detect';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LoadingButton } from '../molecules';

type Props = {
   show: boolean;
   children: React.ReactNode;
   onClose: () => void;
   onDelete: () => Promise<void>;
};

export const ConfirmDeleteDialog = (props: Props) => {
   const [isDeleting, setDeleting] = useState(false);

   const handleDelete = async () => {
      setDeleting(true);
      try {
         await props.onDelete();
      } finally {
         setDeleting(false);
      }
   };

   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Modal.Header closeButton>
            <Modal.Title>Löschen bestätigen</Modal.Title>
         </Modal.Header>

         <Modal.Body>{props.children}</Modal.Body>

         <Modal.Footer className="bg-light">
            <Button variant="outline-link" onClick={props.onClose} disabled={isDeleting}>
               Abbrechen
            </Button>
            <LoadingButton variant="danger" onClick={handleDelete} isLoading={isDeleting}>
               Löschen
            </LoadingButton>
         </Modal.Footer>
      </Modal>
   );
};
