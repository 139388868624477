import React from 'react';
import { Col, Container, Image, Row, Tab, Tabs } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom';
import { FloatingPanel } from '../atoms';
import { Headline } from '../molecules';
import GenericStep0 from '../assets/img/generic_step0.png';
import GenericStep1 from '../assets/img/generic_step1.png';
import MacStep2 from '../assets/img/mac_step2.png';
import MacStep3 from '../assets/img/mac_step3.png';
import MacStep4 from '../assets/img/mac_step4.png';
import MacStep5 from '../assets/img/mac_step5.png';
import IPhoneStep2 from '../assets/img/iphone_step2.png';
import IPhoneStep3 from '../assets/img/iphone_step3.png';
import IPhoneStep4 from '../assets/img/iphone_step4.png';
import IPhoneStep5 from '../assets/img/iphone_step5.png';
import IPhoneStep6 from '../assets/img/iphone_step6.png';
import IPhoneStep7 from '../assets/img/iphone_step7.png';
import IPhoneStep8 from '../assets/img/iphone_step8.png';
import IPhoneStep9 from '../assets/img/iphone_step9.png';
import GoogleStep2 from '../assets/img/google_step2.png';
import GoogleStep3 from '../assets/img/google_step3.png';
import GoogleStep4 from '../assets/img/google_step4.png';
import GoogleStep5 from '../assets/img/google_step5.png';
import GoogleStep6 from '../assets/img/google_step6.png';

const tutorials = {
   iphone: [
      {
         description:
            'Öffne den Dialog zum Kopieren des Links für ein Kalender. Wenn du noch kein Kalender hast, lege zuererst einen an.',
         picture: GenericStep0,
      },
      {
         description: 'Kopiere den Link für den Kalender.',
         picture: GenericStep1,
      },
      {
         description: 'Unter Einstellungen auf `Kalender` gehen.',
         picture: IPhoneStep2,
      },
      {
         description: 'Anschließend auf `Accounts`.',
         picture: IPhoneStep3,
      },
      {
         description: 'Weiter über `Account hinzufügen`.',
         picture: IPhoneStep4,
      },
      {
         description: '`Andere` auswählen.',
         picture: IPhoneStep5,
      },
      {
         description: '`Kalenderabo hinzufügen`',
         picture: IPhoneStep6,
      },
      {
         description: 'Füge den kopierten Link ein.',
         picture: IPhoneStep7,
      },
      {
         description: 'Bestätige über `Weiter`',
         picture: IPhoneStep8,
      },
      {
         description: 'In den Mail-Accounts kann das Abo wieder entfernt werden.',
         picture: IPhoneStep9,
      },
   ],
   macos: [
      {
         description:
            'Öffne den Dialog zum Kopieren des Links für ein Kalender. Wenn du noch kein Kalender hast, lege zuererst einen an.',
         picture: GenericStep0,
      },
      {
         description: 'Kopiere den Link für den Kalender.',
         picture: GenericStep1,
      },
      {
         description: 'Öffne `Mail` und füge neues Abo hinzu.',
         picture: MacStep2,
      },
      {
         description: 'Füge den kopierten Link ein.',
         picture: MacStep3,
      },
      {
         description: 'Stelle Aktualisierung auf `5 Minuten`.',
         picture: MacStep4,
      },
      {
         description: 'Zum Entfernen rechts auf das Abo klicken.',
         picture: MacStep5,
      },
   ],
   google: [
      {
         description:
            'Öffne den Dialog zum Kopieren des Links für ein Kalender. Wenn du noch kein Kalender hast, lege zuererst einen an.',
         picture: GenericStep0,
      },
      {
         description: 'Kopiere den Link für den Kalender.',
         picture: GenericStep1,
      },
      {
         description:
            'Gehe unter https://calendar.google.com unter `Other Calendar` auf das Plus-Symbol.',
         picture: GoogleStep2,
      },
      {
         description: 'Klicke anschließend auf `From URL`.',
         picture: GoogleStep3,
      },
      {
         description: 'Füge nun den kopierten Link ein und klicke `Add calendar`.',
         picture: GoogleStep4,
      },
      {
         description:
            'Die Anlage ist abgeschlossen und du kannst oben Links über den Pfeil wieder zurück gehen.',
         picture: GoogleStep5,
      },
      {
         description: 'Nun solltest du unter `Other calendars` den abonnierten Kalender sehen.',
         picture: GoogleStep6,
      },
      {
         description:
            'Das hinzugefügte Abo taucht nun auch auf Android Geräten auf, welche mit dem gleichen Google-Account laufen.',
      },
   ],
};

export const CalendarAboTutorialPage = () => (
   <Container className="py-2">
      <Headline title="Anleitung für Kalender-Abo" />
      <FloatingPanel>
         <Tabs defaultActiveKey="iphone" className="mb-3">
            <Tab eventKey="iphone" title="iPhone">
               <Tutorial steps={tutorials.iphone} />
            </Tab>
            <Tab eventKey="macos" title="Mac OS">
               <Tutorial steps={tutorials.macos} />
            </Tab>
            <Tab eventKey="google" title="Google/Android">
               <Tutorial steps={tutorials.google} />
            </Tab>
         </Tabs>
      </FloatingPanel>
   </Container>
);

const Tutorial = ({ steps }: { steps: { description: string; picture?: string }[] }) => (
   <div className="px-2">
      <Row>
         {steps.map((s, i) => (
            <Col key={s.description} xs={12} md={6}>
               <div className="d-flex align-items-start">
                  <h4 className="mb-0 me-1">{i + 1}.</h4>
                  <span className="mt-1">{s.description}</span>
               </div>
               {s.picture && (
                  <div className="d-flex flex-fill justify-content-center align-items-center">
                     <Zoom zoomMargin={4}>
                        <div className="justify-content-center align-items-center">
                           <Image src={s.picture} style={{ textAlign: 'center' }} width="80%" />
                        </div>
                     </Zoom>
                  </div>
               )}
            </Col>
         ))}
      </Row>
   </div>
);
