import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiFilterVariant } from '@mdi/js';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import { AbsenceStatus } from '../../types/api';
import { UserMultiSelect } from '../../forms/controls';
import { FloatingPanel } from '../../atoms';

export interface FilterDef {
   users?: number[];
   status?: string;
   start?: Date;
   end?: Date;
}

interface Props {
   onFilterChange: (filter: FilterDef) => void;
}

export const FilterWidget = ({ onFilterChange }: Props) => {
   const [isOpen, setOpen] = useState(false);
   const [users, setUsers] = useState<number[]>([]);
   const [status, setStatus] = useState<string>();
   const [start, setStart] = useState<Date>(
      moment(`${moment().year()}-01-01`, 'YYYY-MM-DD').toDate()
   );
   const [end, setEnd] = useState<Date>(moment(`${moment().year()}-12-31`, 'YYYY-MM-DD').toDate());

   const handleFilterChange = (data: Partial<FilterDef>) => {
      onFilterChange({
         users: users,
         status: status,
         start: start,
         end: end,
         ...data,
      });
   };

   useEffect(() => {
      handleFilterChange({});
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <FloatingPanel className="p-1">
         <Accordion flush>
            <Accordion.Item eventKey="0">
               <Accordion.Header onClick={() => setOpen(v => !v)}>
                  <Icon path={mdiFilterVariant} size={0.75} className="me-1" />
                  {isOpen ? 'Filter verbergen' : 'Filter anzeigen'}
               </Accordion.Header>
               <Accordion.Body className="p-0">
                  <div className="mt-2 px-2">
                     <Row>
                        <Form.Group as={Col} xs={12} sm={6} xl={4} className="mb-3 px-2">
                           <Form.Label>Coach</Form.Label>
                           <UserMultiSelect
                              values={users}
                              onChange={v => {
                                 setUsers(v);
                                 handleFilterChange({ users: v });
                              }}
                           />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} xl={4} className="mb-3 px-2">
                           <Form.Label>Status</Form.Label>
                           <Select
                              key={status}
                              options={AbsenceStatus}
                              placeholder="Alle Abwesenheiten"
                              defaultValue={AbsenceStatus.find(t => t.value === status)}
                              onChange={value => {
                                 setStatus(value?.value);
                                 handleFilterChange({ status: value?.value });
                              }}
                              isClearable
                           />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} lg={3} xl={2} className="mb-3 px-2">
                           <Form.Label>Start</Form.Label>
                           <Form.Control
                              as={DatePicker}
                              wrapperClassName="w-100"
                              dateFormat="dd.MM.yyyy"
                              locale="de"
                              calendarStartDay={1}
                              showWeekNumbers
                              placeholderText="__.__.____"
                              selected={start}
                              onChange={date => {
                                 setStart(date as unknown as Date);
                                 handleFilterChange({
                                    start: date as unknown as Date,
                                 });
                              }}
                           />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} sm={6} lg={3} xl={2} className="mb-3 px-2">
                           <Form.Label>Ende</Form.Label>
                           <Form.Control
                              as={DatePicker}
                              wrapperClassName="w-100"
                              dateFormat="dd.MM.yyyy"
                              locale="de"
                              calendarStartDay={1}
                              showWeekNumbers
                              placeholderText="__.__.____"
                              selected={end}
                              onChange={date => {
                                 setEnd(date as unknown as Date);
                                 handleFilterChange({
                                    end: date as unknown as Date,
                                 });
                              }}
                           />
                        </Form.Group>
                     </Row>
                  </div>
               </Accordion.Body>
            </Accordion.Item>
         </Accordion>
      </FloatingPanel>
   );
};
