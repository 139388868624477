import React, { memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { InfoTooltip } from '../molecules';

export interface FormRowProps {
   /**
    * The label for the form control.
    */
   label?: string;
   /**
    * An optional description for the form control. Only visible when there is a label.
    */
   description?: string;
   /**
    * The form control.
    */
   children: React.ReactNode;
   /**
    * Determine the display type. Default 'compact'.
    *
    * `compact`: The control utilized the whole width and the label is above the control.
    * `responsive`: When there is enough space, the label will be left to the control. Otherwise, the 'compact' type will be used.
    */
   type?: 'compact' | 'responsive';
}

/**
 * Renders a responsive form control with a label and an optional description.
 */
export const FormRow = memo(({ label, description, children, type = 'compact' }: FormRowProps) => (
   <Form.Group as={Row} className="mb-3">
      <Col
         xs={12}
         md={type === 'compact' ? 12 : 4}
         lg={type === 'compact' ? 12 : 3}
         className={`mt-0 d-flex align-items-start justify-content-start ${
            type === 'compact' ? '' : 'mt-md-2 justify-content-md-end'
         }`}
      >
         {label && (
            <Form.Label className="d-inline">
               {label}
               {description && <InfoTooltip text={description} />}
            </Form.Label>
         )}
      </Col>
      <Col
         xs={12}
         md={type === 'compact' ? 12 : 8}
         lg={type === 'compact' ? 12 : 9}
         className="d-flex justify-content-center flex-column"
         style={{ minHeight: '2,375rem' }}
      >
         {children}
      </Col>
   </Form.Group>
));
