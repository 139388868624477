import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiCheck,
   mdiClose,
   mdiPencilOutline,
   mdiPlus,
   mdiRestore,
   mdiTrashCanOutline,
} from '@mdi/js';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Formik, FormikErrors } from 'formik';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { SkeletonPlaceholder } from '../../atoms';
import { RootState } from '../../store';
import { getSelectStyles } from '../../utils';
import { Headline, LoadingButton } from '../../molecules';
import { SelectMonthControl } from '../../forms/controls';
import { PTProvidedService } from '../../types/api';
import { PTProvidedServiceModel } from '../../models';
import { FormUtils, SubmitButton, TextareaControl } from '../../forms';

export const PTProvidedServicesTab = () => {
   const [providedServices, setProvidedServices] = useState<PTProvidedService[]>();
   const [selectedMonth, setSelectedMonth] = useState(moment());

   const loadData = useCallback(async () => {
      setProvidedServices(undefined);
      setProvidedServices(
         await PTProvidedServiceModel.listTimeRange(
            selectedMonth,
            moment(selectedMonth).add(1, 'month')
         )
      );
   }, [selectedMonth]);

   useEffect(() => {
      (async () => {
         await loadData();
      })();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      (async () => loadData())();
   }, [loadData]);

   const handleAfterSave = (providedService: PTProvidedService, operation: 'insert' | 'update') => {
      if (operation === 'insert') setProvidedServices(v => [providedService, ...(v ?? [])]);
      else
         setProvidedServices(v =>
            (v ?? []).map(c => (c.id === providedService.id ? providedService : c))
         );
   };

   return (
      <>
         <Headline title="Dienstleistungen" browserTitle="Personal Trainings - Dienstleistungen">
            Trage hier alle Dienstleistungen für deine PT-Kunden ein, welche neben dem den
            klassischem Training vor Ort erbracht werden (z.B. Erstellung eines Ernährungsplan).
         </Headline>
         <SelectMonthControl className="mt-3" onChange={m => setSelectedMonth(m)} />

         <ListGroup className="floating-panel">
            {!providedServices ? (
               [...Array(6).keys()].map(i => (
                  <ListGroup.Item key={i} className="d-flex justify-content-between">
                     <span className="d-flex">
                        <SkeletonPlaceholder width="20rem" />
                     </span>
                     <SkeletonPlaceholder width="5rem" />
                  </ListGroup.Item>
               ))
            ) : (
               <>
                  <ProvidedServiceRow afterSave={handleAfterSave} />
                  {providedServices.map(ps => (
                     <ProvidedServiceRow
                        key={ps.id}
                        providedService={ps}
                        afterSave={handleAfterSave}
                     />
                  ))}
               </>
            )}
         </ListGroup>
      </>
   );
};

interface ProvidedServiceRowProps {
   providedService?: PTProvidedService;
   afterSave: (providedService: PTProvidedService, operation: 'insert' | 'update') => void;
}

const ProvidedServiceRow = (props: ProvidedServiceRowProps) => {
   const { clients, services } = useSelector((s: RootState) => s.pt);
   const [providedService, setProvidedService] = useState<Partial<PTProvidedService>>(
      props.providedService ?? { id: 0, amount: 1, date: new Date() }
   );
   const [isEditMode, setEditMode] = useState(providedService.id === 0);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);

   const handleSubmitForm = async (values: Partial<PTProvidedService>) => {
      const newProvidedService =
         values.id === 0
            ? await PTProvidedServiceModel.insert(values)
            : await PTProvidedServiceModel.update(values);

      props.afterSave(newProvidedService, providedService.id === 0 ? 'insert' : 'update');
      if (!props.providedService) {
         setProvidedService({ id: 0, amount: 1, date: new Date(), x_date_inserted: new Date() });
      } else {
         setEditMode(false);
         setProvidedService(newProvidedService);
      }
   };

   const handleDelete = async (values: Partial<PTProvidedService>) => {
      values.deleted = true;
      const updatedProvidedService = await PTProvidedServiceModel.update(values);
      props.afterSave(updatedProvidedService, 'update');
      setProvidedService(updatedProvidedService);
      setShowDeleteDialog(false);
   };

   const handleRestore = async () => {
      providedService.deleted = false;
      providedService.delete_reason = null;
      const updatedProvidedService = await PTProvidedServiceModel.update(providedService);
      props.afterSave(updatedProvidedService, 'update');
      setProvidedService(updatedProvidedService);
   };

   return (
      <>
         <ListGroup.Item>
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={providedService}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<PTProvidedService>> = {};

                  if (!values.date) errors.date = 'Bitte gib ein Datum an.';
                  if (!values.client_id) errors.client_id = 'Bitte wähle einen Kunden aus.';
                  if (!values.amount) errors.amount = 'Bitte gib die Anzahl an.';
                  if ((values?.amount ?? 0) <= 0)
                     errors.amount = 'Bitte gib eine Anzahl größer als 0 an.';
                  if (!values.service_id) errors.service_id = 'Bitte wähle einen service aus.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     className={`d-flex justify-content-between align-items-center ${
                        providedService.deleted ? 'text-line-through text-muted' : ''
                     }`}
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Row className="flex-fill me-1">
                        <Col xs={12} md={7} className="d-flex flex-row">
                           {isEditMode ? (
                              <>
                                 <span className="me-1" style={{ width: '7rem' }}>
                                    <Form.Control
                                       as={DatePicker}
                                       wrapperClassName="w-100"
                                       dateFormat="dd.MM.yyyy"
                                       locale="de"
                                       calendarStartDay={1}
                                       showWeekNumbers
                                       placeholderText="__.__.____"
                                       selected={formik.values.date}
                                       onChange={date => formik.setFieldValue('date', date)}
                                    />
                                 </span>

                                 <Select
                                    key={formik.values.client_id}
                                    options={clients}
                                    className="flex-fill"
                                    styles={getSelectStyles(
                                       FormUtils.isInvalid(formik, 'client_id')
                                    )}
                                    getOptionValue={c => c.name}
                                    getOptionLabel={c => c.name}
                                    placeholder="Kunde…"
                                    defaultValue={clients.find(
                                       c => c.id === formik.values.client_id
                                    )}
                                    onChange={value => formik.setFieldValue('client_id', value?.id)}
                                    isDisabled={formik.isSubmitting}
                                 />
                              </>
                           ) : (
                              <span className="ms-1">
                                 <span className="me-1 d-inline-block" style={{ width: '7rem' }}>
                                    {moment(providedService.date).format('DD.MM.YYYY')}
                                 </span>
                                 <span>
                                    {clients.find(c => c.id === providedService.client_id)?.name}
                                 </span>
                              </span>
                           )}
                        </Col>
                        <Col xs={12} sm={12} md={5} className="mt-2 mt-sm-0 d-flex flex-row">
                           {isEditMode ? (
                              <>
                                 <Form.Control
                                    type="number"
                                    name="amount"
                                    className="me-1"
                                    style={{ width: '6rem' }}
                                    value={formik.values.amount}
                                    min={1}
                                    onChange={formik.handleChange}
                                    isInvalid={FormUtils.isInvalid(formik, 'amount')}
                                    disabled={formik.isSubmitting}
                                 />
                                 <Select
                                    key={formik.values.service_id}
                                    options={services}
                                    className="w-100"
                                    styles={getSelectStyles(
                                       FormUtils.isInvalid(formik, 'service_id')
                                    )}
                                    getOptionValue={c => c.name}
                                    getOptionLabel={c => c.name}
                                    placeholder="Service…"
                                    defaultValue={services.find(
                                       c => c.id === formik.values.service_id
                                    )}
                                    onChange={value =>
                                       formik.setFieldValue('service_id', value?.id)
                                    }
                                    isDisabled={formik.isSubmitting}
                                 />
                              </>
                           ) : (
                              <div className="ms-1 me-2">
                                 {`${providedService.amount}x ${
                                    services.find(s => s.id === providedService.service_id)?.name
                                 }`}
                              </div>
                           )}
                        </Col>
                        <Col xs={12}>
                           {isEditMode ? (
                              <Form.Control
                                 as="textarea"
                                 className="mt-2"
                                 rows={3}
                                 name="comment"
                                 placeholder="Kommentar…"
                                 value={formik.values.comment ?? ''}
                                 onChange={formik.handleChange}
                                 isInvalid={FormUtils.isInvalid(formik, 'comment')}
                                 disabled={formik.isSubmitting}
                              />
                           ) : (
                              <em className="mt-2 text-muted">{providedService.comment}</em>
                           )}
                        </Col>
                     </Row>
                     <span className="d-flex justify-content-end" style={{ width: '5rem' }}>
                        {isEditMode && (
                           <div className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                              {providedService.id !== 0 && (
                                 <Button
                                    variant="outline-link"
                                    size="sm"
                                    className="d-flex align-items-center ms-md-2"
                                    onClick={() => setEditMode(false)}
                                    disabled={formik.isSubmitting}
                                 >
                                    <Icon path={mdiClose} size={0.75} />
                                 </Button>
                              )}
                              <SubmitButton
                                 formik={formik}
                                 size="sm"
                                 className="d-flex align-items-center"
                              >
                                 <Icon
                                    path={providedService.id !== 0 ? mdiCheck : mdiPlus}
                                    size={0.75}
                                 />
                              </SubmitButton>
                           </div>
                        )}
                        {!isEditMode && providedService.deleted && (
                           <LoadingButton
                              variant="outline-secondary"
                              size="sm"
                              className="d-flex align-items-center"
                              onClick={handleRestore}
                              tooltip="Wiederherstellen"
                              hideContentWhenLoading
                           >
                              <Icon path={mdiRestore} size={0.75} />
                           </LoadingButton>
                        )}
                        {!isEditMode && !providedService.deleted && (
                           <ButtonGroup>
                              <Button
                                 variant="outline-secondary"
                                 size="sm"
                                 className="d-flex align-items-center"
                                 onClick={() => setEditMode(true)}
                              >
                                 <Icon path={mdiPencilOutline} size={0.75} />
                              </Button>
                              <Button
                                 variant="outline-danger"
                                 size="sm"
                                 className="d-flex align-items-center"
                                 onClick={() => setShowDeleteDialog(true)}
                              >
                                 <Icon path={mdiTrashCanOutline} size={0.75} />
                              </Button>
                           </ButtonGroup>
                        )}
                     </span>
                  </Form>
               )}
            </Formik>
         </ListGroup.Item>
         <Modal
            show={showDeleteDialog}
            onHide={() => setShowDeleteDialog(false)}
            centered={isMobile}
         >
            <Formik
               onSubmit={handleDelete}
               initialValues={providedService}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<PTProvidedService>> = {};

                  if (!values.delete_reason) errors.delete_reason = 'Bitte gib einen Grund an.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>Dienstleistung löschen</Modal.Title>
                     </Modal.Header>

                     <Modal.Body>
                        <TextareaControl
                           formik={formik}
                           name="delete_reason"
                           placeholder="Grund der Löschung…"
                        />
                     </Modal.Body>

                     <Modal.Footer className="bg-light">
                        <Button
                           variant="outline-link"
                           onClick={() => setShowDeleteDialog(false)}
                           disabled={formik.isSubmitting}
                        >
                           Abbrechen
                        </Button>
                        <SubmitButton formik={formik} variant="danger">
                           Löschen
                        </SubmitButton>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
      </>
   );
};
