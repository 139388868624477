import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FloatingPanel } from '../atoms';
import './LoadingOverlay.scss';

interface Props {
   loading?: boolean;
   children: React.ReactNode;
   withBorderRadius?: boolean;
}

// TODO: Anzeigen/Verstecken per Animation
export const LoadingOverlay = (props: Props) => (
   <div className="loading-overlay-wrapper">
      {props.children}

      {props.loading && (
         <div
            className={`align-items-md-center pt-md-0 loading-overlay ${
               props.withBorderRadius ? 'border-radius' : ''
            }`}
         >
            <FloatingPanel className="mt-0 p-4">
               <Spinner animation="border" role="status" variant="success" />
            </FloatingPanel>
         </div>
      )}
   </div>
);
