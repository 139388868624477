import Icon from '@mdi/react';
import { mdiCancel } from '@mdi/js';
import React from 'react';
import { Colors } from '../../utils';
import { EventEntryProps, GenericEvent } from './EventTypes';

export const AbsenceProhibitionEventEntry = ({ event }: EventEntryProps<GenericEvent>) => (
   <span className="d-flex align-items-center">
      <Icon path={mdiCancel} size={0.75} color={Colors.warning} className="me-1" />
      <strong className="small">{event.title}</strong>
   </span>
);
