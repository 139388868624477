import { Badge, Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiContentCopy, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { ConfirmDeleteDialog } from '../dialogs/ConfirmDeleteDialog';
import { LoadingButton } from '../molecules';
import { FloatingPanel, SkeletonPlaceholder } from '../atoms';

interface Event {
   id: number;
   reason: string;
   start: Date;
   end: Date;
}

interface EventModel extends Event {
   isSelected: boolean;
}

interface EventListProps<T> {
   events?: T[];
   formatTimeRange: (start: Moment, end: Moment) => string;
   onAddEvent: () => void;
   onEditEvent: (event: T) => void;
   onDuplicateSingleEvent: (event: T) => void;
   onDeleteEvent: (event: T) => Promise<void>;
   onDeleteManyEvents: (events: T[]) => Promise<void>;
}

export const EventList = <T extends Event>(props: EventListProps<T>) => {
   const [events, setEvents] = useState<EventModel[]>();
   const [eventToDelete, setEventToDelete] = useState<EventModel>();
   const [showDeleteMany, setShowDeleteMany] = useState(false);

   useEffect(() => {
      setEvents(props?.events?.map(e => ({ ...e, isSelected: false } as EventModel)));
   }, [props]);

   const upsertEntry = (entry: EventModel) => {
      setEvents(e => [...(e ?? []).filter(c => c.id !== entry.id), entry]);
   };

   const handleDelete = async () => {
      if (!eventToDelete) return;

      await props.onDeleteEvent(eventToDelete as unknown as T);

      setEventToDelete(undefined);
   };

   const handleDeleteMany = async () => {
      const evt: T[] = (events ?? []).filter(e => e.isSelected).map(e => e as unknown as T);
      await props.onDeleteManyEvents(evt);
      setShowDeleteMany(false);
   };

   return (
      <>
         <div className="d-flex">
            <Button
               variant="success"
               className="floating-button"
               onClick={() => props.onAddEvent()}
            >
               Hinzufügen
            </Button>
         </div>

         <Form>
            <FloatingPanel className="ft-table">
               <Row className="ft-header">
                  <Col xs={9} lg={10} className="ft-th">
                     <Container className="p-0">
                        <Row className="p-0">
                           <Col xs={12} md={7} lg={8}>
                              Grund
                           </Col>
                           <Col xs={12} md={5} lg={4} className="text-md-center d-none d-md-block">
                              Zeitraum
                           </Col>
                        </Row>
                     </Container>
                  </Col>
                  <Col xs={3} lg={2} className="ft-th d-none d-md-block text-end">
                     Aktionen
                  </Col>
               </Row>
               <div className="ft-body">
                  {!events
                     ? [...Array(4).keys()].map(i => (
                          <Row key={i} className="ft-row">
                             <Col xs={9} lg={10} className="ft-td">
                                <Container className="p-0">
                                   <Row className="p-0">
                                      <Col xs={12} md={7} lg={8}>
                                         <SkeletonPlaceholder width="60%" />
                                      </Col>
                                      <Col
                                         xs={12}
                                         md={5}
                                         lg={4}
                                         className="text-start text-md-center"
                                      >
                                         <SkeletonPlaceholder width={150} />
                                      </Col>
                                   </Row>
                                </Container>
                             </Col>
                             <Col xs={3} lg={2} className="ft-td justify-content-end">
                                <SkeletonPlaceholder width={100} />
                             </Col>
                          </Row>
                       ))
                     : events
                          .sort((a, b) => {
                             const timeDiff = a.start.getTime() - b.start.getTime();
                             return timeDiff !== 0 ? timeDiff : a.id - b.id;
                          })
                          .map(ct => (
                             <Row
                                key={ct.id}
                                className={`ft-row ${ct.isSelected ? 'selected' : ''}`}
                                onClick={() => {
                                   ct.isSelected = !ct.isSelected;
                                   upsertEntry(ct);
                                }}
                             >
                                <Col xs={9} lg={10} className="ft-td">
                                   <Container className="p-0">
                                      <Row className="p-0 flex-column flex-md-row">
                                         <Col xs={12} md={7} lg={8}>
                                            {ct.reason}
                                         </Col>
                                         <Col
                                            xs={12}
                                            md={5}
                                            lg={4}
                                            className="text-start text-md-center"
                                         >
                                            <Badge bg="info">
                                               {props.formatTimeRange(
                                                  moment(ct.start),
                                                  moment(ct.end)
                                               )}
                                            </Badge>
                                         </Col>
                                      </Row>
                                   </Container>
                                </Col>
                                <Col xs={3} lg={2} className="ft-td justify-content-end">
                                   <ButtonGroup>
                                      <LoadingButton
                                         variant="outline-primary"
                                         size="sm"
                                         className="d-flex align-items-center"
                                         tooltip="Eintrag duplizieren"
                                         onClick={e => {
                                            props.onDuplicateSingleEvent(ct as unknown as T);
                                            e.stopPropagation();
                                         }}
                                      >
                                         <Icon path={mdiContentCopy} size={0.75} />
                                      </LoadingButton>
                                      <LoadingButton
                                         variant="outline-secondary"
                                         size="sm"
                                         className="d-flex align-items-center"
                                         tooltip="Eintrag bearbeiten"
                                         onClick={e => {
                                            props.onEditEvent(ct as unknown as T);
                                            e.stopPropagation();
                                         }}
                                      >
                                         <Icon path={mdiPencilOutline} size={0.75} />
                                      </LoadingButton>
                                      <LoadingButton
                                         variant="outline-danger"
                                         size="sm"
                                         className="d-flex align-items-center"
                                         tooltip="Eintrag löschen"
                                         onClick={e => {
                                            setEventToDelete(ct);
                                            e.stopPropagation();
                                         }}
                                      >
                                         <Icon path={mdiTrashCanOutline} size={0.75} />
                                      </LoadingButton>
                                   </ButtonGroup>
                                </Col>
                             </Row>
                          ))}
               </div>
            </FloatingPanel>
         </Form>
         <ConfirmDeleteDialog
            show={!!eventToDelete}
            onClose={() => setEventToDelete(undefined)}
            onDelete={handleDelete}
         >
            Möchtest Du den Eintrag <strong>{eventToDelete?.reason}</strong> im Zeitraum{' '}
            <strong>{`${moment(eventToDelete?.start).format('L')}: ${moment(
               eventToDelete?.start
            ).format('LT')} - ${moment(eventToDelete?.end).format('LT')}`}</strong>{' '}
            wirklich löschen.
         </ConfirmDeleteDialog>
         <ConfirmDeleteDialog
            show={showDeleteMany}
            onClose={() => setShowDeleteMany(false)}
            onDelete={handleDeleteMany}
         >
            Möchtest Du{' '}
            <strong>{`${events?.filter(e => e.isSelected).length} ${
               events?.filter(e => e.isSelected).length === 1 ? 'Eintrag' : 'Einträge'
            }`}</strong>{' '}
            wirklich löschen.
         </ConfirmDeleteDialog>
      </>
   );
};
