import React, { memo, useEffect, useMemo, useState } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import { Tooltip } from 'react-tooltip';
import { CoachStatsModel } from '../types/CoachStatsModel';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';
import { MiniProfile } from './MiniProfile';
import { SkeletonPlaceholder } from '../atoms';
import { CoachingHoursProgressBar } from './CoachingHoursProgressBar';
import { VacationProgressBar } from './VacationProgressBar';
import { User } from '../types/api';

interface Props {
   coach: User;
   month: Moment; // TODO: Nicht als Objekt wegen Rerendering übergeben
}

export const EmployeeStatsCard = memo(({ coach, month }: Props) => {
   const [stats, setStats] = useState<CoachStatsModel>();

   useEffect(() => {
      (async () => {
         try {
            const result = await ApiService.http.get<IApiResponse<CoachStatsModel>>(
               `stats/user/${coach.id}?month=${month.format('YYYY-MM')}`
            );
            setStats(result.data.data[0]);
         } catch (error) {
            ApiService.handleError(error);
            return Promise.reject(error);
         }
      })();
   }, [coach, month]);

   const cvWeeks = useMemo(() => {
      const sessionsDone = Object.entries(stats?.classesAndVacationsPerMonth ?? {}).reduce(
         (sum, val) => {
            const [, entry] = val;
            let v = 0;
            if (entry.vacationDays > 0) v += 1;
            if (entry.morningClasses > 0) v += 1;
            if (entry.eveningClasses > 0) v += 1;
            return sum + v;
         },
         0
      );

      // Erwartet wird eine Session pro Woche.
      //    Daher nehmen wir vom aktuellen Jahr die aktuelle Woche.
      let sessionsExpected = moment().week();
      // Von Jahren in der Vergangenheit nehmen wir immer die max. Anzahl der Wochen in diesem Jahr.
      if (month.year() < moment().year()) sessionsExpected = moment(month).endOf('year').weeks();
      // Von Jahren in der Zukunft nehmen wir immer 0.
      if (month.year() > moment().year()) sessionsExpected = 0;

      return {
         sessionsExpected: sessionsExpected,
         maxValue: Math.max(sessionsDone, sessionsExpected),
         sessionsDone: sessionsDone,
         sessionsLeft: Math.max(sessionsExpected - sessionsDone, 0),
         sessionsInAdvance: Math.max(sessionsDone - sessionsExpected, 0),
      };
   }, [month, stats?.classesAndVacationsPerMonth]);

   // TODO: Wenn mehr Stunden als Erlaubt -> roter Balken
   // TODO: Wenn mehr Urlaub als erlaubt -> roter Balken
   return (
      <Card className="w-100">
         <div className="d-flex flex-column align-items-center py-2 bg-light card-img-top">
            <MiniProfile user={coach} type="avatar" avatarSize="6.25rem" />
            <Card.Title as="h5" className="mt-2 px-3 w-100 text-center overflow-ellipsis">
               {coach.first_name} {coach.last_name}
            </Card.Title>
         </div>
         <Card.Body>
            <Card.Text>
               <Card.Title as="h6">Coaching</Card.Title>
               <div className="d-flex gap-2">
                  <div style={{ flex: 1 }}>
                     <small>{moment.months()[month.month()]}</small>
                     {!stats ? (
                        <SkeletonPlaceholder width="100%" height="1rem" />
                     ) : (
                        <CoachingHoursProgressBar
                           id={`${coach.id}-m-${month.month()}`}
                           done={stats.classesMonthDone}
                           all={stats.classesMonthAll}
                        />
                     )}
                  </div>
                  <div style={{ flex: 1 }}>
                     <small>{month.year()}</small>
                     {!stats ? (
                        <SkeletonPlaceholder width="100%" height="1rem" />
                     ) : (
                        <CoachingHoursProgressBar
                           id={`${coach.id}-y-${month.year()}`}
                           done={stats.classesYearDone}
                           all={stats.classesYearAll}
                        />
                     )}
                  </div>
               </div>
            </Card.Text>
            <Card.Text>
               <Card.Title as="h6">Urlaub</Card.Title>
               {!stats ? (
                  <SkeletonPlaceholder width="100%" height="1rem" />
               ) : (
                  <VacationProgressBar
                     daysUsed={stats.vacationUsed}
                     daysPlanned={stats.vacationPlanned}
                     daysRemaining={stats.vacationAvailable}
                     id={`${coach.id}`}
                  />
               )}
            </Card.Text>
            <Card.Text className="mb-2">
               <Card.Title as="h6"># Sessions bis KW {cvWeeks.sessionsExpected}</Card.Title>
               {!stats ? (
                  <SkeletonPlaceholder width="100%" height="1rem" />
               ) : (
                  <ProgressBar className="w-100">
                     {cvWeeks.sessionsDone > 0 && (
                        <>
                           <ProgressBar
                              id={`pg-sessions-done-${coach.id}`}
                              isChild
                              variant="primary"
                              now={(cvWeeks.sessionsDone / cvWeeks.maxValue) * 100}
                              label={cvWeeks.sessionsDone}
                           />
                           <Tooltip
                              anchorSelect={`#pg-sessions-done-${coach.id}`}
                              content={
                                 cvWeeks.sessionsDone === 1
                                    ? '1 Session gecoacht oder Urlaub gehabt.'
                                    : `${cvWeeks.sessionsDone} Sessions gecoacht oder Urlaub gehabt.`
                              }
                              place="top"
                           />
                        </>
                     )}
                     {cvWeeks.sessionsInAdvance > 0 && (
                        <>
                           <ProgressBar
                              id={`pg-sessions-in-advanced-${coach.id}`}
                              isChild
                              variant="success"
                              now={(cvWeeks.sessionsInAdvance / cvWeeks.maxValue) * 100}
                              label={cvWeeks.sessionsInAdvance}
                           />
                           <Tooltip
                              anchorSelect={`#pg-sessions-in-advanced-${coach.id}`}
                              content={
                                 cvWeeks.sessionsInAdvance === 1
                                    ? '1 Session mehr als notwendig.'
                                    : `${cvWeeks.sessionsInAdvance} Sessions mehr als notwendig.`
                              }
                              place="top"
                           />
                        </>
                     )}
                     {cvWeeks.sessionsLeft > 0 && (
                        <>
                           <ProgressBar
                              id={`pg-sessions-left-${coach.id}`}
                              isChild
                              variant="danger"
                              now={(cvWeeks.sessionsLeft / cvWeeks.maxValue) * 100}
                              label={cvWeeks.sessionsLeft}
                           />
                           <Tooltip
                              anchorSelect={`#pg-sessions-left-${coach.id}`}
                              content={
                                 cvWeeks.sessionsLeft === 1
                                    ? '1 Session zu wenig als vereinbart.'
                                    : `${cvWeeks.sessionsLeft} Sessions zu wenig als vereinbart.`
                              }
                              place="top"
                           />
                        </>
                     )}
                  </ProgressBar>
               )}
            </Card.Text>
         </Card.Body>
      </Card>
   );
});
