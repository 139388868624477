import React, { memo, useCallback, useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { mdiAccountSwitch, mdiCalendarAlert, mdiChevronRight, mdiIsland } from '@mdi/js';
import Icon from '@mdi/react';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { Headline, MiniProfile } from '../molecules';
import { Absence, CoachingClass, RepresentationRequest, SingleApiObject } from '../types/api';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';
import { SkeletonPlaceholder } from '../atoms';
import { Colors, formatRange } from '../utils';
import { EditClassDialog } from '../dialogs/EditClassDialog';

export const NotificationsPage = () => {
   const [isLoading, setLoading] = useState(true);
   const [absences, setAbsences] = useState<Absence[]>([]);
   const [representationRequests, setRepresentationRequests] = useState<RepresentationRequest[]>(
      []
   );
   const [classesWithoutCoach, setClasseswithoutCoach] = useState<CoachingClass[]>([]);

   const loadData = useCallback(async () => {
      try {
         setAbsences(
            (await ApiService.http.get<IApiResponse<Absence>>('notifications/absencesToApprove'))
               .data.data
         );

         setRepresentationRequests(
            (
               await ApiService.http.get<IApiResponse<RepresentationRequest>>(
                  'notifications/requestsToVote'
               )
            ).data.data
         );
         setClasseswithoutCoach(
            (
               await ApiService.http.get<IApiResponse<CoachingClass>>(
                  'notifications/classesWithoutCoach'
               )
            ).data.data
         );
         setLoading(false);
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }, []);

   useEffect(() => {
      (async () => {
         await loadData();
      })();
   }, [loadData]);

   return (
      <Container className="py-2">
         <Headline title="Benachrichtigungen" />
         <Row className="justify-content-center">
            <Col xs={12} sm={11} md={10} lg={8} xl={7} xxl={6}>
               <ListGroup className="mt-3">
                  {isLoading &&
                     [...Array(6).keys()].map(i => (
                        <ListGroup.Item key={i} className="d-flex align-items-center">
                           <div>
                              <SkeletonPlaceholder width="3rem" height="3rem" className="me-4" />
                           </div>
                           <div className="d-flex flex-fill flex-column">
                              <SkeletonPlaceholder width="50%" />
                              <SkeletonPlaceholder width="65%" />
                           </div>
                        </ListGroup.Item>
                     ))}
                  {!isLoading &&
                     absences.length +
                        representationRequests.length +
                        classesWithoutCoach.length ===
                        0 && (
                        <ListGroup.Item className="d-flex align-items-center justify-content-center">
                           <em>Keine Einträge vorhanden</em>
                        </ListGroup.Item>
                     )}
                  {!isLoading && absences?.map(a => <AbsenceEntry key={a.id} item={a} />)}
                  {!isLoading &&
                     representationRequests?.map(r => (
                        <RepresentationRequestEntry key={r.id} item={r} />
                     ))}
                  {!isLoading &&
                     classesWithoutCoach?.map(cc => (
                        <CoachingClassWithoutCoach key={cc.id} item={cc} onRefresh={loadData} />
                     ))}
               </ListGroup>
            </Col>
         </Row>
      </Container>
   );
};

interface EntryProps<T extends SingleApiObject> {
   item: T;
   onRefresh?: () => Promise<void>;
}

const AbsenceEntry = memo(({ item }: Omit<EntryProps<Absence>, 'onRefresh'>) => (
   <LinkContainer to={`/manage/absences/list/${item.id}`}>
      <ListGroup.Item action className="d-flex align-items-center">
         <Icon path={mdiIsland} size={2} color={`${Colors.secondary}AA`} className="me-3" />
         <span className="flex-fill d-flex flex-column align-items-start">
            <span>Urlaubsantrag</span>
            <span className="mt-1 text-muted d-flex">
               <MiniProfile user={item.user_id} className="d-none d-md-flex" />
               <MiniProfile user={item.user_id} type="avatar" className="d-flex d-md-none" />
               <span className="mx-1">·</span>
               <span>{formatRange(item.start, item.end, 'simple-date-only')}</span>
            </span>
         </span>
         <Icon path={mdiChevronRight} size={0.75} />
      </ListGroup.Item>
   </LinkContainer>
));

const RepresentationRequestEntry = memo(
   ({ item }: Omit<EntryProps<RepresentationRequest>, 'onRefresh'>) => (
      <LinkContainer to={`/representationRequest/${item.id}`}>
         <ListGroup.Item action className="d-flex align-items-center">
            <Icon
               path={mdiAccountSwitch}
               size={2}
               color={`${Colors.secondary}AA`}
               className="me-3"
            />
            <span className="flex-fill d-flex flex-column align-items-start">
               <span>Offene Vertretungsanfrage</span>
               <span className="mt-1 text-muted d-flex">
                  <MiniProfile user={item.user_id} />
                  <span className="mx-1">·</span>
                  <span>{moment(item.date).format('dd, L')}</span>
               </span>
            </span>
            <Icon path={mdiChevronRight} size={0.75} />
         </ListGroup.Item>
      </LinkContainer>
   )
);

const CoachingClassWithoutCoach = memo(({ item, onRefresh }: EntryProps<CoachingClass>) => {
   const [showEditDialog, setShowEditDialog] = useState(false);

   return (
      <>
         <ListGroup.Item
            action
            onClick={() => setShowEditDialog(true)}
            className="d-flex align-items-center"
         >
            <Icon
               path={mdiCalendarAlert}
               size={2}
               color={`${Colors.secondary}AA`}
               className="me-3"
            />
            <span className="flex-fill d-flex flex-column align-items-start">
               <span>Kein Coach eingeteilt.</span>
               <span className="mt-1 text-muted d-flex">
                  <span>{moment(item.start).format('L')} um&nbsp;</span>
                  <span>{formatRange(item.start, item.end, 'simple-date-with-time')}</span>
               </span>
            </span>
            <Icon path={mdiChevronRight} size={0.75} />
         </ListGroup.Item>
         <EditClassDialog
            show={showEditDialog}
            coachingClass={item}
            onClose={() => setShowEditDialog(false)}
            afterSave={onRefresh}
            afterDelete={onRefresh}
         />
      </>
   );
});
