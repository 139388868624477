import { BaseModel } from './BaseModel';
import { Calendar } from '../types/api';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';

class CalendarModelClass extends BaseModel<Calendar> {
   public override getName(): string {
      return 'calendar';
   }

   public async adminList(): Promise<Calendar[]> {
      try {
         const response = await ApiService.http.get<IApiResponse<Calendar>>(`admin/calendar`);

         return response.data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
export const CalendarModel = new CalendarModelClass();
