import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiStoreRemove } from '@mdi/js';
import { useSelector } from 'react-redux';
import { Colors, setTitle } from '../../utils';
import { Box } from '../../types/api';
import { EditBoxTab } from './EditBoxTab';
import { BoxSideBar } from './BoxSideBar';
import { RootState } from '../../store';
import { CoachesTab } from './CoachesTab';
import { BoxModulesTab } from './BoxModulesTab';

export const AdminPage = () => {
   const boxId = Number(useParams().boxId);
   const boxes = useSelector((s: RootState) => s.admin.boxes);
   const [selectedBox, setSelectedBox] = useState<Partial<Box> | undefined>();

   useEffect(() => setTitle('Administration'), []);

   useEffect(() => {
      if (boxId === 0) setSelectedBox({ id: 0 });
      else setSelectedBox(boxes.find(b => b.id === boxId));
   }, [boxes, boxId]);

   return (
      <Container className="py-2">
         <Row>
            <Col lg={3} md={4} className="mb-4">
               <BoxSideBar />
            </Col>
            <Col>
               {selectedBox ? (
                  <div className="floating-tabs mt-3">
                     <Tabs defaultActiveKey="box">
                        <Tab eventKey="box" title="Stammdaten zur Box">
                           <EditBoxTab box={selectedBox} />
                        </Tab>
                        <Tab eventKey="users" title="Coaches" disabled={selectedBox.id === 0}>
                           <CoachesTab box={selectedBox} />
                        </Tab>
                        <Tab eventKey="modules" title="Module" disabled={selectedBox.id === 0}>
                           <BoxModulesTab boxId={selectedBox.id ?? 0} />
                        </Tab>
                     </Tabs>
                  </div>
               ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center mt-5 text-secondary">
                     <Icon path={mdiStoreRemove} size={5} color={Colors.secondary} />
                     <h1 className="fst-italic text-center">Keine CrossFit-Box ausgewählt</h1>
                  </div>
               )}
            </Col>
         </Row>
      </Container>
   );
};
