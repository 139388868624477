import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Formik, FormikErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiCalendarMonthOutline } from '@mdi/js';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Meeting } from '../types/api';
import { ContentLoader } from '../atoms';
import { MiniProfile } from '../molecules';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { DateInput, RoomSelect, UserMultiSelect } from '../forms/controls';
import { mergeDateAndTime } from '../utils';
import {
   FormFieldError,
   FormRow,
   FormUtils,
   GenericControl,
   SubmitButton,
   TextareaControl,
   TextControl,
} from '../forms';
import { MeetingModel } from '../models/MeetingModel';
import { upsertMeeting } from '../store/actions/appActions';
import { useSession } from '../hooks';

interface FormModel extends Meeting {
   start_time?: string;
   end_time?: string;
}

interface Props {
   show: boolean;
   onClose: () => void;
   meeting?: Partial<FormModel>;
   afterSave?: (meeting: Meeting) => Promise<void> | void;
   // afterDelete?: (cc: Partial<FormModel>) => Promise<void>;
}

export const EditMeetingDialog = (props: Props) => {
   const dispatch = useDispatch();
   const { sessionUser } = useSession();
   const [meeting, setMeeting] = useState<FormModel>();

   useEffect(() => {
      setMeeting({
         ...props.meeting,
         organizer_id: props.meeting?.organizer_id ?? sessionUser?.id,
         start_time: props.meeting?.start
            ? moment(props.meeting?.start).format('HH:mm')
            : undefined,
         end_time: props.meeting?.end ? moment(props.meeting?.end).format('HH:mm') : undefined,
      } as FormModel);
   }, [props.meeting, sessionUser?.id]);

   const handleSubmitForm = async (values: Partial<FormModel>) => {
      // umwandeln in eine Klasse
      let m = {
         ...values,
         start: mergeDateAndTime(values.start, moment(values.start_time, 'HH:mm').toDate()),
         end: mergeDateAndTime(values.end, moment(values.end_time, 'HH:mm').toDate()),
      } as Meeting;

      if (m.id === 0) {
         m = await MeetingModel.insert(m);
      } else {
         m = await MeetingModel.update(m);
      }

      dispatch(upsertMeeting(m));

      await props.afterSave?.(m);

      props.onClose();
   };

   if (!meeting) return null;

   return (
      <Modal show={props.show} onHide={props.onClose} size="lg">
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={meeting}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<FormModel>> = {};

               if (!values.name) errors.name = 'Bitte gib einen Namen an.';
               if (!values.room_id) errors.room_id = 'Bitte wähle einen Raum aus.';
               if (!values.start) errors.start = 'Bitte gib ein Datum an.';
               if (!values.start_time) errors.start_time = 'Bitte gib eine Startzeit an.';
               if (!values.end) errors.end = 'Bitte gib ein Enddatum an.';
               if (!values.end_time) errors.end_time = 'Bitte gib eine Endzeit an.';

               const start = mergeDateAndTime(
                  values.start,
                  moment(values.start_time, 'HH:mm').toDate()
               );
               const end = mergeDateAndTime(values.end, moment(values.end_time, 'HH:mm').toDate());
               // Differenz zwischen Start und Ende muss min. 1 Minute betragen (Wegen ungenauen Millisekunden)
               if (moment(start).isSameOrAfter(moment(end), 'minute'))
                  errors.end_time = 'Das Ende muss nach dem Start sein.';

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title className="d-flex align-items-center">
                        {meeting?.id === 0 ? 'Neues Meeting' : 'Meeting bearbeiten'}
                     </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     {!meeting ? (
                        <ContentLoader />
                     ) : (
                        <>
                           <FormRow label="Organisator">
                              <MiniProfile user={formik.values.organizer_id} />
                           </FormRow>

                           <TextControl
                              formik={formik}
                              name="name"
                              label="Betreff"
                              placeholder="Betreff des Meetings…"
                           />
                           <GenericControl label="Raum" formik={formik} name="room_id">
                              <RoomSelect formik={formik} name="room_id" />
                           </GenericControl>
                           <GenericControl formik={formik} name="start" label="Start">
                              <div className="d-flex gap-2">
                                 <DateInput
                                    formik={formik}
                                    name="start"
                                    onChange={date => {
                                       formik.setFieldValue('start', date);
                                       if (!formik.values.end) formik.setFieldValue('end', date);
                                    }}
                                    style={{ flex: 2 }}
                                 >
                                    {({ inputElem }) => (
                                       <InputGroup>
                                          <InputGroup.Text>
                                             <Icon path={mdiCalendarMonthOutline} size={0.75} />
                                          </InputGroup.Text>
                                          {inputElem}
                                       </InputGroup>
                                    )}
                                 </DateInput>
                                 <InputGroup style={{ flex: 1 }}>
                                    <Form.Control
                                       type="time"
                                       name="start_time"
                                       className="text-end"
                                       value={formik.values.start_time}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       isInvalid={FormUtils.isInvalid(formik, 'start_time')}
                                       disabled={formik.isSubmitting}
                                    />
                                 </InputGroup>
                              </div>
                              <FormFieldError name="start" formik={formik} />
                              <FormFieldError name="start_time" formik={formik} />
                           </GenericControl>
                           <GenericControl formik={formik} name="end" label="Ende">
                              <div className="d-flex gap-2">
                                 <DateInput
                                    formik={formik}
                                    name="end"
                                    onChange={date => formik.setFieldValue('end', date)}
                                    style={{ flex: 2 }}
                                 >
                                    {({ inputElem }) => (
                                       <InputGroup>
                                          <InputGroup.Text>
                                             <Icon path={mdiCalendarMonthOutline} size={0.75} />
                                          </InputGroup.Text>
                                          {inputElem}
                                       </InputGroup>
                                    )}
                                 </DateInput>
                                 <InputGroup style={{ flex: 1 }}>
                                    <Form.Control
                                       type="time"
                                       name="end_time"
                                       className="text-end"
                                       value={formik.values.end_time}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       isInvalid={FormUtils.isInvalid(formik, 'end_time')}
                                       disabled={formik.isSubmitting}
                                    />
                                 </InputGroup>
                              </div>
                              <FormFieldError name="end" formik={formik} />
                              <FormFieldError name="end_time" formik={formik} />
                           </GenericControl>

                           <GenericControl formik={formik} name="participants" label="Teilnehmer">
                              <UserMultiSelect
                                 onChange={userIds => formik.setFieldValue('participants', userIds)}
                                 onBlur={() => formik.handleBlur('participants')}
                                 values={formik.values.participants}
                                 allowAll={false}
                                 filter={u => u.id !== formik.values.organizer_id}
                              />
                           </GenericControl>
                           <TextareaControl formik={formik} name="comment" label="Kommentar" />
                        </>
                     )}
                  </Modal.Body>
                  <Modal.Footer className="bg-light justify-content-end">
                     <CreateUpdateInfo hidden={meeting?.id === 0} obj={meeting} />
                     <Button
                        variant="outline-link"
                        onClick={props.onClose}
                        disabled={formik.isSubmitting}
                     >
                        Schließen
                     </Button>
                     <SubmitButton formik={formik}>Speichern</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
