import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import { Headline } from '../molecules';

export const ChangelogPage = () => (
   <Container className="p-2 changelog">
      <Headline title="Changelog" />
      <Alert variant="secondary">
         <div>All notable changes to this project will be documented in this file.</div>
         <br />
         <div>
            The format is based on{' '}
            <a
               href="https://keepachangelog.com/en/1.0.0/"
               target="_blank"
               rel="noopener noreferrer"
            >
               Keep a Changelog
            </a>
            , and this project adheres to{' '}
            <a href="https://semver.org/spec/v2.0.0.html" target="_blank" rel="noopener noreferrer">
               Semantic Versioning
            </a>
            .
         </div>
      </Alert>
      <h2>2.5.1 - 2023-06-29</h2>
      <h4>Fixed</h4>
      <ul>
         <li>
            Fixes a bug where personal trainings of all coaches are displayed on the dashboard.
         </li>
      </ul>

      <h2>2.5.0 - 2023-06-27</h2>
      <h4>Fixed</h4>
      <ul>
         <li>Fixes a bug where new created users are not enabled by default</li>
         <li>
            Fixes a bug where the available vacation days are not calculated correctly (CP-122)
         </li>
         <li>Fixes the time range validation of box closing times (CP-123)</li>
         <li>
            Fixes a bug where the remaining coaching hours in the current month are calculated
            wrongly (CP-119)
         </li>
         <li>Adds refresh to my classes list on dashboard when changes are made</li>
         <li>
            Fixes a bug where a user with role `Scheduler` can not copy a week due to missing
            permissions on box closing times (CP-129)
         </li>
      </ul>
      <h4>Added</h4>
      <ul>
         <li>App cache for detecting site updates</li>
         <li>Use existing session cookie to try login in before using google authentication</li>
         <li>
            Menu option to cancel a class directly from my classes list on the dashboard (CP-121)
         </li>
         <li>Dashboard widget for my open representation requests (CP-120)</li>
         <li>Allow deletion of multiple personal trainings at once (CP-126)</li>
         <li>Shows my upcoming personal trainings on the dashboard (CP-127)</li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>
            Hides disabled users when showing the coaches availability when assigning a coach to a
            class (CP-125)
         </li>
         <li>Every user can now use the absence type `other` (CP-130)</li>
      </ul>
      <h4>Security</h4>
      <ul>
         <li>Only administrators can add/remove the role `admin` (CP-128)</li>
      </ul>

      <h2>2.4.1 - 2023-01-20</h2>
      <h4>Fixed</h4>
      <ul>
         <li>Fixed a bug where an error occurred when links containing search params</li>
      </ul>

      <h2>2.4.0 - 2023-01-20</h2>
      <h4>Changed</h4>
      <ul>
         <li>Upgraded the sign-in method to Google Identity Services SDK</li>
         <li>Fixed bug where the weeks of year were calculated wrong</li>
      </ul>

      <h2>2.3.0 - 2022-09-22</h2>
      <h4>Added</h4>
      <ul>
         <li>Added a backup coach plan to be used in flavour to create a representation request</li>
      </ul>

      <h2>2.2.2 - 2022-09-22</h2>
      <h4>Fixed</h4>
      <ul>
         <li>Small fixes</li>
      </ul>
      <h2>2.2.1 - 2022-07-11</h2>
      <h4>Changed</h4>
      <ul>
         <li>
            Display the count of sessions in a year a coach has coached a class or has one day of
            vacation per week.
         </li>
      </ul>

      <h2>2.2.0 - 2022-07-09</h2>
      <h4>Added</h4>
      <ul>
         <li>
            Option to allow the choose of a coach for a representation request when a minimum votes
            or time before the classes are reached.
         </li>
         <li>
            Display the count of weeks in a year a coach has at least coached on class or has one
            day of vacation.
         </li>
      </ul>

      <h2>2.1.2 - 2022-05-14</h2>
      <h4>Fixed</h4>
      <ul>
         <li>Increase file size for avatar to 5 MB</li>
         <li>Optimize avatar uploader</li>
      </ul>

      <h2>2.1.0 - 2022-05-14</h2>
      <h4>Added</h4>
      <ul>
         <li>User can upload an avatar to override the profile picture from google. (CP-69)</li>
         <li>User can write notes to the scheduler to tell times where they can not coach.</li>
      </ul>
      <h4>Fixed</h4>
      <ul>
         <li>Linking from notification page to absence for approval.</li>
         <li>Styling of sub menu on mobile view.</li>
      </ul>

      <h2>2.0.1 - 2022-04-10</h2>
      <h4>Fixed</h4>
      <ul>
         <li>User can&apos;t select a date range when creating a new absence.</li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>Changed wording of validation errors (CP-117)</li>
      </ul>

      <h2>2.0.0 - 2022-04-09</h2>
      <h4>Added</h4>
      <ul>
         <li>Added notification icon for open tasks (CP-32)</li>
         <li>Creation of meetings (CP-92)</li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>Change some buttons to be link buttons (CP-94)</li>
         <li>Reduce font size for mobile devices to gain more space (CP-82)</li>
         <li>Navigation structure regarding managing the box, coaches and absences (CP-92)</li>
         <li>Date input of absence (CP-113)</li>
         <li>Form validation (CP-41)</li>
         <li>Classes without a first coach will now be listed under the notification section</li>
      </ul>
      <h4>Added</h4>
      <ul>
         <li>Added management of meetings & box closing times to schedule page (CP-92)</li>
         <li>Filter for events on manage schedule page (CP-92)</li>
         <li>Settings for manage schedule page (CP-92)</li>
      </ul>

      <h2>1.2.0 - 2022-03-15</h2>
      <h4>Added</h4>
      <ul>
         <li>Added demo accounts to easily test the platform (CP-83)</li>
         <li>
            Creation of representation requests incl. voting and automatic assignment of new coach
            (CP-86)
         </li>
         <li>Option to change the year under my absences (CP-87)</li>
         <li>Mail for approval of vacation requests with direct links to grant/reject (CP-91)</li>
         <li>Option to ignore certain absences in overlapping validation</li>
         <li>Option to configure the amount of allowed overlapping absences</li>
         <li>Option to enforce that a coach has to coach at least one time per week (CP-97)</li>
         <li>Permission to manage absences for scheduler role (CP-95)</li>
         <li>
            Options to edit more fields for an absence for box owner or scheduler role (CP-109)
         </li>
         <li>Legend to show the color of the coaches in vacation overview (CP-104)</li>
         <li>Button to directly submit new absence for approval (CP-110)</li>
         <li>
            Email notification to all other coaches when a new representation request is created
            (CP-101)
         </li>
         <li>Deactivated user can be anonymized by the box owner (CP-106)</li>
      </ul>
      <h4>Changed</h4>
      <ul>
         <li>Browser title of several pages.</li>
         <li>Display deactivated users in a separate dialog (CP-105)</li>
         <li>Annual vacation quota now also applies to mini-jobbers (CP-107)</li>
      </ul>
      <h4>Removed</h4>
      <ul>
         <li>Option &apos;maybe&apos; for representation requests (CP-103)</li>
      </ul>
      <h4>Fixed</h4>
      <ul>
         <li>Missing coaches name in calender view when the coach has no nickname.</li>
         <li>Error where the coach can not define his preferred coaching times (CP-100)</li>
      </ul>

      <h2>1.1.1 - 2021-12-25</h2>
      <h4>Fixed</h4>
      <ul>
         <li>
            An error where the classes would not be loaded correctly when the last week of the year
            overlaps with the new year.
         </li>
      </ul>
      <h2>1.1.0 - 2021-11-30</h2>
      <h4>Added</h4>
      <ul>
         <li>
            Option to show/hide box closing times and absence prohibitions in calendar abo (CP-47)
         </li>
         <li>Accountant can create missing provided personal training services (CP-77)</li>
         <li>Accountant can remove provided personal training services (CP-77)</li>
         <li>Registration form for other CrossFit boxes (CP-78)</li>
         <li>Name of client for accounting of personal trainings</li>
      </ul>

      <h2>1.0.0 - 2021-10-01</h2>
      <ul>
         <li>Deployment and usage of Coaching-Zeitplaner.de</li>
      </ul>
   </Container>
);
