import moment, { Moment } from 'moment';
import { BaseModel, TimeBasedObjectModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';
import ApiService from '../services/ApiService';
import { CoachesAvailabilityModel, CoachingClass } from '../types/api';
import { IApiResponse } from '../types/IApiResponse';

class CoachingClassModelClass
   extends BaseModel<CoachingClass>
   implements TimeBasedObjectModel<CoachingClass>
{
   public getName(): string {
      return 'coaching_class';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<CoachingClass[]> {
      return ModelExtension.listTimeRange<CoachingClass>(this.getName(), from, to);
   }

   public async listPerWeek(startOfWeek: Moment): Promise<CoachingClass[]> {
      return ModelExtension.listPerWeek(this, startOfWeek);
   }

   public async cancelClass(id: number, reason: string): Promise<CoachingClass> {
      try {
         const response = await ApiService.http.put<IApiResponse<CoachingClass>>(
            `data/${this.getName()}/${id}/cancel`,
            {
               reason: reason,
            }
         );
         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async restoreClass(id: number): Promise<CoachingClass> {
      try {
         const response = await ApiService.http.put<IApiResponse<CoachingClass>>(
            `data/${this.getName()}/${id}/cancel?restore=1`,
            {}
         );
         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async getAvailableCoaches(
      startDateAndTime: Date,
      classId: number
   ): Promise<CoachesAvailabilityModel> {
      try {
         const response = await ApiService.http.get<IApiResponse<CoachesAvailabilityModel>>(
            `data/${this.getName()}/coaches?start=${moment(
               startDateAndTime
            ).toISOString()}&class_id=${classId}`
         );

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async copyWeek(
      srcWeek: Moment,
      destWeek: Moment,
      skipDuplicates: boolean
   ): Promise<CoachingClass[]> {
      return ModelExtension.copyWeek(CoachingClassModel, srcWeek, destWeek, skipDuplicates, () => ({
         coach_other: [],
      }));
   }

   public override isDoublet(a: CoachingClass, b: CoachingClass): boolean {
      return (
         moment(a.start).isSame(b.start, 'second') &&
         moment(a.end).isSame(b.end, 'second') &&
         a.type_id === b.type_id &&
         a.room_id === b.room_id
      );
   }

   public async replaceCoachWithBackup(
      coachingClassId: number,
      coachId: number
   ): Promise<CoachingClass> {
      try {
         const response = await ApiService.http.put<IApiResponse<CoachingClass>>(
            `data/${this.getName()}/${coachingClassId}/replaceWithBackupCoach?coach_id=${coachId}`
         );

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}

export const CoachingClassModel = new CoachingClassModelClass();
