import { ProgressBar } from 'react-bootstrap';
import React, { memo, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

interface Props {
   /**
    * Amount of days already used.
    */
   daysUsed: number;
   /**
    * Amount of days already planned to used.
    */
   daysPlanned: number;
   /**
    * Amount of days remaining.
    */
   daysRemaining: number;
   /**
    * ID for the tooltips
    */
   id: string;
}

/**
 * Renders a progress bar to display the usage of the vacation days for the user.
 */
export const VacationProgressBar = memo(({ daysUsed, daysPlanned, daysRemaining, id }: Props) => {
   const [daysOverallAvailable, setDaysOverallAvailable] = useState(0);
   useEffect(() => {
      setDaysOverallAvailable(daysUsed + daysPlanned + daysRemaining);
   }, [daysPlanned, daysRemaining, daysUsed]);

   return (
      <ProgressBar className="w-100">
         {daysUsed > 0 && (
            <>
               <ProgressBar
                  id={`pg-vacation-used-${id}`}
                  isChild
                  variant="success"
                  now={daysOverallAvailable === 0 ? 0 : (daysUsed / daysOverallAvailable) * 100}
                  label={daysUsed}
               />
               <Tooltip
                  anchorSelect={`#pg-vacation-used-${id}`}
                  content={
                     daysUsed === 1
                        ? '1 Tag Urlaub wurden bereits genommen'
                        : `${daysUsed} Tage Urlaub wurden bereits genommen`
                  }
                  place="top"
               />
            </>
         )}
         {daysPlanned > 0 && (
            <>
               <ProgressBar
                  id={`pg-vacation-planned-${id}`}
                  striped
                  isChild
                  variant="warning"
                  now={daysOverallAvailable === 0 ? 0 : (daysPlanned / daysOverallAvailable) * 100}
                  label={daysPlanned}
               />
               <Tooltip
                  anchorSelect={`#pg-vacation-planned-${id}`}
                  content={
                     daysPlanned === 1
                        ? '1 Tag Urlaub sind noch geplant (inkl. Entwürfe)'
                        : `${daysPlanned} Tage Urlaub sind noch geplant (inkl. Entwürfe)`
                  }
                  place="top"
               />
            </>
         )}
         {daysRemaining > 0 && (
            <>
               <ProgressBar
                  id={`pg-vacation-remaining-${id}`}
                  className="bg-gray text-dark"
                  isChild
                  now={
                     daysOverallAvailable === 0
                        ? 100
                        : 100 - ((daysUsed + daysPlanned) / daysOverallAvailable) * 100
                  }
                  label={daysRemaining}
               />
               <Tooltip
                  anchorSelect={`#pg-vacation-remaining-${id}`}
                  content={
                     daysRemaining === 1
                        ? '1 Tag Urlaub stehen noch zur Verfügung'
                        : `${daysRemaining} Tage Urlaub stehen noch zur Verfügung`
                  }
                  place="top"
               />
            </>
         )}
      </ProgressBar>
   );
});
