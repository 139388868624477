import moment from 'moment';
import { AnyAction } from 'redux';
import { CoachingScheduleEntry } from '../../types/CoachingScheduleEntry';
import { SchedulePageSettings } from '../../types/SchedulePageSettings';
import { CoachingBackup } from '../../types/api/CoachingBackup';

export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const COPY_WEEK = 'COPY_WEEK';
export const SET_SCHEDULE_ENTRIES_PER_WEEK = 'SET_SCHEDULE_ENTRIES_PER_WEEK';
export const UPSERT_SCHEDULE_ENTRY_IN_WEEK = 'UPSERT_SCHEDULE_ENTRY_IN_WEEK';
export const REMOVE_SCHEDULE_ENTRIES_IN_WEEK = 'REMOVE_SCHEDULE_ENTRIES_IN_WEEK';
export const UPDATE_SCHEDULE_PAGE_SETTINGS = 'UPDATE_SCHEDULE_PAGE_SETTINGS';
export const FORCE_SCHEDULE_PAGE_RELOAD = 'FORCE_SCHEDULE_PAGE_RELOAD';
export const SET_COACHING_BACKUPS = 'SET_COACHING_BACKUPS';
export const UPSERT_COACHING_BACKUP = 'UPSERT_COACHING_BACKUP';
export const REMOVE_COACHING_BACKUP = 'REMOVE_COACHING_BACKUP';

interface IState {
   selectedDate: string;
   copiedWeek: string | null;
   scheduleEntries: {
      [key: string]: CoachingScheduleEntry[];
   };
   coachingBackups: CoachingBackup[];
   settings: SchedulePageSettings;
   reloadCounter: number;
}

const initialState: IState = {
   selectedDate: moment().toISOString(),
   copiedWeek: null,
   scheduleEntries: {},
   coachingBackups: [],
   settings: {
      preventDuplicatesOnCopy: true,
      hiddenEventTypes: [],
   },
   reloadCounter: 0,
};

const ScheduleReducer = (state = initialState, action: AnyAction = { type: '' }): IState => {
   switch (action.type) {
      case SET_SELECTED_DATE:
         return {
            ...state,
            selectedDate: action.data,
         };
      case COPY_WEEK:
         return {
            ...state,
            copiedWeek: action.data,
         };
      case SET_SCHEDULE_ENTRIES_PER_WEEK:
         return {
            ...state,
            scheduleEntries: {
               ...state.scheduleEntries,
               [action.data.week]: action.data.entries,
            },
         };
      case UPSERT_SCHEDULE_ENTRY_IN_WEEK:
         return {
            ...state,
            scheduleEntries: {
               ...state.scheduleEntries,
               [action.data.week]: [
                  ...state.scheduleEntries[action.data.week].filter(
                     e => e.id !== action.data.entry.id || e.type !== action.data.entry.type
                  ),
                  action.data.entry,
               ],
            },
         };
      case REMOVE_SCHEDULE_ENTRIES_IN_WEEK:
         return {
            ...state,
            scheduleEntries: {
               ...state.scheduleEntries,
               [action.data.week]: [
                  ...(state.scheduleEntries[action.data.week] ?? []).filter(
                     e => e.id !== action.data.entry.id || e.type !== action.data.entry.type
                  ),
               ],
            },
         };
      case UPDATE_SCHEDULE_PAGE_SETTINGS:
         return {
            ...state,
            settings: {
               ...state.settings,
               ...action.data,
            },
         };
      case FORCE_SCHEDULE_PAGE_RELOAD:
         return {
            ...state,
            reloadCounter: state.reloadCounter + 1,
         };
      case SET_COACHING_BACKUPS:
         return {
            ...state,
            coachingBackups: action.data,
         };
      case UPSERT_COACHING_BACKUP:
         return {
            ...state,
            coachingBackups: [
               ...state.coachingBackups.filter(cb => cb.id !== action.data.id),
               action.data,
            ],
         };
      case REMOVE_COACHING_BACKUP:
         return {
            ...state,
            coachingBackups: [...state.coachingBackups.filter(cb => cb.id !== action.data.id)],
         };
      default:
         return state;
   }
};

export default ScheduleReducer;
