import React, { useCallback, useEffect, useState } from 'react';
import { EventList } from '../../organisms/EventList';
import { EditAbsenceProhibitionDialog } from '../../dialogs/EditAbsenceProhibitionDialog';
import { Headline } from '../../molecules';
import { AbsenceProhibition } from '../../types/api';
import { AbsenceProhibitionModel } from '../../models';

export const ManageAbsencesProhibitionTab = () => {
   const [prohibitions, setProhibitions] = useState<AbsenceProhibition[]>();
   const [eventToEdit, setEventToEdit] = useState<Partial<AbsenceProhibition>>();

   const loadData = useCallback(async () => {
      setProhibitions(await AbsenceProhibitionModel.list());
   }, []);

   useEffect(() => {
      (async () => {
         await loadData();
      })();
   }, [loadData]);

   const handleAfterSave = (ap: AbsenceProhibition): Promise<void> => {
      setProhibitions(a => [...(a ?? []).filter(c => c.id !== ap.id), ap]);
      return Promise.resolve();
   };

   const handleDelete = async (event: AbsenceProhibition) => {
      await AbsenceProhibitionModel.delete(event);
      setProhibitions(a => (a ?? []).filter(c => c.id !== event.id));
   };

   const onDeleteManyEvents = async (events: AbsenceProhibition[]) => {
      await Promise.all(events.map(e => AbsenceProhibitionModel.delete(e)));

      await loadData();
   };

   return (
      <>
         <Headline title="Urlaubssperre" browserTitle="Abwesenheiten - Urlaubssperre">
            In den hier aufgelisteten Zeiträume ist das Beantragen eines Urlaubes eines Coaches
            nicht möglich. So kann zu bekannten Stoßzeiten sichergestellt werden, dann alle Coaches
            verfügbar sind.
         </Headline>
         <EventList
            events={prohibitions}
            formatTimeRange={(s, e) => `${s.format('DD.MM')} - ${e.format('L')}`}
            onAddEvent={() => setEventToEdit({ id: 0 })}
            onEditEvent={e => setEventToEdit(e)}
            onDuplicateSingleEvent={e => setEventToEdit({ ...e, id: 0 })}
            onDeleteEvent={handleDelete}
            onDeleteManyEvents={onDeleteManyEvents}
         />
         <EditAbsenceProhibitionDialog
            show={!!eventToEdit}
            absenceProhibition={eventToEdit}
            onClose={() => setEventToEdit(undefined)}
            afterSave={handleAfterSave}
         />
      </>
   );
};
