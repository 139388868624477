import React, { useMemo } from 'react';
import { mdiAccount, mdiCalendarMultiselect } from '@mdi/js';
import { useParams } from 'react-router-dom';
import { ProfileTab } from './ProfileTab';
import { CoachingTimesTab } from './CoachingTimesTab';
import { ROLE_COACH } from '../../types/api';
import { MenuItem, PageWithSideMenu } from '../../templates';
import { useSession } from '../../hooks';

export const ProfilePage = () => {
   const { sessionUser } = useSession();
   const params = useParams();

   const menuItems: MenuItem[] = useMemo(() => {
      const items = [
         {
            title: 'Profil',
            key: 'profile',
            icon: mdiAccount,
            component: () => <ProfileTab />,
         },
      ];

      if (sessionUser?.roles.includes(ROLE_COACH)) {
         items.push({
            title: 'Coaching-Zeiten',
            key: 'coachingTimes',
            icon: mdiCalendarMultiselect,
            component: () => <CoachingTimesTab />,
         });
      }

      return items;
   }, [sessionUser?.roles]);

   return (
      <PageWithSideMenu
         menuItems={menuItems}
         selectedMenuItem={params.tab}
         onBuildLink={tab => `/user/profile/${tab}`}
      />
   );
};
