import { Button, Form, ListGroup } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mdiAccountMultiple, mdiDumbbell, mdiMapMarker } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Colors } from '../../utils';
import { SkeletonPlaceholder } from '../../atoms';
import { RootState } from '../../store';
import { setBoxes } from '../../store/actions/adminActions';
import { BoxSearchSelect } from '../../forms/controls';
import { Box, BoxStatistic } from '../../types/api';
import { BoxModel } from '../../models';

// TODO: Bei Anlage/Änderung liste der Boxen aktualisieren
export const BoxSideBar = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const boxId = Number(useParams().boxId);
   const boxes = useSelector((s: RootState) => s.admin.boxes);
   const [searchText, setSearchText] = useState('');
   const [isLoading, setLoading] = useState(false);

   const loadBoxes = useCallback(
      async (queryText = '') => {
         setLoading(true);
         try {
            const result = await BoxModel.adminList(queryText);
            dispatch(setBoxes(result));
            return result;
         } finally {
            setLoading(false);
         }
      },
      [dispatch]
   );

   useEffect(() => {
      (async () => {
         await loadBoxes();
      })();
   }, [loadBoxes]);

   const sortByName = (a: Box, b: Box) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
   };

   return (
      <>
         <div className="flex-column d-none d-md-flex">
            <Form>
               <Form.Control
                  type="text"
                  className="mt-3 mb-2"
                  value={searchText}
                  placeholder="Durchsuchen…"
                  onChange={async e => {
                     setSearchText(e.target.value);
                     await loadBoxes(e.target.value);
                  }}
                  disabled={!boxes}
               />
            </Form>
            <LinkContainer to="/admin/box/0">
               <Button variant="primary" className="mb-3" disabled={!boxes}>
                  Box anlegen
               </Button>
            </LinkContainer>
            {!boxes || isLoading
               ? [...Array(6).keys()].map(i => <BoxCard key={i} />)
               : boxes
                    .sort(sortByName)
                    .map(b => <BoxCard key={b.id} box={b} active={b.id === boxId} />)}
         </div>
         <div className="mt-3 d-flex d-md-none">
            <BoxSearchSelect
               defaultValue={boxId}
               onSearchTextChange={setSearchText}
               onSelect={id => navigate(`/admin/box/${id}`)}
            />
         </div>
      </>
   );
};

interface BoxCardProps {
   box?: Box;
   active?: boolean;
}

export const BoxCard = ({ box, active = false }: BoxCardProps) => {
   const [stats, setStats] = useState<BoxStatistic>();

   useEffect(() => {
      if (!box) return;

      (async () => {
         setStats(await BoxModel.adminStats(box.id));
      })();
   }, [box]);

   return (
      <ListGroup className="floating-nav-panel-md mt-1">
         <LinkContainer to={`/admin/box/${box?.id}`}>
            <ListGroup.Item
               className="px-2 d-flex flex-column align-items-stretch"
               active={active}
               action
            >
               <h5 className="mx-1 mb-0">
                  {box ? box.name : <SkeletonPlaceholder width="80%" height="2rem" />}
               </h5>
               <div className="dropdown-divider flex-fill" />
               <div className="mx-1 d-flex align-items-center justify-content-between">
                  <BoxStats icon={mdiAccountMultiple} value={stats?.coaches} active={active} />
                  <BoxStats icon={mdiDumbbell} value={stats?.classTypes} active={active} />
                  <BoxStats icon={mdiMapMarker} value={stats?.rooms} active={active} />
               </div>
            </ListGroup.Item>
         </LinkContainer>
      </ListGroup>
   );
};

interface BoxStatsProps {
   icon: string;
   value?: number;
   active: boolean;
}
const BoxStats = ({ icon, value, active }: BoxStatsProps) => (
   <div className="d-flex align-items-center">
      <Icon
         className="me-1"
         path={icon}
         size={1}
         color={active ? Colors.background : Colors.secondary}
      />
      {value !== undefined ? <strong>{value}</strong> : <SkeletonPlaceholder width="1.5rem" />}
   </div>
);
