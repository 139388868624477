import React, { memo, useEffect, useState } from 'react';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Modal } from 'react-bootstrap';
import { Colors, isMobile, setTitle } from '../utils';

interface Props {
   /**
    * The text for the headline and the browser title.
    */
   title: string;
   /**
    * The specific browser title. Is used instead of `title` when set.
    */
   browserTitle?: string;
   /**
    * Additional classes.
    */
   className?: string;
   /**
    * An optional page description.
    */
   children?: React.ReactNode;
   /**
    * If set the title will be not set as the browser title. This option will be ignored when a specific browser title is provided.
    */
   doNotSetAsBrowserTitle?: boolean;
}

/**
 * Renders a headline for a page or a page part with an optional description.
 *
 * On mobile the description will be rendered inside an info button to reduce the used space.
 */
export const Headline = memo(({ doNotSetAsBrowserTitle = false, ...props }: Props) => {
   const [showDialog, setShowDialog] = useState(false);

   useEffect(() => {
      if (!doNotSetAsBrowserTitle || props.browserTitle)
         setTitle(props.browserTitle ?? props.title);
   }, [doNotSetAsBrowserTitle, props.browserTitle, props.title]);

   return (
      <>
         <h1 className={`mt-0 mt-md-3 pb-3 ${props.className}`}>
            {props.title}
            {isMobile() && props.children && (
               <Button
                  data-testid="btn-description"
                  className="p-0 ms-1 align-top"
                  variant="link"
                  size="sm"
                  onClick={() => setShowDialog(true)}
               >
                  <Icon
                     className="cursor-pointer"
                     path={mdiInformationOutline}
                     size={1}
                     color={Colors.secondary}
                  />
               </Button>
            )}
         </h1>
         {!isMobile() && props.children && <p className="mb-1 px-1 text-muted">{props.children}</p>}
         <Modal show={showDialog} onHide={() => setShowDialog(false)} centered={isMobile()}>
            <Modal.Header closeButton>
               <Modal.Title>Seitenbeschreibung</Modal.Title>
            </Modal.Header>

            <Modal.Body>{props.children}</Modal.Body>

            <Modal.Footer className="bg-light">
               <Button
                  data-testid="btn-modal-close"
                  variant="outline-link"
                  onClick={() => setShowDialog(false)}
               >
                  Schließen
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
});
