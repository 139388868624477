import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../store';
import {
   setCache,
   setHasNotifications,
   setLoggedIn,
   setLoggedOut,
} from '../store/actions/appActions';
import {
   Box,
   MODULE_PERSONAL_TRAINING,
   Role,
   ROLE_ACCOUNTANT,
   ROLE_BOX_OWNER,
   ROLE_PERSONAL_TRAINER,
   User,
} from '../types/api';
import { setClients, setServices } from '../store/actions/ptActions';
import {
   BoxModel,
   ClassTypeModel,
   PTClientModel,
   PTServiceModel,
   RoleModel,
   RoomModel,
   UserModel,
} from '../models';
import ApiService from '../services/ApiService';

export const useSession = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const sessionUser = useSelector((s: RootState) => s.app.session.user as User | null);
   const sessionRoles = useSelector((s: RootState) => s.app.session.roles);

   const signOut = useCallback(async () => {
      await UserModel.logout();
      dispatch(setLoggedOut());
      navigate('/', { replace: true });
   }, [dispatch, navigate]);

   const roleFilter = (box: Box) => (r: Role) =>
      box.modules.includes(MODULE_PERSONAL_TRAINING) || r.key !== ROLE_PERSONAL_TRAINER;

   const loadAndCacheData = useCallback(
      async (loggedInUser: User) => {
         const [boxes, users, rooms, classTypes, roles] = await Promise.all([
            BoxModel.list(),
            UserModel.list(),
            RoomModel.list(),
            ClassTypeModel.list(),
            RoleModel.list(),
         ]);
         const box = boxes.find(b => b.id === loggedInUser.preferred_box_id) ?? null;
         if (!box) return;

         dispatch(
            setCache({
               allBoxes: boxes,
               box: box,
               users: users,
               rooms: rooms,
               classTypes: classTypes,
               roles: roles.filter(roleFilter(box)),
            })
         );

         if (box.modules.includes(MODULE_PERSONAL_TRAINING)) {
            // Modul "PersonalTraining" ist aktiv, also dementsprechend Daten laden und cachen.
            if (loggedInUser.roles.includes(ROLE_PERSONAL_TRAINER)) {
               // Angemeldeter User ist PersonalTrainer, also dessen Kunden laden.
               dispatch(setClients(await PTClientModel.list()));
            }
            if (
               loggedInUser.roles.includes(ROLE_PERSONAL_TRAINER) ||
               loggedInUser.roles.includes(ROLE_BOX_OWNER) ||
               loggedInUser.roles.includes(ROLE_ACCOUNTANT)
            ) {
               // Angemeldeter User ist PersonalTrainer / Box-Owner / Buchhalter, also die Dienstleitungen laden.
               dispatch(setServices(await PTServiceModel.list()));
            }
         }

         dispatch(setHasNotifications((await ApiService.http.get('/notifications')).data.data[0]));
      },
      [dispatch]
   );

   const signIn = useCallback(
      async (loggedInUser: User) => {
         const roles = (await RoleModel.list()).filter(r => loggedInUser.roles.includes(r.key));
         await loadAndCacheData(loggedInUser);
         dispatch(setLoggedIn(loggedInUser, roles));
         navigate(location, { replace: true });
      },
      [dispatch, loadAndCacheData, location, navigate]
   );

   return {
      sessionUser: sessionUser,
      sessionRoles: sessionRoles,
      signIn: signIn,
      signOut: signOut,
   };
};
