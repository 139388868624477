import React, { CSSProperties, memo } from 'react';
import Icon from '@mdi/react';
import './BadgeIcon.scss';

interface Props {
   showBadge?: boolean;
   icon: string;
   size?: string | number | null;
   badgeStyle?: CSSProperties;
}

export const BadgeIcon = memo(({ showBadge = false, icon, size = 0.75, badgeStyle }: Props) => (
   <span className="badge-icon">
      <Icon path={icon} size={size} />
      {showBadge && <div className="badge" style={badgeStyle} />}
   </span>
));
