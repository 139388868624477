import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import {
   mdiContentDuplicate,
   mdiDotsVerticalCircleOutline,
   mdiPencilOutline,
   mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Colors, formatRange } from '../../utils';
import {
   forceSchedulePageReload,
   removeScheduleEntryInWeek,
   upsertScheduleEntryInWeek,
} from '../../store/actions/scheduleActions';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import { BoxClosingTime } from '../../types/api';
import { EditBoxClosingTimeDialog } from '../../dialogs/EditBoxClosingTimeDialog';
import { BoxClosingTimeModel } from '../../models/BoxClosingTime';
import { DuplicateDialog } from '../../dialogs/DuplicateDialog';
import { CoachingScheduleEntry } from '../../types/CoachingScheduleEntry';

type Props = {
   week: string;
   entry: CoachingScheduleEntry;
};

export const BoxClosingTimeCard = ({ week, entry }: Props) => {
   const dispatch = useDispatch();
   const [showDialog, setShowDialog] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);

   const handleAfterSaveBoxClosingTime = (bct: BoxClosingTime): Promise<void> => {
      dispatch(
         upsertScheduleEntryInWeek(moment(week), {
            id: bct.id,
            type: 'BoxClosingTime',
            obj: bct,
            start: bct.start,
         })
      );
      return Promise.resolve();
   };

   const handleDeleteBoxClosingTime = async () => {
      try {
         const ct = entry.obj as BoxClosingTime;
         await BoxClosingTimeModel.delete(ct);

         dispatch(removeScheduleEntryInWeek(moment(week), entry));

         setShowDeleteDialog(false);
      } catch {
         /* ignore */
      }
   };

   if (entry.type !== 'BoxClosingTime') return null;

   const ct = entry.obj as BoxClosingTime;

   return (
      <div className="d-flex flex-row align-items-center">
         <div
            className="coaching-class-card d-flex bg-secondary text-white"
            style={{
               paddingLeft: '0.75rem',
            }}
         >
            <span className="flex-fill">
               <div className="d-flex flex-wrap small">
                  <span className="">{`${moment(ct.start).format('LT')} - ${moment(ct.end).format(
                     'LT'
                  )}`}</span>
               </div>
               <div className="small fw-bold mb-0">{ct.reason}</div>
            </span>
            <span>
               <Dropdown>
                  <Dropdown.Toggle variant="link" className="no-caret p-0">
                     <Icon path={mdiDotsVerticalCircleOutline} size={1} color={Colors.light} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => setShowDialog(true)}
                     >
                        <Icon
                           path={mdiPencilOutline}
                           size={0.85}
                           color={Colors.secondary}
                           className="me-2"
                        />
                        Bearbeiten
                     </Dropdown.Item>
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => setShowDuplicateDialog(true)}
                     >
                        <Icon
                           path={mdiContentDuplicate}
                           size={0.85}
                           color={Colors.secondary}
                           className="me-2"
                        />
                        Duplizieren
                     </Dropdown.Item>
                     <Dropdown.Divider />
                     <Dropdown.Item
                        className="d-flex align-items-center text-danger"
                        onClick={() => setShowDeleteDialog(true)}
                     >
                        <Icon path={mdiTrashCanOutline} size={0.85} className="me-2" />
                        Löschen
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            </span>
         </div>
         <EditBoxClosingTimeDialog
            boxClosingTime={ct}
            show={showDialog}
            onClose={() => setShowDialog(false)}
            afterSave={handleAfterSaveBoxClosingTime}
         />
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDeleteBoxClosingTime}
         >
            <span>
               Möchtest du die Schließzeit vom{' '}
               <strong>{formatRange(ct?.start, ct?.end, 'full-date-with-time')}</strong> wirklich
               löschen?
            </span>
         </ConfirmDeleteDialog>
         <DuplicateDialog
            entry={ct}
            entryModel={BoxClosingTimeModel}
            show={showDuplicateDialog}
            afterDuplicate={() => dispatch(forceSchedulePageReload())}
            onClose={async () => {
               setShowDuplicateDialog(false);
            }}
         />
      </div>
   );
};
