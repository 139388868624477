import * as React from 'react';
import { RouteObject } from 'react-router-dom';
import { DashboardPage } from './pages/DashboardPage';
import { WelcomePage } from './pages/WelcomePage';
import { ImprintPage } from './pages/ImprintPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { CoachingCalendarPage } from './pages/CoachingCalendarPage';
import { AbsencePage } from './pages/AbsencePage';
import {
   MODULE_PERSONAL_TRAINING,
   ROLE_ACCOUNTANT,
   ROLE_ADMIN,
   ROLE_BOX_OWNER,
   ROLE_COACH,
   ROLE_PERSONAL_TRAINER,
   ROLE_SCHEDULER,
} from './types/api';
import { ManageSchedulePage } from './pages/ManageSchedulePage';
import { EventCoachingClassPage } from './pages/EventCoachingClassPage';
import { PersonalTrainingsPage } from './pages/PersonalTrainingsPage';
import { AccountingPage } from './pages/AccountingPage';
import { ProfilePage } from './pages/ProfilePage';
import { MyCalendarPage } from './pages/MyCalendarPage';
import { CalendarAboTutorialPage } from './pages/CalendarAboTutorialPage';
import { ManageBoxPage } from './pages/ManageBoxPage';
import { ManageEmployeesPage } from './pages/ManageEmployeesPage';
import { AdminPage } from './pages/AdminPage';
import { PageNotFoundPage } from './pages/PageNotFoundPage';
import { RequireAuth } from './utils/RequireAuth';
import { ChangelogPage } from './pages/ChangelogPage';
import { DemoLoginPage } from './pages/DemoLoginPage';
import { RepresentationRequestOverviewPage } from './pages/RepresentationRequest/RepresentationRequestOverviewPage';
import { CreateRepresentationRequestPage } from './pages/RepresentationRequest/CreateRepresentationRequestPage';
import { ViewRepresentationRequestPage } from './pages/RepresentationRequest/ViewRepresentationRequestPage';
import { AdminCalendarPage } from './pages/AdminCalendarPage';
import { NotificationsPage } from './pages/NotificationsPage';
import { EventMeetingPage } from './pages/EventMeetingPage';
import { EventClosingTimePage } from './pages/EventClosingTimePage';
import { EventPersonalTrainingPage } from './pages/EventPersonalTrainingPage';
import { ManageAbsencesPage } from './pages/ManageAbsencesPage';
import { MyScheduleNotesPage } from './pages/MyScheduleNotesPage';
import { StatisticPage } from './pages/StatisticPage';
import { ScheduleBackupPage } from './pages/ScheduleBackupPage';

export interface RouteDef {
   path?: string;
   element: JSX.Element;
   loginRequired?: boolean;
   neededRoles?: string[];
   neededModules?: string[];
   fallbackElement?: JSX.Element;
   children?: RouteDef[];
}

const routesDefinition: RouteDef[] = [
   {
      path: '/',
      element: <DashboardPage />,
      fallbackElement: <WelcomePage />,
   },
   {
      path: '/demo',
      element: <DemoLoginPage />,
   },
   {
      path: '/imprint',
      element: <ImprintPage />,
   },
   {
      path: '/changelog',
      element: <ChangelogPage />,
   },
   {
      path: '/registration',
      element: <RegistrationPage />,
   },
   {
      path: '/calendar',
      element: <CoachingCalendarPage />,
      loginRequired: true,
      /* children: [
         {
            path: ':calView/:startDate', // TODO: Umbauen auf SearchParameter
            element: <CoachingCalendarPage />
         }
      ] */
   },
   {
      path: '/absences',
      element: <AbsencePage />,
      loginRequired: true,
   },
   {
      path: '/schedule',
      element: <ManageSchedulePage />,
      neededRoles: [ROLE_BOX_OWNER, ROLE_SCHEDULER],
   },
   {
      path: '/schedule/notes',
      element: <MyScheduleNotesPage />,
      neededRoles: [ROLE_COACH],
   },
   {
      path: '/schedule/backup',
      element: <ScheduleBackupPage />,
      neededRoles: [ROLE_SCHEDULER, ROLE_BOX_OWNER],
   },
   {
      path: '/event/cc/:eventId',
      element: <EventCoachingClassPage />,
      loginRequired: true,
   },
   {
      path: '/event/pt/:eventId',
      element: <EventPersonalTrainingPage />,
      loginRequired: true,
   },
   {
      path: '/event/ct/:eventId',
      element: <EventClosingTimePage />,
      loginRequired: true,
   },
   {
      path: '/event/m/:eventId',
      element: <EventMeetingPage />,
      loginRequired: true,
   },
   {
      path: '/representationRequest/create/:date/:coachingClassId',
      element: <CreateRepresentationRequestPage />,
      loginRequired: true,
   },
   {
      path: '/representationRequest/:id',
      element: <ViewRepresentationRequestPage />,
      loginRequired: true,
   },
   {
      path: '/representationRequest',
      element: <RepresentationRequestOverviewPage />,
      loginRequired: true,
   },
   {
      path: '/pt',
      element: <PersonalTrainingsPage />,
      neededRoles: [ROLE_PERSONAL_TRAINER],
      neededModules: [MODULE_PERSONAL_TRAINING],
      children: [
         {
            path: ':tab',
            element: <PersonalTrainingsPage />,
            neededRoles: [ROLE_PERSONAL_TRAINER],
            neededModules: [MODULE_PERSONAL_TRAINING],
         },
      ],
   },
   {
      path: '/accounting',
      element: <AccountingPage />,
      neededRoles: [ROLE_ACCOUNTANT, ROLE_BOX_OWNER],
      children: [
         {
            path: ':tab',
            element: <AccountingPage />,
            neededRoles: [ROLE_ACCOUNTANT, ROLE_BOX_OWNER],
         },
      ],
   },
   {
      path: '/statistics',
      element: <StatisticPage />,
      neededRoles: [ROLE_ACCOUNTANT, ROLE_BOX_OWNER],
   },
   {
      path: '/user/profile',
      element: <ProfilePage />,
      loginRequired: true,
      children: [
         {
            path: ':tab',
            element: <ProfilePage />,
            loginRequired: true,
         },
      ],
   },
   {
      path: '/user/calendar',
      element: <MyCalendarPage />,
      loginRequired: true,
   },
   {
      path: '/user/calendar/tutorial',
      element: <CalendarAboTutorialPage />,
      loginRequired: true,
   },
   {
      path: '/manage/box',
      element: <ManageBoxPage />,
      neededRoles: [ROLE_BOX_OWNER],
      children: [
         {
            path: ':tab',
            element: <ManageBoxPage />,
            neededRoles: [ROLE_BOX_OWNER],
         },
      ],
   },
   {
      path: '/manage/employees',
      element: <ManageEmployeesPage />,
      neededRoles: [ROLE_SCHEDULER, ROLE_BOX_OWNER],
      children: [
         {
            path: ':tab',
            element: <ManageEmployeesPage />,
            neededRoles: [ROLE_SCHEDULER, ROLE_BOX_OWNER],
         },
      ],
   },
   {
      path: '/manage/absences',
      element: <ManageAbsencesPage />,
      neededRoles: [ROLE_SCHEDULER, ROLE_BOX_OWNER],
      children: [
         {
            path: ':tab',
            element: <ManageAbsencesPage />,
            neededRoles: [ROLE_SCHEDULER, ROLE_BOX_OWNER],
         },
         {
            path: ':tab/:id',
            element: <ManageAbsencesPage />,
            neededRoles: [ROLE_SCHEDULER, ROLE_BOX_OWNER],
         },
      ],
   },
   {
      path: '/admin',
      element: <AdminPage />,
      neededRoles: [ROLE_ADMIN],
      children: [
         {
            path: 'box/:boxId',
            element: <AdminPage />,
            neededRoles: [ROLE_ADMIN],
         },
      ],
   },
   {
      path: '/admin/calendar',
      element: <AdminCalendarPage />,
      neededRoles: [ROLE_ADMIN],
   },
   {
      path: '/notifications',
      element: <NotificationsPage />,
      loginRequired: true,
   },
   {
      path: '*',
      element: <PageNotFoundPage />,
   },
];

const createRouteObject = (routeDef: RouteDef): RouteObject => {
   const getElement = (): React.ReactNode => {
      if (
         routeDef.loginRequired ||
         routeDef.fallbackElement ||
         routeDef.neededRoles ||
         routeDef.neededModules
      ) {
         return (
            <RequireAuth
               neededRoles={routeDef.neededRoles}
               neededModules={routeDef.neededModules}
               publicElement={routeDef.fallbackElement}
            >
               {routeDef.element}
            </RequireAuth>
         );
      }

      return routeDef.element;
   };

   return {
      path: routeDef.path,
      element: getElement(),
      children: routeDef.children?.map(createRouteObject),
   };
};

const routes: RouteObject[] = routesDefinition.map(createRouteObject);

export default routes;
