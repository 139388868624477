import { SingleApiObject } from './BaseTypes';

export interface Calendar extends SingleApiObject {
   user_id: number;
   access_token: string;
   name: string;
   alarm_before_start: number;
   show_all_coaches: boolean;
   calendar_has_coaches: number[];
   show_all_pt_coaches: boolean;
   calendar_has_pt_coaches: number[];
   show_all_class_types: boolean;
   calendar_has_class_types: number[];
   show_all_rooms: boolean;
   calendar_has_rooms: number[];
   days_in_past: number;
   show_closing_times: boolean;
   show_absence_prohibitions: boolean;
   last_request_time: Date | null;
   last_request_headers: string | null;
   last_request_error: string | null;
   last_fetch: Date | null;
}

export const AlarmBeforeStartOptions = [
   {
      label: 'Keine Erinnerung',
      value: '-1',
   },
   {
      label: 'Bei Ereignisstart',
      value: '0',
   },
   {
      label: '5 Minuten vorher',
      value: '5',
   },
   {
      label: '15 Minuten vorher',
      value: '15',
   },
   {
      label: '30 Minuten vorher',
      value: '30',
   },
   {
      label: '1 Stunde vorher',
      value: '60',
   },
   {
      label: '2 Stunden vorher',
      value: '120',
   },
   {
      label: '4 Stunden vorher',
      value: '240',
   },
   {
      label: '1 Tag vorher',
      value: '1440',
   },
];
