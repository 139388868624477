import moment, { Moment } from 'moment';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';
import { BaseModel } from './BaseModel';
import { SingleApiObject, TimeBasedObject } from '../types/api';

export class ModelExtension {
   public static async listTimeRange<T>(model: string, from?: Moment, to?: Moment): Promise<T[]> {
      try {
         const queryParams: string[] = [];
         if (from) queryParams.push(`from=${from.format('YYYY-MM-DD')}`);
         if (to) queryParams.push(`to=${to.format('YYYY-MM-DD')}`);

         const response = await ApiService.http.get<IApiResponse<T>>(
            `data/${model}/range${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`
         );
         return response.data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public static async listPerWeek<T extends SingleApiObject>(
      model: BaseModel<T>,
      startOfWeek: Moment
   ): Promise<T[]> {
      try {
         if (startOfWeek) {
            const from = startOfWeek.startOf('week');
            const to = moment(from).add(1, 'week');

            return await ModelExtension.listTimeRange(model.getName(), from, to);
         }

         return await model.list();
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public static async copyWeek<T extends TimeBasedObject>(
      model: BaseModel<T>,
      srcWeek: Moment,
      destWeek: Moment,
      skipDuplicates: boolean,
      getOverrideProps: (srcEntry: T) => Partial<T> = () => ({})
   ): Promise<T[]> {
      const src = srcWeek.startOf('week');
      const dest = destWeek.startOf('week');
      const diffInDays = dest.diff(src, 'days');
      try {
         const from = src.format('YYYY-MM-DD');
         const to = moment(src).add(1, 'week').format('YYYY-MM-DD');
         const response = await ApiService.http.get<IApiResponse<T>>(
            `data/${model.getName()}/range?from=${from}&to=${to}`
         );

         let newEntries = response.data.data.map(
            e =>
               ({
                  ...e,
                  id: 0,
                  start: moment(e.start).add(diffInDays, 'days').toDate(),
                  end: moment(e.end).add(diffInDays, 'days').toDate(),
                  ...getOverrideProps(e),
               } as T)
         );

         if (skipDuplicates) {
            // Lade Einträge von der Zielwoche
            const twFrom = dest.format('YYYY-MM-DD');
            const twTo = moment(dest).add(1, 'week').format('YYYY-MM-DD');
            const existingEntries = (
               await ApiService.http.get<IApiResponse<T>>(
                  `data/${model.getName()}/range?from=${twFrom}&to=${twTo}`
               )
            ).data.data;

            // Selektiere alle Einträge, bei denen es in den Existierenden keine Dublette gibt.
            newEntries = newEntries.filter(
               ne => !existingEntries.some(ee => model.isDoublet(ee, ne))
            );
         }

         return await Promise.all(newEntries.map(e => model.insert(e)));
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
