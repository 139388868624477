import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import './App.scss';
import 'moment/locale/de';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { NavBar } from './organisms';
import { RootState } from './store';
import PageFooter from './organisms/PageFooter';
import routes from './Routes';
import { setHasNotifications } from './store/actions/appActions';
import ApiService from './services/ApiService';
import { IApiResponse } from './types/IApiResponse';

const App = () => {
   const dispatch = useDispatch();
   const loginState = useSelector((s: RootState) => s.app.session.loginState);
   const hasNotifications = useSelector((s: RootState) => s.app.hasNotifications);

   useEffect(() => {
      if (loginState !== 'loggedIn') return;

      const timeout = setInterval(() => {
         ApiService.http.get<IApiResponse<boolean>>('/notifications').then(response => {
            const val = response.data.data[0];
            // Nur aktualisieren, wenn der Wert anders als der aktuelle ist
            if (hasNotifications !== val) dispatch(setHasNotifications(val));
         });
      }, 10000);
      return () => clearInterval(timeout);
   });

   const element = useRoutes(routes);

   return (
      <>
         <HttpErrorToast />
         <div id="page-container">
            <NavBar />
            <div id="content" style={{ marginTop: '4rem' }} className="mb-3">
               {element}
            </div>
            <PageFooter />
         </div>
         <ToastContainer
            theme="light"
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
         />
      </>
   );
};

export default App;

const HttpErrorToast = () => {
   const apiError = useSelector((s: RootState) => s.app.apiError);

   useEffect(() => {
      if (apiError) {
         // eslint-disable-next-line no-console
         console.log(apiError);

         if (apiError.response?.status === 403) {
            // FORBIDDEN
            toast.error('Du verfügst nicht über die benötigten Rechte diese Aktion durchzuführen.');
         } else {
            toast.error('Leider trat ein Fehler auf. Bitte versuche es später erneut.');
         }
      }
   }, [apiError]);

   return null;
};
