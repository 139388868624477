import React, { useCallback, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Formik, FormikErrors } from 'formik';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import validator from 'validator';
import { Colors } from '../utils';
import ApiService from '../services/ApiService';
import { FloatingPanel } from '../atoms';
import { Headline } from '../molecules';
import { IApiResponse } from '../types/IApiResponse';
import { ERROR_RECAPTCHA_DENIED, ErrorInfo } from '../types/ErrorInfo';
import { RECAPTCHA_KEY } from '../utils/config';
import { SubmitButton, TextControl } from '../forms';

interface FormModel {
   box_name: string;
   owner_name: string;
   owner_email: string;
}

export const RegistrationPage = () => {
   const [formData, setFormData] = useState<Partial<FormModel>>({});
   const [reCaptchaToken, setReCaptchaToken] = useState('');
   const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

   const handleVerify = useCallback((token: string) => {
      setReCaptchaToken(token);
   }, []);

   const handleSubmitForm = async (values: Partial<FormModel>) => {
      try {
         const body = {
            ...values,
            token: reCaptchaToken,
         };
         await ApiService.http.post(`public/register`, body);
         setRegistrationSuccessful(true);
         setFormData({});
      } catch (err) {
         const error = err as AxiosError<IApiResponse<ErrorInfo>>;
         // FORBIDDEN
         if (error.response?.status === 403) {
            const backendError = error.response.data.data[0];
            if (backendError.error === ERROR_RECAPTCHA_DENIED) {
               toast.error(
                  'Leider hat reCaptcha dich nicht als Mensch erkannt. Bitte versuche es erneut.'
               );
               return;
            }
         }
         ApiService.handleError(error);
      }
   };

   return (
      <Container className="py-2">
         <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
            <Headline title="Registrierung deiner CrossFit Box">
               Hier kannst du deine CrossFit-Box für den Coaching-Zeitplaner registrieren.
            </Headline>
            <FloatingPanel className="pt-4 pb-3">
               {registrationSuccessful ? (
                  <div className="mb-3 flex-fill d-flex flex-column align-items-center">
                     <Icon path={mdiCheck} size={6} color={Colors.success} />
                     <h5>Deine Registrierung wurde erfolgreich abgesendet.</h5>
                  </div>
               ) : (
                  <Formik
                     onSubmit={handleSubmitForm}
                     initialValues={formData}
                     enableReinitialize
                     validate={values => {
                        const errors: FormikErrors<Partial<FormModel>> = {};

                        if (!values.box_name) errors.box_name = 'Bitte gib deinen Box-Namen an.';
                        if (!values.owner_name)
                           errors.owner_name = 'Bitte gib deinen vollständigen Namen an.';
                        if (!values.owner_email)
                           errors.owner_email = 'Bitte gib deine E-Mail-Adresse an.';
                        if (!errors.owner_email && !validator.isEmail(values.owner_email ?? ''))
                           errors.owner_email = 'Das Format der Email-Adresse ist ungültig.';

                        return errors;
                     }}
                  >
                     {formik => (
                        <Form
                           noValidate
                           onSubmit={e => {
                              e.preventDefault();
                              formik.handleSubmit();
                           }}
                        >
                           <GoogleReCaptcha onVerify={handleVerify} />
                           <TextControl
                              formik={formik}
                              name="box_name"
                              label="Name der Box"
                              type="responsive"
                           />
                           <TextControl
                              formik={formik}
                              name="owner_name"
                              label="Name des Box-Owners"
                              type="responsive"
                           />
                           <TextControl
                              inputType="email"
                              formik={formik}
                              name="owner_email"
                              label="Email-Adresse"
                              type="responsive"
                           />

                           <div className="pt-3 d-flex justify-content-end">
                              <SubmitButton formik={formik} className="mx-1">
                                 Registrierung absenden
                              </SubmitButton>
                           </div>
                        </Form>
                     )}
                  </Formik>
               )}
            </FloatingPanel>
         </GoogleReCaptchaProvider>
      </Container>
   );
};
