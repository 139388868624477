import { Formik, FormikErrors } from 'formik';
import { Button, ButtonGroup, Col, Form, InputGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import moment from 'moment/moment';
import Select from 'react-select';
import { mdiArrowDown, mdiArrowUp, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { CoachingBackup, Weekdays } from '../types/api/CoachingBackup';
import { ContentLoader } from '../atoms';
import { FormFieldError, FormRow, FormUtils, GenericControl, SubmitButton } from '../forms';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { useSession } from '../hooks';
import { ROLE_BOX_OWNER, ROLE_SCHEDULER } from '../types/api';
import { getSelectStyles } from '../utils';
import { UserSelect } from '../forms/controls';
import { LoadingButton, MiniProfile, ShowIfRole } from '../molecules';
import { CoachingBackupModel } from '../models/CoachingBackupModel';
import { removeCoachingBackup, upsertCoachingBackup } from '../store/actions/scheduleActions';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';

interface FormModel extends CoachingBackup {
   selected_coach_id?: number;
}

interface Props {
   show: boolean;
   onClose: () => void;
   item: Partial<CoachingBackup>;
   afterSave?: (cb: CoachingBackup) => Promise<void> | void;
}

export const EditCoachingBackupDialog = ({ show, onClose, item, afterSave }: Props) => {
   const { sessionUser } = useSession();
   const dispatch = useDispatch();
   const [isDeleting, setDeleting] = useState(false);
   const [itemToDelete, setItemToDelete] = useState<Partial<CoachingBackup>>();
   const userCanEdit =
      sessionUser?.roles.includes(ROLE_SCHEDULER) || sessionUser?.roles.includes(ROLE_BOX_OWNER);

   const handleSubmitForm = async (values: Partial<FormModel>) => {
      let cb: CoachingBackup;
      if (!values.id) {
         cb = await CoachingBackupModel.insert(values);
      } else {
         cb = await CoachingBackupModel.update(values);
      }

      dispatch(upsertCoachingBackup(cb));
      await afterSave?.(cb);
      onClose();
   };
   return (
      <>
         <Modal show={show} onHide={onClose} size="lg">
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={item as FormModel}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<FormModel>> = {};

                  if (!values.day_of_week && values.day_of_week !== 0)
                     errors.day_of_week = 'Bitte gib einen Wochentag an.';
                  if (!values.start_time) errors.start_time = 'Bitte gib eine Startzeit an.';
                  if (!values.end_time) errors.end_time = 'Bitte gib eine Endzeit an.';

                  // Differenz zwischen Start und Ende muss min. 1 Sekunde betragen (Wegen ungenauen Millisekunden)
                  if (
                     values.start_time &&
                     values.end_time &&
                     moment(values.start_time, 'HH:mm').isSameOrAfter(
                        moment(values.end_time, 'HH:mm'),
                        'minute'
                     )
                  )
                     errors.end_time = 'Das Ende muss nach dem Start sein.';

                  // TODO geht nicht richtig
                  // if (!values.coaches || values.coaches.length === 0)
                  //    errors.coaches = 'Bitte wähle mindestens ein Coach aus.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title className="d-flex align-items-center">
                           {item?.id === 0 ? 'Neuer Eintrag' : 'Eintrag bearbeiten'}
                        </Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        {!item ? (
                           <ContentLoader />
                        ) : (
                           <Row>
                              <Col xs={12} lg={6}>
                                 <GenericControl
                                    label="Wochentag"
                                    formik={formik}
                                    name="day_of_week"
                                 >
                                    <Select
                                       key={formik.values.day_of_week}
                                       options={Weekdays}
                                       styles={getSelectStyles(
                                          FormUtils.isInvalid(formik, 'day_of_week')
                                       )}
                                       placeholder="Wochentag…"
                                       defaultValue={Weekdays.find(
                                          t => t.value === String(formik.values.day_of_week)
                                       )}
                                       onChange={value => {
                                          formik.setFieldValue('day_of_week', Number(value?.value));
                                       }}
                                       onBlur={() => formik.handleBlur('day_of_week')}
                                       isDisabled={formik.isSubmitting || !userCanEdit}
                                    />
                                 </GenericControl>
                                 <FormRow label="Zeitraum">
                                    <Row>
                                       <Col xs={12}>
                                          <Form.Group className="mb-3">
                                             <InputGroup>
                                                <InputGroup.Text>Start</InputGroup.Text>
                                                <Form.Control
                                                   type="time"
                                                   name="start_time"
                                                   className="text-end"
                                                   value={formik.values.start_time}
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   isInvalid={FormUtils.isInvalid(
                                                      formik,
                                                      'start_time'
                                                   )}
                                                   disabled={formik.isSubmitting || !userCanEdit}
                                                />
                                             </InputGroup>
                                             <FormFieldError formik={formik} name="start_time" />
                                          </Form.Group>
                                       </Col>
                                       <Col xs={12}>
                                          <Form.Group className="mb-3">
                                             <InputGroup>
                                                <InputGroup.Text>Ende</InputGroup.Text>
                                                <Form.Control
                                                   type="time"
                                                   name="end_time"
                                                   className="text-end"
                                                   value={formik.values.end_time}
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   isInvalid={FormUtils.isInvalid(
                                                      formik,
                                                      'end_time'
                                                   )}
                                                   disabled={formik.isSubmitting || !userCanEdit}
                                                />
                                             </InputGroup>
                                             <FormFieldError formik={formik} name="end_time" />
                                          </Form.Group>
                                       </Col>
                                    </Row>
                                 </FormRow>
                              </Col>
                              <Col xs={12} lg={6}>
                                 <FormRow label="Coaches">
                                    <div className="d-flex gap-2">
                                       <span className="flex-fill">
                                          <UserSelect
                                             key={formik.values.selected_coach_id}
                                             placeholder="Coach auswählen…"
                                             formik={formik}
                                             name="selected_coach_id"
                                             isOptionDisabled={u =>
                                                formik.values.coaches?.includes(u.id)
                                             }
                                          />
                                       </span>
                                       <Button
                                          className="d-flex align-items-center justify-content-center"
                                          onClick={() => {
                                             if (!formik.values.selected_coach_id) return;

                                             formik.setFieldValue('coaches', [
                                                ...(formik.values.coaches ?? []),
                                                formik.values.selected_coach_id,
                                             ]);
                                             formik.setFieldValue('selected_coach_id', undefined);
                                             formik.validateField('coaches');
                                          }}
                                       >
                                          <Icon path={mdiPlus} size={0.75} />
                                       </Button>
                                    </div>
                                    <FormFieldError formik={formik} name="coaches" />
                                    <ListGroup className="mt-3">
                                       {formik.values.coaches?.map(cid => (
                                          <ListGroup.Item className="d-flex align-items-center gap-2 pe-1">
                                             <MiniProfile user={cid} className="flex-fill" />
                                             <ButtonGroup>
                                                <Button
                                                   id="bc-dialog-move-coach-up"
                                                   variant="link"
                                                   size="sm"
                                                   className="d-flex align-items-center justify-content-center"
                                                   disabled={
                                                      formik.values.coaches?.indexOf(cid) === 0
                                                   }
                                                   onClick={() => {
                                                      const arr = [...formik.values.coaches];
                                                      const i = formik.values.coaches?.indexOf(cid);
                                                      arr.splice(i, 1);
                                                      formik.setFieldValue('coaches', [
                                                         ...arr.slice(0, i - 1),
                                                         cid,
                                                         ...arr.slice(i - 1),
                                                      ]);
                                                   }}
                                                >
                                                   <Icon path={mdiArrowUp} size={0.75} />
                                                </Button>
                                                <Button
                                                   id="bc-dialog-move-coach-down"
                                                   variant="link"
                                                   size="sm"
                                                   className="d-flex align-items-center justify-content-center"
                                                   disabled={
                                                      formik.values.coaches?.indexOf(cid) ===
                                                      (formik.values.coaches?.length ?? 0) - 1
                                                   }
                                                   onClick={() => {
                                                      const arr = [...formik.values.coaches];
                                                      const i = formik.values.coaches?.indexOf(cid);
                                                      arr.splice(i, 1);
                                                      formik.setFieldValue('coaches', [
                                                         ...arr.slice(0, i + 1),
                                                         cid,
                                                         ...arr.slice(i + 1),
                                                      ]);
                                                   }}
                                                >
                                                   <Icon path={mdiArrowDown} size={0.75} />
                                                </Button>

                                                <Button
                                                   id="bc-dialog-remove-coach"
                                                   variant="link"
                                                   size="sm"
                                                   className="d-flex align-items-center justify-content-center text-danger"
                                                   onClick={() => {
                                                      const arr = [...formik.values.coaches];
                                                      arr.splice(
                                                         formik.values.coaches?.indexOf(cid),
                                                         1
                                                      );
                                                      formik.setFieldValue('coaches', arr);
                                                      formik.validateField('coaches');
                                                   }}
                                                >
                                                   <Icon path={mdiTrashCanOutline} size={0.75} />
                                                </Button>
                                             </ButtonGroup>

                                             <Tooltip
                                                anchorSelect="#bc-dialog-move-coach-up"
                                                content="Eine Position nach oben verschieben"
                                                place="bottom"
                                             />
                                             <Tooltip
                                                anchorSelect="#bc-dialog-move-coach-down"
                                                content="Eine Position nach unten verschieben"
                                                place="bottom"
                                             />
                                             <Tooltip
                                                anchorSelect="#bc-dialog-remove-coach"
                                                content="Coach entfernen"
                                                place="bottom"
                                             />
                                          </ListGroup.Item>
                                       ))}
                                    </ListGroup>
                                 </FormRow>
                              </Col>
                           </Row>
                        )}
                     </Modal.Body>
                     <Modal.Footer className="bg-light justify-content-between">
                        <span>
                           <ShowIfRole roles={[ROLE_SCHEDULER, ROLE_BOX_OWNER]}>
                              <LoadingButton
                                 variant="danger"
                                 onClick={() => setItemToDelete(item)}
                                 hidden={item?.id === 0}
                                 disabled={formik.isSubmitting}
                                 isLoading={isDeleting}
                              >
                                 Löschen
                              </LoadingButton>
                           </ShowIfRole>
                        </span>
                        <span>
                           <CreateUpdateInfo hidden={item?.id === 0} obj={item} />
                           <Button
                              variant="outline-link"
                              onClick={onClose}
                              disabled={formik.isSubmitting}
                           >
                              Schließen
                           </Button>
                           <SubmitButton formik={formik}>Speichern</SubmitButton>
                        </span>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
         <ConfirmDeleteDialog
            show={!!itemToDelete}
            onClose={() => setItemToDelete(undefined)}
            onDelete={async () => {
               if (!itemToDelete) return;
               setDeleting(true);
               await CoachingBackupModel.delete(itemToDelete);
               dispatch(removeCoachingBackup(itemToDelete));
               setDeleting(false);
               setItemToDelete(undefined);
               onClose();
            }}
         >
            <span>
               Möchtest du den Eintrag vom{' '}
               <strong>{`${
                  Weekdays.find(w => w.value === String(itemToDelete?.day_of_week))?.label
               } ${itemToDelete?.start_time} bis ${itemToDelete?.end_time}`}</strong>{' '}
               wirklich löschen?
            </span>
         </ConfirmDeleteDialog>
      </>
   );
};
