import { Moment } from 'moment/moment';
import ApiService from '../services/ApiService';
import { SingleApiObject, TimeBasedObject } from '../types/api';

export abstract class BaseModel<T extends SingleApiObject> {
   public abstract getName(): string;

   public async list(filter?: Partial<T>): Promise<T[]> {
      return ApiService.list<T>(this.getName(), filter);
   }

   public async count(filter?: Partial<T>): Promise<number> {
      return ApiService.count<T>(this.getName(), filter);
   }

   public async get(id: number): Promise<T | null> {
      return ApiService.get<T>(this.getName(), id);
   }

   public async insert(obj: Partial<T>): Promise<T> {
      return ApiService.insert<T>(this.getName(), obj);
   }

   public async update(obj: Partial<T>): Promise<T> {
      return ApiService.update<T>(this.getName(), obj);
   }

   public async delete(obj: Partial<T>): Promise<void> {
      return ApiService.delete<T>(this.getName(), obj.id ?? 0);
   }

   public isDoublet(a: T, b: T): boolean {
      return a === b;
   }
}

export interface TimeBasedObjectModel<T extends TimeBasedObject> {
   listTimeRange(from?: Moment, to?: Moment): Promise<T[]>;
}
