import React, { useEffect, useState } from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useSession } from '../hooks';
import { Headline } from '../molecules';
import { SelectYearControl } from '../forms/controls';
import { ScheduleNotes } from '../types/api/ScheduleNotes';
import { ScheduleNotesModel } from '../models/ScheduleNotesModel';
import { SkeletonPlaceholder } from '../atoms';
import { CoachingNoteItem } from '../molecules/CoachingNoteItem';

export const MyScheduleNotesPage = () => {
   const { sessionUser } = useSession();
   const [selectedYear, setSelectedYear] = useState(moment().year());
   const [notes, setNotes] = useState<ScheduleNotes[]>();

   useEffect(() => {
      (async () => {
         const loadedNotes = await ScheduleNotesModel.list({
            year: selectedYear,
            user_id: sessionUser?.id ?? 0,
         });

         const d = [...Array(12).keys()].map(
            month =>
               loadedNotes.find(n => n.month === month + 1) ??
               ({
                  id: 0,
                  user_id: sessionUser?.id ?? 0,
                  year: selectedYear,
                  month: month + 1,
               } as ScheduleNotes)
         );

         setNotes(d);
      })();
   }, [selectedYear, sessionUser]);

   return (
      <Container className="py-2">
         <Headline title="Meine Notizen">
            Die hier zur Verfügung gestellten Notizen helfen den Coaching-Plan besser zu erstellen.
         </Headline>
         <SelectYearControl className="mt-3 justify-content-start" onChange={setSelectedYear} />
         <ListGroup className="mt-3">
            {!notes
               ? [...Array(12).keys()].map(i => (
                    <ListGroup.Item key={i} className="d-flex ">
                       <SkeletonPlaceholder width="70%" className="w-100" />
                       <SkeletonPlaceholder width="3rem" />
                    </ListGroup.Item>
                 ))
               : orderBy(notes, 'month').map(n => (
                    <CoachingNoteItem
                       key={`${selectedYear}-${n.month}`}
                       note={n}
                       onChange={note => {
                          setNotes([
                             ...notes.filter(v => v.year !== note.year || v.month !== note.month),
                             note,
                          ]);
                       }}
                    />
                 ))}
         </ListGroup>
      </Container>
   );
};
