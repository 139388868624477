import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { LoadingButton } from '../molecules';
import { upsertAbsence } from '../store/actions/appActions';
import { formatRange, getSelectStyles } from '../utils';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import {
   ERROR_ABSENCE_NOT_ENOUGH_DAYS_LEFT,
   ERROR_ABSENCE_OVERLAPPING,
   ERROR_ABSENCE_PROHIBITION,
   ErrorInfo,
} from '../types/ErrorInfo';
import { Absence, AbsenceStatus, AbsenceType, ROLE_BOX_OWNER, ROLE_SCHEDULER } from '../types/api';
import { AbsenceModel } from '../models';
import { useSession } from '../hooks';
import { DateRangePicker, UserSelect } from '../forms/controls';
import {
   CheckControl,
   FormFieldError,
   FormRow,
   FormUtils,
   GenericControl,
   SubmitButton,
   TextareaControl,
} from '../forms';

declare type Props = {
   show: boolean;
   onClose: () => void;
   absence?: Partial<Absence> | null;
   afterSave?: (absence: Partial<Absence>) => Promise<void>;
   remainingVacationDays?: number;
   context?: 'user' | 'boxOwner';
};

export const EditAbsenceDialog = ({
   show,
   onClose,
   absence,
   afterSave,
   remainingVacationDays = 999,
   context = 'user',
}: Props) => {
   const dispatch = useDispatch();
   const { sessionUser } = useSession();
   const [vacationDays, setVacationDays] = useState(0);
   const [validationResult, setValidationResult] = useState<ErrorInfo[]>([]);
   const [saveAsDraft, setSaveAsDraft] = useState(false);

   useEffect(() => {
      (async () => {
         setVacationDays(await AbsenceModel.calculateVacationDays(absence?.start, absence?.end));
         setValidationResult([]);
      })();
   }, [absence]);

   if (!absence) return null;

   const handleSubmitForm = async (values: Partial<Absence>) => {
      // Start-Datum beginnt um 00:00 Uhr
      // Ende-Datum endet um 23:59 Uhr
      if (values.end) values.end.setHours(23, 59, 59, 999);

      // Wenn neuer Urlaub erstellt wird, dann immer zuerst als Entwurf, wenn Kontext User
      if (context === 'user' && values.id === 0 && values.type === 'vacation') values.draft = true;

      let response =
         values.id === 0 ? await AbsenceModel.insert(values) : await AbsenceModel.update(values);

      // Da es nicht als Entwurf gespeichert werden soll, wird die Abwesenheit direkt eingereicht.
      if (!saveAsDraft && context === 'user')
         response = await AbsenceModel.submitForApproval(response);

      dispatch(upsertAbsence(response));

      if (afterSave) await afterSave(response);

      onClose();
      setSaveAsDraft(false);
   };

   const getDiffDisplay = (): string => {
      if (vacationDays === 0) return '';
      if (vacationDays === 1) return '1 Tag';

      return `${vacationDays} Tage`;
   };

   const getValidationError = (error: ErrorInfo): string => {
      switch (error.error) {
         case ERROR_ABSENCE_NOT_ENOUGH_DAYS_LEFT:
            return `Du überschreitest deine verfügbare Urlaubstage um ${error.payload?.days} ${
               (error.payload?.days ?? 0) === 1 ? 'Tag' : 'Tage'
            }.`;
         case ERROR_ABSENCE_OVERLAPPING:
            return 'In diesem Zeitraum liegen bereits zu viele Abwesenheiten vor.';
         case ERROR_ABSENCE_PROHIBITION:
            return `Der Zeitraum überschneidet sich mit der Urlaubssperre ${
               error.payload?.reason
            } (${formatRange(
               error.payload?.start as string,
               error.payload?.end as string,
               'simple-date-only'
            )}).`;
         default:
            return error.message ?? error.error;
      }
   };

   return (
      <Modal show={show} onHide={onClose} size="lg">
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={absence}
            enableReinitialize
            validate={async values => {
               const errors: FormikErrors<Partial<Absence>> = {};

               setVacationDays(await AbsenceModel.calculateVacationDays(values.start, values.end));

               if (!values.user_id) errors.user_id = 'Bitte wähle ein Coach aus.';

               if (!values.type) errors.type = 'Bitte wähle ein Typ aus.';

               if (!values.start) errors.start = 'Bitte gib ein Startdatum an.';

               if (!values.end) errors.end = 'Bitte gib ein Enddatum an.';

               if (values.start && values.end && moment(values.start).isAfter(values.end, 'day'))
                  errors.end = 'Das Ende muss nach dem Start sein.';

               setValidationResult([]);

               if (!errors.type && !errors.start && !errors.end) {
                  const result = await AbsenceModel.validate(absence?.id ?? 0, values);
                  setValidationResult(result);

                  if (context === 'user' && result.length > 0) errors.id = 'ValidationError';
               }

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        {absence.id === 0 ? 'Neue Abwesenheit' : 'Abwesenheit bearbeiten'}
                     </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     {!formik.values.draft &&
                        (context === 'user' ||
                           (!sessionUser?.roles.includes(ROLE_SCHEDULER) &&
                              !sessionUser?.roles.includes(ROLE_BOX_OWNER))) && (
                           <Alert variant="primary" className="small">
                              Eingereichte Abwesenheiten können nur noch bedingt bearbeitet werden.
                              Lösche und lege die Abwesenheit neu an oder wende dich an deinen
                              Box-Owner.
                           </Alert>
                        )}
                     {sessionUser?.type !== 'freelancer' &&
                        formik.values.draft &&
                        remainingVacationDays !== 999 && (
                           <Alert variant="info" className="small">
                              Verbleibende Urlaubstage: <strong>{remainingVacationDays}</strong>
                           </Alert>
                        )}
                     {validationResult.length > 0 && (
                        <Alert
                           variant={context === 'user' ? 'danger' : 'warning'}
                           className="small"
                        >
                           {validationResult.map(v => (
                              <div key={v.error}>{getValidationError(v)}</div>
                           ))}
                           {context === 'user' && (
                              <div>
                                 Bitte wende dich an den Box-Owner, wenn du die Abwesenheit dennoch
                                 eingetragen haben möchtest.
                              </div>
                           )}
                        </Alert>
                     )}
                     <Row>
                        <Col xs={12} lg={6}>
                           {context === 'boxOwner' && (
                              <GenericControl
                                 label="Coach"
                                 formik={formik}
                                 name="user_id"
                                 description="Der Coach für den die Abwesenheit gilt."
                              >
                                 <UserSelect
                                    key={formik.values.user_id}
                                    formik={formik}
                                    name="user_id"
                                    placeholder="Coach…"
                                    disabled={formik.values.id !== 0}
                                    isClearable
                                 />
                              </GenericControl>
                           )}
                           <GenericControl
                              label="Art der Abwesenheit"
                              formik={formik}
                              name="type"
                              description="Die Art der Abwesenheit entscheidet, ob Urlaubstage verbraucht werden oder nicht. Alle Abwesenheitsarten müssen vom Box-Owner freigegeben werden."
                           >
                              <Select
                                 key={formik.values.type}
                                 options={AbsenceType}
                                 styles={getSelectStyles(FormUtils.isInvalid(formik, 'type'))}
                                 placeholder="Art der Abwesenheit…"
                                 defaultValue={AbsenceType.find(
                                    t => t.value === formik.values.type
                                 )}
                                 onChange={value => {
                                    formik.setFieldValue('type', value?.value);
                                 }}
                                 onBlur={() => formik.handleBlur('type')}
                                 isDisabled={
                                    formik.isSubmitting ||
                                    (!formik.values.draft &&
                                       (context === 'user' ||
                                          (!sessionUser?.roles.includes(ROLE_SCHEDULER) &&
                                             !sessionUser?.roles.includes(ROLE_BOX_OWNER))))
                                 }
                              />
                           </GenericControl>
                           <FormRow label="Zeitraum">
                              <DateRangePicker
                                 formik={formik}
                                 nameStart="start"
                                 nameEnd="end"
                                 disabled={
                                    !formik.values.draft &&
                                    (context === 'user' ||
                                       (!sessionUser?.roles.includes(ROLE_SCHEDULER) &&
                                          !sessionUser?.roles.includes(ROLE_BOX_OWNER)))
                                 }
                              />
                              <FormFieldError formik={formik} name="start" />
                              <FormFieldError formik={formik} name="end" />
                              <small className="text-muted">{getDiffDisplay()}</small>
                           </FormRow>
                           {context === 'boxOwner' && (
                              <>
                                 <GenericControl name="status" label="Status" formik={formik}>
                                    <Select
                                       key={formik.values.status}
                                       options={AbsenceStatus}
                                       styles={getSelectStyles(
                                          FormUtils.isInvalid(formik, 'status')
                                       )}
                                       placeholder="Status…"
                                       defaultValue={AbsenceStatus.find(
                                          t => t.value === formik.values.status
                                       )}
                                       onChange={value => {
                                          formik.setFieldValue('status', value?.value);
                                       }}
                                       onBlur={() => formik.handleBlur('status')}
                                       isDisabled={formik.isSubmitting}
                                    />
                                 </GenericControl>
                                 <CheckControl
                                    formik={formik}
                                    name="ignore_during_overlap_validation"
                                    label="Bei Prüfung ignorieren"
                                    description="Wenn aktiv, wird diese Abwesenheit bei der Prüfung überlappender Abwesenheiten ignoriert."
                                 />
                                 <CheckControl
                                    formik={formik}
                                    name="draft"
                                    label="Ist ein Entwurf"
                                    description="Gibt an, ob die Abwesenheit noch als Entwurf vorliegt."
                                 />
                              </>
                           )}
                        </Col>
                        <Col xs={12} lg={6}>
                           <TextareaControl
                              label="Kommentar"
                              formik={formik}
                              name="comment"
                              description="Der Kommentar ist optional und besitzt sonst keine Funktionalität"
                           />
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                     <CreateUpdateInfo hidden={absence.id === 0} obj={absence} />
                     <Button
                        variant="outline-link"
                        onClick={onClose}
                        disabled={formik.isSubmitting}
                     >
                        Schließen
                     </Button>
                     {absence.id === 0 && context !== 'boxOwner' ? (
                        <>
                           <LoadingButton
                              variant="outline-secondary"
                              isLoading={formik.isSubmitting}
                              disabled={!formik.isValid}
                              onClick={async () => {
                                 setSaveAsDraft(true);
                                 await formik.submitForm();
                              }}
                           >
                              Entwurf speichern
                           </LoadingButton>
                           <SubmitButton formik={formik}>Einreichen</SubmitButton>
                        </>
                     ) : (
                        <SubmitButton formik={formik}>Speichern</SubmitButton>
                     )}
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
