import { Moment } from 'moment';
import { BaseModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';
import { PTProvidedService } from '../types/api';

class PTProvidedServiceModelClass extends BaseModel<PTProvidedService> {
   public getName(): string {
      return 'pt_provided_service';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<PTProvidedService[]> {
      return ModelExtension.listTimeRange<PTProvidedService>(this.getName(), from, to);
   }
}
export const PTProvidedServiceModel = new PTProvidedServiceModelClass();
