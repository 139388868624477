import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, ListGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import { Formik, FormikErrors } from 'formik';
import { useDispatch } from 'react-redux';
import { Headline } from '../../molecules';
import { SkeletonPlaceholder } from '../../atoms';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import { removeService, upsertService } from '../../store/actions/ptActions';
import { PTService } from '../../types/api';
import { PTServiceModel } from '../../models';
import { FormUtils, SubmitButton } from '../../forms';

export const ManageBoxPTServicesTab = () => {
   const dispatch = useDispatch();
   const [service, setServices] = useState<PTService[]>();

   const loadData = useCallback(async () => {
      setServices(await PTServiceModel.list());
   }, []);

   useEffect(() => {
      (async () => {
         await loadData();
      })();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      (async () => loadData())();
   }, [loadData]);

   const handleAfterSave = (ptService: PTService, operation: 'insert' | 'update') => {
      if (operation === 'insert') setServices(v => [ptService, ...(v ?? [])]);
      else setServices(v => (v ?? []).map(c => (c.id === ptService.id ? ptService : c)));

      dispatch(upsertService(ptService));
   };

   const handleAfterDelete = (ptService: Partial<PTService>) => {
      setServices(v => (v ?? []).filter(c => c.id !== ptService.id));
      dispatch(removeService(ptService));
   };

   return (
      <>
         <Headline title="PT Leistungen" browserTitle="Box-Administration - PT Leistungen" />
         <ListGroup className="floating-panel">
            {!service ? (
               [...Array(6).keys()].map(i => (
                  <ListGroup.Item key={i} className="d-flex justify-content-between">
                     <span className="d-flex">
                        <SkeletonPlaceholder width="20rem" />
                     </span>
                     <SkeletonPlaceholder width="5rem" />
                  </ListGroup.Item>
               ))
            ) : (
               <>
                  <PTServiceRow afterSave={handleAfterSave} />
                  {service.length === 0 ? (
                     <ListGroup.Item className="d-flex align-items-center justify-content-center">
                        <em>Keine Dienstleistungen vorhanden</em>
                     </ListGroup.Item>
                  ) : (
                     service.map(s => (
                        <PTServiceRow
                           key={s.id}
                           service={s}
                           afterSave={handleAfterSave}
                           afterDelete={handleAfterDelete}
                        />
                     ))
                  )}
               </>
            )}
         </ListGroup>
      </>
   );
};

interface PTServiceRowProps {
   service?: PTService;
   afterSave: (service: PTService, operation: 'insert' | 'update') => void;
   afterDelete?: (service: Partial<PTService>) => void;
}

const PTServiceRow = (props: PTServiceRowProps) => {
   const [service, setService] = useState<Partial<PTService>>(props.service ?? { id: 0, name: '' });
   const [isEditMode, setEditMode] = useState(service.id === 0);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);

   const handleSubmitForm = async (values: Partial<PTService>) => {
      const newService =
         values.id === 0
            ? await PTServiceModel.insert(values)
            : await PTServiceModel.update(values);

      props.afterSave(newService, service.id === 0 ? 'insert' : 'update');
      if (!props.service) {
         setService({ id: 0, name: '', x_date_inserted: new Date() });
      } else {
         setService(newService);
         setEditMode(false);
      }
   };

   const handleDelete = async () => {
      if (!props.service) return;

      await PTServiceModel.delete(props.service);
      props.afterDelete?.(props.service);
   };

   return (
      <>
         <ListGroup.Item>
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={service}
               enableReinitialize
               validateOnMount
               validate={values => {
                  const errors: FormikErrors<Partial<PTService>> = {};

                  if (!values.name) errors.name = 'Bitte gib einen Namen für den Service an.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     className="d-flex justify-content-between align-items-center"
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     {isEditMode ? (
                        <Form.Control
                           type="text"
                           name="name"
                           size="sm"
                           placeholder="Name/Beschreibung der Leistung…"
                           className="me-2"
                           value={formik.values.name ?? ''}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           isInvalid={FormUtils.isInvalid(formik, 'name')}
                           disabled={formik.isSubmitting}
                        />
                     ) : (
                        <span>{service.name}</span>
                     )}
                     <span className="text-end" style={{ width: '6rem' }}>
                        {isEditMode ? (
                           <div className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                              {service.id !== 0 && (
                                 <Button
                                    variant="outline-link"
                                    size="sm"
                                    className="d-flex align-items-center ms-md-2"
                                    onClick={() => setEditMode(false)}
                                    disabled={formik.isSubmitting}
                                 >
                                    <Icon path={mdiClose} size={0.75} />
                                 </Button>
                              )}
                              <SubmitButton
                                 formik={formik}
                                 size="sm"
                                 className="d-flex align-items-center"
                              >
                                 <Icon path={service.id !== 0 ? mdiCheck : mdiPlus} size={0.75} />
                              </SubmitButton>
                           </div>
                        ) : (
                           <ButtonGroup>
                              <Button
                                 variant="outline-secondary"
                                 size="sm"
                                 className="d-flex align-items-center"
                                 onClick={() => setEditMode(true)}
                              >
                                 <Icon path={mdiPencilOutline} size={0.75} />
                              </Button>
                              <Button
                                 variant="outline-danger"
                                 size="sm"
                                 className="d-flex align-items-center"
                                 onClick={() => setShowDeleteDialog(true)}
                              >
                                 <Icon path={mdiTrashCanOutline} size={0.75} />
                              </Button>
                           </ButtonGroup>
                        )}
                     </span>
                  </Form>
               )}
            </Formik>
         </ListGroup.Item>
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest Du die Dienstleistung <strong>{service.name}</strong> wirklich löschen?
            </span>
         </ConfirmDeleteDialog>
      </>
   );
};
