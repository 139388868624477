import { Container, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setAbsenceProhibitions, setAbsences } from '../../store/actions/appActions';
import { ContentLoader } from '../../atoms';
import { MyAbsencesTab } from './MyAbsencesTab';
import { AbsenceCalendarTab } from './AbsenceCalendarTab';
import { Headline } from '../../molecules';
import { ROLE_COACH } from '../../types/api';
import { AbsenceModel, AbsenceProhibitionModel } from '../../models';
import { useCache, useSession } from '../../hooks';
import {
   AbsenceEvent,
   AbsenceProhibitionEvent,
   GenericEvent,
} from '../../organisms/Calendar/EventTypes';
import { Colors } from '../../utils';

export const AbsencePage = () => {
   const { sessionUser } = useSession();
   const dispatch = useDispatch();
   const absences = useSelector((s: RootState) => s.app.absences);
   const absenceProhibitions = useSelector((s: RootState) => s.app.absenceProhibitions);
   const { users } = useCache();
   const [activeTab, setActiveTab] = useState<string>();
   const [events, setEvents] = useState<GenericEvent[]>();
   const [isLoading, setLoading] = useState(false);

   // Abwesenheiten laden und in State packen
   const loadEvents = useCallback(
      async (month: Moment = moment().set('date', 1)) => {
         setLoading(true);
         try {
            // In der Anzeige wird der vorherige und nächste Monat ggf. angerissen, daher eine Woche
            //    vorher und nachher mehr laden.
            const from = moment(month).add(-1, 'week');
            const to = moment(from).add(6, 'week');
            dispatch(
               setAbsences(
                  (await AbsenceModel.listTimeRange(from, to)).filter(a => a.status !== 'rejected')
               )
            );
            dispatch(setAbsenceProhibitions(await AbsenceProhibitionModel.listTimeRange(from, to)));
         } finally {
            setLoading(false);
         }
      },
      [dispatch]
   );

   useEffect(() => {
      (async () => loadEvents())();
   }, [loadEvents]);

   // Abwesenheiten & Sperrzeiten von State nehmen und nach Events konvertieren
   useEffect(() => {
      const evts: GenericEvent[] = [];
      evts.push(
         ...absences.map<AbsenceEvent>(a => ({
            id: a.id,
            type: 'Absence',
            color: users.find(u => u.id === a.user_id)?.absence_color,
            isPending: a.draft,
            user: users.find(u => u.id === a.user_id),
            absence: a,
            start: moment(a.start).toDate(),
            end: moment(a.end).toDate(),
            allDay: true,
         }))
      );
      evts.push(
         ...absenceProhibitions.map<AbsenceProhibitionEvent>(a => ({
            id: a.id,
            type: 'AbsenceProhibition',
            color: Colors.secondary,
            absenceProhibition: a,
            title: a.reason,
            start: moment(a.start).toDate(),
            end: moment(a.end).toDate(),
            allDay: true,
         }))
      );

      setEvents(evts);
   }, [absences, absenceProhibitions, users]);

   if (!events) {
      return (
         <Container className="py-2">
            <ContentLoader />
         </Container>
      );
   }

   return (
      <Container className="py-2">
         <Headline title="Abwesenheiten">
            Hier erhältst du eine Übersicht der Abwesenheiten (Urlaub, Krank, etc.) aller Coaches
            deiner Box. Im Tab <strong className="fst-italic">Meine Abwesenheiten</strong> kannst du
            deine eigenen Abwesenheiten anlegen und verwalten.
         </Headline>
         <div className="floating-tabs mt-3">
            <Tabs
               defaultActiveKey="calendar"
               onSelect={async k => {
                  if (k === 'calendar') await loadEvents();
                  setActiveTab(k ?? undefined);
               }}
            >
               <Tab eventKey="calendar" title="Kalender">
                  <AbsenceCalendarTab
                     events={events}
                     isLoading={isLoading}
                     onReloadEvents={m => loadEvents(m)}
                  />
               </Tab>
               <Tab
                  eventKey="my_absences"
                  title="Meine Abwesenheiten"
                  disabled={!sessionUser?.roles.includes(ROLE_COACH)}
               >
                  <MyAbsencesTab activeTab={activeTab} />
               </Tab>
            </Tabs>
         </div>
      </Container>
   );
};
