import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { Colors } from '../../utils';
import { LoadingOverlay } from '../../organisms/LoadingOverlay';
import { Headline } from '../../molecules';
import { SelectYearControl } from '../../forms/controls';
import { AbsenceModel, AbsenceProhibitionModel, HolidaysModel } from '../../models';
import { AbsenceType } from '../../types/api';
import { useCache } from '../../hooks';

interface AbsenceOverview {
   id: number;
   start: Date;
   end: Date;
   user_id?: number;
   text?: string;
   status: string;
   type: string;
   draft: boolean;
}

export const ManageAbsencesOverviewTab = () => {
   const [entries, setEntries] = useState<AbsenceOverview[]>();
   const [year, setYear] = useState(moment().year());

   const handleChangeYear = async (changedYear: number) => {
      setYear(changedYear);
      setEntries(undefined);

      setEntries([
         ...(
            await AbsenceModel.listTimeRange(
               moment(`${changedYear}-01-01`, 'YYYY-MM-DD'),
               moment(`${changedYear}-12-31`, 'YYYY-MM-DD')
            )
         )
            .filter(a => a.status !== 'rejected')
            .map(a => ({
               id: a.id,
               start: a.start,
               end: a.end,
               user_id: a.user_id,
               status: a.status,
               type: a.type,
               draft: a.draft,
            })),
         ...(
            await AbsenceProhibitionModel.listTimeRange(
               moment(`${changedYear}-01-01`, 'YYYY-MM-DD'),
               moment(`${changedYear}-12-31`, 'YYYY-MM-DD')
            )
         ).map(a => ({
            id: a.id,
            start: a.start,
            end: a.end,
            text: `Urlaubssperre: ${a.reason}`,
            status: 'unknown',
            type: 'prohibition',
            draft: false,
         })),
         ...(
            await HolidaysModel.listTimeRange(
               moment(`${changedYear}-01-01`, 'YYYY-MM-DD'),
               moment(`${changedYear}-12-31`, 'YYYY-MM-DD')
            )
         ).map(h => ({
            id: h.id,
            start: h.date,
            end: h.date,
            text: h.name,
            status: 'unknown',
            type: 'holiday',
            draft: false,
         })),
      ]);
   };

   const getFilteredEntries = (month: number) => {
      if (!entries) return [];

      const monthStart = moment(`${year}-${month.toString().padStart(2, '0')}`, 'YYYY-MM');
      const monthEnd = moment(monthStart).add(1, 'month').add(-1, 'second');

      return entries
         .filter(e => moment(e.start).isBefore(monthEnd) && moment(e.end).isAfter(monthStart))
         .sort((a, b) => {
            const timeDiff = a.start.getTime() - b.start.getTime();
            return timeDiff !== 0 ? timeDiff : a.id - b.id;
         });
   };

   return (
      <>
         <Headline title="Urlaubsübersicht" browserTitle="Abwesenheiten - Urlaubsübersicht">
            Zeigt dir eine Übersicht der Abwesenheiten deiner CrossFit-Box für das ganze Jahr an.
            Tage, an welchen mehr als ein Coach abwesend ist, werden rot markiert. Die Farbe der
            Balken entsprechen dabei den Farben der Coaches.
         </Headline>
         <SelectYearControl className="mt-3 justify-content-start" onChange={handleChangeYear} />
         <div id="absence-overview" className="mt-3">
            <LoadingOverlay loading={!entries} withBorderRadius>
               <Table className="floating-table mt-0" hover responsive>
                  <thead>
                     <tr className="bg-light">
                        <th aria-label="Monat" />
                        {[...Array(31).keys()].map(i => (
                           <td key={i}>{i + 1}</td>
                        ))}
                     </tr>
                  </thead>
                  <tbody>
                     {[...Array(12).keys()].map(i => (
                        <MonthRow
                           key={i}
                           monthInYear={`${year}-${(i + 1).toString().padStart(2, '0')}`}
                           entries={getFilteredEntries(i + 1)}
                        />
                     ))}
                  </tbody>
               </Table>
            </LoadingOverlay>
         </div>
      </>
   );
};

interface MonthRowProps {
   monthInYear: string;
   entries: AbsenceOverview[];
}

const MonthRow = ({ monthInYear, entries }: MonthRowProps) => {
   const mMonthInYear = moment(monthInYear, 'YYYY-MM');
   const daysInMonth = mMonthInYear.daysInMonth();

   const getFilteredEntries = (day: number) => {
      const mDay = moment(`${monthInYear}-${day.toString().padStart(2, '0')}`, 'YYYY-MM-DD');

      return entries.filter(
         e => moment(e.start).isSameOrBefore(mDay) && moment(e.end).isSameOrAfter(mDay)
      );
   };
   return (
      <tr>
         <th className="pe-1">{moment.monthsShort()[mMonthInYear.month()]}</th>
         {[...Array(31).keys()].map(i =>
            i + 1 <= daysInMonth ? (
               <DayCell
                  key={i}
                  dayInMonthInYear={`${monthInYear}-${(i + 1).toString().padStart(2, '0')}`}
                  entries={getFilteredEntries(i + 1)}
               />
            ) : (
               <td key={i} className="no-valid-day-in-month" />
            )
         )}
      </tr>
   );
};

interface DayCellProps {
   dayInMonthInYear: string;
   entries: AbsenceOverview[];
}

const DayCell = ({ dayInMonthInYear, entries }: DayCellProps) => {
   const { users, box } = useCache();

   const hasToManyAbsences = (): boolean =>
      entries.filter(e => e.type !== 'prohibition' && e.type !== 'holiday').length >
      (box?.simultaneous_absences ?? 999);

   const holiday = () => {
      const h = entries.find(e => e.type === 'holiday');
      if (h) {
         return (
            <>
               <div id={`day-cell-holiday-${h.id}`} className="holiday" />
               <Tooltip
                  anchorSelect={`#day-cell-holiday-${h.id}`}
                  content={h.text ?? ''}
                  place="top"
               />
            </>
         );
      }

      return <div className="holiday-placeholder" />;
   };

   const getEntryClasses = (elem: AbsenceOverview): string => {
      const classes = ['chip'];
      classes.push(`absence-type-${elem.type}`);
      switch (elem.type) {
         case 'prohibition':
            break;
         case 'holiday':
            break;
         default:
            classes.push(`absence-status-${elem.status}`);
      }
      if (elem.draft) classes.push('absence-draft');

      const d = moment(dayInMonthInYear, 'YYYY-MM-DD');
      if (elem.start.getMonth() === d.month() && elem.start.getDate() === d.date())
         classes.push('first');
      if (elem.end.getMonth() === d.month() && elem.end.getDate() === d.date())
         classes.push('last');

      return classes.join(' ');
   };

   return (
      <td
         className={`weekday-${moment(dayInMonthInYear).weekday()} ${
            hasToManyAbsences() ? 'to-many-entries' : ''
         }`}
      >
         {holiday()}
         {entries
            .filter(e => e.type !== 'holiday')
            .map(e => {
               const user = users.find(u => u.id === e.user_id);
               let ttt;
               if (e.type === 'prohibition') ttt = e.text ?? '';
               else
                  ttt = `${AbsenceType.find(v => v.value === e.type)?.label}: ${user?.first_name} ${
                     user?.last_name
                  }`;

               return (
                  <>
                     <div
                        id={`day-cell-absence-${dayInMonthInYear}-${e.id}`}
                        key={e.id}
                        className={getEntryClasses(e)}
                        style={{
                           backgroundColor: user?.absence_color || Colors.secondary,
                        }}
                     />
                     <Tooltip
                        anchorSelect={`#day-cell-absence-${dayInMonthInYear}-${e.id}`}
                        content={ttt ?? ''}
                        place="top"
                     />
                  </>
               );
            })}
      </td>
   );
};
