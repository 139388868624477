import { mdiCheckCircle, mdiCloseCircle, mdiMinusCircle } from '@mdi/js';
import { Colors } from '../../utils';
import { SingleApiObject } from './BaseTypes';

export type DayOfWeekType =
   | 'monday'
   | 'tuesday'
   | 'wednesday'
   | 'thursday'
   | 'friday'
   | 'saturday'
   | 'sunday';
export type TimeOfDayType = 'morning' | 'afternoon';
export type PreferenceType = 'yes' | 'never' | 'does_not_matter';

export interface PreferredCoachingTimes extends SingleApiObject {
   user_id: number;
   day_of_week: DayOfWeekType;
   time_of_day: TimeOfDayType;
   preference: PreferenceType;
}

export const DayOfWeekOptions: { label: string; labelShort: string; value: DayOfWeekType }[] = [
   {
      label: 'Montag',
      labelShort: 'Mo',
      value: 'monday',
   },
   {
      label: 'Dienstag',
      labelShort: 'Di',
      value: 'tuesday',
   },
   {
      label: 'Mittwoch',
      labelShort: 'Mi',
      value: 'wednesday',
   },
   {
      label: 'Donnerstag',
      labelShort: 'Do',
      value: 'thursday',
   },
   {
      label: 'Freitag',
      labelShort: 'Fr',
      value: 'friday',
   },
   {
      label: 'Samstag',
      labelShort: 'Sa',
      value: 'saturday',
   },
   {
      label: 'Sonntag',
      labelShort: 'So',
      value: 'sunday',
   },
];

export const CoachingPreferenceOptions = [
   {
      label: 'Ich möchte coachen',
      icon: mdiCheckCircle,
      variant: 'outline-success',
      color: Colors.success,
      value: 'yes',
   },
   {
      label: 'Ist mir egal',
      icon: mdiMinusCircle,
      variant: 'outline-secondary',
      color: Colors.secondary,
      value: 'does_not_matter',
   },
   {
      label: 'Ich kann nicht coachen',
      icon: mdiCloseCircle,
      variant: 'outline-danger',
      color: Colors.danger,
      value: 'never',
   },
];
