import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Headline, LoadingButton } from '../../molecules';
import { FilterDef, FilterWidget } from './FilterWidget';
import { EditAbsenceDialog } from '../../dialogs/EditAbsenceDialog';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import { AbsenceList } from '../../organisms';
import { formatRange } from '../../utils';
import { Absence } from '../../types/api';
import { AbsenceModel } from '../../models';

interface Props {
   onUpdateCounter: () => Promise<void>;
}

export const ManageAbsencesListTab = ({ onUpdateCounter }: Props) => {
   const params = useParams();
   const [urlParams] = useSearchParams();
   const [absencesToApprove, setAbsencesToApprove] = useState<Absence[]>();

   const [absences, setAbsences] = useState<Absence[]>();
   const [filter, setFilter] = useState<FilterDef>();
   const [absenceToEdit, setAbsenceToEdit] = useState<Partial<Absence>>();
   const [absenceToDelete, setAbsenceToDelete] = useState<Absence>();

   const loadData = useCallback(async () => {
      if (!filter) return;
      setAbsences(undefined);

      let result = await AbsenceModel.listTimeRange(moment(filter.start), moment(filter.end));
      result = result
         .filter(a => !filter.status || a.status === filter.status)
         .filter(a => filter.users?.length === 0 || filter.users?.includes(a.user_id));

      setAbsences(result);

      setAbsencesToApprove(await AbsenceModel.list({ status: 'pending', draft: false }));
   }, [filter]);

   useEffect(() => {
      (async () => {
         await loadData();
      })();
   }, [loadData]);

   const handleGrant = useCallback(
      async (item: Absence) => {
         item.status = 'granted';
         await AbsenceModel.update(item);
         await loadData();
         await onUpdateCounter();
         toast.success('Der Antrag wurde erfolgreich genehmigt.');
      },
      [loadData, onUpdateCounter]
   );

   const handleReject = useCallback(
      async (item: Absence) => {
         item.status = 'granted';
         await AbsenceModel.update(item);
         await loadData();
         await onUpdateCounter();
         toast.success('Der Antrag wurde erfolgreich abgelehnt.');
      },
      [loadData, onUpdateCounter]
   );

   useEffect(() => {
      (async () => {
         if (absencesToApprove && urlParams.get('action')) {
            const absence = absencesToApprove.find(a => a.id === Number(params.id));
            // Angeforderte Abwesenheit existiert nicht
            if (!absence) return;

            switch (urlParams.get('action')) {
               case 'grant':
                  await handleGrant(absence);
                  break;
               case 'reject':
                  await handleReject(absence);
                  break;
               default:
                  // nothing to do
                  break;
            }
         }
      })();
   }, [absencesToApprove, handleGrant, handleReject, params.id, urlParams]);

   const handleDelete = async () => {
      if (!absenceToDelete) return;

      try {
         await AbsenceModel.delete(absenceToDelete);
         setAbsenceToDelete(undefined);
         await loadData();
      } catch {
         /* ignore */
      }
   };

   return (
      <>
         <Headline title="Offene Anträge" browserTitle="Abwesenheiten - Liste" />

         <AbsenceList
            absences={absencesToApprove}
            highlightedId={Number(params.id)}
            className="mt-3"
            context="boxOwner"
         >
            {({ item }) => (
               <ButtonGroup>
                  <LoadingButton
                     variant="outline-success"
                     size="sm"
                     className="d-flex align-items-center"
                     onClick={() => handleGrant(item)}
                     tooltip="Antrag genehmigen"
                     tooltipPlacement="top"
                     hideContentWhenLoading
                  >
                     <Icon path={mdiCheck} size={0.75} />
                  </LoadingButton>
                  <LoadingButton
                     variant="outline-danger"
                     size="sm"
                     className="d-flex align-items-center"
                     onClick={() => handleReject(item)}
                     tooltip="Antrag ablehnen"
                     tooltipPlacement="top"
                     hideContentWhenLoading
                  >
                     <Icon path={mdiClose} size={0.75} />
                  </LoadingButton>
               </ButtonGroup>
            )}
         </AbsenceList>

         <Headline title="Alle Abwesenheiten" />

         <Button
            variant="success"
            className="floating-button"
            onClick={() => {
               setAbsenceToEdit({
                  id: 0,
                  draft: false,
                  status: 'granted',
               });
            }}
         >
            Neue Abwesenheit
         </Button>
         <FilterWidget onFilterChange={setFilter} />
         <AbsenceList absences={absences} className="floating-panel" context="boxOwner">
            {({ item }) => (
               <ButtonGroup>
                  <LoadingButton
                     variant="outline-secondary"
                     size="sm"
                     className="d-flex align-items-center"
                     tooltip="Abwesenheit bearbeiten"
                     onClick={() => setAbsenceToEdit(item)}
                  >
                     <Icon path={mdiPencilOutline} size={0.75} />
                  </LoadingButton>
                  <LoadingButton
                     variant="outline-danger"
                     size="sm"
                     className="d-flex align-items-center"
                     tooltip="Abwesenheit löschen"
                     onClick={() => setAbsenceToDelete(item)}
                  >
                     <Icon path={mdiTrashCanOutline} size={0.75} />
                  </LoadingButton>
               </ButtonGroup>
            )}
         </AbsenceList>
         <EditAbsenceDialog
            show={!!absenceToEdit}
            onClose={() => setAbsenceToEdit(undefined)}
            absence={absenceToEdit}
            afterSave={() => loadData()}
            context="boxOwner"
         />
         <ConfirmDeleteDialog
            show={!!absenceToDelete}
            onClose={() => setAbsenceToDelete(undefined)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest du die Abwesenheit vom{' '}
               <strong>
                  {formatRange(absenceToDelete?.start, absenceToDelete?.end, 'simple-date-only')}
               </strong>{' '}
               wirklich löschen?
            </span>
         </ConfirmDeleteDialog>
      </>
   );
};
