import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MiniProfile } from '../molecules';

interface Props {
   coaches: number[];
   numberOfItems?: number;
}

export const CoachesAvatarList = ({ coaches, numberOfItems = 5 }: Props) => (
   <div className="ps-2 d-inline-flex">
      {coaches.slice(0, numberOfItems - 1).map(c => (
         <span
            key={c}
            className="bg-white rounded-circle"
            style={{ marginLeft: '-0.5rem', padding: 2 }}
         >
            <MiniProfile user={c} type="avatar" />
         </span>
      ))}
      {coaches.length > numberOfItems - 1 && (
         <span className="bg-white rounded-circle" style={{ marginLeft: '-0.5rem', padding: 2 }}>
            <OverlayTrigger
               trigger={['hover', 'focus']}
               placement="top"
               delay={{ show: 250, hide: 400 }}
               overlay={
                  <Tooltip id="tooltip-user-more">
                     {coaches.slice(numberOfItems - 1).map(c => (
                        <MiniProfile user={c} type="nameOnly" />
                     ))}
                  </Tooltip>
               }
            >
               <small
                  className="d-flex justify-content-center align-items-center bg-secondary rounded-circle text-white fw-bold"
                  style={{ width: '1.5rem', height: '1.5rem' }}
               >{`+${coaches.length - numberOfItems + 1}`}</small>
            </OverlayTrigger>
         </span>
      )}
   </div>
);
