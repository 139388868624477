import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment/moment';
import { PieCustomLayerProps, ResponsivePie } from '@nivo/pie';
import { groupBy, orderBy } from 'lodash';
import { PieLayer } from '@nivo/pie/dist/types/types';
import { Headline } from '../../molecules';
import { SelectYearControl } from '../../forms/controls';
import { RepresentationRequestModel } from '../../models';
import { RepresentationRequestHasVoteModel } from '../../models/RepresentationRequestHasVote';
import { RepresentationRequest, RepresentationRequestHasVote, ROLE_COACH } from '../../types/api';
import { Colors, notEmpty } from '../../utils';
import { ContentLoader } from '../../atoms';
import { useCache } from '../../hooks';

interface Request extends RepresentationRequest {
   votesYes: RepresentationRequestHasVote[];
   votesNo: RepresentationRequestHasVote[];
   selectedVote?: RepresentationRequestHasVote;
}

interface ChartData {
   id: string;
   label: string;
   value: number;
   color?: string;
}

export const StatisticPage = () => {
   const { users } = useCache();
   const [isLoading, setLoading] = useState(false);
   const [selectedYear, setSelectedYear] = useState(moment().year());
   const [requests, setRequests] = useState<Request[]>([]);
   const [yesVotesByUser, setYesVotesByUser] = useState<ChartData[]>([]);
   const [noVotesByUser, setNoVotesByUser] = useState<ChartData[]>([]);
   const [notVotedByUser, setNotVotedByUser] = useState<ChartData[]>([]);

   useEffect(() => {
      (async () => {
         if (isLoading) return;

         setLoading(true);
         try {
            /**
             * Daten laden
             */

            const data = await RepresentationRequestModel.listTimeRange(
               moment(`${selectedYear}-01-01`, 'YYYY-MM-DD'),
               moment(`${selectedYear}-12-31`, 'YYYY-MM-DD')
            );

            const repRequests = await Promise.all(
               data.map(async r => {
                  const votes = await RepresentationRequestHasVoteModel.list({
                     request_id: r.id,
                  });

                  return {
                     ...r,
                     votesYes: votes.filter(v => v.vote === 'yes'),
                     votesNo: votes.filter(v => v.vote === 'no'),
                     selectedVote: votes.find(v => v.is_selected),
                  } as Request;
               })
            );
            setRequests(repRequests);

            /**
             * Daten für UI aufbereiten
             */

            const coaches = users.filter(u => u.enabled).filter(u => u.roles.includes(ROLE_COACH));

            // setSuccessfulQuoteRequests([
            //    {
            //       id: 'Erfolgreich',
            //       label: 'Erfolgreich',
            //       value: repRequests?.filter(r => r.selectedVote).length,
            //       color: `${Colors.success}55`,
            //    },
            //    {
            //       id: 'Nicht erfolgreich',
            //       label: 'Nicht erfolgreich',
            //       value: repRequests?.filter(r => !r.selectedVote).length,
            //       color: `${Colors.danger}55`,
            //    },
            // ]);
            const yesVotesRaw: { coach_id: number; count: number }[] = [];
            const noVotesRaw: { coach_id: number; count: number }[] = [];
            repRequests.forEach(r => {
               r.votesYes.forEach(v => {
                  const entry = yesVotesRaw.find(e => e.coach_id === v.user_id);
                  if (entry) {
                     entry.count += 1;
                  } else {
                     yesVotesRaw.push({ coach_id: v.user_id, count: 1 });
                  }
               });
               r.votesNo.forEach(v => {
                  const entry = noVotesRaw.find(e => e.coach_id === v.user_id);
                  if (entry) {
                     entry.count += 1;
                  } else {
                     noVotesRaw.push({ coach_id: v.user_id, count: 1 });
                  }
               });
            });

            const yesVotes = orderBy(
               yesVotesRaw
                  .map(e => {
                     const coach = coaches.find(u => u.id === e.coach_id);
                     if (!coach) return null;

                     return {
                        id: `${coach?.first_name} ${coach?.last_name}`,
                        label: `${coach?.first_name} ${coach?.last_name}`,
                        value: e.count,
                        color: `${coach?.absence_color}55`,
                     } as ChartData;
                  })
                  .filter(notEmpty),
               'value',
               'desc'
            );
            const noVotes = orderBy(
               noVotesRaw
                  .map(e => {
                     const coach = coaches.find(u => u.id === e.coach_id);
                     if (!coach) return null;

                     return {
                        id: `${coach?.first_name} ${coach?.last_name}`,
                        label: `${coach?.first_name} ${coach?.last_name}`,
                        value: e.count,
                        color: `${coach?.absence_color}55`,
                     } as ChartData;
                  })
                  .filter(notEmpty),
               'value',
               'desc'
            );

            setYesVotesByUser(yesVotes);
            setNoVotesByUser(noVotes);
            setNotVotedByUser(
               coaches.map(c => {
                  const coach = coaches.find(u => u.id === c.id);
                  const yv = yesVotesRaw.find(v => v.coach_id === c.id)?.count ?? 0;
                  const nv = noVotesRaw.find(v => v.coach_id === c.id)?.count ?? 0;
                  return {
                     id: `${coach?.first_name} ${coach?.last_name}`,
                     label: `${coach?.first_name} ${coach?.last_name}`,
                     value: repRequests.length - yv - nv,
                     color: `${coach?.absence_color}55`,
                  };
               })
            );
         } finally {
            setLoading(false);
         }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedYear]);

   return (
      <Container className="py-2">
         <Headline title="Statistiken" />
         <SelectYearControl
            className="flex-row w-auto"
            year={selectedYear}
            onChange={year => setSelectedYear(year)}
         />

         {/* eslint-disable-next-line no-nested-ternary */}
         {isLoading ? (
            <ContentLoader />
         ) : requests.length === 0 ? (
            <div className="d-flex justify-content-center mt-4">Keine Daten vorhanden.</div>
         ) : (
            <>
               <h3 className="mt-4">Vertretungsanfragen</h3>
               <Row>
                  <Col xs={12} sm={6} lg={3}>
                     <h5>Antragssteller</h5>
                     <PieChart
                        data={Object.entries(groupBy(requests.map(r => r.user_id))).map(
                           ([coachId, r]) => {
                              const coach = users.find(u => String(u.id) === coachId);

                              return {
                                 id: `${coach?.first_name} ${coach?.last_name}`,
                                 label: `${coach?.first_name} ${coach?.last_name}`,
                                 value: r.length,
                                 color: `${coach?.absence_color}55`,
                              };
                           }
                        )}
                        showSum
                     />
                  </Col>
                  <Col xs={12} sm={6} lg={3}>
                     <h5>Ja-Stimmen</h5>
                     <PieChart data={yesVotesByUser} />
                  </Col>
                  <Col xs={12} sm={6} lg={3}>
                     <h5>Nein-Stimmen</h5>
                     <PieChart data={noVotesByUser} />
                  </Col>
                  <Col xs={12} sm={6} lg={3}>
                     <h5>Enthaltungen</h5>
                     <PieChart data={notVotedByUser} />
                  </Col>
                  <Col xs={12} sm={6} lg={3}>
                     <h5>Ausgewählt</h5>
                     <PieChart
                        data={Object.entries(groupBy(requests.map(r => r.selectedVote?.user_id)))
                           .map(([coachId, r]) => {
                              const coach = users.find(u => String(u.id) === coachId);
                              if (!coach) return null;

                              return {
                                 id: `${coach?.first_name} ${coach?.last_name}`,
                                 label: `${coach?.first_name} ${coach?.last_name}`,
                                 value: r.length,
                                 color: `${coach?.absence_color}55`,
                              };
                           })
                           .filter(notEmpty)}
                     />
                  </Col>
               </Row>
            </>
         )}
      </Container>
   );
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }: PieCustomLayerProps<ChartData>) => {
   const total = useMemo(
      () => dataWithArc.reduce((sum, entry) => sum + entry.value, 0),
      [dataWithArc]
   );

   return (
      <text
         x={centerX}
         y={centerY}
         textAnchor="middle"
         dominantBaseline="central"
         style={{
            fontSize: '52px',
            fontWeight: 600,
         }}
      >
         {total}
      </text>
   );
};

const PieChart = ({ data, showSum = false }: { data: ChartData[]; showSum?: boolean }) => {
   const layers = useMemo(() => {
      const l: PieLayer<ChartData>[] = ['arcs', 'arcLabels', 'arcLinkLabels', 'legends'];
      if (showSum) l.push(CenteredMetric);
      return l;
   }, [showSum]);

   return (
      <div className="p-1 pb-4" style={{ width: '100%', aspectRatio: '1' }}>
         <ResponsivePie
            data={data}
            sortByValue
            colors={{ datum: 'data.color' }}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.5}
            cornerRadius={2}
            padAngle={1.5}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
               from: 'color',
               modifiers: [['darker', 4]],
            }}
            enableArcLinkLabels={false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={Colors.secondary}
            layers={layers}
         />
      </div>
   );
};
