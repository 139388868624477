import { Accordion, Button, Col, Form, FormCheck, InputGroup, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import Select from 'react-select';
import { ShowIfModule } from '../molecules';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { RoomMultiSelect, UserMultiSelect } from '../forms/controls';
import { Colors, getSelectStyles } from '../utils';
import { AlarmBeforeStartOptions, Calendar, MODULE_PERSONAL_TRAINING } from '../types/api';
import { CalendarModel } from '../models';
import { useSession } from '../hooks';
import { CheckControl, FormUtils, GenericControl, SubmitButton, TextControl } from '../forms';

interface FormModel {
   name: string;
   alarm_before_start: string;
   show_all_coaches: boolean;
   calendar_has_coaches: number[];
   show_all_pt_coaches: boolean;
   calendar_has_pt_coaches: number[];
   show_all_class_types: boolean;
   calendar_has_class_types: number[];
   show_all_rooms: boolean;
   calendar_has_rooms: number[];
   days_in_past: number;
   show_closing_times: boolean;
   show_absence_prohibitions: boolean;
}
declare type Props = {
   show: boolean;
   onClose: () => void;
   calendar?: Partial<Calendar> | null;
   afterSave?: (calendar: Partial<Calendar>) => Promise<void>;
};

export const EditCalendarDialog = ({ show, onClose, calendar, afterSave }: Props) => {
   const { sessionUser } = useSession();
   const [formData, setFormData] = useState<Partial<FormModel>>();

   useEffect(() => {
      (async () => {
         setFormData({
            name: calendar?.name,
            alarm_before_start: `${calendar?.alarm_before_start ?? '-1'}`,
            show_all_coaches: calendar?.show_all_coaches ?? false,
            calendar_has_coaches: calendar?.calendar_has_coaches ?? [sessionUser?.id],
            show_all_pt_coaches: calendar?.show_all_pt_coaches ?? false,
            calendar_has_pt_coaches: calendar?.calendar_has_pt_coaches ?? [],
            show_all_class_types: calendar?.show_all_class_types ?? true,
            calendar_has_class_types: calendar?.calendar_has_class_types ?? [],
            show_all_rooms: calendar?.show_all_rooms ?? true,
            calendar_has_rooms: calendar?.calendar_has_rooms ?? [],
            days_in_past: calendar?.days_in_past ?? 14,
            show_closing_times: calendar?.show_closing_times ?? true,
            show_absence_prohibitions: calendar?.show_absence_prohibitions ?? true,
         } as Partial<FormModel>);
      })();
   }, [calendar, sessionUser]);

   const handleSubmitForm = async (values: Partial<FormModel>) => {
      const c = {
         ...(calendar ?? {}),
         user_id: sessionUser?.id,
         name: values.name,
         alarm_before_start:
            values.alarm_before_start === '-1' ? null : Number(values.alarm_before_start),
         show_all_coaches: values.calendar_has_coaches?.length === 0,
         calendar_has_coaches: values.calendar_has_coaches ?? [],
         show_all_pt_coaches: values.show_all_pt_coaches,
         calendar_has_pt_coaches: values.calendar_has_pt_coaches ?? [],
         show_all_class_types: values.calendar_has_class_types?.length === 0,
         calendar_has_class_types: values.calendar_has_class_types ?? [],
         show_all_rooms: values.calendar_has_rooms?.length === 0,
         calendar_has_rooms: values.calendar_has_rooms ?? [],
         days_in_past: values.days_in_past,
         show_closing_times: values.show_closing_times,
         show_absence_prohibitions: values.show_absence_prohibitions,
      } as Calendar;

      let cal;
      if (c.id === 0) {
         cal = await CalendarModel.insert(c);
      } else {
         cal = await CalendarModel.update(c);
      }

      if (afterSave) await afterSave(cal);

      onClose();
   };

   return (
      <Modal show={show} onHide={onClose} size="lg">
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={formData ?? {}}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<FormModel>> = {};

               if (!values.name) errors.name = 'Bitte gib einen Namen an.';

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        {calendar?.id === 0 ? 'Neuer Kalender' : 'Kalender bearbeiten'}
                     </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <Row>
                        <Col xs={12} lg={6}>
                           <TextControl
                              formik={formik}
                              name="name"
                              label="Name"
                              placeholder="Name des Kalenders…"
                              description="Der Name wird später auch im Kalender/Handy angezeigt."
                           />
                        </Col>
                        <Col xs={12} lg={6}>
                           <GenericControl
                              formik={formik}
                              name="alarm_before_start"
                              label="Erinnerung"
                              description="Gibt an, wie viel vorher eine Erinnerung für den Coaching-Termin ausgelöst werden soll, sofern dein Gerät dies unterstützt und aktiviert hat."
                           >
                              <Select
                                 options={AlarmBeforeStartOptions}
                                 value={AlarmBeforeStartOptions.find(
                                    a => a.value === formik.values.alarm_before_start
                                 )}
                                 onChange={v =>
                                    formik.setFieldValue('alarm_before_start', v?.value)
                                 }
                                 onBlur={() => formik.handleBlur('alarm_before_start')}
                                 isDisabled={formik.isSubmitting}
                                 styles={getSelectStyles()}
                              />
                           </GenericControl>
                        </Col>
                     </Row>
                     <Accordion flush>
                        <Accordion.Item eventKey="0">
                           <Accordion.Header>Erweiterte Einstellungen</Accordion.Header>
                           <Accordion.Body className="p-0">
                              <div className="mt-3">
                                 <Row>
                                    <Col xs={12} lg={6}>
                                       <GenericControl
                                          label="Folgende Coaches anzeigen"
                                          formik={formik}
                                          name="calendar_has_coaches"
                                          description="Es werden nur Klassen im Kalender angezeigt, bei denen die ausgewählten Coaches als erster, zweiter oder weiterer Coach eingetragen ist."
                                       >
                                          <UserMultiSelect
                                             values={formik.values.calendar_has_coaches}
                                             onChange={selectedValues =>
                                                formik.setFieldValue(
                                                   'calendar_has_coaches',
                                                   selectedValues
                                                )
                                             }
                                             onBlur={() =>
                                                formik.handleBlur('calendar_has_coaches')
                                             }
                                             isDisabled={formik.isSubmitting}
                                          />
                                       </GenericControl>
                                       <GenericControl
                                          label="Folgende Klassen anzeigen"
                                          formik={formik}
                                          name="calendar_has_class_types"
                                          description="Es werden nur die ausgewählten Klassentypen im Kalender angezeigt."
                                       >
                                          <RoomMultiSelect
                                             values={formik.values.calendar_has_class_types}
                                             onChange={selectedValues =>
                                                formik.setFieldValue(
                                                   'calendar_has_class_types',
                                                   selectedValues
                                                )
                                             }
                                             onBlur={() =>
                                                formik.handleBlur('calendar_has_class_types')
                                             }
                                             isDisabled={formik.isSubmitting}
                                          />
                                       </GenericControl>
                                       <GenericControl
                                          label="Folgende Räume anzeigen"
                                          formik={formik}
                                          name="calendar_has_rooms"
                                          description="Es werden nur die ausgewählten Räume im Kalender angezeigt."
                                       >
                                          <RoomMultiSelect
                                             values={formik.values.calendar_has_rooms}
                                             onChange={selectedValues =>
                                                formik.setFieldValue(
                                                   'calendar_has_rooms',
                                                   selectedValues
                                                )
                                             }
                                             onBlur={() => formik.handleBlur('calendar_has_rooms')}
                                             isDisabled={formik.isSubmitting}
                                          />
                                       </GenericControl>
                                       <ShowIfModule module={MODULE_PERSONAL_TRAINING}>
                                          <GenericControl
                                             label="PTs folgender Coaches anzeigen"
                                             formik={formik}
                                             name="calendar_has_pt_coaches"
                                             description="Es werden nur Personal Trainings im Kalender angezeigt, welche von den ausgewählten Coaches durchgeführt werden."
                                          >
                                             <InputGroup className="d-flex align-items-center">
                                                <FormCheck
                                                   type="switch"
                                                   id="module-switch-all-pts"
                                                   label=" "
                                                   className="me-2 cursor-pointer"
                                                   color={Colors.success}
                                                   checked={formik.values.show_all_pt_coaches}
                                                   disabled={formik.isSubmitting}
                                                   onChange={() =>
                                                      formik.setFieldValue(
                                                         'show_all_pt_coaches',
                                                         !formik.values.show_all_pt_coaches
                                                      )
                                                   }
                                                   onBlur={() =>
                                                      formik.handleBlur('show_all_pt_coaches')
                                                   }
                                                />
                                                <UserMultiSelect
                                                   className="flex-fill"
                                                   values={formik.values.calendar_has_pt_coaches}
                                                   onChange={selectedValues =>
                                                      formik.setFieldValue(
                                                         'calendar_has_pt_coaches',
                                                         selectedValues
                                                      )
                                                   }
                                                   isDisabled={
                                                      formik.isSubmitting ||
                                                      !formik.values.show_all_pt_coaches
                                                   }
                                                />
                                             </InputGroup>
                                          </GenericControl>
                                       </ShowIfModule>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                       <GenericControl
                                          label="Zeitraum für Termine in Vergangenheit"
                                          formik={formik}
                                          name="days_in_past"
                                          description="Gibt ab, wie viele Tage in der Vergangenheit Termine für das Kalender-Abo bereitgestellt wird. Einige Clients (z.B. Google) haben mit zu vielen Terminen Probleme."
                                       >
                                          <InputGroup>
                                             <Form.Control
                                                type="number"
                                                name="days_in_past"
                                                value={formik.values.days_in_past}
                                                min={0}
                                                max={365}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={FormUtils.isInvalid(
                                                   formik,
                                                   'days_in_past'
                                                )}
                                                disabled={formik.isSubmitting}
                                             />
                                             <InputGroup.Text>Tage</InputGroup.Text>
                                          </InputGroup>
                                       </GenericControl>
                                       <CheckControl
                                          label="Schließzeiten der Box anzeigen"
                                          formik={formik}
                                          name="show_closing_times"
                                          description="Wenn aktiv, werden im Kalender zusätzlich die Schließzeiten der Box angezeigt."
                                       />
                                       <CheckControl
                                          label="Urlaubssperren anzeigen"
                                          formik={formik}
                                          name="show_absence_prohibitions"
                                          description="Wenn aktiv, werden im Kalender zusätzlich die eingetragenen Urlaubssperren der Box angezeigt."
                                       />
                                    </Col>
                                 </Row>
                              </div>
                           </Accordion.Body>
                        </Accordion.Item>
                     </Accordion>
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                     <CreateUpdateInfo hidden={(calendar?.id ?? 0) === 0} obj={calendar} />
                     <Button
                        variant="outline-link"
                        onClick={onClose}
                        disabled={formik.isSubmitting}
                     >
                        Schließen
                     </Button>
                     <SubmitButton formik={formik}>Speichern</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
