import React from 'react';
import { Alert, Badge, Col, Container, ListGroup, Row } from 'react-bootstrap';
import moment from 'moment';
import { mdiEmailArrowRightOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { SkeletonPlaceholder } from '../atoms';
import { AbsenceApprovalState, MiniProfile } from '../molecules';
import { Colors } from '../utils';
import { Absence, AbsenceType } from '../types/api';

interface Props {
   /**
    * An array of [Absences]{@link Absence} to be displayed.
    */
   absences?: Absence[];
   /**
    * Used to highlight the specific {@link Absence}.
    */
   highlightedId?: number;
   /**
    * Additional classes.
    */
   className?: string;
   /**
    * The content for the action column on the right of the list. Intended primarily for buttons.
    */
   children: (props: { item: Absence }) => React.ReactNode;
   /**
    * The display context of the list for contextual features.
    * Default to `user`.
    */
   context?: 'user' | 'boxOwner';
}

/**
 * Renders a list of [Absences]{@link Absence} as a {@link ListGroup}. Based on the [context]{@link Props.context}
 * additional features are available.
 */
export const AbsenceList = ({
   absences,
   highlightedId,
   className,
   children,
   context = 'user',
}: Props) => (
   <ListGroup className={className}>
      {!absences
         ? [...Array(3).keys()].map(i => (
              <ListGroup.Item key={i} className="d-flex align-items-center justify-content-between">
                 <Container className="ps-0" fluid>
                    <Row className="flex-fill px-2">
                       <Col xs={7} sm={5} lg={3} className="px-1">
                          <SkeletonPlaceholder width="80%" />
                       </Col>
                       <Col xs={5} sm={7} lg={4} className="px-1">
                          <SkeletonPlaceholder width={75} />
                       </Col>
                       <Col
                          xs={2}
                          sm={1}
                          md={2}
                          lg={1}
                          className="px-1 d-flex align-items-center justify-content-start justify-content-lg-center"
                       >
                          <SkeletonPlaceholder width={25} />
                       </Col>
                       <Col
                          xs={6}
                          md={4}
                          className="px-1 d-flex align-items-center justify-content-start justify-content-lg-center"
                       >
                          <SkeletonPlaceholder width={125} />
                       </Col>
                    </Row>
                    <Row className="px-2">
                       <Col className="px-1 text-muted">
                          <SkeletonPlaceholder width="80%" />
                          <SkeletonPlaceholder width="35%" />
                       </Col>
                    </Row>
                 </Container>
                 <SkeletonPlaceholder width={65} height="1.75rem" />
              </ListGroup.Item>
           ))
         : absences
              .sort((a, b) => {
                 const timeDiff = a.start.getTime() - b.start.getTime();
                 return timeDiff !== 0 ? timeDiff : a.id - b.id;
              })
              .map(e => (
                 <ListGroup.Item key={e.id} variant={e.id === highlightedId ? 'info' : undefined}>
                    {e.draft && context !== 'boxOwner' && (
                       <Row className="px-2 mb-1">
                          <Col className="px-1">
                             <Alert className="py-1 px-2 small" variant="warning">
                                <span>
                                   Dieser Urlaub ist nur ein Entwurf. Bitte reiche den Urlaub über
                                </span>
                                <Icon
                                   path={mdiEmailArrowRightOutline}
                                   size={0.7}
                                   color={Colors.primary}
                                   className="mx-1"
                                />
                                <span>zur Genehmigung ein.</span>
                             </Alert>
                          </Col>
                       </Row>
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                       <Container className="ps-0">
                          <Row className="flex-fill px-2">
                             <Col xs={7} sm={5} lg={3} className="px-1">
                                <span>{moment(e.start).format('DD.MM.')}</span>
                                <span>{' - '}</span>
                                <span>{moment(e.end).format('DD.MM.YY')}</span>
                             </Col>
                             <Col xs={5} sm={7} lg={4} className="px-1">
                                {AbsenceType.find(t => t.value === e.type)?.label}
                             </Col>
                             <Col
                                xs={2}
                                sm={1}
                                md={2}
                                lg={1}
                                className="px-1 d-flex align-items-center justify-content-start justify-content-lg-center"
                             >
                                <MiniProfile user={e.user_id} type="avatar" />
                             </Col>
                             <Col
                                xs={6}
                                md={4}
                                className="px-1 d-flex align-items-center justify-content-start justify-content-lg-center"
                             >
                                {e.draft ? (
                                   <Badge bg="info" className="fst-italic">
                                      Entwurf
                                   </Badge>
                                ) : (
                                   <AbsenceApprovalState status={e.status} />
                                )}
                             </Col>
                          </Row>
                          <Row className="px-2">
                             <Col className="px-1 text-muted">{e.comment}</Col>
                          </Row>
                       </Container>
                       {children({ item: e })}
                    </div>
                 </ListGroup.Item>
              ))}
      {absences && absences.length === 0 && (
         <ListGroup.Item className="d-flex align-items-center justify-content-center">
            <em>Keine Abwesenheiten vorhanden</em>
         </ListGroup.Item>
      )}
   </ListGroup>
);
