import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { getSelectStyles } from '../../utils';
import { CountryStateModel } from '../../models';

interface Props {
   value: number;
   countryId: number;
   onChange: (value: number) => void;
   onBlur: () => void;
   isInvalid?: boolean;
   disabled?: boolean;
}

export const CountryStateSelect = (props: Props) => {
   const [states, setStates] = useState<{ label: string; value: string }[]>([]);
   const [selectedState, setSelectedState] = useState<{ label: string; value: string }>();

   useEffect(() => {
      (async () => {
         setStates(
            (await CountryStateModel.list({ country_id: props.countryId })).map(s => ({
               label: s.name,
               value: `${s.id}`,
            }))
         );
      })();
   }, [props.countryId]);

   useEffect(() => {
      setSelectedState(states.find(s => s.value === `${props.value}`));
   }, [props.value, states]);

   return (
      <Select
         key={selectedState?.value ?? ''}
         options={states}
         placeholder="Bundesland auswählen…"
         styles={getSelectStyles(props.isInvalid)}
         defaultValue={states.find(s => s.value === selectedState?.value ?? '')}
         onChange={value => props.onChange(Number(value?.value))}
         onBlur={props.onBlur}
         isClearable
         isDisabled={props.disabled}
      />
   );
};
