import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Culture, DateLocalizer, SlotInfo } from 'react-big-calendar';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Headline } from '../molecules';
import { FloatingPanel } from '../atoms';
import { Calendar } from '../organisms/Calendar';
import { LoadingOverlay } from '../organisms/LoadingOverlay';
import { EventDropProps, EventResizeProps } from '../utils/types';
import { CoachingBackupEvent, GenericEvent } from '../organisms/Calendar/EventTypes';
import { CoachingBackup } from '../types/api/CoachingBackup';
import { useSession } from '../hooks';
import { ROLE_BOX_OWNER, ROLE_SCHEDULER } from '../types/api';
import { CoachingBackupModel } from '../models/CoachingBackupModel';
import { EditCoachingBackupDialog } from '../dialogs/EditCoachingBackupDialog';
import { RootState } from '../store';
import { setCoachingBackups, upsertCoachingBackup } from '../store/actions/scheduleActions';

export const ScheduleBackupPage = () => {
   const { sessionUser } = useSession();
   const dispatch = useDispatch();
   const [isLoading, setLoading] = useState(true);
   const coachingBackups = useSelector((s: RootState) => s.schedule.coachingBackups);
   const [events, setEvents] = useState<CoachingBackupEvent[]>([]);
   const [eventToEdit, setEventToEdit] = useState<Partial<CoachingBackup>>();

   const startOfWeek = useMemo(() => moment().startOf('week'), []);
   const userCanEditEvents =
      sessionUser?.roles.includes(ROLE_SCHEDULER) || sessionUser?.roles.includes(ROLE_BOX_OWNER);

   const createEventEntry = useCallback(
      (cb: CoachingBackup): CoachingBackupEvent => {
         const startTimeParts = cb.start_time.split(':');
         const endTimeParts = cb.end_time.split(':');
         return {
            id: cb.id,
            type: 'CoachingBackup',
            start: moment(startOfWeek)
               .add(cb.day_of_week, 'day')
               .add(Number(startTimeParts[0]), 'hour')
               .add(Number(startTimeParts[1]), 'minute')
               .toDate(),
            end: moment(startOfWeek)
               .add(cb.day_of_week, 'day')
               .add(Number(endTimeParts[0]), 'hour')
               .add(Number(endTimeParts[1]), 'minute')
               .toDate(),
            coachingBackup: cb,
         };
      },
      [startOfWeek]
   );

   useEffect(() => {
      (async () => {
         dispatch(setCoachingBackups(await CoachingBackupModel.list()));
         setLoading(false);
      })();
   }, [dispatch]);

   useEffect(() => {
      setEvents(coachingBackups.map(createEventEntry));
   }, [coachingBackups, createEventEntry]);

   const updateEvent = async (event: CoachingBackupEvent, newStart: Date, newEnd: Date) => {
      if (!userCanEditEvents) {
         // eslint-disable-next-line no-alert
         alert('Du verfügst nicht über das Recht zum Bearbeiten.');
         return;
      }

      const updatedEvent = await CoachingBackupModel.update({
         ...event.coachingBackup,
         day_of_week: moment(newStart).weekday(),
         start_time: moment(newStart).format('HH:mm'),
         end_time: moment(newEnd).format('HH:mm'),
      });

      event.coachingBackup = updatedEvent;
      // Damit die Ansicht nicht flackert, ändern wir das auch direkt an dem Event
      event.start = newStart;
      event.end = newEnd;

      dispatch(upsertCoachingBackup(updatedEvent));
   };

   const handleEventDrop = async (args: EventDropProps<GenericEvent>) => {
      if (args.event.type !== 'CoachingBackup') return;

      if (userCanEditEvents && args.isAllDay) {
         // Ablegen in Ganztägig wird nicht unterstützt
         // eslint-disable-next-line no-alert
         alert(
            'Das Verschieben eines Termines in die Zeile für ganztägige Termine wird nicht unterstützt.'
         );
         return;
      }

      await updateEvent(
         args.event as CoachingBackupEvent,
         new Date(args.start),
         new Date(args.end)
      );
   };

   const handleEventResize = async (args: EventResizeProps<GenericEvent>) => {
      if (args.event.type !== 'CoachingBackup') return;

      await updateEvent(
         args.event as CoachingBackupEvent,
         new Date(args.start),
         new Date(args.end)
      );
   };

   const handleSelectSlot = async (slot: SlotInfo) => {
      if (!userCanEditEvents) {
         // eslint-disable-next-line no-alert
         alert('Du verfügst nicht über das Recht zum Bearbeiten.');
         return;
      }

      if (moment(slot.end).diff(slot.start, 'minute') < 60) return;

      setEventToEdit({
         id: 0,
         day_of_week: moment(new Date(slot.start)).weekday(),
         start_time: moment(new Date(slot.start)).format('HH:mm'),
         end_time: moment(new Date(slot.end)).format('HH:mm'),
         coaches: [],
      });
   };

   return (
      <Container className="py-2">
         <Headline title="Coaching-Backup" />
         <div className="d-flex justify-content-sm-start">
            <Button
               variant="primary"
               className="mt-0 flex-fill flex-sm-grow-0"
               onClick={() => {
                  setEventToEdit({
                     id: 0,
                  });
               }}
            >
               Neuer Eintrag
            </Button>
         </div>
         <LoadingOverlay loading={isLoading} withBorderRadius>
            <FloatingPanel>
               <Calendar
                  events={events}
                  onEventDrop={handleEventDrop}
                  resizable
                  selectable
                  onEventResize={handleEventResize}
                  onSelectSlot={handleSelectSlot}
                  defaultView="week"
                  views={['week']}
                  formats={{
                     dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) =>
                        isMobile
                           ? String(localizer?.format(date, 'dd', culture))
                           : String(localizer?.format(date, 'dddd', culture)),
                  }}
                  toolbar={false}
               />
            </FloatingPanel>
         </LoadingOverlay>
         <EditCoachingBackupDialog
            show={!!eventToEdit}
            item={eventToEdit ?? {}}
            onClose={() => setEventToEdit(undefined)}
            afterSave={() => {
               setEventToEdit(undefined);
            }}
         />
      </Container>
   );
};
