import React, { memo } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import { Colors, isMobile } from '../utils';

interface Props {
   /**
    * The text for inside the tooltip.
    */
   text: string;
   /**
    * An optional element which has the tooltip.
    */
   children?: React.ReactElement;
}

/**
 * Renders the given text as a tooltip for the given children. If no children is provided, an info icon is used.
 */
export const InfoTooltip = memo((props: Props) => (
   <OverlayTrigger
      trigger={isMobile() ? ['click'] : ['focus', 'hover']}
      placement="auto"
      rootClose
      overlay={
         <Popover id="popover-meta">
            <Popover.Body className="d-flex flex-column">{props.text}</Popover.Body>
         </Popover>
      }
   >
      {props.children ?? (
         <Icon
            className="mx-1 cursor-pointer align-middle"
            path={mdiHelpCircleOutline}
            size={0.75}
            color={Colors.secondary}
         />
      )}
   </OverlayTrigger>
));
