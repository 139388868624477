import React, { CSSProperties, memo } from 'react';
import { Spinner } from 'react-bootstrap';

type ContentLoaderProps = {
   /**
    * Additional styles.
    */
   style?: CSSProperties;
   /**
    * Additional classes.
    */
   className?: string;
};

/**
 * Creates a styled spinner as loading animation for page content or parts if it.
 */
export const ContentLoader = memo(
   ({ className, style = { height: '12.5rem' } }: ContentLoaderProps) => (
      <div
         className={`d-flex align-items-center justify-content-center ${className ?? ''}`}
         style={style}
      >
         <Spinner animation="border" role="status" variant="success" />
      </div>
   )
);
