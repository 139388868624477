import { Moment } from 'moment/moment';
import { BaseModel } from './BaseModel';
import ApiService from '../services/ApiService';
import { RepresentationRequest } from '../types/api';
import { IApiResponse } from '../types/IApiResponse';
import { ModelExtension } from './ModelExtension';

class RepresentationRequestModelClass extends BaseModel<RepresentationRequest> {
   public getName(): string {
      return 'representation_request';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<RepresentationRequest[]> {
      return ModelExtension.listTimeRange<RepresentationRequest>(this.getName(), from, to);
   }

   async listOverview() {
      try {
         const response = await ApiService.http.get<IApiResponse<RepresentationRequest>>(
            `data/${this.getName()}/overview`
         );

         return response.data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
export const RepresentationRequestModel = new RepresentationRequestModelClass();
