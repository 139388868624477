import { SingleApiObject } from './BaseTypes';

export const MODULE_PERSONAL_TRAINING = 'PersonalTraining';

export interface Box extends SingleApiObject {
   name: string;
   is_demo: boolean;
   street_address: string | null;
   postal_code: string | null;
   city: string | null;
   sessions_scheduled_until: Date;
   country_id: number;
   state_id: number;
   working_days: number;
   vacation_forfeiture_until: number;
   simultaneous_absences: number;
   rr_number_of_votes_required: number;
   rr_locked_until_days_before: number;
   modules: string[];
}

export interface BoxStatistic {
   coaches: number;
   classTypes: number;
   rooms: number;
}
