import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { SelectMonthControl } from '../../forms/controls';
import { useCache } from '../../hooks';
import { EmployeeStatsCard, Headline } from '../../molecules';

export const ManageEmployeeStatsTab = () => {
   const cachedData = useCache();
   const [selectedMonth, setSelectedMonth] = useState(moment());

   return (
      <>
         <Headline title="Statistiken" browserTitle="Mitarbeiter - Statistiken">
            Hier erhältst Du eine Übersicht über die Coaching-Stunden, Abwesenheiten und weiterer
            Sachen.
         </Headline>
         <SelectMonthControl className="mt-3" onChange={m => setSelectedMonth(m)} />
         <Row className="mt-3">
            {cachedData.users
               .filter(u => u.enabled)
               .map(u => (
                  <Col key={u.id} xs={12} sm={6} md={4} className="mb-2">
                     <EmployeeStatsCard coach={u} month={selectedMonth} />
                  </Col>
               ))}
         </Row>
      </>
   );
};
