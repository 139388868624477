import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, ListGroup, Spinner } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import orderBy from 'lodash/orderBy';
import { ScheduleNotes } from '../../types/api/ScheduleNotes';
import { CoachingNoteItem } from '../../molecules/CoachingNoteItem';
import { ScheduleNotesModel } from '../../models/ScheduleNotesModel';
import { useSession } from '../../hooks';
import { isMobile } from '../../utils';

export const ScheduleNotesWidget = () => {
   const { sessionUser } = useSession();
   const [notes, setNotes] = useState<ScheduleNotes[]>();
   const notesToLoad = isMobile() ? 2 : 3;

   useEffect(() => {
      (async () => {
         const currentMonth = moment().set('date', 1);
         setNotes(
            await Promise.all(
               [...Array(notesToLoad).keys()].map(async i => {
                  const month = moment(currentMonth).add(i, 'month');
                  const result = await ScheduleNotesModel.list({
                     user_id: sessionUser?.id ?? 0,
                     year: month.year(),
                     month: month.month() + 1,
                  });

                  return (
                     result?.[0] ??
                     ({
                        id: 0,
                        user_id: sessionUser?.id ?? 0,
                        year: month.year(),
                        month: month.month() + 1,
                     } as ScheduleNotes)
                  );
               })
            )
         );
      })();
   }, [notesToLoad, sessionUser?.id]);

   const handleNoteChange = (changedNode: ScheduleNotes) => {
      setNotes(values => [
         ...(values ?? []).filter(
            n => n.year !== changedNode.year || n.month !== changedNode.month
         ),
         changedNode,
      ]);
   };

   return (
      <div>
         <div className="d-flex align-items-center justify-content-between">
            <h5>Notizen</h5>
            <LinkContainer to="schedule/notes">
               <Button variant="link" size="sm">
                  mehr...
               </Button>
            </LinkContainer>
         </div>

         {!notes
            ? [...Array(notesToLoad).keys()].map(i => (
                 <ListGroup key={i} className="mb-2">
                    <ListGroup.Item className="py-3 flex-fill d-flex justify-content-center">
                       <Spinner animation="border" role="status" variant="success" size="sm" />
                    </ListGroup.Item>
                 </ListGroup>
              ))
            : orderBy(notes, ['year', 'month']).map(n => (
                 <ListGroup key={`${n.year}-${n.month}`} className="mb-2">
                    <CoachingNoteItem note={n} onChange={handleNoteChange} />
                 </ListGroup>
              ))}
      </div>
   );
};
