import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { mdiCalendarMonth, mdiCalendarRemove, mdiFormatListBulleted } from '@mdi/js';
import { Col, Row } from 'react-bootstrap';
import sortBy from 'lodash/sortBy';
import { AbsenceModel } from '../../models';
import { MenuItem, PageWithSideMenu } from '../../templates';
import { useCache } from '../../hooks';
import { ROLE_COACH } from '../../types/api';
import { ManageAbsencesOverviewTab } from './ManageAbsencesOverviewTab';
import { ManageAbsencesListTab } from './ManageAbsencesListTab';
import { ManageAbsencesProhibitionTab } from './ManageAbsencesProhibitionTab';
import './index.scss';

export const ManageAbsencesPage = () => {
   const params = useParams();
   const [openRequestsCount, setOpenRequestsCount] = useState(0);

   const updateCounter = useCallback(async () => {
      setOpenRequestsCount(await AbsenceModel.count({ status: 'pending', draft: false }));
   }, []);

   useEffect(() => {
      (async () => {
         await updateCounter();
      })();
   }, [updateCounter]);

   const menuItems: MenuItem[] = useMemo(
      () => [
         {
            title: 'Urlaubsübersicht',
            key: 'overview',
            icon: mdiCalendarMonth,
            component: () => <ManageAbsencesOverviewTab />,
            left: () => <CoachesColors />,
         },
         {
            title: 'Abwesenheiten',
            key: 'list',
            icon: mdiFormatListBulleted,
            badgeValue: openRequestsCount,
            badgeVariant: 'warning',
            component: () => <ManageAbsencesListTab onUpdateCounter={updateCounter} />,
         },
         {
            title: 'Urlaubssperre',
            key: 'prohibition',
            icon: mdiCalendarRemove,
            component: () => <ManageAbsencesProhibitionTab />,
         },
      ],

      [openRequestsCount, updateCounter]
   );

   const handleBuildLink = useCallback(
      (tab: string) => {
         if (tab === 'openRequests' && params.id) return `/employees/${tab}/${params.id}`;
         return `/manage/absences/${tab}`;
      },
      [params.id]
   );

   return (
      <PageWithSideMenu
         breakpoint="lg"
         menuItems={menuItems}
         selectedMenuItem={params.tab}
         onBuildLink={handleBuildLink}
      />
   );
};

const CoachesColors = () => {
   const { users } = useCache();

   return (
      <Row className="p-1">
         {sortBy(users, ['first_name', 'last_name'])
            .filter(u => u.enabled && u.roles.includes(ROLE_COACH))
            .map(u => (
               <Col key={u.id} xs={6} className="d-flex flex-row align-items-center">
                  <span
                     className="me-2 d-inline-block align-middle"
                     style={{
                        height: '1rem',
                        width: '1rem',
                        borderRadius: '0.25rem',
                        backgroundColor: u.absence_color,
                        flexShrink: 0,
                     }}
                  />
                  <span className=" overflow-ellipsis">{`${u.first_name} ${u.last_name}`}</span>
               </Col>
            ))}
      </Row>
   );
};
