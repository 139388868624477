import React, { memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Icon from '@mdi/react';
import {
   mdiAccount,
   mdiAccountArrowLeft,
   mdiAccountMultiple,
   mdiCalendarClock,
   mdiCalendarMonthOutline,
   mdiCalendarTextOutline,
   mdiCog,
   mdiDomain,
   mdiExitRun,
   mdiFormatListBulletedSquare,
   mdiPoll,
   mdiShieldStarOutline,
   mdiSwapHorizontal,
} from '@mdi/js';
import { useSelector } from 'react-redux';
import { ShowIfRole } from '../../molecules';
import {
   ROLE_ACCOUNTANT,
   ROLE_ADMIN,
   ROLE_BOX_OWNER,
   ROLE_COACH,
   ROLE_SCHEDULER,
} from '../../types/api';
import { Colors } from '../../utils';
import { RootState } from '../../store';
import { useCache, useSession } from '../../hooks';
import { SwitchBoxDialog } from '../../dialogs/SwitchBoxDialog';
import { Avatar } from '../../molecules/Avatar';

export const AvatarDropdownButton = memo(() => {
   const { allBoxes } = useCache();
   const { signOut } = useSession();
   const { user } = useSelector((s: RootState) => s.app.session);
   const [showBoxChangeDialog, setShowBoxChangeDialog] = useState(false);

   return (
      <>
         <Dropdown>
            <Dropdown.Toggle variant="primary">
               <Avatar url={user?.profile_url} style={{ width: '1.5rem' }} />
               <span className="ps-2 d-none d-sm-inline align-middle">
                  {user?.first_name} {user?.last_name}
               </span>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
               <ShowIfRole roles={[ROLE_ADMIN]}>
                  <LinkContainer to="/admin">
                     <Dropdown.Item className="d-flex align-items-center fst-italic">
                        <Icon
                           className="me-1"
                           path={mdiShieldStarOutline}
                           size={0.75}
                           color={Colors.yellow}
                        />
                        Administration
                     </Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/calendar">
                     <Dropdown.Item className="d-flex align-items-center fst-italic">
                        <Icon
                           className="me-1"
                           path={mdiShieldStarOutline}
                           size={0.75}
                           color={Colors.yellow}
                        />
                        Alle Kalender
                     </Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Divider />
               </ShowIfRole>
               <ShowIfRole roles={[ROLE_BOX_OWNER]}>
                  <LinkContainer to="/manage/box">
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiCog} size={0.85} className="me-2" />
                        Box-Administration
                     </Dropdown.Item>
                  </LinkContainer>
               </ShowIfRole>
               <ShowIfRole roles={[ROLE_SCHEDULER, ROLE_BOX_OWNER]}>
                  <LinkContainer to="/manage/employees">
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiAccountMultiple} size={0.85} className="me-2" />
                        Mitarbeiter
                     </Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/manage/absences">
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiAccountArrowLeft} size={0.85} className="me-2" />
                        Abwesenheiten
                     </Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/statistics">
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiPoll} size={0.85} className="me-2" />
                        Statistiken
                     </Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/schedule/backup">
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiCalendarClock} size={0.85} className="me-2" />
                        Backup-Coaches
                     </Dropdown.Item>
                  </LinkContainer>
               </ShowIfRole>
               <ShowIfRole roles={[ROLE_ACCOUNTANT, ROLE_BOX_OWNER]}>
                  <LinkContainer to="/accounting">
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiFormatListBulletedSquare} size={0.85} className="me-2" />
                        Abrechnung
                     </Dropdown.Item>
                  </LinkContainer>
               </ShowIfRole>
               <ShowIfRole roles={[ROLE_ACCOUNTANT, ROLE_SCHEDULER, ROLE_BOX_OWNER]}>
                  <Dropdown.Divider />
               </ShowIfRole>
               <LinkContainer to="/user/profile">
                  <Dropdown.Item className="d-flex align-items-center">
                     <Icon path={mdiAccount} size={0.85} className="me-2" />
                     Mein Profil
                  </Dropdown.Item>
               </LinkContainer>
               <LinkContainer to="/user/calendar">
                  <Dropdown.Item className="d-flex align-items-center">
                     <Icon path={mdiCalendarMonthOutline} size={0.85} className="me-2" />
                     Meine Kalender
                  </Dropdown.Item>
               </LinkContainer>
               <LinkContainer to="/representationRequest">
                  <Dropdown.Item className="d-flex align-items-center">
                     <Icon path={mdiSwapHorizontal} size={0.85} className="me-2" />
                     Vertretungsanfragen
                  </Dropdown.Item>
               </LinkContainer>
               <ShowIfRole roles={[ROLE_COACH]}>
                  <LinkContainer to="/schedule/notes">
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiCalendarTextOutline} size={0.85} className="me-2" />
                        Notizen
                     </Dropdown.Item>
                  </LinkContainer>
               </ShowIfRole>
               {allBoxes.length >= 2 && (
                  <>
                     <Dropdown.Divider />
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => setShowBoxChangeDialog(true)}
                     >
                        <Icon path={mdiDomain} size={0.85} className="me-2" />
                        Box wechseln
                     </Dropdown.Item>
                     <Dropdown.Divider />
                  </>
               )}
               <Dropdown.Item
                  className="d-flex align-items-center"
                  style={{ color: Colors.danger }}
                  onClick={signOut}
               >
                  <Icon path={mdiExitRun} size={0.85} className="me-2" color={Colors.danger} />
                  Abmelden
               </Dropdown.Item>
            </Dropdown.Menu>
         </Dropdown>
         <SwitchBoxDialog
            show={showBoxChangeDialog}
            onClose={() => setShowBoxChangeDialog(false)}
         />
      </>
   );
});
