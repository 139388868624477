import { useImage } from 'react-image';
import { mdiAccountSupervisorCircle } from '@mdi/js';
import Icon from '@mdi/react';
import React, { memo } from 'react';
import { Image } from 'react-bootstrap';
import { Colors } from '../utils';
import { Box } from '../types/api';
import { API_ENDPOINT } from '../utils/config';

interface Props {
   /**
    * The CrossFit Box for the logo.
    */
   box?: Partial<Box> | null;
}

/**
 * Loads and displays the logo of the given CrossFit Box.
 */
export const BoxLogo = memo(({ box }: Props) => {
   const { src } = useImage({
      srcList: box?.id ? `${API_ENDPOINT}/avatar/box/${box.id}` : '',
      useSuspense: false,
   });

   return (
      <span
         className="me-2 d-flex align-items-center overflow-hidden"
         style={{ borderRadius: 4, width: '2rem', height: '2rem' }}
      >
         {src ? (
            <Image src={src} width="100%" height="100%" />
         ) : (
            <span className="d-flex align-items-center justify-content-center bg-dark w-100 h-100">
               <Icon path={mdiAccountSupervisorCircle} size={1} color={Colors.white} />
            </span>
         )}
      </span>
   );
});
