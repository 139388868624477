import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { PageNotFoundPage } from '../pages/PageNotFoundPage';
import { useCache } from '../hooks';
import { ContentLoader } from '../atoms';

interface RequireAuthProps {
   neededRoles?: string[];
   neededModules?: string[];
   publicElement?: JSX.Element;
   children: JSX.Element;
}

export const RequireAuth = ({
   children,
   neededRoles,
   neededModules,
   publicElement,
}: RequireAuthProps) => {
   const loginState = useSelector((s: RootState) => s.app.session.loginState);
   const sessionRoles = useSelector((s: RootState) => s.app.session.roles);
   const { box } = useCache();

   if (loginState === 'unknown') return <ContentLoader />;

   const shouldRenderPage = () => {
      // Nicht angemeldet? -> 404
      if (loginState !== 'loggedIn') return false;

      // Angemeldet und keine Rollen/Module benötigt? -> OK
      if (!neededRoles && !neededModules) return true;

      // Rolle vorhanden und kein Module benötigt -> OK
      if (neededRoles && sessionRoles.some(r => neededRoles.includes(r.key)) && !neededModules)
         return true;

      // Keine Rolle notwendig und Module vorhanden -> OK
      if (!neededRoles && neededModules && neededModules?.some(m => box?.modules?.includes(m)))
         return true;

      // Beides vorhanden -> OK
      if (
         sessionRoles.some(r => neededRoles?.includes(r.key)) &&
         neededModules?.some(m => box?.modules?.includes(m))
      )
         return true;

      // sonst 404
      return false;
   };

   const getUnauthorizedComponent = () => publicElement ?? <PageNotFoundPage />;

   return shouldRenderPage() ? children : getUnauthorizedComponent();
};
