import { FormikProps } from 'formik';

const isInvalid = <T,>(
   formik: Pick<FormikProps<T>, 'errors' | 'isValid' | 'touched' | 'submitCount'>,
   fieldName: keyof T
) =>
   !!formik.errors[fieldName] &&
   ((!formik.isValid && formik.submitCount > 0) || !!formik.touched[fieldName]);

export const FormUtils = {
   isInvalid: isInvalid,
};
