import React from 'react';
import { useModule } from '../hooks';

interface Props {
   children: React.ReactNode;
   module: string;
}
export const ShowIfModule = ({ children, module }: Props) => {
   const enabled = useModule(module);

   // eslint-disable-next-line react/jsx-no-useless-fragment
   return enabled ? <>{children}</> : null;
};
