import React from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';
import { SideMenuItem } from '../molecules';

export interface MenuItem {
   title: string;
   key: string;
   icon: string;
   badgeValue?: number;
   badgeVariant?: Variant;
   badgeType?: 'default' | 'pill';
   component: () => React.ReactNode;
   left?: () => React.ReactNode;
}

interface Props {
   /**
    * Defines the breakpoint on which the menu will be shown to the right and not to the top anymore.
    */
   breakpoint?: 'md' | 'lg' | 'always';
   /**
    * The menu items with the component for the main part.
    */
   menuItems: MenuItem[];
   /**
    * A function that builds a nav link based on the given menu item key.
    * @param menuItemKey
    */
   onBuildLink: (menuItemKey: string) => string;
   /**
    * The current selected menu item.
    */
   selectedMenuItem?: string;
}

/**
 * Renders a page layout with a menu to switch the primary content based on the selected item.
 *
 * Based on the given breakpoint the menu will be on larger screens to the left and on smaller screens on top.
 */
export const PageWithSideMenu = ({
   breakpoint = 'md',
   menuItems,
   selectedMenuItem,
   onBuildLink,
}: Props) => (
   <Container className="py-2">
      <Row>
         <Col xs={12} className={`mb-4 col-${breakpoint}-3`}>
            <ListGroup className={`floating-nav-panel-${breakpoint}`}>
               {menuItems.map(i => (
                  <SideMenuItem
                     key={i.key}
                     titleBreakpoint={breakpoint}
                     itemKey={i.key}
                     activeItemKey={selectedMenuItem ?? menuItems[0].key}
                     icon={i.icon}
                     itemTitle={i.title}
                     badgeValue={i.badgeValue}
                     badgeVariant={i.badgeVariant}
                     badgeType={i.badgeType}
                     to={onBuildLink(i.key)}
                  />
               ))}
            </ListGroup>
            <div className={`mt-3 d-none d-${breakpoint}-block`}>
               {menuItems.find(i => i.key === (selectedMenuItem ?? menuItems[0].key))?.left?.()}
            </div>
         </Col>
         <Col>
            {menuItems.find(i => i.key === (selectedMenuItem ?? menuItems[0].key))?.component()}
         </Col>
      </Row>
   </Container>
);
