import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserSettings } from '../types/api';
import { useSession } from './useSession';
import { UserModel } from '../models';
import { updateSessionUser } from '../store/actions/appActions';

export const useUserSettings = (): [
   Partial<UserSettings>,
   (updateFn: (currentSettings: Partial<UserSettings>) => Partial<UserSettings>) => Promise<void>
] => {
   const dispatch = useDispatch();
   const { sessionUser } = useSession();
   const [settings, setSettings] = useState<Partial<UserSettings>>({});

   useEffect(() => {
      try {
         const s = JSON.parse(sessionUser?.settings ?? '{}');
         setSettings({
            ...s,
            schedule: {
               ...(s.schedule ?? {}),
               weeksBefore: s.schedule?.weeksBefore ?? 1,
               weeksAfter: s.schedule?.weeksAfter ?? 1,
            },
         });
      } catch {
         /* ignore */
         setSettings({});
      }
   }, [sessionUser]);

   const updateSettings = useCallback(
      async (updateFn: (currentSettings: Partial<UserSettings>) => Partial<UserSettings>) => {
         const newSettings = updateFn(settings);
         // Update User
         const updatedUser = await UserModel.update({
            ...sessionUser,
            settings: JSON.stringify(newSettings),
         });
         dispatch(updateSessionUser(updatedUser));
         setSettings(newSettings);
      },
      [sessionUser, settings, dispatch]
   );

   return [settings, updateSettings];
};
