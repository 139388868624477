import React, { useEffect, useState } from 'react';
import {
   mdiArrowExpand,
   mdiCalendarOutline,
   mdiClockTimeFourOutline,
   mdiIsland,
   mdiPill,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Badge, Button, Col, Modal, Popover, Row } from 'react-bootstrap';
import { AbsenceModel } from '../../models';
import { Absence } from '../../types/api';
import { AbsenceEvent, EventEntryProps } from './EventTypes';
import EventPopover from '../../molecules/EventPopover';
import { Colors, formatRange } from '../../utils';
import { AbsenceApprovalState, MiniProfile } from '../../molecules';
import { CreateUpdateInfo } from '../CreateUpdateInfo';

// TODO: Wenn Dialog über Popup geöffnet wird, bleibt Popup stehen
export const AbsenceEventEntry = ({ event }: EventEntryProps<AbsenceEvent>) => {
   const [showDialog, setShowDialog] = useState(false);
   const [showOverlay, setShowOverlay] = useState(false);

   useEffect(() => {
      if (event.popupTarget) setShowOverlay(true);
   }, [event.popupTarget]);

   const handleExpand = () => {
      setShowOverlay(false);
      setShowDialog(true);
   };
   const getAbsenceIcon = (type: string) => {
      let icon;
      switch (type) {
         case 'absence':
            icon = mdiIsland;
            break;
         case 'illness':
            icon = mdiPill;
            break;
         default:
            icon = mdiIsland;
            break;
      }

      return <Icon className="me-1" path={icon} size={0.75} />;
   };

   return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <div
         role="tooltip"
         onClick={e => {
            if (event.popupTarget) e.stopPropagation();
         }}
      >
         <span className="d-flex align-items-center overflow-hidden">
            {event.absence.status === 'pending' && (
               <Badge bg="primary" className="me-1">
                  Vorbehalt
               </Badge>
            )}
            {getAbsenceIcon(event.absence.type)}
            <strong className="text-nowrap small">
               {event.user?.first_name} {event.user?.last_name}
            </strong>
         </span>
         <EventPopover
            target={event.popupTarget}
            show={showOverlay}
            onHide={() => {
               setShowOverlay(false);
               event.popupTarget = undefined;
            }}
         >
            {popover(event, handleExpand)}
         </EventPopover>

         <DetailDialog event={event} show={showDialog} onClose={() => setShowDialog(false)} />
      </div>
   );
};

const popover = (event: AbsenceEvent, onExpand: () => void) => (
   <Popover id="popover-basic" style={{ maxWidth: 350 }}>
      <Popover.Body className="p-0">
         <div className="d-flex">
            <div className="ps-3 pt-2 pe-2">
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiCalendarOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <div className="mb-1">
                     <h3 className="mb-1">Abwesenheit</h3>
                     <AbsenceApprovalState status={event.absence.status} />
                  </div>
               </div>
               <div className="d-flex flex-row my-1">
                  <Icon
                     path={mdiClockTimeFourOutline}
                     color={Colors.secondary}
                     size={1}
                     className="me-1 mt-1"
                  />
                  <div>
                     <h5 className="mb-0">
                        {formatRange(event.start, event.end, 'full-date-only')}
                     </h5>
                  </div>
               </div>
            </div>
            <div>
               <Button variant="link" className="me-1 mt-1 btn p-0" onClick={onExpand}>
                  <Icon path={mdiArrowExpand} color={Colors.secondary} size={1} />
               </Button>
            </div>
         </div>
         <EventPopover.Divider />
         <div className="mx-3 mb-3">
            {event.user && <MiniProfile user={event.user} type="large" />}
         </div>
      </Popover.Body>
   </Popover>
);

type DetailDialogProps = {
   event: AbsenceEvent;
   show: boolean;
   onClose: () => void;
};
const DetailDialog = ({ event, show, onClose }: DetailDialogProps) => {
   const [absence, setAbsence] = useState<Absence | null>();

   useEffect(() => {
      (async () => {
         setAbsence(await AbsenceModel.get(event.id));
      })();
   }, [event]);

   return (
      <Modal show={show} onHide={onClose} centered>
         <Modal.Header closeButton>
            <Modal.Title>Details zur Abwesenheit</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Row>
               <Col xs={2} className="me-3 my-2">
                  Wann:
               </Col>
               <Col className="my-2 fw-bold">
                  {formatRange(absence?.start, absence?.end, 'full-date-only')}
               </Col>
            </Row>
            <Row>
               <Col xs={2} className="me-3 my-2">
                  Wer:
               </Col>
               <Col className="my-2">
                  {event.user && <MiniProfile user={event.user} type="large" />}
               </Col>
            </Row>
            <Row>
               <Col sm={2} className="me-3 my-2">
                  Kommentar:
               </Col>
               <Col className="my-2">{absence?.comment}</Col>
            </Row>
         </Modal.Body>
         <Modal.Footer className="bg-light">
            <CreateUpdateInfo obj={absence} />
            <Button variant="secondary" onClick={onClose}>
               Schließen
            </Button>
         </Modal.Footer>
      </Modal>
   );
};
