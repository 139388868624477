import Select from 'react-select';
import React from 'react';
import { getSelectStyles } from '../../utils';
import { FormUtils } from '../FormUtils';
import { MiniProfile } from '../../molecules';
import { useCache } from '../../hooks';
import { FormikControl } from '../../types/FormikControl';
import { User } from '../../types/api';

interface Props<T> extends FormikControl<T> {
   isOptionDisabled?: (u: User) => boolean;
   placeholder: string;
   disabled?: boolean;
   isClearable?: boolean;
}

export const UserSelect = <T,>({
   formik,
   name,
   disabled = false,
   isClearable = false,
   placeholder,
   isOptionDisabled,
}: Props<T>) => {
   const { users } = useCache();
   return (
      <Select
         options={users.filter(u => u.enabled)}
         isOptionDisabled={isOptionDisabled}
         styles={getSelectStyles(FormUtils.isInvalid(formik, name))}
         getOptionValue={c => `${c.first_name} ${c.last_name}`}
         formatOptionLabel={u => <MiniProfile user={u} />}
         placeholder={placeholder}
         defaultValue={users.find(c => c.id === (formik.values[name] as unknown as number))}
         onChange={value => formik.setFieldValue(name as string, value?.id)}
         onBlur={() => formik.handleBlur(name)}
         isDisabled={formik.isSubmitting || disabled}
         isClearable={isClearable}
      />
   );
};
