import { Badge, ListGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import React, { memo } from 'react';
import { Variant } from 'react-bootstrap/types';
import { Link } from 'react-router-dom';
import { Colors } from '../utils';

interface Props {
   /**
    * The icon for the menu item.
    */
   icon: string;
   /**
    * The title for the menu item.
    */
   itemTitle: string;
   /**
    * Defines a breakpoint where when the screen is greater or equal the title will be visible otherwise not.
    *
    * Default value: `md`.
    */
   titleBreakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'always';
   /**
    * An unique key for the menu item.
    */
   itemKey: string;
   /**
    * The current active menu item. It will be used to highlight the selected one.
    */
   activeItemKey: string;
   /**
    * The url the item should navigate to.
    */
   to: string;
   /**
    * A value for a badge. If there is no value, no badge will be visible.
    */
   badgeValue?: number;
   /**
    * The variant for the badge.
    */
   badgeVariant?: Variant;
   /**
    * The type of the badge.
    */
   badgeType?: 'default' | 'pill';
}

/**
 * Renders a menu item for a {@link ListGroup}.
 */
export const SideMenuItem = memo(
   ({
      icon,
      itemTitle,
      titleBreakpoint = 'md',
      to,
      badgeValue,
      badgeVariant = 'secondary',
      badgeType = 'pill',
      itemKey,
      activeItemKey,
   }: Props) => (
      <ListGroup.Item
         as={Link}
         className={`px-2 px-${titleBreakpoint}-3 d-flex align-items-center justify-content-center justify-content-${titleBreakpoint}-start`}
         active={activeItemKey === itemKey}
         to={to}
         replace
         action
      >
         <Icon
            className={`me-${titleBreakpoint}-1`}
            path={icon}
            size={1}
            color={activeItemKey === itemKey ? Colors.white : Colors.secondary}
         />
         <span className={`d-none d-${titleBreakpoint}-inline flex-${titleBreakpoint}-fill`}>
            {itemTitle}
         </span>
         {(badgeValue ?? 0) > 0 && (
            <Badge bg={badgeVariant} className="ms-2" pill={badgeType === 'pill'}>
               {badgeValue}
            </Badge>
         )}
      </ListGroup.Item>
   )
);
