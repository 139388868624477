import Select from 'react-select';
import React from 'react';
import { getSelectStyles } from '../../utils';
import { FormikControl } from '../../types/FormikControl';
import { useCache } from '../../hooks';
import { FormUtils } from '../FormUtils';

interface Props<T> extends FormikControl<T> {
   disabled?: boolean;
   isClearable?: boolean;
}

export const RoomSelect = <T,>({
   formik,
   name,
   disabled = false,
   isClearable = false,
}: Props<T>) => {
   const { rooms } = useCache();

   return (
      <Select
         key={formik.values[name] as unknown as number}
         options={rooms}
         styles={getSelectStyles(FormUtils.isInvalid(formik, name))}
         getOptionValue={r => r.name}
         getOptionLabel={r => r.name}
         placeholder="Raum auswählen…"
         defaultValue={rooms.find(r => r.id === (formik.values[name] as unknown as number))}
         onChange={value => formik.setFieldValue(name as string, value?.id)}
         onBlur={() => formik.handleBlur(name)}
         isDisabled={formik.isSubmitting || disabled}
         isClearable={isClearable}
      />
   );
};
