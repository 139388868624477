import { Moment } from 'moment';
import { BaseModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';
import { Holidays } from '../types/api';

class HolidaysModelClass extends BaseModel<Holidays> {
   public getName(): string {
      return 'holidays';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<Holidays[]> {
      return ModelExtension.listTimeRange<Holidays>(this.getName(), from, to);
   }
}
export const HolidaysModel = new HolidaysModelClass();
