import { isMobile } from 'react-device-detect';
import { Button, ButtonGroup, Form, Modal } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import Icon from '@mdi/react';
import { mdiRotateLeft, mdiRotateRight } from '@mdi/js';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '../molecules';
import { UserModel } from '../models';
import { updateSessionUser } from '../store/actions/appActions';
import { useSession } from '../hooks';
import { LineWithText } from '../atoms';

const TO_RADIANS = Math.PI / 180;

interface Props {
   show: boolean;
   onClose: () => void;
}

export const UploadAvatarDialog = (props: Props) => {
   const dispatch = useDispatch();
   const { sessionUser } = useSession();
   const [file, setFile] = useState<File>();
   const [fileError, setFileError] = useState('');
   const [crop, setCrop] = useState<Crop>();
   const imgRef = useRef<HTMLImageElement>(null);
   const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
   const [rotate, setRotate] = useState(0);

   const handleClose = () => {
      setFile(undefined);
      setFileError('');
      props.onClose();
   };

   // Einmal das Crop berechnen, da es sonst nur bei Änderungen geschieht
   useEffect(() => {
      if (completedCrop || !imgRef.current) return;

      setTimeout(() => {
         const { width, height } = imgRef.current?.getBoundingClientRect() ?? {};
         const size = Math.min(width ?? 0, height ?? 0) * 0.9;
         const newCrop = {
            x: ((width ?? size) - size) / 2,
            y: ((height ?? size) - size) / 2,
            width: size,
            height: size,
            unit: 'px',
         } as PixelCrop;

         setCompletedCrop(newCrop);
         setCrop(newCrop);
      }, 100);
   }, [completedCrop, crop, file]);

   const cropImage = async (imageElm: HTMLImageElement, cropInfo: PixelCrop) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error('No 2d context');

      const scaleX = imageElm.naturalWidth / imageElm.width;
      const scaleY = imageElm.naturalHeight / imageElm.height;
      // devicePixelRatio slightly increases sharpness on retina devices
      // at the expense of slightly slower render times and needing to
      // size the image back down if you want to download/upload and be
      // true to the images natural size.
      // const pixelRatio = window.devicePixelRatio;
      const pixelRatio = 0.5; // Decrease image by 50%

      canvas.width = Math.floor(cropInfo.width * scaleX * pixelRatio);
      canvas.height = Math.floor(cropInfo.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = 'high';

      const cropX = cropInfo.x * scaleX;
      const cropY = cropInfo.y * scaleY;

      const rotateRads = rotate * TO_RADIANS;
      const centerX = imageElm.naturalWidth / 2;
      const centerY = imageElm.naturalHeight / 2;

      ctx.save();

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 3) Rotate around the origin
      ctx.rotate(rotateRads);
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
         imageElm,
         0,
         0,
         imageElm.naturalWidth,
         imageElm.naturalHeight,
         0,
         0,
         imageElm.naturalWidth,
         imageElm.naturalHeight
      );

      ctx.restore();

      return new Promise<Blob>((resolve, reject) => {
         canvas.toBlob(blob => {
            if (blob) {
               resolve(blob);
            } else {
               reject(new Error('Canvas is empty'));
            }
         }, file?.type);
      });
   };

   // TODO: Only works, when the user changes something. When directly Calling `Save` nothing will happens
   //    since completedCrop is still undefined.
   const handleSave = async () => {
      if (!imgRef.current || !file || !completedCrop) return;

      const croppedBlob = await cropImage(imgRef.current, completedCrop);

      await UserModel.uploadAvatar(croppedBlob);

      const updatedUser = await UserModel.get(sessionUser?.id ?? 0);
      if (updatedUser) dispatch(updateSessionUser(updatedUser));

      handleClose();
   };

   const handleRemoveAvatar = async () => {
      await UserModel.removeAvatar();

      const updatedUser = await UserModel.get(sessionUser?.id ?? 0);
      if (updatedUser) dispatch(updateSessionUser(updatedUser));

      handleClose();
   };

   return (
      <Modal show={props.show} onHide={handleClose} centered={isMobile}>
         <Modal.Header closeButton>
            <Modal.Title>
               {sessionUser?.has_avatar ? 'Avatar ändern' : 'Neuen Avatar hochladen'}
            </Modal.Title>
         </Modal.Header>

         <Modal.Body className="d-flex flex-column align-items-stretch">
            {!file ? (
               <>
                  {sessionUser?.has_avatar && (
                     <>
                        <Button
                           variant="outline-danger"
                           className="my-4"
                           onClick={handleRemoveAvatar}
                        >
                           Bestehendes Avatar-Bild entfernen
                        </Button>
                        <LineWithText text="oder" className="mb-4" />
                     </>
                  )}
                  {fileError && <Form.Text className="text-danger mb-2">{fileError}</Form.Text>}
                  <FileUploader
                     label="Klicke zum Hochladen oder ziehe eine Datei hinein."
                     classes="file_uploader"
                     handleChange={setFile}
                     name="file"
                     types={['JPG', 'JPEG', 'PNG', 'BMP']}
                     maxSize={5}
                     onTypeError={(err: string) => setFileError(err)}
                     onSizeError={(err: string) => setFileError(err)}
                  />
               </>
            ) : (
               <>
                  <ButtonGroup className="mb-2 justify-content-center ">
                     <Button
                        variant="outline-primary"
                        className="flex-grow-0 d-flex align-items-center justify-content-center"
                        onClick={() => setRotate(v => v - 90)}
                     >
                        <Icon path={mdiRotateLeft} size={0.75} />
                     </Button>
                     <Button
                        variant="outline-primary"
                        className="flex-grow-0 d-flex align-items-center justify-content-center"
                        onClick={() => setRotate(v => v + 90)}
                     >
                        <Icon path={mdiRotateRight} size={0.75} />
                     </Button>
                  </ButtonGroup>
                  <ReactCrop
                     crop={crop}
                     onChange={c => {
                        setCrop(c);
                     }}
                     onComplete={c => setCompletedCrop(c)}
                     aspect={1}
                     keepSelection
                     circularCrop
                  >
                     <img
                        ref={imgRef}
                        alt="avatar to crop"
                        style={{ transform: `rotate(${rotate}deg)` }}
                        src={URL.createObjectURL(file)}
                     />
                  </ReactCrop>
               </>
            )}
         </Modal.Body>

         <Modal.Footer className="bg-light justify-content-end">
            <Button variant="outline-link" onClick={handleClose}>
               Abbrechen
            </Button>
            <LoadingButton variant="primary" onClick={handleSave} disabled={!file}>
               Speichern
            </LoadingButton>
         </Modal.Footer>
      </Modal>
   );
};
