import { Button, Container } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { SlotInfo, View } from 'react-big-calendar';
import { RootState } from '../../store';
import { Headline, ShowIfRole } from '../../molecules';
import { EditClassDialog } from '../../dialogs/EditClassDialog';
import {
   setCoachingClasses,
   setMeetings,
   upsertCoachingClass,
} from '../../store/actions/appActions';
import { FloatingPanel } from '../../atoms';
import { LoadingOverlay } from '../../organisms/LoadingOverlay';
import { FilterDef, FilterWidget } from './FilterWidget';
import { EventDropProps, EventResizeProps } from '../../utils/types';
import {
   BoxClosingTime,
   CoachingClass,
   Meeting,
   MODULE_PERSONAL_TRAINING,
   PTClass,
   ROLE_SCHEDULER,
} from '../../types/api';
import { CoachingClassModel, PTClassModel } from '../../models';
import { BoxClosingTimeModel } from '../../models/BoxClosingTime';
import { useCache, useModule, useSession } from '../../hooks';
import { EditMeetingDialog } from '../../dialogs/EditMeetingDialog';
import { MeetingModel } from '../../models/MeetingModel';
import { Calendar, DEFAULT_VIEW_WEB } from '../../organisms/Calendar';
import { Colors } from '../../utils';
import {
   CoachingEvent,
   GenericEvent,
   MeetingEvent,
   PTEvent,
} from '../../organisms/Calendar/EventTypes';

export const CoachingCalendarPage = () => {
   const dispatch = useDispatch();
   const ptModuleEnabled = useModule(MODULE_PERSONAL_TRAINING);
   const { sessionUser, sessionRoles } = useSession();
   const coachingClasses = useSelector((s: RootState) => s.app.coachingClasses);
   const meetings = useSelector((s: RootState) => s.app.meetings);
   const cachedData = useCache();
   const [urlParams] = useSearchParams();

   const [isLoading, setLoading] = useState(false);
   const [closingTimes, setClosingTimes] = useState<BoxClosingTime[]>([]);
   const [ptClasses, setPTClasses] = useState<PTClass[]>([]);
   const [coachingEvents, setCoachingEvents] = useState<CoachingEvent[]>([]);
   const [filteredEvents, setFilteredEvents] = useState<GenericEvent[]>([]);
   const [filter, setFilter] = useState<FilterDef>();
   const [classToEdit, setClassToEdit] = useState<Partial<CoachingClass>>();
   const [meetingToEdit, setMeetingToEdit] = useState<Partial<Meeting>>();
   const [userIsScheduler, setUserIsScheduler] = useState(false);

   useEffect(() => {
      setUserIsScheduler(!!sessionRoles.find(r => r.key === ROLE_SCHEDULER));
   }, [sessionRoles]);

   // Coaching-Sessions & Schließzeiten laden und in State packen
   const loadEvents = useCallback(
      async (start: Date, view: View) => {
         setLoading(true);
         try {
            // In der Anzeige wird der vorherige und nächste Monat/Woche/Tag ggf. angerissen, daher eine Woche
            //    vorher und nachher laden.
            const from = moment(start).add(-1, 'week');
            const to = moment(from).add(view === 'month' ? 6 : 2, 'week');

            dispatch(setCoachingClasses(await CoachingClassModel.listTimeRange(from, to)));
            setClosingTimes(await BoxClosingTimeModel.listTimeRange(from, to));
            dispatch(setMeetings(await MeetingModel.listTimeRange(from, to)));

            if (ptModuleEnabled) setPTClasses(await PTClassModel.listTimeRange(from, to));
         } finally {
            setLoading(false);
         }
      },
      [dispatch, ptModuleEnabled]
   );

   useEffect(() => {
      (async () => {
         const start = urlParams.get('date')
            ? moment(urlParams.get('date'), 'YYYY-MM-DD')
            : moment();
         const view = urlParams.get('view') ?? DEFAULT_VIEW_WEB;
         await loadEvents(start.toDate(), view as View);
      })();
   }, [urlParams, loadEvents]);

   // Coaching-Sessions von State nehmen und in CalenderEvents umwandeln
   useEffect(() => {
      setCoachingEvents(
         coachingClasses.map<CoachingEvent>(cc => {
            const classType = cachedData.classTypes.find(t => t.id === cc.type_id);
            const ssu = moment(cachedData.box?.sessions_scheduled_until.getTime());
            ssu.add(1, 'day'); // Es wird ja der Tagesanfang gespeichert

            return {
               type: 'CoachingClass',
               color: classType?.color,
               isPending: (cc.start?.getTime() ?? 0) > ssu.toDate().getTime(),
               isCanceled: cc.canceled,
               id: cc.id,
               coachingClass: cc,
               classType: classType,
               room: cachedData.rooms.find(r => r.id === cc.room_id),
               coach: cachedData.users.find(u => u.id === cc.coach_id),
               start: cc.start,
               end: cc.end,
            };
         })
      );
   }, [coachingClasses, cachedData]);

   // Auf Basis der Daten und Filter die Events ermitteln
   useEffect(() => {
      const ct = closingTimes.map<GenericEvent>(bct => ({
         type: 'Other',
         color: Colors.secondary,
         id: bct.id,
         title: bct.reason,
         start: bct.start,
         end: bct.end,
      }));
      const pt = ptClasses.map<PTEvent>(c => {
         const coach = cachedData.users.find(u => u.id === c.coach_id);

         return {
            type: 'PTClass',
            color: Colors.secondary,
            id: c.id,
            ptClass: c,
            title: `PT - ${coach?.nickname || coach?.first_name}`,
            coach: coach,
            room: cachedData.rooms.find(r => r.id === c.room_id),
            start: c.start,
            end: c.end,
         };
      });

      const mts = meetings.map<MeetingEvent>(m => ({
         type: 'Meeting',
         color: '#FFFF00',
         id: m.id,
         meeting: m,
         title: m.name,
         organizer: cachedData.users.find(u => u.id === m.organizer_id),
         room: cachedData.rooms.find(r => r.id === m.room_id),
         start: m.start,
         end: m.end,
      }));

      if (!filter) {
         setFilteredEvents([...ct, ...pt, ...coachingEvents, ...mts]);
      } else {
         setFilteredEvents([
            ...ct,
            ...pt.filter(
               e => filter.rooms?.length === 0 || filter.rooms?.some(id => e.ptClass.room_id === id)
            ),
            ...mts
               .filter(
                  m =>
                     filter.rooms?.length === 0 ||
                     filter.rooms?.some(id => m.meeting.room_id === id)
               )
               .filter(
                  m =>
                     filter.users?.length === 0 ||
                     filter.users?.some(id =>
                        [m.meeting.organizer_id, ...m.meeting.participants].includes(id)
                     )
               ),
            ...coachingEvents
               .filter(
                  e =>
                     filter.users?.length === 0 ||
                     filter.users?.some(id =>
                        [
                           e.coachingClass.coach_id,
                           e.coachingClass.coach_second_id,
                           ...e.coachingClass.coach_other,
                        ].includes(id)
                     )
               )
               .filter(
                  e =>
                     filter.classTypes?.length === 0 ||
                     filter.classTypes?.some(id => e.coachingClass.type_id === id)
               )
               .filter(
                  e =>
                     filter.rooms?.length === 0 ||
                     filter.rooms?.some(id => e.coachingClass.room_id === id)
               ),
         ]);
      }
   }, [cachedData, coachingEvents, closingTimes, ptClasses, meetings, filter]);

   const moveEvent = async (args: EventDropProps<GenericEvent>) => {
      if (args.event.type !== 'CoachingClass') return;

      const coachingEvent = args.event as CoachingEvent;

      if (!userIsScheduler) {
         // eslint-disable-next-line no-alert
         alert('Du verfügst nicht über das Recht zum Terminieren von Klassen.');
         return;
      }

      if (args.isAllDay) {
         // Ablegen in Ganztägig wird nicht unterstützt
         // eslint-disable-next-line no-alert
         alert(
            'Das Verschieben eines Termines in die Zeile für ganztägige Termine wird nicht unterstützt.'
         );
         return;
      }

      coachingEvent.coachingClass.start = new Date(args.start);
      coachingEvent.coachingClass.end = new Date(args.end);
      // Damit die Ansicht nicht flackert, ändern wir das auch direkt an dem Event
      coachingEvent.start = coachingEvent.coachingClass.start;
      coachingEvent.end = coachingEvent.coachingClass.end;
      dispatch(upsertCoachingClass(await CoachingClassModel.update(coachingEvent.coachingClass)));
   };

   const resizeEvent = async (args: EventResizeProps<GenericEvent>) => {
      if (args.event.type !== 'CoachingClass') return;

      const coachingEvent = args.event as CoachingEvent;

      if (!userIsScheduler) {
         // eslint-disable-next-line no-alert
         alert('Du verfügst nicht über das Recht zum Terminieren von Klassen.');
         return;
      }

      coachingEvent.coachingClass.start = new Date(args.start);
      coachingEvent.coachingClass.end = new Date(args.end);
      // Damit die Ansicht nicht flackert, ändern wir das auch direkt an dem Event
      coachingEvent.start = coachingEvent.coachingClass.start;
      coachingEvent.end = coachingEvent.coachingClass.end;
      dispatch(upsertCoachingClass(await CoachingClassModel.update(coachingEvent.coachingClass)));
   };

   const newEvent = (slot: SlotInfo) => {
      if (!userIsScheduler) {
         // eslint-disable-next-line no-alert
         alert('Du verfügst nicht über das Recht zum Terminieren von Klassen.');
         return;
      }

      if (moment(slot.end).diff(slot.start, 'minute') < 60) return;

      setClassToEdit({
         id: 0,
         start: new Date(slot.start),
         end: new Date(slot.end),
      });
   };

   return (
      <Container className="py-2">
         <Headline title="Coaching-Kalender" />
         <ShowIfRole roles={[ROLE_SCHEDULER]}>
            <div className="d-flex flex-row gap-2 justify-content-sm-start">
               <Button
                  variant="primary"
                  className="mt-0 flex-fill flex-sm-grow-0"
                  onClick={() => {
                     setClassToEdit({
                        id: 0,
                     });
                  }}
               >
                  Neue Klasse
               </Button>
               <Button
                  variant="outline-primary"
                  className="mt-0 flex-fill flex-sm-grow-0"
                  onClick={() => {
                     setMeetingToEdit({
                        id: 0,
                        organizer_id: sessionUser?.id,
                     });
                  }}
               >
                  Neues Meeting
               </Button>
            </div>

            <EditClassDialog
               show={!!classToEdit}
               coachingClass={classToEdit}
               onClose={() => setClassToEdit(undefined)}
            />
            <EditMeetingDialog
               show={!!meetingToEdit}
               meeting={meetingToEdit}
               onClose={() => setMeetingToEdit(undefined)}
            />
         </ShowIfRole>

         <FilterWidget onFilterChange={f => setFilter(f)} />

         <LoadingOverlay loading={isLoading} withBorderRadius>
            <FloatingPanel>
               <Calendar
                  events={filteredEvents}
                  onEventDrop={moveEvent}
                  resizable={userIsScheduler}
                  selectable={userIsScheduler}
                  onEventResize={resizeEvent}
                  onSelectSlot={newEvent}
                  onNavigate={loadEvents}
                  showScheduleStatus
               />
            </FloatingPanel>
         </LoadingOverlay>
      </Container>
   );
};
