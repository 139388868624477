import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
   Alert,
   Button,
   Col,
   Container,
   Form,
   InputGroup,
   ListGroup,
   Modal,
   Row,
} from 'react-bootstrap';
import { mdiCheckBold, mdiCloseThick, mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import sortBy from 'lodash/sortBy';
import { Formik } from 'formik';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Colors, formatRange, notEmpty } from '../../utils';
import { PageNotFoundPage } from '../PageNotFoundPage';
import { Headline, LoadingButton, MiniProfile } from '../../molecules';
import { ContentLoader, FloatingPanel } from '../../atoms';
import { ItemList } from '../../organisms/ItemList';
import { upsertCoachingClass } from '../../store/actions/appActions';
import {
   CoachingClass,
   RepresentationRequest,
   RepresentationRequestHasVote,
   ROLE_ADMIN,
   ROLE_BOX_OWNER,
   ROLE_COACH,
   ROLE_SCHEDULER,
} from '../../types/api';
import { CoachingClassModel, RepresentationRequestModel } from '../../models';
import { RepresentationRequestHasVoteModel } from '../../models/RepresentationRequestHasVote';
import { useCache, useSession } from '../../hooks';
import { FormRow, TextareaControl } from '../../forms';

export const ViewRepresentationRequestPage = () => {
   const { classTypes, rooms } = useCache();
   const { sessionUser } = useSession();
   const { id } = useParams();
   const [request, setRequest] = useState<RepresentationRequest | null | undefined>();
   const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([]);
   const [linkWasCopied, setLinkWasCopied] = useState(false);
   const [showCancelDialog, setShowCancelDialog] = useState(false);

   const loadData = useCallback(async () => {
      try {
         const rr = await RepresentationRequestModel.get(Number(id));
         setRequest(rr);

         if (!rr) return;

         const classes = (
            await Promise.all(rr.coaching_classes.map(async ccId => CoachingClassModel.get(ccId)))
         ).filter(notEmpty);
         /* const classes: CoachingClass[] = [];
         for (const ccId of rr.coaching_classes) {
            const cc = await CoachingClassModel.get(ccId);
            if (cc) classes.push(cc);
         } */
         setCoachingClasses(classes);
      } catch {
         setRequest(null);
      }
   }, [id]);

   useEffect(() => {
      (async () => {
         await loadData();
      })();
   }, [loadData]);

   const handleAfterVote = async () => {
      await loadData();
   };

   const handleCloseRequest = async () => {
      if (!request) return;
      request.status = 'closed';
      setRequest(await RepresentationRequestModel.update(request));
      setShowCancelDialog(false);
      return Promise.resolve();
   };

   if (request === null) {
      return <PageNotFoundPage />;
   }

   if (!request) {
      return (
         <Container className="py-2">
            <ContentLoader />
         </Container>
      );
   }

   return (
      <Container className="py-2">
         <Headline title="Vertretungsanfrage" />
         {request.status === 'closed' && (
            <Alert variant="primary">
               Die Anfrage ist geschlossen und es können keine Stimmen mehr abgegeben werden.
            </Alert>
         )}
         {request.status === 'open' && request.user_id !== sessionUser?.id && (
            <PerformVote request={request} onAfterVote={handleAfterVote} />
         )}
         <FloatingPanel>
            <FormRow label="Link zum Teilen" type="responsive">
               <div className="flex-fill d-flex flex-column">
                  <InputGroup>
                     <Form.Control
                        type="text"
                        name="request-url"
                        value={`${window.location.origin}/representationRequest/${request.id}`}
                        readOnly
                     />
                     <Button
                        variant="primary"
                        className="d-flex align-items-center"
                        onClick={async () => {
                           await navigator.clipboard.writeText(
                              `${window.location.origin}/representationRequest/${request.id}`
                           );
                           setLinkWasCopied(true);
                        }}
                     >
                        <Icon path={mdiContentCopy} size={0.75} />
                     </Button>
                  </InputGroup>
                  {linkWasCopied && (
                     <small>Der Link wurde erfolgreich in die Zwischenablage kopiert.</small>
                  )}
               </div>
            </FormRow>
            <FormRow label="Coach" type="responsive">
               <MiniProfile user={request?.user_id} />
            </FormRow>
            <FormRow label="Klassen" type="responsive">
               <ListGroup className="flex-fill">
                  {coachingClasses
                     ?.sort((a, b) => {
                        const timeDiff = a.start.getTime() - b.start.getTime();
                        return timeDiff !== 0 ? timeDiff : a.id - b.id;
                     })
                     .map(cc => (
                        <ListGroup.Item
                           key={cc.id}
                           variant="primary"
                           className="d-flex flex-column"
                        >
                           <h5>{formatRange(cc.start, cc.end, 'full-date-with-time')}</h5>
                           <div>
                              <span>{classTypes.find(ct => ct.id === cc.type_id)?.name}</span>
                              <span className="mx-1">·</span>
                              <span>{rooms.find(r => r.id === cc.room_id)?.name}</span>
                           </div>
                        </ListGroup.Item>
                     ))}
               </ListGroup>
            </FormRow>
            {request?.comment && (
               <FormRow label="Kommentar" type="responsive">
                  {request?.comment}
               </FormRow>
            )}
            {request.status === 'open' && request.user_id === sessionUser?.id && (
               <div className="d-flex justify-content-end flex-column flex-md-row">
                  <LoadingButton
                     variant="outline-secondary"
                     onClick={() => setShowCancelDialog(true)}
                  >
                     Anfrage schließen
                  </LoadingButton>
               </div>
            )}
         </FloatingPanel>

         <VoteResults
            request={request}
            coachingClasses={coachingClasses}
            onAfterSelectVote={loadData}
         />

         <Modal
            show={showCancelDialog}
            onHide={() => setShowCancelDialog(false)}
            centered={isMobile}
         >
            <Modal.Header closeButton>
               <Modal.Title>Anfrage abbrechen</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               Möchtest du die Anfrage wirklich schließen ohne einen Coach ausgewählt zu haben?
            </Modal.Body>

            <Modal.Footer className="bg-light">
               <Button variant="outline-link" onClick={() => setShowCancelDialog(false)}>
                  Nein
               </Button>
               <LoadingButton variant="danger" onClick={handleCloseRequest}>
                  Ja, Anfrage schließen
               </LoadingButton>
            </Modal.Footer>
         </Modal>
      </Container>
   );
};

interface VoteResultsProps {
   request: RepresentationRequest;
   coachingClasses: CoachingClass[];
   onAfterSelectVote: () => Promise<void>;
}

const VoteResults = ({ request, coachingClasses, onAfterSelectVote }: VoteResultsProps) => {
   const { sessionUser } = useSession();
   const { users, box } = useCache();
   const dispatch = useDispatch();
   const [votes, setVotes] = useState<RepresentationRequestHasVote[]>([]);
   const [selectedVote, setSelectedVote] = useState<RepresentationRequestHasVote>();

   useEffect(() => {
      (async () => {
         const result = await RepresentationRequestHasVoteModel.list({ request_id: request.id });
         setVotes(
            result.sort((a, b) => {
               if (a.vote === 'yes' && b.vote !== 'yes') return -1;
               if (a.vote === 'no' && b.vote !== 'no') return 1;
               return 0;
            })
         );
      })();
   }, [request]);

   const handleUpdateClasses = async () => {
      if (!selectedVote) return;

      const updatedVote = await RepresentationRequestHasVoteModel.markAsSelected(selectedVote.id);
      setVotes([...votes.filter(v => v.id !== selectedVote.id), updatedVote]);

      // Klassen aktualisieren
      const classes = (
         await Promise.all(
            request.coaching_classes.map(async classId => CoachingClassModel.get(classId))
         )
      ).filter(notEmpty);

      const updatedClasses = await Promise.all(
         classes.map(async coachingClass => {
            // Erster Coach austauschen
            if (coachingClass.coach_id === request.user_id)
               coachingClass.coach_id = selectedVote.user_id;

            // Zweiter Coach austauschen
            if (coachingClass.coach_second_id === request.user_id)
               coachingClass.coach_second_id = selectedVote.user_id;

            // Weitere Coaches austauschen
            if (coachingClass.coach_other.includes(request.user_id)) {
               coachingClass.coach_other = [
                  ...coachingClass.coach_other.filter(cid => cid !== request.user_id),
                  selectedVote.user_id,
               ];
            }

            return CoachingClassModel.update(coachingClass);
         })
      );

      updatedClasses.forEach(cc => dispatch(upsertCoachingClass(cc)));

      // Anfrage schließen
      request.status = 'closed';
      await RepresentationRequestModel.update(request);

      setSelectedVote(undefined);
      await onAfterSelectVote();
   };

   const getCoachName = (coachId?: number) => {
      const user = users.find(u => u.id === coachId);
      if (!user) return '';

      return `${user.first_name} ${user.last_name}`;
   };

   const showVote = useCallback(
      () =>
         sessionUser?.roles.includes(ROLE_ADMIN) ||
         sessionUser?.roles.includes(ROLE_BOX_OWNER) ||
         sessionUser?.roles.includes(ROLE_SCHEDULER) ||
         request.user_id === sessionUser?.id,
      [sessionUser, request]
   );

   const requiredVotes = (box?.rr_number_of_votes_required ?? 0) - votes.length;
   const dateRequesterCanSelectVoter = moment(
      Math.min(...coachingClasses.map(cc => cc.start.getTime()))
   ).subtract(box?.rr_locked_until_days_before ?? 0, 'days');

   // Sofern die Anzahl Tage davor erreicht sind, kann auf jeden Fall abgestimmt werden
   const canSelectVoter = () => {
      // Beide Funktionen sind deaktiviert
      if (
         (box?.rr_locked_until_days_before ?? 0) <= 0 &&
         (box?.rr_number_of_votes_required ?? 0) <= 0
      )
         return true;

      if ((box?.rr_locked_until_days_before ?? 0) <= 0) return requiredVotes <= 0;

      if ((box?.rr_number_of_votes_required ?? 0) <= 0)
         return dateRequesterCanSelectVoter.isBefore(moment());

      return dateRequesterCanSelectVoter.isBefore(moment()) || requiredVotes <= 0;
   };

   return (
      <>
         <Container className="px-0">
            {coachingClasses.length > 0 && !canSelectVoter() && (
               <Row className="mt-3">
                  <Col>
                     <Alert variant="danger">
                        <Alert.Heading>Auswahl des Coaches noch gesperrt.</Alert.Heading>
                        {requiredVotes > 0 && (
                           <p>
                              Es fehlen noch <strong>{requiredVotes} Stimmen</strong>, ehe ein Coach
                              ausgewählt werden kann.
                           </p>
                        )}
                        {dateRequesterCanSelectVoter.isAfter(moment()) && (
                           <p>
                              Erst ab dem{' '}
                              <strong>{dateRequesterCanSelectVoter.format('L [um] LTS')}</strong>{' '}
                              kann ein Coach ausgewählt werden.
                           </p>
                        )}
                     </Alert>
                  </Col>
               </Row>
            )}
            <Row>
               <Col sm={12} md={6}>
                  <ItemList
                     title="Abgestimmt"
                     countComponent={c => <em className="ms-2">({c})</em>}
                     emptyMessage="Noch keine Abstimmungen vorhanden"
                     items={votes}
                     variant={item => (item.is_selected ? 'success' : undefined)}
                  >
                     {({ item }) => (
                        <div className="d-flex align-items-center">
                           {showVote() && (
                              <div className="me-3">
                                 {item.vote === 'yes' && (
                                    <Icon path={mdiCheckBold} size={1} color={Colors.success} />
                                 )}
                                 {item.vote === 'no' && (
                                    <Icon path={mdiCloseThick} size={1} color={Colors.danger} />
                                 )}
                              </div>
                           )}
                           <div className="d-flex flex-fill flex-column">
                              <MiniProfile user={item.user_id} />
                              {showVote() && <div className="fst-italic">{item.comment}</div>}
                           </div>
                           {request.status === 'open' &&
                              request.user_id === sessionUser?.id &&
                              item.vote !== 'no' && (
                                 <div>
                                    <LoadingButton
                                       variant={item.vote === 'yes' ? 'primary' : 'outline-primary'}
                                       className="me-3"
                                       onClick={() => setSelectedVote(item)}
                                       size="sm"
                                       disabled={!canSelectVoter()}
                                    >
                                       Auswählen
                                    </LoadingButton>
                                 </div>
                              )}
                           {request.status === 'closed' && item.is_selected && (
                              <em className="me-3">Ausgewählt</em>
                           )}
                        </div>
                     )}
                  </ItemList>
               </Col>
               <Col sm={12} md={6}>
                  <ItemList
                     title="Noch nicht abgestimmt"
                     countComponent={c => <em className="ms-2">({c})</em>}
                     emptyMessage="Noch keine Abstimmungen vorhanden"
                     items={sortBy(
                        users.filter(
                           u =>
                              u.roles.includes(ROLE_COACH) && // nur Coaches anzeigen
                              !votes.some(v => v.user_id === u.id) && // hat noch keinen Vote abgegeben
                              u.id !== request?.user_id // Ist nicht der Ersteller der Anfrage
                        ),
                        ['first_name', 'last_name']
                     )}
                  >
                     {({ item }) => <MiniProfile user={item} />}
                  </ItemList>
               </Col>
            </Row>
         </Container>

         <Modal show={!!selectedVote} onHide={() => setSelectedVote(undefined)} centered={isMobile}>
            <Modal.Header closeButton>
               <Modal.Title>Auswahl bestätigen</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               Soll <strong>{getCoachName(selectedVote?.user_id)}</strong> als Coach für die Klassen
               hinterlegt werden?
            </Modal.Body>

            <Modal.Footer className="bg-light">
               <Button variant="outline-link" onClick={() => setSelectedVote(undefined)}>
                  Nein
               </Button>
               <LoadingButton variant="primary" onClick={handleUpdateClasses}>
                  Ja, Coach hinterlegen
               </LoadingButton>
            </Modal.Footer>
         </Modal>
      </>
   );
};

interface PerformVoteProps {
   request: RepresentationRequest;
   onAfterVote: () => Promise<void>;
}

const PerformVote = (props: PerformVoteProps) => {
   const { sessionUser } = useSession();
   const [myVote, setMyVote] = useState<RepresentationRequestHasVote>();

   useEffect(() => {
      (async () => {
         const votes = await RepresentationRequestHasVoteModel.list({
            request_id: props.request.id,
            user_id: sessionUser?.id,
         });
         if (votes.length === 1) setMyVote(votes[0]);
      })();
   }, [props.request, sessionUser]);

   const handleSubmitForm = async (vote: Partial<RepresentationRequestHasVote>) => {
      const v = await RepresentationRequestHasVoteModel.insert(vote);
      setMyVote(v);
      await props.onAfterVote();
   };

   const handleDeleteVote = async () => {
      if (!myVote) return;

      await RepresentationRequestHasVoteModel.delete(myVote);
      setMyVote(undefined);
      await props.onAfterVote();
   };

   if (!myVote) {
      return (
         <Alert variant="info">
            <Alert.Heading>Abstimmung notwendig!</Alert.Heading>
            <p>Bitte geben an, ob du alle unten aufgelisteten Klassen übernehmen kannst.</p>
            <hr />

            <Formik
               onSubmit={handleSubmitForm}
               initialValues={{
                  id: 0,
                  request_id: props.request.id,
                  user_id: sessionUser?.id,
               }}
               enableReinitialize
               validateOnMount
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <TextareaControl
                        formik={formik}
                        name="comment"
                        label="Kommentar"
                        placeholder="Kommentar…"
                        type="responsive"
                     />
                     <FormRow type="responsive">
                        <div className="d-flex flex-column flex-md-row justify-content-start gap-1">
                           <LoadingButton
                              variant="success"
                              onClick={async () => {
                                 formik.setFieldValue('vote', 'yes');
                                 await formik.submitForm();
                              }}
                           >
                              Ja, ich kann
                           </LoadingButton>
                           <LoadingButton
                              variant="danger"
                              onClick={async () => {
                                 formik.setFieldValue('vote', 'no');
                                 await formik.submitForm();
                              }}
                           >
                              Nein, ich kann nicht
                           </LoadingButton>
                        </div>
                     </FormRow>
                  </Form>
               )}
            </Formik>
         </Alert>
      );
   }

   return (
      <Alert variant="info">
         <Alert.Heading className="d-flex align-items-center">
            {myVote.vote === 'yes' && <Icon path={mdiCheckBold} size={1} color={Colors.success} />}
            {myVote.vote === 'no' && <Icon path={mdiCloseThick} size={1} color={Colors.danger} />}
            <span className="ms-2">
               {myVote.vote === 'yes' && 'Ja, ich kann.'}
               {myVote.vote === 'no' && 'Nein, ich kann nicht.'}
            </span>
         </Alert.Heading>
         <em>{myVote.comment}</em>
         <hr />
         <div className="d-flex flex-column flex-md-row justify-content-end">
            <LoadingButton variant="outline-danger" onClick={handleDeleteVote}>
               Abstimmung zurücknehmen
            </LoadingButton>
         </div>
      </Alert>
   );
};
