import moment, { Moment } from 'moment';
import * as rdd from 'react-device-detect';
import {
   mdiClockOutline,
   mdiClockTimeEightOutline,
   mdiClockTimeElevenOutline,
   mdiClockTimeFiveOutline,
   mdiClockTimeFourOutline,
   mdiClockTimeNineOutline,
   mdiClockTimeOneOutline,
   mdiClockTimeSevenOutline,
   mdiClockTimeSixOutline,
   mdiClockTimeTenOutline,
   mdiClockTimeThreeOutline,
   mdiClockTimeTwelveOutline,
   mdiClockTimeTwoOutline,
} from '@mdi/js';

export function setTitle(title: string) {
   document.title = title ? `${title} | Coaching Zeitplaner` : 'Coaching Zeitplaner';
}

export const mergeDateAndTime = (day?: Date | null, time?: Date | null): Date | null => {
   if (!day || !time) return null;

   const d = new Date(day.getTime());

   d.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
   return d;
};

export const Colors = {
   white: '#ffffff',
   primary: '#007bff',
   secondary: '#6c757d',
   success: '#28a745',
   warning: '#ffc107',
   danger: '#dc3545',
   info: '#17a2b8',
   dark: '#343a40',
   yellow: '#ffc107',
   light: '#f8f9fa',
   background: '#e9ecef',
   textBlack: '#000000B6',
   textWhite: '#FFFFFFF3',
};

export const pickerColors = [
   '#980000',
   '#ff0000',
   '#ff9900',
   '#ffff00',
   '#02ff00',
   '#02ffff',
   '#4a86e8',
   '#0000ff',
   '#9900ff',
   '#ff00ff',
   // '#e3b6ad', '#f4cccc', '#fce5cd', '#fff2cc', '#d9ead3', '#d0e0e3', '#c9daf8', '#cfe2f3', '#d9d2e9', '#ead1dc',
   // '#da7c6a', '#ea9999', '#f9cb9c', '#ffe599', '#b6d7a8', '#a2c4c9', '#a4c2f4', '#9fc5e8', '#b4a7d6', '#d5a6bd',
   '#c94024',
   '#e06666',
   '#f6b26b',
   '#ffd966',
   '#93c47d',
   '#76a5af',
   '#6d9eeb',
   '#6fa8dc',
   '#8e7cc3',
   '#c27ba0',
   '#a61c00',
   '#cc0000',
   '#e69138',
   '#f1c232',
   '#6aa84f',
   '#45818e',
   '#3c78d8',
   '#3d85c6',
   '#674ea7',
   '#a64d79',
   '#85200c',
   '#990000',
   '#b45f06',
   '#bf9000',
   '#38761d',
   '#134f5c',
   '#1155cc',
   '#0b5394',
   '#351c75',
   '#741b47',
   '#5b0f00',
   '#660000',
   '#783f04',
   '#7f6000',
   '#274e13',
   '#0c343d',
   '#1c4587',
   '#073763',
   '#20124d',
   '#4c1130',
];

/* Return array of dates for nth ISO week of year
 ** @param {number|string} isoWeekNum - ISO week number, default is 1
 ** @param {number|string} year - ISO week year, default is current year
 ** @returns {Array} of 7 dates for specified week
 */
export const getISOWeekDates = (date: Moment) => {
   const d = moment(date); // Neue Instanz, sodass diese manipuliert werden kann
   const dates = [];
   for (let i = 0; i < 7; i += 1) {
      dates.push(d.format('YYYY-MM-DD'));
      d.add(1, 'day');
   }
   return dates;
};

export const formatRange = (
   start: Moment | Date | string | undefined,
   end: Moment | Date | string | undefined,
   format: 'simple-date-only' | 'full-date-only' | 'simple-date-with-time' | 'full-date-with-time'
) => {
   const s = moment(start);
   const e = moment(end);
   switch (format) {
      // Date only
      case 'simple-date-only':
         return `${s.format('L')} bis ${e.format('L')}`;
      case 'full-date-only':
         return `${s.format('dddd, LL')} bis ${e.format('dddd, LL')}`;
      // Date & Time
      case 'simple-date-with-time':
         return `${s.format('LT')} bis ${e.format('LT')}`;
      case 'full-date-with-time':
         return `${s.format('dd L: LT')} bis ${e.format('LT')}`;
      default:
         return '';
   }
};

export const setImpersonateMode = (enable: boolean) => {
   if (enable) localStorage.setItem('impersonate', 'true');
   else localStorage.removeItem('impersonate');
};

export const isImpersonateModeEnabled = () => localStorage.getItem('impersonate') === 'true';

export const isMobile = () => rdd.isMobile;

export const notEmpty = <TValue,>(value: TValue | null | undefined): value is TValue =>
   value !== null && value !== undefined;

export const getSelectStyles = (isInValid = false) => ({
   menu: (provided: Record<string, unknown>) => ({
      ...provided,
      zIndex: 4,
   }),
   placeholder: (provided: Record<string, unknown>) => ({
      ...provided,
      'white-space': 'nowrap',
      overflow: 'hidden',
   }),
   control: (provided: any) =>
      !isInValid
         ? provided
         : {
              ...provided,
              borderColor: Colors.danger,
              '&:hover': { borderColor: Colors.danger },
           },
});

export const getClockIcon = (date: Moment | Date): string => {
   switch (moment(date).hour() % 12) {
      case 0:
         return mdiClockTimeTwelveOutline;
      case 1:
         return mdiClockTimeOneOutline;
      case 2:
         return mdiClockTimeTwoOutline;
      case 3:
         return mdiClockTimeThreeOutline;
      case 4:
         return mdiClockTimeFourOutline;
      case 5:
         return mdiClockTimeFiveOutline;
      case 6:
         return mdiClockTimeSixOutline;
      case 7:
         return mdiClockTimeSevenOutline;
      case 8:
         return mdiClockTimeEightOutline;
      case 9:
         return mdiClockTimeNineOutline;
      case 10:
         return mdiClockTimeTenOutline;
      case 11:
         return mdiClockTimeElevenOutline;
      default:
         return mdiClockOutline;
   }
};
