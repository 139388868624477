import moment, { Moment } from 'moment';
import { Button, ButtonGroup } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCloseCircle, mdiContentCopy, mdiContentPaste } from '@mdi/js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { Tooltip } from 'react-tooltip';
import { LoadingButton } from '../../molecules';
import { copyWeek, setScheduleEntriesPerWeek } from '../../store/actions/scheduleActions';
import { getISOWeekDates } from '../../utils';
import { RootState } from '../../store';
import { CoachingClassCard } from './CoachingClassCard';
import { setBox } from '../../store/actions/appActions';
import { SkeletonPlaceholder } from '../../atoms';
import { BoxModel, CoachingClassModel } from '../../models';
import { useCache, useSession, useUserSettings } from '../../hooks';
import { CoachingScheduleEntry } from '../../types/CoachingScheduleEntry';
import { MeetingModel } from '../../models/MeetingModel';
import { BoxClosingTimeModel } from '../../models/BoxClosingTime';
import { ROLE_SCHEDULER } from '../../types/api';
import { MeetingCard } from './MeetingCard';
import { BoxClosingTimeCard } from './BoxClosingTimeCard';

interface DisplayModel {
   [key: string]: CoachingScheduleEntry[];
}

interface Props {
   startOfWeek: string;
   scrollIntoView?: boolean;
}

const WeekOfYearRow = (props: Props) => {
   const momentStartOfWeek = moment(props.startOfWeek);
   const dispatch = useDispatch();
   const { selectedDate, copiedWeek, reloadCounter, settings } = useSelector(
      (s: RootState) => s.schedule
   );
   const scheduleEntries = useSelector(
      (s: RootState) => s.schedule.scheduleEntries[momentStartOfWeek.format('YYYY-MM-DD')] ?? []
   );
   const { box } = useCache();
   const { sessionRoles } = useSession();
   const [userSettings] = useUserSettings();
   const [entries, setEntries] = useState<DisplayModel>({});
   const [isLoading, setLoading] = useState(false);
   const ref = useRef<HTMLDivElement>(null);

   // Laden der Daten vom Backend und Speichern im State
   const loadScheduleEntries = useCallback(async () => {
      setLoading(true);
      const sow = moment(props.startOfWeek);
      try {
         dispatch(
            setScheduleEntriesPerWeek(sow, [
               ...(sessionRoles.some(r => r.key === ROLE_SCHEDULER)
                  ? (await CoachingClassModel.listPerWeek(sow)).map(
                       cc =>
                          ({
                             id: cc.id,
                             type: 'CoachingClass',
                             obj: cc,
                             start: cc.start,
                          } as CoachingScheduleEntry)
                    )
                  : []),
               ...(await MeetingModel.listPerWeek(sow)).map(
                  m =>
                     ({
                        id: m.id,
                        type: 'Meeting',
                        obj: m,
                        start: m.start,
                     } as CoachingScheduleEntry)
               ),
               ...(await BoxClosingTimeModel.listPerWeek(sow)).map(
                  ct =>
                     ({
                        id: ct.id,
                        type: 'BoxClosingTime',
                        obj: ct,
                        start: ct.start,
                     } as CoachingScheduleEntry)
               ),
            ])
         );
      } finally {
         setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch, props.startOfWeek, sessionRoles, reloadCounter]);

   // Aufbereitung der Daten aus dem State für die Woche in verschiedene Buckets pro Tag zur Anzeige
   useEffect(() => {
      (async () => {
         const result: DisplayModel = {};

         getISOWeekDates(moment(props.startOfWeek)).forEach(d => {
            result[d] = [];
         });

         // Kopieren ist notwendig, da sonst das Original geändert wird und der Effect erneut ausgeführt wird (und erneut)
         let copiedEntries = [...scheduleEntries];

         // Daten anhand der Einstellungen filtern
         copiedEntries = copiedEntries.filter(e => !settings.hiddenEventTypes.includes(e.type));

         sortBy(copiedEntries, 'start').forEach(e => {
            const day = moment(e.start).format('YYYY-MM-DD');
            result[day]?.push(e);
         });

         setEntries(result);
      })();
   }, [props.startOfWeek, scheduleEntries, settings]);

   // Initiales Laden der Klassen beim Anzeigen der Woche
   useEffect(() => {
      (async () => {
         await loadScheduleEntries();
      })();
   }, [loadScheduleEntries]);

   // Scrollen zur Woche, sofern angefordert und alle Daten geladen sind
   useEffect(() => {
      if (props.scrollIntoView && entries && ref?.current) ref?.current?.scrollIntoView();
   }, [props.scrollIntoView, ref, entries]);

   const handleCopyWeekToClipboard = (week: Moment) => {
      dispatch(copyWeek(week.toISOString()));
      const weekOfYear = momentStartOfWeek.isoWeek();
      toast.info(`Woche ${weekOfYear} wurde kopiert.`);
   };

   const handlePasteWeekFromClipboard = async () => {
      if (!copiedWeek) return;

      if (sessionRoles.some(r => r.key === ROLE_SCHEDULER)) {
         await CoachingClassModel.copyWeek(
            moment(copiedWeek),
            momentStartOfWeek,
            !!userSettings.schedule?.preventDuplicates
         );
      }

      if (!userSettings.schedule?.doNotCopyMeetings) {
         await MeetingModel.copyWeek(
            moment(copiedWeek),
            momentStartOfWeek,
            !!userSettings.schedule?.preventDuplicates
         );
      }

      if (!userSettings.schedule?.doNotCopyBoxClosingTimes) {
         await BoxClosingTimeModel.copyWeek(
            moment(copiedWeek),
            momentStartOfWeek,
            !!userSettings.schedule?.preventDuplicates
         );
      }

      await loadScheduleEntries();

      toast.info(
         `Woche ${moment(
            copiedWeek
         ).isoWeek()} wurde in Woche ${momentStartOfWeek.isoWeek()} eingefügt.`
      );
      dispatch(copyWeek(null));
   };

   const scheduleIsCompleted = (date: Moment) =>
      date.isSameOrBefore(moment(box?.sessions_scheduled_until), 'day');
   const markAsNotScheduled = async (date: Moment) => {
      const newBox = {
         ...box,
         sessions_scheduled_until: date.add(-1, 'day').toDate(),
      };
      const b = await BoxModel.update(newBox);
      dispatch(setBox(b));
   };

   const markAsScheduled = async (date: Moment) => {
      const newBox = {
         ...box,
         sessions_scheduled_until: date.toDate(),
      };
      const b = await BoxModel.update(newBox);
      dispatch(setBox(b));
   };

   const renderEntryCard = (entry: CoachingScheduleEntry) => {
      switch (entry.type) {
         case 'CoachingClass':
            return <CoachingClassCard key={entry.id} week={props.startOfWeek} entry={entry} />;
         case 'Meeting':
            return <MeetingCard key={entry.id} week={props.startOfWeek} entry={entry} />;
         case 'BoxClosingTime':
            return <BoxClosingTimeCard key={entry.id} week={props.startOfWeek} entry={entry} />;
         default:
            return null;
      }
   };

   return (
      <div ref={ref} className="week">
         <div className="week-of-year">
            <h2>{momentStartOfWeek.isoWeek()}</h2>
            <ButtonGroup vertical>
               <LoadingButton
                  id={`schedule-week-of-year-copy-${momentStartOfWeek.format('YYYY-MM-DD')}`}
                  variant="secondary"
                  size="sm"
                  className="d-flex align-items-center"
                  hideContentWhenLoading
                  onClick={() => handleCopyWeekToClipboard(momentStartOfWeek)}
               >
                  <Icon path={mdiContentCopy} size={0.75} />
               </LoadingButton>
               <LoadingButton
                  id={`schedule-week-of-year-paste-${momentStartOfWeek.format('YYYY-MM-DD')}`}
                  variant="secondary"
                  size="sm"
                  className="d-flex align-items-center"
                  hideContentWhenLoading
                  onClick={handlePasteWeekFromClipboard}
                  disabled={!copiedWeek}
               >
                  <Icon path={mdiContentPaste} size={0.75} />
               </LoadingButton>
            </ButtonGroup>
            <Tooltip
               anchorSelect={`#schedule-week-of-year-copy-${momentStartOfWeek.format(
                  'YYYY-MM-DD'
               )}`}
               content="Woche kopieren"
               place="right"
            />
            <Tooltip
               anchorSelect={`#schedule-week-of-year-paste-${momentStartOfWeek.format(
                  'YYYY-MM-DD'
               )}`}
               content="Kopierte Woche einfügen"
               place="right"
            />
         </div>
         <div className="week-days d-flex flex-column flex-lg-row">
            {Object.entries(entries).map(([day, entriesOfTheDay]) => {
               const morningClasses = entriesOfTheDay.filter(c => c.start.getHours() < 12);
               const afternoonClasses = entriesOfTheDay.filter(c => c.start.getHours() >= 12);
               const theDay = moment(day);

               return (
                  <div key={day} className="week-day">
                     <div
                        className={`week-day-header ${
                           scheduleIsCompleted(theDay) ? 'schedule-ok' : 'schedule-nok'
                        }`}
                     >
                        {scheduleIsCompleted(theDay) ? (
                           <Button
                              variant="link"
                              onClick={() => markAsNotScheduled(theDay)}
                              className="p-0 d-flex align-items-center justify-content-center"
                           >
                              <Icon
                                 path={mdiCloseCircle}
                                 className="icon icon-schedule-ok"
                                 size={0.7}
                              />
                           </Button>
                        ) : (
                           <Button
                              variant="link"
                              onClick={() => markAsScheduled(theDay)}
                              className="p-0 d-flex align-items-center justify-content-center"
                           >
                              <Icon
                                 path={mdiCheckCircle}
                                 className="icon icon-schedule-nok"
                                 size={0.7}
                              />
                           </Button>
                        )}

                        <small
                           className={`fw-bold ${
                              theDay.isSame(selectedDate, 'day') ? 'today' : ''
                           }`}
                        >
                           {theDay.format('dd DD. MMM')}
                        </small>
                     </div>
                     <div className="week-day-classes">
                        {isLoading ? (
                           [...Array(Math.floor((theDay.date() % 5) % 3) + 1).keys()].map(i => (
                              <SkeletonPlaceholder key={i} height="5rem" width="100%" />
                           ))
                        ) : (
                           <>
                              {morningClasses.map(renderEntryCard)}
                              {afternoonClasses.length > 0 && (
                                 <div className="d-flex align-items-center my-1">
                                    <div className="dropdown-divider flex-fill" />
                                    <span className="mx-1">Nachmittag</span>
                                    <div className="dropdown-divider flex-fill" />
                                 </div>
                              )}
                              {afternoonClasses.map(renderEntryCard)}
                           </>
                        )}
                     </div>
                  </div>
               );
            })}
         </div>
      </div>
   );
};

// WeekOfYearRow.whyDidYouRender = true;

export default React.memo(WeekOfYearRow);
