import React, { useMemo } from 'react';
import { mdiCalendarMonth, mdiCardAccountDetailsOutline, mdiPackageVariantClosed } from '@mdi/js';
import { useParams } from 'react-router-dom';
import { PTCalenderTab } from './PTCalenderTab';
import { PTClientsTab } from './PTClientsTab';
import { PTProvidedServicesTab } from './PTProvidedServicesTab';
import { MenuItem, PageWithSideMenu } from '../../templates';

export const PersonalTrainingsPage = () => {
   const params = useParams();

   const menuItems: MenuItem[] = useMemo(
      () => [
         {
            title: 'Kalender',
            key: 'calendar',
            icon: mdiCalendarMonth,
            component: () => <PTCalenderTab />,
         },
         {
            title: 'Dienstleistungen',
            key: 'services',
            icon: mdiPackageVariantClosed,
            component: () => <PTProvidedServicesTab />,
         },
         {
            title: 'Kunden',
            key: 'clients',
            icon: mdiCardAccountDetailsOutline,
            component: () => <PTClientsTab />,
         },
      ],

      []
   );

   return (
      <PageWithSideMenu
         breakpoint="always"
         menuItems={menuItems}
         selectedMenuItem={params.tab}
         onBuildLink={tab => `/pt/${tab}`}
      />
   );
};
