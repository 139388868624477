import React, { useMemo } from 'react';
import { mdiDumbbell, mdiMapMarker, mdiPackageVariantClosed, mdiStore } from '@mdi/js';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ManageBoxClassTypesTab } from './ManageBoxClassTypesTab';
import { ManageBoxRoomsTab } from './ManageBoxRoomsTab';
import { ManageBoxTab } from './ManageBoxTab';
import { useCache, useModule } from '../../hooks';
import { RootState } from '../../store';
import { ManageBoxPTServicesTab } from './ManageBoxPTServicesTab';
import { MODULE_PERSONAL_TRAINING } from '../../types/api';
import { MenuItem, PageWithSideMenu } from '../../templates';

export const ManageBoxPage = () => {
   const params = useParams();
   const cachedData = useCache();
   const ptModuleEnabled = useModule(MODULE_PERSONAL_TRAINING);
   const ptServices = useSelector((s: RootState) => s.pt.services);

   const menuItems: MenuItem[] = useMemo(() => {
      const items: MenuItem[] = [
         {
            title: 'Box',
            key: 'box',
            icon: mdiStore,
            component: () => <ManageBoxTab />,
         },
         {
            title: 'Kurstypen',
            key: 'classType',
            icon: mdiDumbbell,
            badgeValue: cachedData.classTypes.length,
            badgeType: 'default',
            component: () => <ManageBoxClassTypesTab />,
         },
         {
            title: 'Räume',
            key: 'room',
            icon: mdiMapMarker,
            badgeValue: cachedData.rooms.length,
            badgeType: 'default',
            component: () => <ManageBoxRoomsTab />,
         },
      ];

      if (ptModuleEnabled) {
         items.push({
            title: 'PT Leistungen',
            key: 'ptService',
            icon: mdiPackageVariantClosed,
            badgeValue: ptServices.length,
            badgeType: 'default',
            component: () => <ManageBoxPTServicesTab />,
         });
      }

      return items;
   }, [cachedData.classTypes, cachedData.rooms, ptModuleEnabled, ptServices]);

   return (
      <PageWithSideMenu
         menuItems={menuItems}
         selectedMenuItem={params.tab}
         onBuildLink={tab => `/manage/box/${tab}`}
         breakpoint="lg"
      />
   );
};
