import React, { memo } from 'react';
import './LineWithText.scss';

interface Props {
   /**
    * The text to show between the lines.
    */
   text: string;
   /**
    * Additional classes.
    */
   className?: string;
}

export const LineWithText = memo(({ text, className }: Props) => (
   <div className={`line-with-text ${className ?? ''}`}>{text}</div>
));
