import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Icon from '@mdi/react';
import { mdiAccountCowboyHat, mdiWeightLifter } from '@mdi/js';
import { FloatingPanel } from '../atoms';
import { Headline, LoadingButton } from '../molecules';
import { Colors } from '../utils';
import { UserModel } from '../models';
import { useSession } from '../hooks';

export const DemoLoginPage = () => {
   const navigate = useNavigate();
   const { signIn } = useSession();

   const signInDemoAccount = async (account: string) => {
      if (!account) return;

      try {
         const loggedInUser = await UserModel.authenticateDemoAccount(account, account);
         await signIn(loggedInUser);
         navigate('/', { replace: true });
      } catch (error) {
         // TODO: Fehler bzgl. falscher Username/Passwort anzeigen
         toast.error('Es gab ein Fehler beim Anmelden. Bitte versuche es später erneut.');
      }
   };

   return (
      <Container className="p-2">
         <Row className="justify-content-center">
            <Col xs={12} sm={10} md={8} lg={7} xl={6}>
               <FloatingPanel className="p-4">
                  <Headline className="text-center mt-0 mb-4" title="Demo-Zugang" />
                  <div>
                     <div className="mb-4">
                        In der Demo-Umgebung kannst du dir die Funktionen aus Sicht eines Coaches
                        und aus Sicht eines Box-Owners anschauen und ausprobieren. Dabei brauchst du
                        dir keine Gedanken über die Änderungen machen, da diese Umgebung jede Nacht
                        zurückgesetzt wird.
                     </div>
                     <Row className="mb-3">
                        <Col md={6} className="my-2">
                           <Card className="text-center h-100">
                              <Card.Header>Coach</Card.Header>
                              <Card.Body className="d-flex flex-column align-items-center">
                                 <Card.Title>
                                    <Icon
                                       path={mdiWeightLifter}
                                       size={3}
                                       color={Colors.secondary}
                                    />
                                 </Card.Title>
                                 <Card.Text className="flex-fill text-muted">
                                    Als Coach siehst du deine Termine, welche auch per Kalender-Abo
                                    auf deinem Handy eingezeigt werden können.
                                 </Card.Text>
                                 <LoadingButton
                                    variant="primary"
                                    onClick={() => signInDemoAccount('demo-coach')}
                                 >
                                    Als <strong>Coach</strong> anmelden
                                 </LoadingButton>
                              </Card.Body>
                           </Card>
                        </Col>
                        <Col md={6} className="my-2">
                           <Card className="text-center h-100">
                              <Card.Header>Box-Owner</Card.Header>
                              <Card.Body className="d-flex flex-column align-items-center">
                                 <Card.Title>
                                    <Icon
                                       path={mdiAccountCowboyHat}
                                       size={3}
                                       color={Colors.secondary}
                                    />
                                 </Card.Title>
                                 <Card.Text className="flex-fill text-muted">
                                    Als Box-Owner verwaltest du deine Box & Coaches und erstellst
                                    den Coaching-Plan.
                                 </Card.Text>
                                 <LoadingButton
                                    variant="primary"
                                    onClick={() => signInDemoAccount('demo-box-owner')}
                                 >
                                    Als <strong>Box-Owner</strong> anmelden
                                 </LoadingButton>
                              </Card.Body>
                           </Card>
                        </Col>
                     </Row>
                  </div>
               </FloatingPanel>
            </Col>
         </Row>
      </Container>
   );
};
