import { Alert } from 'react-bootstrap';
import moment from 'moment';
import React, { memo } from 'react';
import { CoachingClass } from '../types/api';
import { useCache } from '../hooks';

interface Props {
   /**
    * The coaching class with the information about the cancellation.
    */
   coachingClass: Partial<CoachingClass>;
}

/**
 * Renders an alert when the class is canceled with information about time, user and reason for cancellation.
 */
export const CanceledCoachingClassAlert = memo(({ coachingClass }: Props) => {
   const { users } = useCache();

   const getCancelCoach = () => {
      const coach = users.find(u => u.id === coachingClass?.cancel_user_id);
      return coach ? `${coach.first_name} ${coach.last_name}` : 'Unbekannt';
   };

   if (!coachingClass.canceled) return null;

   return (
      <Alert variant="warning">
         Die Klasse wurde am{' '}
         <strong>{moment(coachingClass.cancel_time).format('L [um] LT')}</strong> von{' '}
         <strong>{getCancelCoach()}</strong> abgesagt.
         <em
            className="ms-2 mt-1 ps-1 d-block"
            style={{
               whiteSpace: 'pre-line',
               borderLeftColor: 'rgba(0,0,0,.15)',
               borderLeftWidth: '0.25rem',
               borderLeftStyle: 'solid',
            }}
         >
            {coachingClass.cancel_reason}
         </em>
      </Alert>
   );
});
