export interface ErrorInfo {
   error: string;
   message?: string;
   payload?: {
      [key: string]: string | number | boolean;
   };
}

export const ERROR_RECAPTCHA_DENIED = 'reCaptcha_denied';
export const ERROR_ABSENCE_NOT_ENOUGH_DAYS_LEFT = 'NotEnoughDaysLeft';
export const ERROR_ABSENCE_OVERLAPPING = 'Overlapping';
export const ERROR_ABSENCE_PROHIBITION = 'Prohibition';
