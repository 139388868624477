import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React from 'react';
import { Formik, FormikErrors } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FormUtils, GenericControl, SubmitButton, TextControl } from '../forms';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { AbsenceProhibition } from '../types/api';
import { AbsenceProhibitionModel } from '../models';

declare type Props = {
   show: boolean;
   onClose: () => void;
   absenceProhibition?: Partial<AbsenceProhibition>;
   afterSave?: (bct: AbsenceProhibition) => Promise<void>;
};

export const EditAbsenceProhibitionDialog = ({
   show,
   onClose,
   absenceProhibition,
   afterSave,
}: Props) => {
   if (!absenceProhibition) return null;

   const handleSubmitForm = async (values: Partial<AbsenceProhibition>) => {
      let ap;
      if (values.id === 0) {
         ap = await AbsenceProhibitionModel.insert(values);
      } else {
         ap = await AbsenceProhibitionModel.update(values);
      }

      if (afterSave) await afterSave(ap);

      onClose();
   };

   return (
      <Modal show={show} onHide={onClose}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={absenceProhibition}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<AbsenceProhibition>> = {};

               if (!values.reason) errors.reason = 'Bitte gib einen Grund an';

               if (!values.start) errors.start = 'Bitte gib ein Startdatum an..';

               if (!values.end) errors.end = 'Bite gib ein Enddatum an.';

               if (values.start && values.end && moment(values.start).isAfter(values.end, 'day'))
                  errors.end = 'Das Ende muss nach dem Start sein.';

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        {absenceProhibition.id === 0
                           ? 'Neue Urlaubssperre'
                           : 'Urlaubssperre bearbeiten'}
                     </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <TextControl
                        formik={formik}
                        name="reason"
                        placeholder="Grund…"
                        label="Grund"
                        description="Der Grund sollte kurz und prägnant angeben, warum in dieser Zeit keine Urlaube möglich sind."
                     />
                     <Row>
                        <Col sm={6}>
                           <GenericControl
                              formik={formik}
                              name="start"
                              label="Von"
                              description="Beginn der Urlaubssperre"
                           >
                              <Form.Control
                                 as={DatePicker}
                                 wrapperClassName="d-flex"
                                 dateFormat="dd.MM.yyyy"
                                 locale="de"
                                 calendarStartDay={1}
                                 showWeekNumbers
                                 placeholderText="__.__.____"
                                 selectsStart
                                 startDate={formik.values.start}
                                 endDate={formik.values.end}
                                 maxDate={formik.values.end}
                                 selected={formik.values.start}
                                 onChange={date => formik.setFieldValue('start', date)}
                                 onBlur={() => formik.handleBlur('start')}
                                 isInvalid={FormUtils.isInvalid(formik, 'start')}
                                 disabled={formik.isSubmitting}
                              />
                           </GenericControl>
                        </Col>
                        <Col sm={6}>
                           <GenericControl
                              formik={formik}
                              name="end"
                              label="Bis"
                              description="Ende der Urlaubssperre"
                           >
                              <Form.Control
                                 as={DatePicker}
                                 wrapperClassName="d-flex"
                                 dateFormat="dd.MM.yyyy"
                                 locale="de"
                                 calendarStartDay={1}
                                 showWeekNumbers
                                 placeholderText="__.__.____"
                                 selectsEnd
                                 startDate={formik.values.start}
                                 endDate={formik.values.end}
                                 minDate={formik.values.start}
                                 selected={formik.values.end}
                                 onChange={date => formik.setFieldValue('end', date)}
                                 onBlur={() => formik.handleBlur('end')}
                                 isInvalid={FormUtils.isInvalid(formik, 'end')}
                                 disabled={formik.isSubmitting}
                              />
                           </GenericControl>
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                     <CreateUpdateInfo
                        hidden={absenceProhibition.id === 0}
                        obj={absenceProhibition}
                     />
                     <Button
                        variant="outline-link"
                        onClick={onClose}
                        disabled={formik.isSubmitting}
                     >
                        Schließen
                     </Button>
                     <SubmitButton formik={formik}>Speichern</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
