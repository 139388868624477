import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { Headline } from '../../molecules';
import { setCache } from '../../store/actions/appActions';
import { FloatingPanel } from '../../atoms';
import { useCache } from '../../hooks';
import { BoxForm, handleValidate } from '../../organisms';
import { BoxModel } from '../../models';
import { Box } from '../../types/api';
import { SubmitButton } from '../../forms';

export const ManageBoxTab = () => {
   const dispatch = useDispatch();
   const cachedData = useCache();
   const [logoFile, setLogoFile] = useState<File>();

   const handleSubmitForm = async (values: Partial<Box>): Promise<void> => {
      const updatedBox = await BoxModel.update(values as Box);

      if (logoFile) await BoxModel.uploadLogo(updatedBox.id, logoFile);

      dispatch(
         setCache({
            ...cachedData,
            box: updatedBox,
         })
      );
   };

   return (
      <Formik
         onSubmit={handleSubmitForm}
         initialValues={cachedData.box as Partial<Box>}
         enableReinitialize
         validate={handleValidate}
      >
         {formik => (
            <Form
               noValidate
               onSubmit={e => {
                  e.preventDefault();
                  formik.handleSubmit();
               }}
            >
               <Headline
                  title={`${cachedData.box?.name}`}
                  browserTitle={`Box-Administration - ${cachedData.box?.name}`}
               />
               <FloatingPanel>
                  <BoxForm setLogoFile={setLogoFile} {...formik} />
               </FloatingPanel>
               <SubmitButton formik={formik} className="floating-button">
                  Speichern
               </SubmitButton>
            </Form>
         )}
      </Formik>
   );
};
