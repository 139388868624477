import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import React, { memo, useState } from 'react';
import { ButtonVariant } from 'react-bootstrap/types';
import DatePicker from 'react-datepicker';

interface Props {
   /**
    * The display variant for the button.
    */
   variant?: ButtonVariant;
   /**
    * The size of the button.
    */
   size?: 'sm' | 'lg';
   /**
    * Additional classes.
    */
   className?: string;
   /**
    * Is executed when the date is changed by the use.
    * @param date
    */
   onDateChange: (date: Date) => void;
}

/**
 * Renders split button to select `today` or a specific date via a drop down calendar.
 */
export const SelectDateDropDownButton = memo((props: Props) => {
   const [show, setShow] = useState(false);

   return (
      <Dropdown
         className={props.className}
         as={ButtonGroup}
         show={show}
         onToggle={() => setShow(v => !v)}
      >
         {/* BorderColor zum besseren erkennen */}
         <Button
            variant={props.variant}
            size={props.size}
            onClick={() => props.onDateChange(new Date())}
         >
            Heute
         </Button>

         <Dropdown.Toggle
            split
            variant={props.variant}
            size={props.size}
            id="dropdown-split-basic"
         />

         <Dropdown.Menu
            as={CustomMenu}
            className="p-0 mt-1"
            onDateChange={(date: Date | null) => {
               props.onDateChange(date ?? new Date());
               setShow(false);
            }}
         />
      </Dropdown>
   );
});

interface CustomMenuProps {
   style?: React.CSSProperties;
   className?: string;
   onDateChange: (date: Date | null) => void;
}

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef((props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
   const [value, setValue] = useState<Date | null>(new Date());

   return (
      <div ref={ref} style={props.style} className={props.className}>
         <DatePicker
            dateFormat="dd.MM.yyyy"
            locale="de"
            calendarStartDay={1}
            showWeekNumbers
            placeholderText="__.__.____"
            selected={value}
            onChange={date => {
               setValue(date as Date);
               props.onDateChange(date as Date);
            }}
            inline
         />
      </div>
   );
});
CustomMenu.displayName = 'CustomMenu';
