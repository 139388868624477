import React from 'react';
import { MultiSelect } from './MultiSelect';
import { useCache } from '../../hooks';

interface Props {
   values?: number[];
   onChange: (userIds: number[]) => void;
   onBlur?: () => void;
   isDisabled?: boolean;
}

export const RoomMultiSelect = ({ values = [], onChange, onBlur, isDisabled }: Props) => {
   const { rooms } = useCache();

   return (
      <MultiSelect
         options={rooms.map(r => ({ label: r.name, value: `${r.id}` }))}
         values={rooms
            .filter(r => values.includes(r.id))
            .map(r => ({ label: r.name, value: `${r.id}` }))}
         onChange={selectedValues => onChange(selectedValues.map(v => Number(v.value)))}
         onBlur={onBlur}
         isDisabled={isDisabled}
      />
   );
};
