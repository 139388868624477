import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';
import { SkeletonPlaceholder } from '../atoms';

interface Props<T> {
   title?: string;
   countComponent?: (count: number) => React.ReactNode;
   header?: React.ReactNode;
   emptyMessage?: string;
   hideWhenEmpty?: boolean;
   items: T[] | null | undefined;
   onClick?: (item: T) => void;
   children: (props: { item: T }) => React.ReactNode;
   variant?: (item: T) => Variant | undefined;
}

const ITEM_LIMIT = 5;

export const ItemList = <T extends { id: number }>(props: Props<T>) => {
   const [showAllEntries, setShowAllEntries] = useState(false);

   if (props.hideWhenEmpty && props.items?.length === 0) return null;

   return (
      <div className="my-1">
         <ListGroup className="floating-panel">
            <ListGroup.Item
               className="d-flex bg-secondary text-white"
               style={{ minHeight: '3rem' }}
            >
               {props.header ? (
                  props.header
               ) : (
                  <h4 className="mb-0">
                     {props.title}
                     {props.countComponent?.(props.items?.length ?? 0)}
                  </h4>
               )}
            </ListGroup.Item>
            {!props.items
               ? [...Array(3).keys()].map(i => (
                    <ListGroup.Item key={i}>
                       <SkeletonPlaceholder width="70%" />
                    </ListGroup.Item>
                 ))
               : props.items
                    .slice(0, showAllEntries ? props.items.length : ITEM_LIMIT)
                    .map(item => (
                       <ListGroup.Item
                          key={item.id}
                          variant={props.variant?.(item)}
                          className="pe-0"
                          action={!!props.onClick}
                          onClick={() => props.onClick?.(item)}
                       >
                          {props.children({ item })}
                       </ListGroup.Item>
                    ))}
            {props.items?.length === 0 && (
               <ListGroup.Item className="d-flex justify-content-center fst-italic">
                  {props.emptyMessage}
               </ListGroup.Item>
            )}
            {(props.items?.length ?? 0) > ITEM_LIMIT && (
               <ListGroup.Item
                  className="d-flex justify-content-center"
                  onClick={() => setShowAllEntries(v => !v)}
                  action
               >
                  {showAllEntries ? 'Zeige weniger an' : 'Zeige mehr an'}
               </ListGroup.Item>
            )}
         </ListGroup>
      </div>
   );
};
