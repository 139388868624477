import { useParams } from 'react-router-dom';
import { Button, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiCalendarMonth, mdiChevronLeft, mdiText } from '@mdi/js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { LinkContainer } from 'react-router-bootstrap';
import { formatRange, getClockIcon, setTitle } from '../utils';
import { SkeletonPlaceholder } from '../atoms';
import { BoxClosingTime } from '../types/api';
import { BoxClosingTimeModel } from '../models/BoxClosingTime';

export const EventClosingTimePage = () => {
   const { eventId } = useParams();
   const [closingTime, setClosingTime] = useState<BoxClosingTime>();
   const [paramIsInvalid, setParamIsInvalid] = useState(false);

   useEffect(() => setTitle('Coaching-Klasse'), []);

   useEffect(() => {
      (async () => {
         const id = Number(eventId);
         if (!Number.isNaN(id)) {
            try {
               setClosingTime((await BoxClosingTimeModel.get(id)) ?? undefined);
               return;
            } catch {
               /* ignore */
            }
         }
         toast.error('Die aufgerufene Coaching-Class existiert nicht.');
         setParamIsInvalid(true);
      })();
   }, [eventId]);

   return (
      <Container className="py-2">
         <Row>
            <Col xs={0} md={2} />
            <Col
               xs={12}
               md={8}
               className="d-flex flex-row justify-content-between align-items-center"
            >
               <LinkContainer
                  to={{
                     pathname: '/calendar',
                     search: `?view=day&date=${moment(closingTime?.start).format('YYYY-MM-DD')}`,
                  }}
               >
                  <Button variant="outline-link" className="d-flex align-items-center ps-1">
                     <Icon path={mdiChevronLeft} size={1} />
                     Kalender
                  </Button>
               </LinkContainer>
            </Col>
            <Col xs={0} md={2} />
         </Row>
         <Row>
            <Col xs={0} md={2} />
            <Col xs={12} md={8}>
               <ListGroup className="floating-panel">
                  <ListGroup.Item className="bg-secondary text-white">
                     <h2 className="mb-0">Schließzeit</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                     {paramIsInvalid && (
                        <div className="d-flex justify-content-center">
                           <em>Schließzeit existiert nicht.</em>
                        </div>
                     )}
                     {!closingTime && !paramIsInvalid && (
                        <Row>
                           <Col>
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="6rem" />
                           </Col>
                        </Row>
                     )}
                     {closingTime && !paramIsInvalid && (
                        <Row>
                           <Col>
                              <FieldRow
                                 icon={mdiCalendarMonth}
                                 value={moment(closingTime?.start).format('L')}
                              />
                              <FieldRow
                                 icon={getClockIcon(closingTime.start)}
                                 value={`${formatRange(
                                    closingTime?.start,
                                    closingTime?.end,
                                    'simple-date-with-time'
                                 )}`}
                              />
                              <FieldRow icon={mdiText}>
                                 <Form.Control
                                    as="textarea"
                                    className="bg-white"
                                    rows={3}
                                    value={closingTime?.reason ?? ''}
                                    readOnly
                                 />
                              </FieldRow>
                           </Col>
                        </Row>
                     )}
                  </ListGroup.Item>
               </ListGroup>
            </Col>
            <Col xs={0} md={2} />
         </Row>
      </Container>
   );
};

interface FieldRowProps {
   icon?: string;
   value?: string;
   children?: React.ReactNode;
}

// TODO: Code doppelt
const FieldRow = (props: FieldRowProps) => (
   <Row className="my-2 align-items-center">
      <Col>
         <InputGroup>
            {props.icon && (
               <InputGroup.Text>
                  <Icon path={props.icon} size={1} />
               </InputGroup.Text>
            )}
            {props.children || (
               <Form.Control className="bg-white" type="text" value={props.value} readOnly />
            )}
         </InputGroup>
      </Col>
   </Row>
);
