import { Moment } from 'moment';
import { BaseModel, TimeBasedObjectModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';
import { PTClass } from '../types/api';

class PTClassModelClass extends BaseModel<PTClass> implements TimeBasedObjectModel<PTClass> {
   public getName(): string {
      return 'pt_class';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<PTClass[]> {
      return ModelExtension.listTimeRange<PTClass>(this.getName(), from, to);
   }

   public async listPerWeek(startOfWeek: Moment): Promise<PTClass[]> {
      return ModelExtension.listPerWeek(this, startOfWeek);
   }
}

export const PTClassModel = new PTClassModelClass();
