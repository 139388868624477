import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../molecules';
import { RootState } from '../../store';
import { MultiSelect } from '../../forms/controls';
import { updateSchedulePageSettings } from '../../store/actions/scheduleActions';
import { FormRow } from '../../forms';

interface Props {
   show: boolean;
   onClose: () => void;
}

export const ScheduleFilterDialog = ({ show, onClose }: Props) => {
   const dispatch = useDispatch();
   const settings = useSelector((s: RootState) => s.schedule.settings);
   const [eventsToHide, setEventsToHide] = useState(settings.hiddenEventTypes);

   const options = useMemo(
      () => [
         {
            label: 'Coaching-Klassen',
            value: 'CoachingClass',
         },
         {
            label: 'Meetings',
            value: 'Meeting',
         },
         {
            label: 'Schließzeiten',
            value: 'BoxClosingTime',
         },
      ],
      []
   );

   const handleSave = () => {
      dispatch(updateSchedulePageSettings({ hiddenEventTypes: eventsToHide }));
      onClose();
   };

   return (
      <Modal show={show} onHide={onClose} centered={isMobile}>
         <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
         </Modal.Header>

         <Modal.Body>
            <FormRow label="Einträge anzeigen">
               <MultiSelect
                  options={options}
                  values={options.filter(o => !eventsToHide.includes(o.value))}
                  onChange={selectedValues =>
                     setEventsToHide(
                        options.filter(o => !selectedValues.includes(o)).map(v => v.value)
                     )
                  }
                  allowAll={false}
                  isClearable={false}
               />
            </FormRow>
         </Modal.Body>

         <Modal.Footer className="bg-light">
            <Button variant="outline-link" onClick={onClose}>
               Schließen
            </Button>
            <LoadingButton variant="primary" onClick={handleSave}>
               Anwenden
            </LoadingButton>
         </Modal.Footer>
      </Modal>
   );
};
