import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import {
   mdiAccountCowboyHat,
   mdiAccountMultiple,
   mdiAccountPlus,
   mdiCalendarMonth,
   mdiChevronLeft,
   mdiDumbbell,
   mdiMapMarker,
   mdiText,
} from '@mdi/js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { LinkContainer } from 'react-router-bootstrap';
import { formatRange, getClockIcon, setTitle } from '../utils';
import { EditClassDialog } from '../dialogs/EditClassDialog';
import { CanceledCoachingClassAlert, MiniProfile } from '../molecules';
import { SkeletonPlaceholder } from '../atoms';
import { CancelRestoreCoachingClassDialog } from '../dialogs/CancelRestoreCoachingClassDialog';
import { CoachingClass } from '../types/api';
import { CoachingClassModel } from '../models';
import { useCache } from '../hooks';

export const EventCoachingClassPage = () => {
   const navigate = useNavigate();
   const { eventId } = useParams();
   const cachedData = useCache();
   const [coachingClass, setCoachingClass] = useState<CoachingClass>();
   const [showEditDialog, setShowEditDialog] = useState(false);
   const [paramIsInvalid, setParamIsInvalid] = useState(false);
   const [showCancelRestoreDialog, setShowCancelRestoreDialog] = useState(false);

   useEffect(() => setTitle('Coaching-Klasse'), []);

   useEffect(() => {
      (async () => {
         const id = Number(eventId);
         if (!Number.isNaN(id)) {
            try {
               setCoachingClass((await CoachingClassModel.get(id)) ?? undefined);
               return;
            } catch {
               /* ignore */
            }
         }

         toast.error('Die aufgerufene Coaching-Class existiert nicht.');
         setParamIsInvalid(true);
      })();
   }, [eventId]);

   const handleSave = (cc: Partial<CoachingClass>) => {
      setCoachingClass(cc as CoachingClass);
      return Promise.resolve();
   };

   return (
      <Container className="py-2">
         <Row>
            <Col xs={0} md={2} />
            <Col
               xs={12}
               md={8}
               className="d-flex flex-row justify-content-between align-items-center"
            >
               <LinkContainer
                  to={{
                     pathname: '/calendar',
                     search: `?view=day&date=${moment(coachingClass?.start).format('YYYY-MM-DD')}`,
                  }}
               >
                  <Button variant="outline-link" className="d-flex align-items-center ps-1">
                     <Icon path={mdiChevronLeft} size={1} />
                     Kalender
                  </Button>
               </LinkContainer>
               {!paramIsInvalid && (
                  <LinkContainer
                     to={`/representationRequest/create/${moment(coachingClass?.start).format(
                        'YYYY-MM-DD'
                     )}/${coachingClass?.id}`}
                  >
                     <Button variant="info">Vertretung anfordern</Button>
                  </LinkContainer>
               )}
            </Col>
            <Col xs={0} md={2} />
         </Row>
         <Row>
            <Col xs={0} md={2} />
            <Col xs={12} md={8}>
               <ListGroup className="floating-panel">
                  <ListGroup.Item className="bg-secondary text-white">
                     <h2 className="mb-0">Coaching-Klasse</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                     {paramIsInvalid && (
                        <div className="d-flex justify-content-center">
                           <em>Coaching-Class existiert nicht.</em>
                        </div>
                     )}
                     {!coachingClass && !paramIsInvalid && (
                        <Row>
                           <Col xs={12} lg={6}>
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="6rem" />
                           </Col>
                           <Col xs={12} lg={6}>
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="2rem" />
                              <SkeletonPlaceholder width="100%" height="6rem" />
                           </Col>
                        </Row>
                     )}
                     {coachingClass && !paramIsInvalid && (
                        <>
                           <CanceledCoachingClassAlert coachingClass={coachingClass} />
                           <Row>
                              <Col xs={12} lg={6}>
                                 <FieldRow
                                    icon={mdiDumbbell}
                                    value={
                                       cachedData.classTypes.find(
                                          ct => ct.id === coachingClass?.type_id
                                       )?.name
                                    }
                                 />
                                 <FieldRow
                                    icon={mdiMapMarker}
                                    value={
                                       cachedData.rooms.find(r => r.id === coachingClass?.room_id)
                                          ?.name
                                    }
                                 />
                                 <FieldRow
                                    icon={mdiCalendarMonth}
                                    value={moment(coachingClass?.start).format('L')}
                                 />
                                 <FieldRow
                                    icon={getClockIcon(coachingClass.start)}
                                    value={`${formatRange(
                                       coachingClass?.start,
                                       coachingClass?.end,
                                       'simple-date-with-time'
                                    )}`}
                                 />
                                 <FieldRow icon={mdiText}>
                                    <Form.Control
                                       as="textarea"
                                       className="bg-white"
                                       rows={3}
                                       value={coachingClass?.comment ?? ''}
                                       readOnly
                                    />
                                 </FieldRow>
                              </Col>
                              <Col xs={12} lg={6}>
                                 {coachingClass?.coach_id ? (
                                    <FieldRow icon={mdiAccountCowboyHat}>
                                       <MiniProfile
                                          className="form-control"
                                          user={coachingClass?.coach_id}
                                       />
                                    </FieldRow>
                                 ) : (
                                    <Alert variant="danger" className="w-100 mb-0 mt-2 p-2">
                                       Kein Coach!
                                    </Alert>
                                 )}
                                 {coachingClass?.coach_second_id && (
                                    <FieldRow icon={mdiAccountPlus}>
                                       <MiniProfile
                                          className="form-control"
                                          user={coachingClass?.coach_second_id}
                                       />
                                    </FieldRow>
                                 )}
                                 {(coachingClass?.coach_other?.length ?? 0) > 0 && (
                                    <FieldRow icon={mdiAccountMultiple}>
                                       <div className="form-control h-auto d-flex flex-column">
                                          {coachingClass?.coach_other.map(co => (
                                             <div key={co} className="my-1">
                                                <MiniProfile user={co} />
                                             </div>
                                          ))}
                                       </div>
                                    </FieldRow>
                                 )}
                              </Col>
                           </Row>
                        </>
                     )}
                  </ListGroup.Item>
                  {!paramIsInvalid && (
                     <ListGroup.Item className="bg-light d-flex justify-content-between">
                        <Button
                           variant={coachingClass?.canceled ? 'outline-success' : 'outline-danger'}
                           onClick={() => setShowCancelRestoreDialog(true)}
                        >
                           {coachingClass?.canceled ? 'Reaktivieren' : 'Klasse absagen'}
                        </Button>
                        <CancelRestoreCoachingClassDialog
                           show={showCancelRestoreDialog}
                           coachingClass={coachingClass}
                           onClose={() => setShowCancelRestoreDialog(false)}
                           afterSave={cc => setCoachingClass(cc)}
                        />
                        <Button variant="primary" onClick={() => setShowEditDialog(true)}>
                           Bearbeiten
                        </Button>
                     </ListGroup.Item>
                  )}
               </ListGroup>
               <EditClassDialog
                  coachingClass={coachingClass ?? {}}
                  show={showEditDialog}
                  onClose={() => setShowEditDialog(false)}
                  afterSave={handleSave}
                  afterDelete={() => {
                     navigate('/calendar');
                     return Promise.resolve();
                  }}
               />
            </Col>
            <Col xs={0} md={2} />
         </Row>
      </Container>
   );
};

interface FieldRowProps {
   icon?: string;
   value?: string;
   children?: React.ReactNode;
}

// TODO: Code doppelt
const FieldRow = (props: FieldRowProps) => (
   <Row className="my-2 align-items-center">
      <Col>
         <InputGroup>
            {props.icon && (
               <InputGroup.Text>
                  <Icon path={props.icon} size={1} />
               </InputGroup.Text>
            )}
            {props.children || (
               <Form.Control className="bg-white" type="text" value={props.value} readOnly />
            )}
         </InputGroup>
      </Col>
   </Row>
);
