import { Moment } from 'moment';
import {
   COPY_WEEK,
   FORCE_SCHEDULE_PAGE_RELOAD,
   REMOVE_COACHING_BACKUP,
   REMOVE_SCHEDULE_ENTRIES_IN_WEEK,
   SET_COACHING_BACKUPS,
   SET_SCHEDULE_ENTRIES_PER_WEEK,
   SET_SELECTED_DATE,
   UPDATE_SCHEDULE_PAGE_SETTINGS,
   UPSERT_COACHING_BACKUP,
   UPSERT_SCHEDULE_ENTRY_IN_WEEK,
} from '../reducers/scheduleReducer';
import { CoachingScheduleEntry } from '../../types/CoachingScheduleEntry';
import { SchedulePageSettings } from '../../types/SchedulePageSettings';
import { CoachingBackup } from '../../types/api/CoachingBackup';

export const setSelectedDate = (date: string) => ({
   type: SET_SELECTED_DATE,
   data: date,
});

export const copyWeek = (week: string | null) => ({
   type: COPY_WEEK,
   data: week,
});

export const setScheduleEntriesPerWeek = (week: Moment, entries: CoachingScheduleEntry[]) => ({
   type: SET_SCHEDULE_ENTRIES_PER_WEEK,
   data: {
      week: week.startOf('week').format('YYYY-MM-DD'),
      entries: entries,
   },
});

export const upsertScheduleEntryInWeek = (week: Moment, entry: CoachingScheduleEntry) => ({
   type: UPSERT_SCHEDULE_ENTRY_IN_WEEK,
   data: {
      week: week.startOf('week').format('YYYY-MM-DD'),
      entry: entry,
   },
});

export const removeScheduleEntryInWeek = (
   week: Moment,
   entry: Pick<CoachingScheduleEntry, 'id' | 'type'>
) => ({
   type: REMOVE_SCHEDULE_ENTRIES_IN_WEEK,
   data: {
      week: week.startOf('week').format('YYYY-MM-DD'),
      entry: entry,
   },
});

export const updateSchedulePageSettings = (settings: Partial<SchedulePageSettings>) => ({
   type: UPDATE_SCHEDULE_PAGE_SETTINGS,
   data: settings,
});

export const forceSchedulePageReload = () => ({
   type: FORCE_SCHEDULE_PAGE_RELOAD,
});

export const setCoachingBackups = (coachingBackups: CoachingBackup[]) => ({
   type: SET_COACHING_BACKUPS,
   data: coachingBackups,
});

export const upsertCoachingBackup = (coachingBackup: CoachingBackup) => ({
   type: UPSERT_COACHING_BACKUP,
   data: coachingBackup,
});

export const removeCoachingBackup = (coachingBackup: Partial<CoachingBackup>) => ({
   type: REMOVE_COACHING_BACKUP,
   data: coachingBackup,
});
