import { Alert, Card, Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SkeletonPlaceholder } from '../../atoms';
import ApiService from '../../services/ApiService';
import { CoachingHoursProgressBar, VacationProgressBar } from '../../molecules';
import { CoachStatsModel } from '../../types/CoachStatsModel';
import { IApiResponse } from '../../types/IApiResponse';
import { useCache, useSession } from '../../hooks';

export const CoachingStatsWidget = () => {
   const { sessionUser } = useSession();
   const { box } = useCache();
   const [stats, setStats] = useState<CoachStatsModel>();

   useEffect(() => {
      (async () => {
         if (!sessionUser) return;

         try {
            const result = await ApiService.http.get<IApiResponse<CoachStatsModel>>(
               `stats/user/${sessionUser.id}?month=${moment().format('YYYY-MM')}`
            );
            setStats(result.data.data[0]);
         } catch (error) {
            ApiService.handleError(error);
            return Promise.reject(error);
         }
      })();
   }, [sessionUser]);

   return (
      <div>
         <h5>Statistiken</h5>
         <Row>
            <Col>
               {(stats?.vacationExpiring ?? 0) > 0 && (
                  <Alert variant="danger">
                     {(stats?.vacationExpiring ?? 0) === 1 ? (
                        <>
                           Du hast noch <strong>1</strong> Urlaubstag, welcher ab dem{' '}
                           <strong>
                              {moment(`${moment().year()}-01-01`)
                                 .add((box?.vacation_forfeiture_until ?? 0) + 1, 'day')
                                 .format('L')}
                           </strong>{' '}
                           verfällt!
                        </>
                     ) : (
                        <>
                           Du hast noch <strong>{stats?.vacationExpiring}</strong> Urlaubstage,
                           welche ab dem{' '}
                           <strong>
                              {moment(`${moment().year()}-01-01`)
                                 .add(box?.vacation_forfeiture_until ?? 0, 'day')
                                 .format('L')}
                           </strong>{' '}
                           verfallen!
                        </>
                     )}
                  </Alert>
               )}
            </Col>
         </Row>
         <Row>
            <Col xs={6} md={12}>
               <Card className="mb-2">
                  <Card.Body className="pt-2">
                     <h6 className="text-muted">{`Coaching ${
                        moment.months()[moment().month()]
                     }`}</h6>
                     {!stats ? (
                        <SkeletonPlaceholder width="100%" height="1rem" />
                     ) : (
                        <CoachingHoursProgressBar
                           done={stats.classesMonthDone}
                           all={stats.classesMonthAll}
                           id={`${sessionUser?.id ?? 0}`}
                        />
                     )}
                  </Card.Body>
               </Card>
            </Col>
            <Col xs={6} md={12}>
               <Card>
                  <Card.Body className="pt-2">
                     <h6 className="text-muted">{`Urlaub ${moment().year()}`}</h6>
                     {!stats ? (
                        <SkeletonPlaceholder width="100%" height="1rem" />
                     ) : (
                        <VacationProgressBar
                           daysUsed={stats.vacationUsed}
                           daysPlanned={stats.vacationPlanned}
                           daysRemaining={stats.vacationAvailable}
                           id={`${sessionUser?.id ?? 0}`}
                        />
                     )}
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </div>
   );
};
