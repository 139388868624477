import React from 'react';
import { FormRow } from './FormRow';
import { FormFieldError } from './FormFieldError';
import { FormikControl } from '../types/FormikControl';

export interface ControlProps<T> extends FormikControl<T> {
   /**
    * The label for the form control.
    */
   label?: string;
   /**
    * An optional description for the form control.
    */
   description?: string;
   /**
    * When `true` any available validation errors will be hidden.
    */
   hideValidationErrors?: boolean;
   /**
    * Determine the display type. Default 'compact'.
    *
    * `compact`: The control utilized the whole width and the label is above the control.
    * `responsive`: When there is enough space, the label will be left to the control. Otherwise, the 'compact' type will be used.
    */
   type?: 'compact' | 'responsive';
}

export interface GenericControlProps<T> extends ControlProps<T> {
   /**
    * The form control.
    */
   children: React.ReactNode;
}

/**
 * Renders a responsive form control with a label and an optional description.
 * Additional any available validation errors will be displayed when enabled.
 */
export const GenericControl = <T,>({
   formik,
   name,
   label,
   description,
   children,
   hideValidationErrors = false,
   type,
}: GenericControlProps<T>) => (
   <FormRow label={label} description={description} type={type}>
      {children}
      {!hideValidationErrors && <FormFieldError name={name} formik={formik} />}
   </FormRow>
);
