import React, { useEffect, useState } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { isImpersonateModeEnabled } from '../utils';
import { UserModel } from '../models';
import { useSession } from '../hooks';
import { RootState } from '../store';

/**
 * Creates a sign-in button for Google accounts.
 */
export const GoogleSignInButton = () => {
   const { signIn, signOut } = useSession();
   const loginState = useSelector((s: RootState) => s.app.session.loginState);
   const [loginViaCookieFailed, setLoginViaCookieFailed] = useState(false);

   // Versucht ein Login mit vorhandenem Session-Cookie
   useEffect(() => {
      (async () => {
         if (loginState === 'unknown') {
            try {
               // Wir versuchen, ohne Token über Session-Cookie ein Login durchzuführen
               const loggedInUser = await UserModel.authenticate();
               await signIn(loggedInUser);
            } catch {
               setLoginViaCookieFailed(true);
               await signOut();
            }
         }
      })();
   }, [loginState, signIn, signOut]);

   const handleLogin = async (credentialResponse: CredentialResponse) => {
      const { credential } = credentialResponse;
      if (credential) {
         try {
            const loggedInUser = isImpersonateModeEnabled()
               ? await UserModel.authenticate()
               : await UserModel.authenticate(credential);
            await signIn(loggedInUser);
         } catch (error) {
            toast.error('Es gab ein Fehler beim Anmelden. Bitte versuche es später erneut.');
            await signOut();
         }
      }
   };

   const handleLoginFailure = () => {
      toast.error('Es gab ein Fehler beim Anmelden. Bitte versuche es später erneut.');
   };

   return (
      <GoogleLogin
         data-testid="GoogleLogin"
         text="signin"
         onSuccess={handleLogin}
         onError={handleLoginFailure}
         auto_select
         useOneTap={loginViaCookieFailed && loginState !== 'loggedOut'}
      />
   );
};
