import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { CountryModel } from '../../models';
import { getSelectStyles } from '../../utils';

interface Props {
   value: number;
   onChange: (value: number) => void;
   onBlur: () => void;
   isInvalid?: boolean;
   disabled?: boolean;
}

export const CountrySelect = (props: Props) => {
   const [countries, setCountries] = useState<{ label: string; value: string }[]>([]);
   const [selectedCountry, setSelectedCountry] = useState<{ label: string; value: string }>();

   useEffect(() => {
      (async () => {
         setCountries((await CountryModel.list()).map(c => ({ label: c.name, value: `${c.id}` })));
      })();
   }, []);

   useEffect(() => {
      setSelectedCountry(countries.find(c => c.value === `${props.value}`));
   }, [props.value, countries]);

   return (
      <Select
         key={selectedCountry?.value ?? ''}
         options={countries}
         placeholder="Land auswählen…"
         styles={getSelectStyles(props.isInvalid)}
         defaultValue={countries.find(c => c.value === selectedCountry?.value ?? '')}
         onChange={value => props.onChange(Number(value?.value))}
         onBlur={props.onBlur}
         isClearable
         isDisabled={props.disabled}
      />
   );
};
