import axios, { AxiosError, AxiosInstance } from 'axios';
import moment from 'moment';
import store from '../store';
import { setApiError } from '../store/actions/appActions';
import { IApiResponse } from '../types/IApiResponse';
import { API_ENDPOINT } from '../utils/config';
import { SingleApiObject } from '../types/api';

class ApiService {
   public http: AxiosInstance;

   constructor(endpoint: string) {
      this.http = axios.create({
         baseURL: endpoint,
         withCredentials: true,
      });
      this.http.interceptors.response.use(originalResponse => {
         this.handleDates(originalResponse.data);
         return originalResponse;
      });
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   private handleDates(body: any) {
      if (body === null || body === undefined || typeof body !== 'object') return body;

      Object.keys(body).forEach(key => {
         const value = body[key];
         const isoDate = moment.utc(value, moment.ISO_8601);
         if (typeof value === 'string' && isoDate.isValid()) {
            body[key] = isoDate.toDate();
         } else if (typeof value === 'object') {
            this.handleDates(value);
         }
      });
   }

   public handleError(err: any) {
      const error = err as AxiosError;
      if (error.response?.status === 404) {
         // 404-Fehler müssen von der Page behandelt werden
         return;
      }

      if (error.response?.status === 401) {
         // alert(JSON.stringify(error.response));
         // Session ist abgelaufen, also refreshen wir die Seite
         window.location.reload();
      } else {
         store.dispatch(setApiError(error));
      }
   }

   public async list<T extends { [key: string]: any }>(
      model: string,
      filter?: Partial<T>
   ): Promise<T[]> {
      try {
         let queryString = '';
         if (filter)
            queryString = Object.keys(filter)
               .map(f => `${f}=${encodeURIComponent(filter[f] ?? '')}`)
               .join('&');

         const response = await this.http.get<IApiResponse<T>>(
            `data/${model}${queryString ? `?${queryString}` : ''}`
         );

         return response.data.data;
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async count<T extends { [key: string]: any }>(
      model: string,
      filter?: Partial<T>
   ): Promise<number> {
      try {
         let queryString = '';
         if (filter)
            queryString = Object.keys(filter)
               .map(f => `${f}=${encodeURIComponent(filter[f] ?? '')}`)
               .join('&');

         const response = await this.http.get<IApiResponse<number>>(
            `data/${model}/count${queryString ? `?${queryString}` : ''}`
         );

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async get<T>(model: string, id: number): Promise<T | null> {
      try {
         const response = await this.http.get<IApiResponse<T>>(`data/${model}/${id}`);

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async insert<T>(model: string, obj: Partial<T>): Promise<T> {
      try {
         const response = await this.http.post<IApiResponse<T>>(`data/${model}`, obj);

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async update<T extends SingleApiObject>(model: string, obj: Partial<T>): Promise<T> {
      try {
         const response = await this.http.put<IApiResponse<T>>(`data/${model}/${obj.id}`, obj);

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async delete<T>(model: string, id: number): Promise<void> {
      try {
         await this.http.delete<IApiResponse<T>>(`data/${model}/${id}`);
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }
}

export default new ApiService(API_ENDPOINT);
