import moment, { Moment } from 'moment';
import { BaseModel, TimeBasedObjectModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';

import { BoxClosingTime } from '../types/api';

class BoxClosingTimeModelClass
   extends BaseModel<BoxClosingTime>
   implements TimeBasedObjectModel<BoxClosingTime>
{
   public getName(): string {
      return 'box_closing_time';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<BoxClosingTime[]> {
      return ModelExtension.listTimeRange<BoxClosingTime>(this.getName(), from, to);
   }

   public async listPerWeek(startOfWeek: Moment): Promise<BoxClosingTime[]> {
      return ModelExtension.listPerWeek(this, startOfWeek);
   }

   public async copyWeek(
      srcWeek: Moment,
      destWeek: Moment,
      skipDuplicates: boolean
   ): Promise<BoxClosingTime[]> {
      return ModelExtension.copyWeek(BoxClosingTimeModel, srcWeek, destWeek, skipDuplicates);
   }

   public override isDoublet(a: BoxClosingTime, b: BoxClosingTime): boolean {
      return moment(a.start).isSame(b.start, 'second') && moment(a.end).isSame(b.end, 'second');
   }
}
export const BoxClosingTimeModel = new BoxClosingTimeModelClass();
