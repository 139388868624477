import DatePicker from 'react-datepicker';
import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { FormUtils } from '../FormUtils';

interface Props<T> {
   formik: Pick<
      FormikProps<T>,
      | 'errors'
      | 'isValid'
      | 'touched'
      | 'values'
      | 'handleChange'
      | 'handleBlur'
      | 'isSubmitting'
      | 'setFieldValue'
      | 'submitCount'
   >;
   nameStart: keyof T;
   nameEnd: keyof T;
   disabled?: boolean;
}
export const DateRangePicker = <T,>({ formik, nameStart, nameEnd, disabled }: Props<T>) => {
   const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
      formik.values[nameStart] as unknown as Date,
      formik.values[nameEnd] as unknown as Date,
   ]);
   const [startDate, endDate] = dateRange;

   return (
      <Form.Control
         as={DatePicker}
         className="form-control"
         wrapperClassName="w-100"
         selectsRange
         startDate={startDate}
         endDate={endDate}
         onChange={update => {
            const value = update as unknown as [Date | null, Date | null];
            setDateRange(value);
            formik.setFieldValue(nameStart as string, value[0]);
            formik.setFieldValue(nameEnd as string, value[1]);
         }}
         onChangeRaw={(e: any) => {
            const inputVal = e.target.value as string;
            if (!inputVal) return;

            const [start, end] = inputVal.split('-');
            if (start && end) {
               const value = [
                  moment(start.trim(), 'DD.MM.YYYY').toDate(),
                  moment(end.trim(), 'DD.MM.YYYY').toDate(),
               ];
               setDateRange([value[0], value[1]]);
               formik.setFieldValue(nameStart as string, value[0]);
               formik.setFieldValue(nameEnd as string, value[1]);
            }
         }}
         dateFormat="dd.MM.yyyy"
         locale="de"
         calendarStartDay={1}
         showWeekNumbers
         popperPlacement="bottom"
         placeholderText="__.__.____ - __.__.____"
         onBlur={() => {
            formik.handleBlur(nameStart);
            formik.handleBlur(nameEnd);
         }}
         isInvalid={FormUtils.isInvalid(formik, nameStart) || FormUtils.isInvalid(formik, nameEnd)}
         disabled={formik.isSubmitting || disabled}
      />
   );
};
