import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import {
   mdiAccount,
   mdiDotsVerticalCircleOutline,
   mdiPencilOutline,
   mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import { EditClassDialog } from '../../dialogs/EditClassDialog';
import { Colors, formatRange } from '../../utils';
import {
   removeScheduleEntryInWeek,
   upsertScheduleEntryInWeek,
} from '../../store/actions/scheduleActions';
import { MiniProfile } from '../../molecules';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import { SelectCoachDialog } from '../../dialogs/SelectCoachDialog';
import { removeCoachingClass, upsertCoachingClass } from '../../store/actions/appActions';
import { ClassType, CoachingClass, Room } from '../../types/api';
import { CoachingClassModel } from '../../models';
import { useCache } from '../../hooks';
import { CoachingScheduleEntry } from '../../types/CoachingScheduleEntry';

type Props = {
   week: string;
   entry: CoachingScheduleEntry;
};
export const CoachingClassCard = ({ week, entry }: Props) => {
   const dispatch = useDispatch();
   const cachedData = useCache();
   const [classType, setClassType] = useState<ClassType | null>();
   const [room, setRoom] = useState<Room | null>();
   const [showDialog, setShowDialog] = useState(false);
   const [showSelectCoachDialog, setShowSelectCoachDialog] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [coaches, setCoaches] = useState<number[]>();
   const coachSelectMode = useRef<'1st' | '2nd'>();

   useEffect(() => {
      if (entry.type === 'CoachingClass') {
         const cc = entry.obj as CoachingClass;
         setClassType(cachedData.classTypes.find(ct => ct.id === cc.type_id));
         setRoom(cachedData.rooms.find(r => r.id === cc.room_id));

         setCoaches([
            ...(cc.coach_id ? [cc.coach_id] : []),
            ...(cc.coach_second_id ? [cc.coach_second_id] : []),
            ...cc.coach_other,
         ]);
      }
   }, [entry, cachedData]);

   const handleAfterSave = (coachingClass: CoachingClass): Promise<void> => {
      dispatch(
         upsertScheduleEntryInWeek(moment(week), {
            id: coachingClass.id,
            type: 'CoachingClass',
            obj: coachingClass,
            start: coachingClass.start,
         })
      );
      return Promise.resolve();
   };

   const handleSelectCoach = async (coachId: number) => {
      if (entry.type !== 'CoachingClass') return;

      const cc = entry.obj as CoachingClass;

      switch (coachSelectMode.current) {
         case '1st':
            cc.coach_id = coachId;
            break;
         case '2nd':
            cc.coach_second_id = coachId;
            break;
         default:
            return;
      }

      const updatedClass = await CoachingClassModel.update(cc);
      dispatch(upsertCoachingClass(updatedClass));
      dispatch(
         upsertScheduleEntryInWeek(moment(week), {
            id: updatedClass.id,
            type: 'CoachingClass',
            obj: updatedClass,
            start: updatedClass.start,
         })
      );
      setShowSelectCoachDialog(false);
      const coach = cachedData.users.find(u => u.id === coachId);
      toast.success(
         <>
            <strong>
               {coach?.first_name} {coach?.last_name}
            </strong>
            {' wurde der Klasse '}
            <strong>{cachedData.classTypes.find(ct => ct.id === cc.type_id)?.name}</strong>
            {' am '}
            <strong>{moment(cc.start).format('L [um] LTS')}</strong>
            {' zugeordnet.'}
         </>
      );
   };

   const handleDelete = async () => {
      try {
         const cc = entry.obj as CoachingClass;
         await CoachingClassModel.delete(cc);

         dispatch(removeCoachingClass(cc));
         dispatch(removeScheduleEntryInWeek(moment(week), entry));

         setShowDeleteDialog(false);
      } catch {
         /* ignore */
      }
   };

   if (entry.type !== 'CoachingClass') return null;

   const cc = entry.obj as CoachingClass;
   return (
      <div className="d-flex flex-row align-items-center">
         <div
            className="coaching-class-card d-flex"
            style={{
               borderLeftColor: classType?.color,
               borderLeftStyle: 'solid',
               borderLeftWidth: '0.5rem',
               ...(!cc.coach_id ? { backgroundColor: `${Colors.danger}22` } : {}),
            }}
         >
            <span className="flex-fill">
               <div className="d-flex flex-wrap small">
                  <span className="">{`${moment(cc.start).format('LT')} - ${moment(cc.end).format(
                     'LT'
                  )}`}</span>
                  <span className="mx-1">·</span>
                  <span className="fst-italic">{room?.name}</span>
               </div>
               <div className="small fw-bold mb-0">{classType?.name}</div>
               <span className="small coaches-list gap-1">
                  {coaches && coaches.length === 0 ? (
                     <Badge bg="warning" className="d-flex align-items-center">
                        kein Coach
                     </Badge>
                  ) : (
                     coaches?.map(c => <MiniProfile key={c} user={c} badgeVariant="secondary" />)
                  )}
               </span>
            </span>
            <span>
               <Dropdown>
                  <Dropdown.Toggle variant="link" className="no-caret p-0">
                     <Icon path={mdiDotsVerticalCircleOutline} size={1} color={Colors.secondary} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => setShowDialog(true)}
                     >
                        <Icon
                           path={mdiPencilOutline}
                           size={0.85}
                           color={Colors.secondary}
                           className="me-2"
                        />
                        Bearbeiten
                     </Dropdown.Item>
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => {
                           coachSelectMode.current = '1st';
                           setShowSelectCoachDialog(true);
                        }}
                     >
                        <Icon
                           path={mdiAccount}
                           size={0.85}
                           color={Colors.secondary}
                           className="me-2"
                        />
                        Ersten Coach zuweisen
                     </Dropdown.Item>
                     <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={() => {
                           coachSelectMode.current = '2nd';
                           setShowSelectCoachDialog(true);
                        }}
                     >
                        <Icon
                           path={mdiAccount}
                           size={0.85}
                           color={Colors.secondary}
                           className="me-2"
                        />
                        Zweiten Coach zuweisen
                     </Dropdown.Item>
                     <Dropdown.Divider />
                     <Dropdown.Item
                        className="d-flex align-items-center text-danger"
                        onClick={() => setShowDeleteDialog(true)}
                     >
                        <Icon path={mdiTrashCanOutline} size={0.85} className="me-2" />
                        Klasse löschen
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            </span>
         </div>
         <EditClassDialog
            coachingClass={cc}
            show={showDialog}
            onClose={() => setShowDialog(false)}
            afterSave={handleAfterSave}
         />

         <SelectCoachDialog
            show={showSelectCoachDialog}
            onClose={() => setShowSelectCoachDialog(false)}
            coachingClass={cc}
            onSelectCoach={handleSelectCoach}
         />
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest du die Klasse{' '}
               <strong>{cachedData.classTypes.find(c => c.id === cc.type_id)?.name}</strong> vom{' '}
               <strong>{formatRange(cc?.start, cc?.end, 'full-date-with-time')}</strong> wirklich
               löschen?
            </span>
         </ConfirmDeleteDialog>
      </div>
   );
};
