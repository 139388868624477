/**
 * Google Auth Client ID.
 */
export const CLIENT_ID = '709484308137-svf41gnm6tbmcnegacbn2m32qnpviuuj.apps.googleusercontent.com';

export const API_ENDPOINT =
   process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001/v1'
      : 'https://api.coaching-zeitplaner.de/v1';

export const RECAPTCHA_KEY =
   process.env.NODE_ENV === 'development'
      ? '6Lfx0eIcAAAAADGdik5965n8pvBH_wFnNGdZ_wdl'
      : '6Ld5vOIcAAAAAGlQMakg4hhd8KnsUutkNfr_A9dI';
