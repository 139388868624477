import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiTrashCanOutline } from '@mdi/js';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { Formik, FormikErrors } from 'formik';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import ApiService from '../../services/ApiService';
import { getSelectStyles } from '../../utils';
import { Headline, LoadingButton, MiniProfile, ShowIfRole } from '../../molecules';
import { SkeletonPlaceholder } from '../../atoms';
import { SelectMonthControl } from '../../forms/controls';
import { RootState } from '../../store';
import { ConfirmDeleteDialog } from '../../dialogs/ConfirmDeleteDialog';
import {
   PTClient,
   PTProvidedService,
   ROLE_ACCOUNTANT,
   ROLE_BOX_OWNER,
   ROLE_PERSONAL_TRAINER,
} from '../../types/api';
import { IApiResponse } from '../../types/IApiResponse';
import { useCache } from '../../hooks';
import { FormUtils, GenericControl, SubmitButton } from '../../forms';

interface PTAccountingEntry {
   id: number;
   type: 'class' | 'service';
   date: Date;
   coach: string;
   client_id: number;
   service: string;
   amount: number;
   deleted: boolean;
   delete_reason: string | null;
}

export const AccountingPersonalTrainings = () => {
   const cachedData = useCache();
   const [selectedMonth, setSelectedMonth] = useState(moment());
   const [selectedCoachId, setSelectedCoachId] = useState(0);
   const [entries, setEntries] = useState<PTAccountingEntry[]>();
   const [showAddDialog, setShowAddDialog] = useState(false);
   const [entryToDelete, setEntryToDelete] = useState<PTAccountingEntry>();
   const [clients, setClients] = useState<PTClient[]>([]);

   useEffect(() => {
      (async () => {
         try {
            const response = await ApiService.http.get<IApiResponse<PTClient>>(
               'accounting/pt/clients'
            );
            setClients(response.data.data);
         } catch (error) {
            ApiService.handleError(error);
            return Promise.reject(error);
         }
      })();
   }, []);

   const loadData = useCallback(async () => {
      setEntries(undefined);

      try {
         const queryParams: string[] = [];
         queryParams.push(`month=${selectedMonth.format('YYYY-MM')}`);
         if (selectedCoachId) queryParams.push(`coach_id=${selectedCoachId}`);

         const response = await ApiService.http.get<IApiResponse<PTAccountingEntry>>(
            `accounting/pt${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`
         );
         setEntries(response.data.data);
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }, [selectedMonth, selectedCoachId]);

   useEffect(() => {
      (async () => {
         await loadData();
      })();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      (async () => loadData())();
   }, [loadData]);

   const handleDelete = async () => {
      try {
         await ApiService.http.delete(
            `/accounting/pt/provided_service/${entryToDelete?.type}/${entryToDelete?.id}`
         );
         await loadData();
         setEntryToDelete(undefined);
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
      return Promise.resolve();
   };

   return (
      <div className="py-2">
         <Headline title="Abrechnung Personal Trainings">
            Zeigt alle Leistungen, welcher ein Personal Trainer erfasst hat.
         </Headline>
         <ShowIfRole roles={[ROLE_BOX_OWNER, ROLE_ACCOUNTANT]}>
            <Button
               variant="success"
               className="floating-button"
               onClick={() => setShowAddDialog(true)}
            >
               Eintrag hinzufügen
            </Button>
         </ShowIfRole>
         <Row className="mt-3">
            <Col xs={12} lg={6} className="d-flex">
               <SelectMonthControl onChange={m => setSelectedMonth(m)} />
            </Col>
            <Col className="mt-2 mt-lg-0">
               <Select
                  key={selectedCoachId}
                  options={cachedData.users.filter(u => u.roles.includes(ROLE_PERSONAL_TRAINER))}
                  getOptionValue={c => `${c.first_name} ${c.last_name}`}
                  formatOptionLabel={u => <MiniProfile user={u} />}
                  placeholder="Personal Trainer…"
                  defaultValue={cachedData.users.find(c => c.id === selectedCoachId)}
                  onChange={value => setSelectedCoachId(value?.id ?? 0)}
                  isClearable
               />
            </Col>
         </Row>
         <ListGroup className="floating-panel">
            <ListGroup.Item className="mb-0 d-flex align-items-center bg-secondary text-white">
               <Row className="d-none d-sm-flex flex-fill">
                  <Col xs={12} sm={3} md={2}>
                     Datum
                  </Col>
                  <Col xs={6} sm={2} md={3}>
                     Coach
                  </Col>
                  <Col xs={6} sm={2} md={2}>
                     Kunde
                  </Col>
                  <Col xs={12} sm={5} md={5}>
                     Dienstleistung
                  </Col>
               </Row>
               <Row className="d-flex flex-fill d-sm-none">
                  <Col>Dienstleistungen</Col>
               </Row>
               <div style={{ minWidth: '3rem' }} />
            </ListGroup.Item>
            {!entries
               ? [...Array(6).keys()].map(i => (
                    <ListGroup.Item key={i} className="d-flex align-items-center">
                       <Row className="flex-fill">
                          <Col xs={12} sm={3} md={2}>
                             <SkeletonPlaceholder width="5rem" />
                          </Col>
                          <Col xs={6} sm={2} md={3}>
                             <SkeletonPlaceholder width="4rem" />
                          </Col>
                          <Col xs={6} sm={2} md={2}>
                             <SkeletonPlaceholder width="4rem" />
                          </Col>
                          <Col xs={12} sm={5} md={5}>
                             <SkeletonPlaceholder width="10rem" />
                          </Col>
                       </Row>
                       <div style={{ minWidth: '3rem' }} />
                    </ListGroup.Item>
                 ))
               : entries
                    ?.sort((a, b) => a.date.getTime() - b.date.getTime())
                    .map(e => (
                       <ListGroup.Item
                          key={`${e.type}-${e.id}`}
                          className="d-flex align-items-center"
                       >
                          <Row
                             className={`flex-fill ${
                                e.deleted ? 'text-line-through text-muted' : ''
                             }`}
                          >
                             <Col xs={12} sm={3} md={2} className="align-self-center">
                                {moment(e.date).format('L')}
                             </Col>
                             <Col xs={6} sm={2} md={3} className="align-self-center">
                                {e.coach}
                             </Col>
                             <Col xs={6} sm={2} md={2} className="align-self-center">
                                {clients.find(c => c.id === e.client_id)?.name}
                             </Col>
                             <Col xs={12} sm={5} md={5}>
                                {`${e.amount}x ${e.service}`}

                                {e.deleted && (
                                   <>
                                      <Icon
                                         id="pt-class-deleted"
                                         path={mdiInformationOutline}
                                         size={1}
                                         className="ms-1"
                                      />
                                      <Tooltip
                                         anchorSelect="#pt-class-deleted"
                                         content={e.delete_reason ?? ''}
                                         place="top"
                                      />
                                   </>
                                )}
                             </Col>
                          </Row>
                          <div className="d-flex justify-content-end" style={{ minWidth: '3rem' }}>
                             <LoadingButton
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center"
                                tooltip="Eintrag löschen"
                                onClick={() => setEntryToDelete(e)}
                             >
                                <Icon path={mdiTrashCanOutline} size={0.75} />
                             </LoadingButton>
                          </div>
                       </ListGroup.Item>
                    ))}
         </ListGroup>
         <AddPTServiceDialog
            clients={clients}
            show={showAddDialog}
            onClose={() => setShowAddDialog(false)}
            afterInsert={loadData}
         />
         <ConfirmDeleteDialog
            show={!!entryToDelete}
            onClose={() => setEntryToDelete(undefined)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest du den Eintrag vom{' '}
               <strong>{moment(entryToDelete?.date).format('L')}</strong> von{' '}
               <strong>{entryToDelete?.coach}</strong> wirklich löschen?
            </span>
         </ConfirmDeleteDialog>
      </div>
   );
};

interface AddPTServiceDialogProps {
   show: boolean;
   onClose: () => void;
   afterInsert: () => Promise<void>;
   clients: PTClient[];
}

const AddPTServiceDialog = (props: AddPTServiceDialogProps) => {
   const cachedData = useCache();
   const services = useSelector((s: RootState) => s.pt.services);

   const handleSubmitForm = async (values: Partial<PTProvidedService>) => {
      try {
         await ApiService.http.post<IApiResponse<PTClient>>(
            'accounting/pt/provided_service',
            values
         );
         await props.afterInsert();
         props.onClose();
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   };

   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={{ id: 0, amount: 1 }}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<PTProvidedService>> = {};

               if (!values.user_id) errors.user_id = 'Bitte wähle einen Personal-Trainer aus.';
               if (!values.date) errors.date = 'Bitte gib ein Datum an.';
               if (!values.client_id) errors.client_id = 'Bitte wähle einen Kunden aus.';
               if (!values.amount) errors.amount = 'Bitte gib die Anzahl an.';
               if (!values.service_id)
                  errors.service_id = 'Bitte wähle den geleisteten Service aus.';

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>Neue PT-Leistung hinzufügen</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                     <GenericControl
                        formik={formik}
                        name="user_id"
                        label="Personal-Trainer"
                        description="Der Personal-Trainer, welcher die Leistung erbracht hat."
                     >
                        <Select
                           key={formik.values.user_id}
                           options={cachedData.users.filter(u =>
                              u.roles.includes(ROLE_PERSONAL_TRAINER)
                           )}
                           styles={getSelectStyles(FormUtils.isInvalid(formik, 'user_id'))}
                           getOptionValue={c => `${c.first_name} ${c.last_name}`}
                           formatOptionLabel={u => <MiniProfile user={u} />}
                           placeholder="Personal Trainer…"
                           defaultValue={cachedData.users.find(c => c.id === formik.values.user_id)}
                           onChange={value => {
                              formik.setFieldValue('user_id', value?.id);
                              // Leeren des Kunden, da jeder Coach seine eigenen Kunden hat
                              formik.setFieldValue('client_id', undefined);
                           }}
                           onBlur={() => formik.handleBlur('user_id')}
                           isDisabled={formik.isSubmitting}
                           isClearable
                        />
                     </GenericControl>
                     <GenericControl
                        formik={formik}
                        name="date"
                        label="Datum"
                        description="Das Datum an dem die Leistung erbracht wurde."
                     >
                        <Form.Control
                           as={DatePicker}
                           wrapperClassName="w-100"
                           dateFormat="dd.MM.yyyy"
                           locale="de"
                           calendarStartDay={1}
                           showWeekNumbers
                           placeholderText="__.__.____"
                           selected={formik.values.date}
                           onChange={date => formik.setFieldValue('date', date)}
                           isInvalid={FormUtils.isInvalid(formik, 'date')}
                           onBlur={() => formik.handleBlur('date')}
                        />
                     </GenericControl>
                     <GenericControl
                        formik={formik}
                        name="client_id"
                        label="Kunde"
                        description="Der Kunde, für den die Leistung erbracht wurde."
                     >
                        <Select
                           key={formik.values.client_id}
                           options={props.clients.filter(c => c.coach_id === formik.values.user_id)}
                           className="flex-fill"
                           styles={getSelectStyles(FormUtils.isInvalid(formik, 'client_id'))}
                           getOptionValue={c => c.name}
                           getOptionLabel={c => c.name}
                           placeholder="Kunde…"
                           defaultValue={props.clients.find(c => c.id === formik.values.client_id)}
                           onChange={value => formik.setFieldValue('client_id', value?.id)}
                           onBlur={() => formik.handleBlur('client_id')}
                           isDisabled={formik.isSubmitting}
                        />
                     </GenericControl>

                     <GenericControl
                        formik={formik}
                        name="service_id"
                        label="Dienstleistungen"
                        description="Die Leistung, welche für den Kunden erbracht wurde."
                     >
                        <Select
                           key={formik.values.service_id}
                           options={services}
                           className="w-100"
                           styles={getSelectStyles(FormUtils.isInvalid(formik, 'service_id'))}
                           getOptionValue={c => c.name}
                           getOptionLabel={c => c.name}
                           placeholder="Service…"
                           defaultValue={services.find(c => c.id === formik.values.service_id)}
                           onChange={value => formik.setFieldValue('service_id', value?.id)}
                           onBlur={() => formik.handleBlur('service_id')}
                           isDisabled={formik.isSubmitting}
                        />
                     </GenericControl>
                  </Modal.Body>

                  <Modal.Footer className="bg-light">
                     <Button
                        variant="outline-link"
                        onClick={props.onClose}
                        disabled={formik.isSubmitting}
                     >
                        Abbrechen
                     </Button>
                     <SubmitButton formik={formik}>Hinzufügen</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
