import { CirclePicker } from 'react-color';
import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { pickerColors } from '../../utils';

interface Props {
   size?: 'sm' | 'lg';
   className?: string;
   value?: string;
   onChange?: (hex: string) => void;
   onBlur?: () => void;
   disabled?: boolean;
}

export const ColorInput = (props: Props) => (
   <OverlayTrigger
      trigger="click"
      placement="auto"
      rootClose
      overlay={
         <Popover id="popover-color" style={{ maxWidth: '22rem' }}>
            <Popover.Header as="h3" className="text-center">
               Farbe auswählen
            </Popover.Header>
            <Popover.Body>
               <CirclePicker
                  color={props.value}
                  colors={pickerColors}
                  onChangeComplete={c => props.onChange?.(c.hex)}
                  onChange={() => props.onBlur?.()}
                  circleSize={18}
                  width="100%"
                  styles={{
                     default: {
                        card: {
                           maxWidth: '21rem',
                        },
                     },
                  }}
               />
            </Popover.Body>
         </Popover>
      }
   >
      <Button
         variant="outline-secondary"
         className={`d-flex w-100 ${props.className}`}
         size={props.size}
         disabled={props.disabled}
      >
         <span style={{ backgroundColor: props.value, width: '100%' }}>&nbsp;</span>
      </Button>
   </OverlayTrigger>
);
