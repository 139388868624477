import {
   Alert,
   Button,
   ButtonGroup,
   Container,
   Form,
   InputGroup,
   ListGroup,
   Modal,
} from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { mdiCalendarOutline, mdiContentCopy, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SkeletonPlaceholder } from '../atoms';
import { Headline, LoadingButton } from '../molecules';
import { ConfirmDeleteDialog } from '../dialogs/ConfirmDeleteDialog';
import { EditCalendarDialog } from '../dialogs/EditCalendarDialog';
import { API_ENDPOINT } from '../utils/config';
import { Calendar } from '../types/api';
import { CalendarModel } from '../models';

export const MyCalendarPage = () => {
   const [calendars, setCalendars] = useState<Calendar[]>();
   const [selectedCalendar, setSelectedCalendar] = useState<Calendar>();
   const [showLinkDialog, setShowLinkDialog] = useState(false);
   const [linkWasCopied, setLinkWasCopied] = useState(false);
   const [objToDelete, setObjToDelete] = useState<Calendar>();
   const [calendarToEdit, setCalendarToEdit] = useState<Partial<Calendar>>();

   const loadCalendars = useCallback(async () => {
      const result = await CalendarModel.list();
      setCalendars(result);
   }, []);

   useEffect(() => {
      (async () => {
         await loadCalendars();
      })();
   }, [loadCalendars]);

   const generateCalendarLink = (accessToken?: string): string => {
      if (!accessToken) return '';

      return `${API_ENDPOINT}/calendar/${accessToken}`;
   };

   const handleDelete = async () => {
      if (!objToDelete) return;

      await CalendarModel.delete(objToDelete);
      await loadCalendars();
      setObjToDelete(undefined);
   };

   return (
      <Container className="py-2">
         <Headline title="Meine Kalender-Abos">
            Hier kannst du verschiedene Kalender-Abos anlegen. Anschließend kannst du für den
            konkreten Kalender den Abo-Link auf deinem Handy/Laptop einrichten, sodass die
            Coaching-Termine direkt in deinem Kalender angezeigt werden.
            <br /> <br />
            <Link to="/user/calendar/tutorial">
               Klicke hier für Anleitung zum Einrichten auf dem Handy/Laptop.
            </Link>
         </Headline>
         <Button
            variant="success"
            className="mb-3 floating-button"
            onClick={() => setCalendarToEdit({ id: 0, alarm_before_start: 60 })}
            disabled={!calendars}
         >
            Neuen Kalender hinzufügen
         </Button>
         {calendars && calendars.length === 0 && (
            <div className="fst-italic">
               Du hast noch keinen Kalender. Klicke auf `Neuen Kalender hinzufügen` um deinen ersten
               Kalender anzulegen.
            </div>
         )}
         <ListGroup className="floating-panel">
            {!calendars
               ? [...Array(6).keys()].map(i => (
                    <ListGroup.Item key={i}>
                       <SkeletonPlaceholder width="60%" />
                    </ListGroup.Item>
                 ))
               : calendars.map(c => (
                    <ListGroup.Item
                       key={c.id}
                       className="d-flex align-items-center justify-content-between"
                    >
                       <div>
                          <div>{c.name}</div>
                          <small className="fst-italic">
                             Letzter Abruf:{' '}
                             {c.last_fetch
                                ? moment(c.last_fetch).format('L [um] LTS')
                                : 'kein Abruf bisher'}
                          </small>
                       </div>
                       <ButtonGroup>
                          <LoadingButton
                             variant="outline-primary"
                             size="sm"
                             tooltip="Link zum Kalender anzeigen"
                             onClick={() => {
                                setSelectedCalendar(c);
                                setShowLinkDialog(true);
                             }}
                          >
                             <Icon path={mdiCalendarOutline} size={0.75} />
                          </LoadingButton>
                          <LoadingButton
                             variant="outline-secondary"
                             size="sm"
                             className="d-flex align-items-center"
                             tooltip="Einstellungen"
                             onClick={() => setCalendarToEdit(c)}
                          >
                             <Icon path={mdiPencilOutline} size={0.75} />
                          </LoadingButton>
                          <LoadingButton
                             variant="outline-danger"
                             size="sm"
                             className="d-flex align-items-center"
                             tooltip="Kalender löschen"
                             onClick={() => setObjToDelete(c)}
                          >
                             <Icon path={mdiTrashCanOutline} size={0.75} />
                          </LoadingButton>
                       </ButtonGroup>
                    </ListGroup.Item>
                 ))}
         </ListGroup>
         <Modal
            show={showLinkDialog}
            onHide={() => {
               setShowLinkDialog(false);
               setLinkWasCopied(false);
               setSelectedCalendar(undefined);
            }}
         >
            <Modal.Header closeButton>
               <Modal.Title>Einladungslink</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Alert variant="info">
                  <small>
                     Dieser Link ist ein abonierbarer Kalender für z.B. das Handy.{' '}
                     <Link to="/user/calendar/tutorial">Klicke hier</Link> um eine Anleitung zur
                     Einrichtung des Kalenders zu sehen.
                  </small>
               </Alert>
               <InputGroup>
                  <Form.Control
                     type="text"
                     name="calendar-url"
                     value={generateCalendarLink(selectedCalendar?.access_token)}
                     readOnly
                  />
                  <Button
                     variant="primary"
                     className="d-flex align-items-center"
                     onClick={async () => {
                        await navigator.clipboard.writeText(
                           generateCalendarLink(selectedCalendar?.access_token)
                        );
                        setLinkWasCopied(true);
                     }}
                  >
                     <Icon path={mdiContentCopy} size={0.75} />
                  </Button>
               </InputGroup>
               {linkWasCopied && (
                  <small>Der Link wurde erfolgreich in die Zwischenablage kopiert.</small>
               )}
            </Modal.Body>
            <Modal.Footer className="bg-light">
               <Button
                  variant="secondary"
                  onClick={() => {
                     setShowLinkDialog(false);
                     setLinkWasCopied(false);
                     setSelectedCalendar(undefined);
                  }}
               >
                  Schließen
               </Button>
            </Modal.Footer>
         </Modal>

         <EditCalendarDialog
            calendar={calendarToEdit}
            show={!!calendarToEdit}
            onClose={() => setCalendarToEdit(undefined)}
            afterSave={() => loadCalendars()}
         />

         <ConfirmDeleteDialog
            show={!!objToDelete}
            onClose={() => setObjToDelete(undefined)}
            onDelete={handleDelete}
         >
            <span>
               Möchtest Du den Kalender &apos;<strong>{objToDelete?.name}</strong>&apos; wirklich
               löschen?
            </span>
         </ConfirmDeleteDialog>
      </Container>
   );
};
