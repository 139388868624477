import { Accordion, Col, Form, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiFilterVariant } from '@mdi/js';
import { FloatingPanel } from '../../atoms';
import { ClassTypeMultiSelect, RoomMultiSelect, UserMultiSelect } from '../../forms/controls';

export interface FilterDef {
   users?: number[];
   classTypes?: number[];
   rooms?: number[];
}

interface Props {
   onFilterChange: (filter: FilterDef) => void;
}

export const FilterWidget = ({ onFilterChange }: Props) => {
   const [isOpen, setOpen] = useState(false);
   const [users, setUsers] = useState<number[]>([]);
   const [classTypes, setClassTypes] = useState<number[]>([]);
   const [rooms, setRooms] = useState<number[]>([]);

   const handleFilterChange = (data: Partial<FilterDef>) => {
      onFilterChange({
         users: users,
         classTypes: classTypes,
         rooms: rooms,
         ...data,
      });
   };

   return (
      <FloatingPanel className="p-1">
         <Accordion flush>
            <Accordion.Item eventKey="0">
               <Accordion.Header onClick={() => setOpen(v => !v)}>
                  <Icon path={mdiFilterVariant} size={0.75} className="me-1" />
                  {isOpen ? 'Filter verbergen' : 'Filter anzeigen'}
               </Accordion.Header>
               <Accordion.Body className="p-0">
                  <div className="mt-2">
                     <Row>
                        <Form.Group as={Col} xs={12} md={4} className="mb-3">
                           <Form.Label>Coach</Form.Label>
                           <UserMultiSelect
                              values={users}
                              onChange={v => {
                                 setUsers(v);
                                 handleFilterChange({ users: v });
                              }}
                           />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={4} className="mb-3">
                           <Form.Label>Klasse</Form.Label>
                           <ClassTypeMultiSelect
                              values={classTypes}
                              onChange={v => {
                                 setClassTypes(v);
                                 handleFilterChange({ classTypes: v });
                              }}
                           />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md={4} className="mb-3">
                           <Form.Label>Raum</Form.Label>
                           <RoomMultiSelect
                              values={rooms}
                              onChange={v => {
                                 setRooms(v);
                                 handleFilterChange({ rooms: v });
                              }}
                           />
                        </Form.Group>
                     </Row>
                  </div>
               </Accordion.Body>
            </Accordion.Item>
         </Accordion>
      </FloatingPanel>
   );
};
