import { Formik, FormikErrors } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import React from 'react';
import validator from 'validator';
import { getSelectStyles } from '../utils';
import { ColorInput } from '../forms/controls';
import { CreateUpdateInfo } from '../organisms/CreateUpdateInfo';
import { CheckControl, FormUtils, GenericControl, SubmitButton, TextControl } from '../forms';
import { ROLE_ADMIN, User, UserTypes } from '../types/api';
import { CalendarModel, UserModel } from '../models';
import { useCache, useSession } from '../hooks';

interface Props {
   show: boolean;
   user?: Partial<User>;
   afterSave: (user: User, operation: 'insert' | 'update') => Promise<void>;
   onClose: () => void;
}

export const EditUserDialog = (props: Props) => {
   const cachedData = useCache();
   const { sessionUser } = useSession();

   const handleSubmitForm = async (user: Partial<User>) => {
      let usr: User;
      let operation: 'insert' | 'update';
      if (user.id === 0) {
         operation = 'insert';
         usr = await UserModel.insert(user);
         // Default-Kalender anlegen
         await CalendarModel.insert({
            user_id: usr.id,
            name: 'Meine Coaching-Termine',
            alarm_before_start: 60,
            days_in_past: 14,
            show_all_coaches: false,
            calendar_has_coaches: [usr.id],
            show_all_class_types: true,
            calendar_has_class_types: [],
            show_all_rooms: true,
            calendar_has_rooms: [],
            show_all_pt_coaches: true,
            calendar_has_pt_coaches: [],
         });
      } else {
         operation = 'update';
         usr = await UserModel.update(user);
      }

      await props.afterSave(usr, operation);
      props.onClose();
   };

   if (!props.user) {
      return null;
   }
   return (
      <Modal show={props.show} onHide={props.onClose} size="lg">
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={props.user}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<User>> = {};

               if (values.is_demo) {
                  if (!values.sub) errors.sub = 'Bitte gib einen Account-Namen an.';
                  // Nur bei der Anlage ist das Passwort ein Pflichtfeld
                  if (values.id === 0 && !values.demo_password)
                     errors.demo_password = 'Bitte gib ein Password an.';
               }

               if (!values.email) errors.email = 'Bitte gib eine E-Mail-Adresse an.';
               if (!errors.email && !validator.isEmail(values.email ?? ''))
                  errors.email = 'Das Format der E-Mail-Adresse ist ungültig.';

               if (!values.first_name) errors.first_name = 'Bitte gib einen Vornamen an.';

               if (!values.last_name) errors.last_name = 'Bitte gib einen Nachnamen an.';

               if (!values.absence_color) errors.absence_color = 'Bitte wähle eine Farbe aus.';

               if (!values.type) errors.type = 'Bitte wähle einen Typ aus.';

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     {props.user?.is_demo ? (
                        <Modal.Title>
                           {props.user?.id === 0
                              ? 'Neuer Demo-Benutzer'
                              : 'Demo-Benutzer bearbeiten'}
                        </Modal.Title>
                     ) : (
                        <Modal.Title>
                           {props.user?.id === 0 ? 'Neuer Benutzer' : 'Benutzer bearbeiten'}
                        </Modal.Title>
                     )}
                  </Modal.Header>
                  <Modal.Body>
                     <Row>
                        <Col xs={12} lg={6}>
                           {!formik.values.is_demo ? (
                              <TextControl
                                 inputType="email"
                                 name="email"
                                 label="E-Mail"
                                 description="Die E-Mail-Adresse dient gleichzeitig für den Login über Google."
                                 placeholder="GMail-Adresse…"
                                 disabled={formik.values.is_demo || props.user?.id !== 0}
                                 formik={formik}
                              />
                           ) : (
                              <>
                                 <TextControl
                                    name="sub"
                                    label="Demo-Account-Name"
                                    placeholder="Demo-Account-Name…"
                                    disabled={props.user?.id !== 0}
                                    formik={formik}
                                 />
                                 <TextControl
                                    name="demo_password"
                                    inputType="password"
                                    label="Password"
                                    placeholder="Password…"
                                    disabled={formik.values.is_demo && props.user?.id !== 0}
                                    formik={formik}
                                 />
                              </>
                           )}
                           <Row>
                              <Col xs={6}>
                                 <TextControl
                                    name="first_name"
                                    label="Vorname"
                                    placeholder="Vorname…"
                                    disabled={formik.values.is_demo && props.user?.id !== 0}
                                    formik={formik}
                                 />
                              </Col>
                              <Col xs={6}>
                                 <TextControl
                                    name="last_name"
                                    label="Nachname"
                                    placeholder="Nachname…"
                                    disabled={formik.values.is_demo && props.user?.id !== 0}
                                    formik={formik}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col xs={6}>
                                 <TextControl
                                    name="nickname"
                                    label="Spitzname"
                                    description="Der optionale Spitzname wird an einigen Stellen als Name des Coaches verwendet, um Platz zu sparen."
                                    placeholder="Spitzname…"
                                    disabled={formik.values.is_demo && props.user?.id !== 0}
                                    formik={formik}
                                 />
                              </Col>
                              <Col xs={6}>
                                 <TextControl
                                    name="phone"
                                    label="Telefonnummer"
                                    placeholder="Nummer…"
                                    disabled={formik.values.is_demo && props.user?.id !== 0}
                                    formik={formik}
                                    inputType="tel"
                                 />
                              </Col>
                           </Row>
                           <GenericControl
                              name="roles"
                              label="Rollen"
                              description="Die Berechtigungsrollen aktivieren spezielle Programmfunktionen, welche für die Ausführung der Rollen benötigt werden."
                              formik={formik}
                           >
                              <Select
                                 options={cachedData.roles
                                    .filter(r => {
                                       // Only a logged-in user with role `admin` kann assign role `admin` to other users
                                       if (r.key === ROLE_ADMIN)
                                          return sessionUser?.roles?.includes(ROLE_ADMIN);
                                       return true;
                                    })
                                    .map(r => ({
                                       label: r.name,
                                       value: r.key,
                                    }))}
                                 styles={getSelectStyles(FormUtils.isInvalid(formik, 'roles'))}
                                 value={cachedData.roles
                                    .filter(r => formik.values.roles?.includes(r.key))
                                    .map(r => ({ label: r.name, value: r.key }))}
                                 onChange={roles =>
                                    formik.setFieldValue(
                                       'roles',
                                       roles.map(r => r.value)
                                    )
                                 }
                                 onBlur={() => formik.handleBlur('roles')}
                                 isDisabled={
                                    formik.isSubmitting ||
                                    (formik.values.is_demo && props.user?.id !== 0)
                                 }
                                 hideSelectedOptions={false}
                                 closeMenuOnSelect={false}
                                 isMulti
                                 isClearable
                              />
                           </GenericControl>
                           <CheckControl
                              name="enabled"
                              label="User ist aktiv"
                              formik={formik}
                              disabled={formik.values.is_demo && props.user?.id !== 0}
                           />
                        </Col>
                        <Col>
                           <GenericControl
                              name="type"
                              label="Anstellung"
                              description="Die Art der Anstellung hat Auswirkungen auf Funktionen der Anwendung."
                              formik={formik}
                           >
                              <Select
                                 key={formik.values.type}
                                 options={UserTypes}
                                 styles={getSelectStyles(FormUtils.isInvalid(formik, 'type'))}
                                 placeholder="Art der Beschäftigung…"
                                 defaultValue={UserTypes.find(t => t.value === formik.values.type)}
                                 onChange={value => {
                                    formik.setFieldValue('type', value?.value);
                                 }}
                                 onBlur={() => formik.handleBlur('type')}
                                 isDisabled={formik.isSubmitting}
                              />
                           </GenericControl>
                           {formik.values.type !== 'freelancer' && (
                              <Row>
                                 <Col xs={6}>
                                    <GenericControl
                                       name="vacation_remaining_last_year"
                                       label="Resturlaub"
                                       description="Anzahl der Urlaubstage, welche im letzten Jahr nicht genommen wurden und somit für das aktuelle Jahr zusätzlich zur Verfügung stehen."
                                       formik={formik}
                                    >
                                       <InputGroup>
                                          <Form.Control
                                             type="number"
                                             name="vacation_remaining_last_year"
                                             placeholder="Resturlaub…"
                                             value={formik.values.vacation_remaining_last_year}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             isInvalid={FormUtils.isInvalid(
                                                formik,
                                                'vacation_remaining_last_year'
                                             )}
                                             disabled={formik.isSubmitting}
                                          />
                                          <InputGroup.Text>Tage</InputGroup.Text>
                                       </InputGroup>
                                    </GenericControl>
                                 </Col>
                                 <Col xs={6}>
                                    <GenericControl
                                       name="vacation_days_current_year"
                                       label="Urlaubstage"
                                       description="Anzahl der Urlaubstage, die im aktuellen Jahr zur Verfügung stehen."
                                       formik={formik}
                                    >
                                       <InputGroup>
                                          <Form.Control
                                             type="number"
                                             name="vacation_days_current_year"
                                             placeholder="Urlaubstage im Jahr…"
                                             value={formik.values.vacation_days_current_year}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             isInvalid={FormUtils.isInvalid(
                                                formik,
                                                'vacation_days_current_year'
                                             )}
                                             disabled={formik.isSubmitting}
                                          />
                                          <InputGroup.Text>Tage</InputGroup.Text>
                                       </InputGroup>
                                    </GenericControl>
                                 </Col>
                              </Row>
                           )}
                           <GenericControl
                              name="monthly_classes_count"
                              label="Stunden pro Monat"
                              description="Gibt an, wie viele Stunden der Coach pro Monat maximal arbeiten soll. Ein Wert von 0 gilt als unbegrenzt."
                              formik={formik}
                           >
                              <InputGroup>
                                 <Form.Control
                                    type="number"
                                    name="monthly_classes_count"
                                    placeholder="mtl. Stunden…"
                                    value={formik.values.monthly_classes_count}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={FormUtils.isInvalid(formik, 'monthly_classes_count')}
                                    disabled={formik.isSubmitting}
                                 />
                                 <InputGroup.Text>Stunden</InputGroup.Text>
                              </InputGroup>
                           </GenericControl>

                           <GenericControl
                              name="absence_color"
                              label="Farbe im Urlaubskalender"
                              description="Die hier angegeben Farbe wird verwendet um die Einträge im gemeinsamen Urlaubskalender einfacher von einander unterscheiden zu können."
                              formik={formik}
                           >
                              <ColorInput
                                 value={formik.values.absence_color}
                                 onChange={c => formik.setFieldValue('absence_color', c)}
                                 onBlur={() => formik.handleBlur('absence_color')}
                              />
                           </GenericControl>
                           <CheckControl
                              name="has_to_coach_every_week"
                              label="Muss min. einmal pro Woche coachen"
                              description="Wenn aktiv, muss ein Coach mindestens eine Stunde pro Woche coachen oder mindestens einen Tag Urlaub in der Woche haben."
                              formik={formik}
                              disabled={formik.values.is_demo && props.user?.id !== 0}
                           />
                        </Col>
                     </Row>
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                     <CreateUpdateInfo hidden={props.user?.id === 0} obj={props.user} />
                     <Button
                        variant="outline-link"
                        onClick={props.onClose}
                        disabled={formik.isSubmitting}
                     >
                        Schließen
                     </Button>
                     <SubmitButton formik={formik}>Speichern</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
