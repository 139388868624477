import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Badge, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiCalendarCheck,
   mdiCancel,
   mdiDotsVerticalCircleOutline,
   mdiPencilOutline,
   mdiSwapHorizontal,
} from '@mdi/js';

import sortBy from 'lodash/sortBy';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { MiniProfile } from '../../molecules';
import { ItemList } from '../../organisms/ItemList';
import { Colors, formatRange } from '../../utils';
import { EditClassDialog } from '../../dialogs/EditClassDialog';
import { CoachingClass, Meeting, PTClass } from '../../types/api';
import { CoachingClassModel, PTClassModel } from '../../models';
import { useCache, useSession } from '../../hooks';
import { MeetingModel } from '../../models/MeetingModel';
import { EditMeetingDialog } from '../../dialogs/EditMeetingDialog';
import { CoachesAvatarList } from '../../organisms/CoachesAvatarList';
import { CancelRestoreCoachingClassDialog } from '../../dialogs/CancelRestoreCoachingClassDialog';
import { EditPTClassDialog } from '../../dialogs/EditPTClassDialog';
import { RootState } from '../../store';

interface PageModel {
   id: number;
   obj: CoachingClass | Meeting | PTClass;
   type: 'CoachingClass' | 'Meeting' | 'PTClass';
   start: Date;
   end: Date;
}

export const MyCoachingClassesWidget = memo(() => {
   const { sessionUser } = useSession();
   const cachedData = useCache();
   const [dbCoachingClasses, setDbCoachingClasses] = useState<CoachingClass[]>([]);
   const [dbMeetings, setDbMeetings] = useState<Meeting[]>([]);
   const [dbPersonalTrainings, setDbPersonalTrainings] = useState<PTClass[]>([]);
   const [myEvents, setMyEvents] = useState<PageModel[]>();

   const coachingClassFilter = (coachId: number) => (cc: CoachingClass) =>
      (cc.coach_id === coachId ||
         cc.coach_second_id === coachId ||
         cc.coach_other.includes(coachId)) &&
      !cc.canceled;
   const meetingFilter = (coachId: number) => (m: Meeting) =>
      m.organizer_id === coachId || m.participants.includes(coachId);

   const ptClassFilter = (coachId: number) => (ptClass: PTClass) => ptClass.coach_id === coachId;

   useEffect(() => {
      (async () => {
         const suid = sessionUser?.id ?? 0;
         const ssu = moment(cachedData.box?.sessions_scheduled_until.getTime()).add(1, 'day'); // Es wird ja der Tagesanfang gespeichert
         // Lade meine Coaching-Klassen
         setDbCoachingClasses(
            (await CoachingClassModel.listTimeRange(moment(), ssu)).filter(
               coachingClassFilter(suid)
            )
         );
         // Laden meiner Meetings
         setDbMeetings((await MeetingModel.listTimeRange(moment())).filter(meetingFilter(suid)));
         setDbPersonalTrainings(
            (await PTClassModel.listTimeRange(moment(), moment().add(1, 'month'))).filter(
               ptClassFilter(suid)
            )
         );
      })();
   }, [sessionUser?.id, cachedData]);

   useEffect(() => {
      const suid = sessionUser?.id ?? 0;

      const events = [
         ...dbCoachingClasses.filter(coachingClassFilter(suid)).map(
            cc =>
               ({
                  id: cc.id,
                  obj: cc,
                  type: 'CoachingClass',
                  start: cc.start,
                  end: cc.end,
               } as PageModel)
         ),
         ...dbMeetings.filter(meetingFilter(suid)).map(
            m =>
               ({
                  id: m.id,
                  obj: m,
                  type: 'Meeting',
                  start: m.start,
                  end: m.end,
               } as PageModel)
         ),
         ...dbPersonalTrainings.filter(ptClassFilter(suid)).map(
            pt =>
               ({
                  id: pt.id,
                  obj: pt,
                  type: 'PTClass',
                  start: pt.start,
                  end: pt.end,
               } as PageModel)
         ),
      ];
      setMyEvents(sortBy(events, 'start'));
   }, [dbCoachingClasses, dbMeetings, dbPersonalTrainings, sessionUser?.id]);

   const handleOnChange = (
      typeOfChange: 'update' | 'delete',
      typeOfEvent: PageModel['type'],
      event: Partial<CoachingClass | Meeting | PTClass>
   ) => {
      switch (typeOfChange) {
         case 'update':
            if (typeOfEvent === 'CoachingClass') {
               setDbCoachingClasses(cc => [
                  ...cc.filter(c => c.id !== event.id),
                  event as CoachingClass,
               ]);
            }

            if (typeOfEvent === 'Meeting') {
               setDbMeetings(m => [...m.filter(o => o.id !== event.id), event as Meeting]);
            }

            if (typeOfEvent === 'PTClass') {
               setDbPersonalTrainings(pt => [
                  ...pt.filter(o => o.id !== event.id),
                  event as PTClass,
               ]);
            }
            break;
         case 'delete':
            if (typeOfEvent === 'CoachingClass')
               setDbCoachingClasses(cc => [...cc.filter(c => c.id !== event.id)]);

            if (typeOfEvent === 'Meeting')
               setDbMeetings(m => [...m.filter(o => o.id !== event.id)]);

            if (typeOfEvent === 'PTClass')
               setDbPersonalTrainings(pt => [...pt.filter(o => o.id !== event.id)]);

            break;
         default:
            break;
      }
   };

   return (
      <ItemList
         header={
            <div className="d-flex flex-fill align-items-center justify-content-between">
               <h4 className="mb-0">Deine Termine</h4>
               <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                     <Tooltip id="tooltip-coaching-plan">
                        Der Coaching-Plan ist bis{' '}
                        <b>{moment(cachedData.box?.sessions_scheduled_until).format('L')}</b>{' '}
                        erstellt.
                     </Tooltip>
                  }
               >
                  <Badge bg="light" className="ms-2 text-dark d-flex align-items-center">
                     <Icon path={mdiCalendarCheck} size={0.85} color={Colors.textBlack} />
                     {moment(cachedData.box?.sessions_scheduled_until).format('L')}
                  </Badge>
               </OverlayTrigger>
            </div>
         }
         emptyMessage="Keine anstehende Termine"
         items={myEvents}
      >
         {({ item }) => (
            <>
               {item.type === 'CoachingClass' && (
                  <CoachingClassEntry item={item.obj as CoachingClass} onChange={handleOnChange} />
               )}
               {item.type === 'Meeting' && (
                  <MeetingEntry item={item.obj as Meeting} onChange={handleOnChange} />
               )}
               {item.type === 'PTClass' && (
                  <PTClassEntry item={item.obj as PTClass} onChange={handleOnChange} />
               )}
            </>
         )}
      </ItemList>
   );
});

interface CoachingClassEntryProps {
   item: CoachingClass;
   onChange: (
      typeOfChange: 'update' | 'delete',
      typeOfEvent: PageModel['type'],
      event: Partial<CoachingClass | Meeting | PTClass>
   ) => void;
}

const CoachingClassEntry = memo(({ item, onChange }: CoachingClassEntryProps) => {
   const { classTypes } = useCache();
   const [showEditDialog, setShowEditDialog] = useState(false);
   const [showCancelDialog, setShowCancelDialog] = useState(false);

   const classType = classTypes.find(ct => ct.id === item.type_id);

   return (
      <>
         <div className="d-flex align-items-start justify-content-center">
            <div className="flex-fill">
               <div className="d-flex flex-row flex-wrap align-items-center">
                  <Badge bg="success" className="me-2">
                     {moment(item.start).fromNow()}
                  </Badge>
                  <span className="small">
                     {formatRange(item.start, item.end, 'full-date-with-time')}
                  </span>
               </div>

               <span className="mt-1 d-flex align-items-center">
                  {classType && (
                     <>
                        {classType.name}
                        <span className="mx-1">·</span>
                     </>
                  )}
                  <MiniProfile user={item.coach_id} />
                  {(item.coach_second_id || item.coach_other.length > 0) && (
                     <span className="mx-1">·</span>
                  )}
                  <CoachesAvatarList
                     coaches={[item.coach_second_id ?? 0, ...item.coach_other]}
                     numberOfItems={4}
                  />
               </span>
               {item.comment && <div className="mt-1 fst-italic">{item.comment}</div>}
            </div>
            <Dropdown className="align-self-center">
               <Dropdown.Toggle variant="link" className="no-caret p-0 mx-2">
                  <Icon path={mdiDotsVerticalCircleOutline} size={1} color={Colors.secondary} />
               </Dropdown.Toggle>

               <Dropdown.Menu>
                  <Dropdown.Item
                     className="d-flex align-items-center"
                     onClick={() => setShowEditDialog(true)}
                  >
                     <Icon
                        path={mdiPencilOutline}
                        size={0.85}
                        color={Colors.secondary}
                        className="me-2"
                     />
                     Bearbeiten
                  </Dropdown.Item>
                  <LinkContainer
                     to={`/representationRequest/create/${moment(item.start).format(
                        'YYYY-MM-DD'
                     )}/${item.id}`}
                  >
                     <Dropdown.Item className="d-flex align-items-center">
                        <Icon path={mdiSwapHorizontal} size={0.85} className="me-2" />
                        Vertretung anfordern
                     </Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Item
                     className="d-flex align-items-center"
                     onClick={() => setShowCancelDialog(true)}
                  >
                     <Icon path={mdiCancel} size={0.85} className="me-2" />
                     Klasse absagen
                  </Dropdown.Item>
               </Dropdown.Menu>
            </Dropdown>
         </div>
         <EditClassDialog
            coachingClass={item}
            show={showEditDialog}
            onClose={() => setShowEditDialog(false)}
            afterSave={cc => onChange('update', 'CoachingClass', cc)}
            afterDelete={cc => onChange('delete', 'CoachingClass', cc)}
         />
         <CancelRestoreCoachingClassDialog
            coachingClass={item}
            show={showCancelDialog}
            onClose={() => setShowCancelDialog(false)}
            afterSave={cc => onChange('update', 'CoachingClass', cc)}
         />
      </>
   );
});

interface MeetingEntryProps {
   item: Meeting;
   onChange: (
      typeOfChange: 'update' | 'delete',
      typeOfEvent: PageModel['type'],
      event: Partial<CoachingClass | Meeting | PTClass>
   ) => void;
}

const MeetingEntry = memo(({ item, onChange }: MeetingEntryProps) => {
   const [showEditDialog, setShowEditDialog] = useState(false);

   return (
      <>
         <div className="d-flex align-items-start">
            <div className="flex-fill">
               <div className="d-flex flex-row flex-wrap align-items-center">
                  <Badge bg="success" className="me-2">
                     {moment(item.start).fromNow()}
                  </Badge>
                  <span className="small">
                     {formatRange(item.start, item.end, 'full-date-with-time')}
                  </span>
               </div>

               <span className="mt-1 d-flex align-items-center">
                  <span>Meeting</span>
                  <span className="mx-1">·</span>
                  <MiniProfile user={item.organizer_id} />
                  <span className="mx-1">·</span>
                  <CoachesAvatarList coaches={item.participants} numberOfItems={4} />
               </span>
               {item.comment && <div className="mt-1 fst-italic">{item.comment}</div>}
            </div>
            <Dropdown className="align-self-center">
               <Dropdown.Toggle variant="link" className="no-caret p-0 mx-2">
                  <Icon path={mdiDotsVerticalCircleOutline} size={1} color={Colors.secondary} />
               </Dropdown.Toggle>

               <Dropdown.Menu>
                  <Dropdown.Item
                     className="d-flex align-items-center"
                     onClick={() => setShowEditDialog(true)}
                  >
                     <Icon
                        path={mdiPencilOutline}
                        size={0.85}
                        color={Colors.secondary}
                        className="me-2"
                     />
                     Bearbeiten
                  </Dropdown.Item>
               </Dropdown.Menu>
            </Dropdown>
         </div>
         <EditMeetingDialog
            meeting={item}
            show={showEditDialog}
            onClose={() => setShowEditDialog(false)}
            afterSave={m => onChange('update', 'Meeting', m)}
         />
      </>
   );
});

interface PTClassEntryProps {
   item: PTClass;
   onChange: (
      typeOfChange: 'update' | 'delete',
      typeOfEvent: PageModel['type'],
      event: Partial<CoachingClass | Meeting | PTClass>
   ) => void;
}

const PTClassEntry = memo(({ item, onChange }: PTClassEntryProps) => {
   const { rooms } = useCache();
   const ptClients = useSelector((s: RootState) => s.pt.clients);
   const [showEditDialog, setShowEditDialog] = useState(false);

   return (
      <>
         <div className="d-flex align-items-start">
            <div className="flex-fill">
               <div className="d-flex flex-row flex-wrap align-items-center">
                  <Badge bg="success" className="me-2">
                     {moment(item.start).fromNow()}
                  </Badge>
                  <span className="small">
                     {formatRange(item.start, item.end, 'full-date-with-time')}
                  </span>
               </div>

               <span className="mt-1 d-flex align-items-center">
                  <span>Personal Training</span>
                  <span className="mx-1">·</span>
                  <span>{ptClients.find(c => c.id === item.client_id)?.name}</span>
                  <span className="mx-1">·</span>
                  <span>{rooms.find(r => r.id === item.room_id)?.name}</span>
               </span>
               {item.comment && <div className="mt-1 fst-italic">{item.comment}</div>}
            </div>
            <Dropdown className="align-self-center">
               <Dropdown.Toggle variant="link" className="no-caret p-0 mx-2">
                  <Icon path={mdiDotsVerticalCircleOutline} size={1} color={Colors.secondary} />
               </Dropdown.Toggle>

               <Dropdown.Menu>
                  <Dropdown.Item
                     className="d-flex align-items-center"
                     onClick={() => setShowEditDialog(true)}
                  >
                     <Icon
                        path={mdiPencilOutline}
                        size={0.85}
                        color={Colors.secondary}
                        className="me-2"
                     />
                     Bearbeiten
                  </Dropdown.Item>
               </Dropdown.Menu>
            </Dropdown>
         </div>
         <EditPTClassDialog
            ptClass={item}
            show={showEditDialog}
            onClose={() => setShowEditDialog(false)}
            afterSave={pt => onChange('update', 'PTClass', pt)}
         />
      </>
   );
});
