import React, { useCallback, useEffect, useState } from 'react';
import moment, { unitOfTime } from 'moment';
import { Alert, Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { Formik, FormikErrors } from 'formik';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { FormUtils, GenericControl, SubmitButton } from '../forms';
import { BaseModel } from '../models/BaseModel';
import { TimeBasedObject } from '../types/api';
import { getSelectStyles } from '../utils';

interface IntervalOptionsType {
   label: string;
   value: '1-week' | '2-week' | '1-month';
}

const IntervalOptions: IntervalOptionsType[] = [
   {
      label: 'Wöchentlich',
      value: '1-week',
   },
   {
      label: 'Alle 2 Wochen',
      value: '2-week',
   },
   {
      label: 'Monatlich',
      value: '1-month',
   },
];

interface DuplicateFormModel {
   interval: IntervalOptionsType['value'];
   copyUntil?: Date;
}

interface Props<T extends TimeBasedObject> {
   entry: T;
   entryModel: BaseModel<T>;
   show: boolean;
   afterDuplicate?: (insertedEntries: T[]) => void;
   onClose: () => Promise<void> | void;
}

export const DuplicateDialog = <T extends TimeBasedObject>(props: Props<T>) => {
   const [progress, setProgress] = useState(-1);
   const [newEventsCount, setNewEventsCount] = useState<number>();

   useEffect(() => {
      setProgress(-1);
   }, [props]);

   const calculateEventDates = useCallback(
      (values: DuplicateFormModel): [Date, Date][] | null => {
         if (!values.copyUntil) return null;

         const copyStart = moment();
         const copyEnd = moment(values.copyUntil);
         const parts = values.interval.split('-');
         const copyInterval = Number(parts[0]);
         const copyUnit = parts[1] as unitOfTime.DurationConstructor;

         const startDate = moment(copyStart); // Make a copy because we will mutate this instance
         let count = 0;
         const newEventDates: [Date, Date][] = [];
         while (startDate.isSameOrBefore(copyEnd, 'day')) {
            startDate.add(copyInterval, copyUnit);
            count += 1;
            const eventStartDate = moment(props.entry.start).add(copyInterval * count, copyUnit);
            const eventEndDate = moment(props.entry.end).add(copyInterval * count, copyUnit);
            if (eventStartDate.isSameOrBefore(copyEnd, 'day')) {
               // Wir Speichern nur, wenn der Eintrag vor dem ausgewählten Kopierdatum ist.
               newEventDates.push([eventStartDate.toDate(), eventEndDate.toDate()]);
            }
         }

         return newEventDates;
      },
      [props.entry.end, props.entry.start]
   );

   const handleSubmitForm = async (values: DuplicateFormModel) => {
      const eventDates = calculateEventDates(values);
      if (!eventDates) return;

      const insertedEntries: T[] = [];
      await Promise.all(
         eventDates.map(async ([newStart, newEnd], index) => {
            const newEntry = {
               ...props.entry,
               id: 0,
               start: newStart,
               end: newEnd,
            } as T;

            insertedEntries.push(await props.entryModel.insert(newEntry));
            setProgress(Math.floor((eventDates.length / index) * 100));
         })
      );

      await props.afterDuplicate?.(insertedEntries);
      await props.onClose();
   };

   return (
      <Modal show={props.show} onHide={props.onClose}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={{ interval: '1-week' } as DuplicateFormModel}
            enableReinitialize
            validate={values => {
               setNewEventsCount(calculateEventDates(values)?.length);
               const errors: FormikErrors<DuplicateFormModel> = {};

               if (!values.copyUntil) errors.copyUntil = 'Bitte gib ein Datum an.';

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>Duplizieren</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     {progress >= 0 && <ProgressBar className="mb-3" animated now={progress} />}
                     <GenericControl label="Intervall" formik={formik} name="interval">
                        <Select
                           key={formik.values.interval}
                           styles={getSelectStyles(FormUtils.isInvalid(formik, 'interval'))}
                           options={IntervalOptions}
                           defaultValue={IntervalOptions.find(
                              t => t.value === formik.values.interval
                           )}
                           onChange={value => {
                              formik.setFieldValue('interval', value?.value);
                           }}
                           onBlur={() => formik.handleBlur('interval')}
                           isDisabled={formik.isSubmitting}
                        />
                     </GenericControl>
                     <GenericControl label="Duplizieren bis" formik={formik} name="copyUntil">
                        <Form.Control
                           as={DatePicker}
                           wrapperClassName="d-flex"
                           dateFormat="dd.MM.yyyy"
                           locale="de"
                           calendarStartDay={1}
                           showWeekNumbers
                           placeholderText="__.__.____"
                           minDate={props.entry.start}
                           selected={formik.values.copyUntil}
                           onChange={date => formik.setFieldValue('copyUntil', date)}
                           onBlur={() => formik.handleBlur('copyUntil')}
                           isInvalid={FormUtils.isInvalid(formik, 'copyUntil')}
                           disabled={formik.isSubmitting}
                        />
                     </GenericControl>
                     {newEventsCount !== undefined && (
                        <Alert variant="primary">
                           {newEventsCount === 0 && (
                              <span>
                                 Es werden <strong>keine</strong> neue Termine angelegt.
                              </span>
                           )}
                           {newEventsCount === 1 && (
                              <span>
                                 Es wird <strong>1</strong> neuer Termine angelegt.
                              </span>
                           )}
                           {newEventsCount > 1 && (
                              <span>
                                 Es werden <strong>{newEventsCount}</strong> neue Termine angelegt.
                              </span>
                           )}
                        </Alert>
                     )}
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                     <Button
                        variant="outline-link"
                        onClick={props.onClose}
                        disabled={formik.isSubmitting}
                     >
                        Schließen
                     </Button>
                     <SubmitButton formik={formik}>Duplizieren</SubmitButton>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
