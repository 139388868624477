import { useDispatch } from 'react-redux';
import { Alert, Badge, Button, ButtonGroup, Form, ListGroup, Modal, Table } from 'react-bootstrap';
import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiLogin, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { isMobile } from 'react-device-detect';
import { Headline, LoadingButton, MiniProfile, ShowIfRole } from '../../molecules';
import { setCache } from '../../store/actions/appActions';
import { setImpersonateMode } from '../../utils';
import { EditUserDialog } from '../../dialogs/EditUserDialog';
import { ROLE_ADMIN, ROLE_BOX_OWNER, User } from '../../types/api';
import { UserModel } from '../../models';
import { useCache } from '../../hooks';

export const ManageEmployeeListTab = () => {
   const dispatch = useDispatch();
   const cachedData = useCache();
   const [userToEdit, setUserToEdit] = useState<Partial<User> | undefined>();
   const [showDeactivatedUser, setShowDeactivatedUser] = useState(false);
   const [userToDelete, setUserToDelete] = useState<User | undefined>();
   const [confirmDelete, setConfirmDelete] = useState('');
   const [isDeleting, setDeleting] = useState(false);

   const handleAfterSave = async (user: User) => {
      dispatch(
         setCache({
            ...cachedData,
            users: [...cachedData.users.filter(u => u.id !== user.id), user],
         })
      );

      return Promise.resolve();
   };

   const handleDelete = async () => {
      if (!userToDelete) return;

      setDeleting(true);
      try {
         const updatedUser = await UserModel.anonymize(userToDelete);
         dispatch(
            setCache({
               ...cachedData,
               users: [...cachedData.users.filter(u => u.id !== updatedUser.id), updatedUser],
            })
         );
         setUserToDelete(undefined);
      } finally {
         setDeleting(false);
      }
   };

   return (
      <>
         <Headline title="Coaches" browserTitle="Mitarbeiter - Coaches" />
         <ShowIfRole roles={[ROLE_BOX_OWNER]}>
            <Button
               variant="success"
               className="floating-button"
               onClick={() => setUserToEdit({ id: 0, enabled: true })}
            >
               Hinzufügen
            </Button>
         </ShowIfRole>
         <Alert variant="primary" className="mt-3">
            Benutzer können nicht gelöscht werden und müssen stattdessen deaktiviert werden.
         </Alert>
         <ListGroup className="floating-panel">
            {cachedData.users.find(u => !u.enabled) && (
               <ListGroup.Item className="d-flex justify-content-center">
                  <Button variant="link" size="sm" onClick={() => setShowDeactivatedUser(true)}>
                     {`${
                        cachedData.users.filter(u => !u.enabled).length
                     } deaktivierte(r) Benutzer anzeigen`}
                  </Button>
               </ListGroup.Item>
            )}
            {cachedData.users
               .filter(u => u.enabled)
               .sort((u1, u2) => u1.first_name.localeCompare(u2.first_name))
               .map(u => (
                  <ListGroup.Item
                     key={u.id}
                     className="d-flex align-items-center justify-content-between"
                  >
                     <span className="d-flex flex-column flex-md-row align-items-md-center">
                        <span className="d-flex align-items-center">
                           <div
                              className="me-2"
                              style={{
                                 height: '1rem',
                                 width: '1rem',
                                 borderRadius: '0.25rem',
                                 backgroundColor: u.absence_color,
                              }}
                           />
                           <MiniProfile user={u} type="normal" />
                        </span>
                        <span className="ms-md-2">
                           {cachedData.roles
                              .filter(r => u.roles.includes(r.key))
                              .map(r => (
                                 <Badge key={r.id} bg="secondary" className="me-2">
                                    {r.name}
                                 </Badge>
                              ))}
                        </span>
                     </span>
                     <ButtonGroup>
                        <ShowIfRole roles={[ROLE_ADMIN]}>
                           {process.env.NODE_ENV === 'development' && (
                              <Button
                                 variant="outline-warning"
                                 size="sm"
                                 className="d-flex align-items-center"
                                 onClick={async () => {
                                    await UserModel.impersonate(u.id);
                                    setImpersonateMode(true);
                                    window.location.reload();
                                 }}
                              >
                                 <Icon path={mdiLogin} size={0.75} />
                              </Button>
                           )}
                        </ShowIfRole>
                        <ShowIfRole roles={[ROLE_BOX_OWNER]}>
                           <Button
                              variant="outline-primary"
                              size="sm"
                              className="d-flex align-items-center"
                              onClick={() => setUserToEdit(u)}
                           >
                              <Icon path={mdiPencilOutline} size={0.75} />
                           </Button>
                        </ShowIfRole>
                     </ButtonGroup>
                  </ListGroup.Item>
               ))}
         </ListGroup>

         <Modal
            show={showDeactivatedUser}
            onHide={() => setShowDeactivatedUser(false)}
            centered
            size="lg"
         >
            <Modal.Header closeButton>
               <Modal.Title>Deaktivierte Benutzer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
               <Table className="mt-0 mb-0" hover responsive>
                  <tbody>
                     {cachedData.users
                        .filter(u => !u.enabled)
                        .map(u => (
                           <tr key={u.id}>
                              <td className="d-flex justify-content-between mx-2">
                                 <MiniProfile user={u} type="normal" />
                                 <ShowIfRole roles={[ROLE_BOX_OWNER]}>
                                    <ButtonGroup>
                                       <Button
                                          variant="outline-danger"
                                          size="sm"
                                          className="d-flex align-items-center"
                                          onClick={() => setUserToDelete(u)}
                                       >
                                          <Icon path={mdiTrashCanOutline} size={0.75} />
                                       </Button>
                                       <Button
                                          variant="outline-secondary"
                                          size="sm"
                                          className="d-flex align-items-center"
                                          onClick={() => setUserToEdit(u)}
                                       >
                                          <Icon path={mdiPencilOutline} size={0.75} />
                                       </Button>
                                    </ButtonGroup>
                                 </ShowIfRole>
                              </td>
                           </tr>
                        ))}
                  </tbody>
               </Table>
            </Modal.Body>
            <Modal.Footer className="bg-light">
               <Button variant="secondary" onClick={() => setShowDeactivatedUser(false)}>
                  Schließen
               </Button>
            </Modal.Footer>
         </Modal>

         <EditUserDialog
            show={!!userToEdit}
            user={userToEdit}
            onClose={() => setUserToEdit(undefined)}
            afterSave={handleAfterSave}
         />

         <Modal show={!!userToDelete} onHide={() => setUserToDelete(undefined)} centered={isMobile}>
            <Modal.Header closeButton>
               <Modal.Title>Löschen bestätigen</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <p>
                  Möchtest Du den Coach{' '}
                  <strong>{`${userToDelete?.first_name} ${userToDelete?.last_name}`}</strong>{' '}
                  wirklich löschen?
                  <br />
                  <br />
                  Bitte gebe zur Bestätigung folgenden Text ein:{' '}
                  <strong>{`${userToDelete?.first_name} ${userToDelete?.last_name}`}</strong>
                  <Form.Group className="mb-3">
                     <Form.Control
                        type="text"
                        name="confirm"
                        className="mt-2"
                        placeholder="Bestätigung der Löschung…"
                        value={confirmDelete}
                        onChange={evt => setConfirmDelete(evt.target.value)}
                     />
                  </Form.Group>
                  <Alert variant="secondary" className="small">
                     Der Coach wird nicht tatsächlich gelöscht, sondern DSGVO-konform anonymisiert.
                     Dabei werden alle personenbezogenen Daten entfernt, bzw. durch generische Daten
                     ersetzt.
                     <br />
                     <br />
                     Dieser Vorgang kann dabei nicht rückgängig gemacht werden!
                  </Alert>
               </p>
            </Modal.Body>

            <Modal.Footer className="bg-light">
               <Button
                  variant="outline-link"
                  onClick={() => setUserToDelete(undefined)}
                  disabled={isDeleting}
               >
                  Abbrechen
               </Button>
               <LoadingButton
                  variant="danger"
                  onClick={handleDelete}
                  isLoading={isDeleting}
                  disabled={
                     confirmDelete !== `${userToDelete?.first_name} ${userToDelete?.last_name}`
                  }
               >
                  Löschen
               </LoadingButton>
            </Modal.Footer>
         </Modal>
      </>
   );
};
