import { SET_BOXES, UPSERT_BOX } from '../reducers/adminReducer';
import { Box } from '../../types/api';

export const setBoxes = (boxes: Box[]) => ({
   type: SET_BOXES,
   data: boxes,
});

export const upsertBox = (box: Box) => ({
   type: UPSERT_BOX,
   data: box,
});
