import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Badge, Button, ButtonGroup, Form, ListGroup, Modal } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { Formik, FormikErrors } from 'formik';
import { setCache } from '../../store/actions/appActions';
import { Headline } from '../../molecules';
import { CreateUpdateInfo } from '../../organisms/CreateUpdateInfo';
import { ColorInput, RoomSelect } from '../../forms/controls';
import { ClassType } from '../../types/api';
import { ClassTypeModel } from '../../models';
import { useCache } from '../../hooks';
import { CheckControl, GenericControl, SubmitButton, TextControl } from '../../forms';

export const ManageBoxClassTypesTab = () => {
   const dispatch = useDispatch();
   const cachedData = useCache();
   const [showDialog, setShowDialog] = useState(false);
   const [classTypeToEdit, setClassTypeToEdit] = useState<Partial<ClassType>>({});

   const handleSubmitForm = async (classType: Partial<ClassType>) => {
      let ct: ClassType;
      if (classType.id === 0) ct = await ClassTypeModel.insert(classType);
      else ct = await ClassTypeModel.update(classType);

      dispatch(
         setCache({
            ...cachedData,
            classTypes: [...cachedData.classTypes.filter(t => t.id !== ct.id), ct],
         })
      );
      setShowDialog(false);
   };

   const handleDelete = async (ct: ClassType) => {
      await ClassTypeModel.delete(ct);
      dispatch(
         setCache({
            ...cachedData,
            classTypes: [...cachedData.classTypes.filter(t => t.id !== ct.id)],
         })
      );
   };

   return (
      <>
         <Headline title="Kurstypen" browserTitle="Box-Administration - Kurstypen" />
         <div className="d-flex flex-fill">
            <Button
               variant="success"
               className="flex-fill flex-md-grow-0"
               onClick={() => {
                  setClassTypeToEdit({ id: 0, is_billable: true });
                  setShowDialog(true);
               }}
            >
               Kurstyp hinzufügen
            </Button>
         </div>

         <ListGroup className="floating-panel">
            {cachedData.classTypes
               .sort((c1, c2) => c1.name.localeCompare(c2.name))
               .map(c => (
                  <ListGroup.Item
                     key={c.id}
                     className="d-flex align-items-center justify-content-between"
                  >
                     <div className="d-flex align-items-center flex-wrap">
                        <div
                           className="me-2"
                           style={{
                              height: '1rem',
                              width: '1rem',
                              borderRadius: '0.25rem',
                              backgroundColor: c.color,
                           }}
                        />
                        <span className="me-2">{c.name}</span>
                        {!c.is_billable && (
                           <Badge bg="light" text="danger">
                              Abrechnung deaktiviert
                           </Badge>
                        )}
                     </div>
                     <ButtonGroup>
                        <Button
                           variant="outline-secondary"
                           size="sm"
                           className="d-flex align-items-center"
                           onClick={() => {
                              setClassTypeToEdit(c);
                              setShowDialog(true);
                           }}
                        >
                           <Icon path={mdiPencilOutline} size={0.75} />
                        </Button>
                        <Button
                           variant="outline-danger"
                           size="sm"
                           className="d-flex align-items-center"
                           onClick={() => handleDelete(c)}
                        >
                           <Icon path={mdiTrashCanOutline} size={0.75} />
                        </Button>
                     </ButtonGroup>
                  </ListGroup.Item>
               ))}
         </ListGroup>

         <Modal show={showDialog} onHide={() => setShowDialog(false)}>
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={classTypeToEdit}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<ClassType>> = {};

                  if (!values.name) errors.name = 'Bitte gib einen Namen für den Kurs an.';

                  if (!values.color) errors.color = 'Bitte wähle eine Farbe aus.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>
                           {classTypeToEdit.id === 0 ? 'Neuer Kurstyp' : 'Kurstyp bearbeiten'}
                        </Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <TextControl formik={formik} name="name" label="Name" />
                        <GenericControl
                           formik={formik}
                           name="preferred_room_id"
                           label="Bevorzugter Raum"
                        >
                           <RoomSelect formik={formik} name="preferred_room_id" />
                        </GenericControl>
                        <GenericControl formik={formik} name="color" label="Farbe im Kalender">
                           <ColorInput
                              value={formik.values.color}
                              onChange={c => formik.setFieldValue('color', c)}
                           />
                        </GenericControl>
                        <CheckControl
                           formik={formik}
                           name="is_billable"
                           label="Kurs wird abgerechnet"
                        />
                     </Modal.Body>
                     <Modal.Footer className="bg-light">
                        <CreateUpdateInfo hidden={classTypeToEdit.id === 0} obj={classTypeToEdit} />
                        <Button
                           variant="outline-link"
                           onClick={() => setShowDialog(false)}
                           disabled={formik.isSubmitting}
                        >
                           Schließen
                        </Button>
                        <SubmitButton formik={formik}>Speichern</SubmitButton>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
      </>
   );
};
