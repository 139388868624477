import { Button, ButtonGroup, Col, ListGroup, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import {
   mdiAccountArrowRight,
   mdiAccountPlus,
   mdiClose,
   mdiLogin,
   mdiPencilOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { v4 as uuidv4 } from 'uuid';
import { SkeletonPlaceholder } from '../../atoms';
import { LoadingButton, MiniProfile } from '../../molecules';
import { setImpersonateMode } from '../../utils';
import { EditUserDialog } from '../../dialogs/EditUserDialog';
import { UserSearchSelect } from '../../forms/controls';
import { UserModel } from '../../models';
import { Box, User } from '../../types/api';

interface Props {
   box: Partial<Box>;
}

export const CoachesTab = ({ box }: Props) => {
   const [coaches, setCoaches] = useState<User[] | null>();
   const [coachToEdit, setCoachToEdit] = useState<Partial<User>>();
   const [selectedUser, setSelectedUser] = useState<User>();

   useEffect(() => {
      (async () => {
         setCoaches(null);

         if (box?.id && box.id >= 0) setCoaches(await UserModel.adminList(box.id));
      })();
   }, [box]);

   const handleAfterSafe = async (user: User, operation: 'insert' | 'update') => {
      if (operation === 'insert' && box.id) {
         await UserModel.adminAddToBox(user.id, box.id, true);
      }

      if (box.id) setCoaches(await UserModel.adminList(box.id));
   };

   const handleAddUserToBox = async () => {
      if (selectedUser && box.id) {
         await UserModel.adminAddToBox(selectedUser.id, box.id);
         setCoaches(await UserModel.adminList(box.id));
      }
   };

   const handleRemoveUserFromBox = async (userId: number) => {
      if (userId && box.id) {
         await UserModel.adminRemoveFromBox(userId, box.id);
         setCoaches(await UserModel.adminList(box.id));
      }
   };

   return (
      <>
         <Row>
            <Col className="d-flex mb-3">
               <Button
                  variant="primary"
                  className="me-2 d-flex align-items-center"
                  onClick={() => {
                     setCoachToEdit({
                        id: 0,
                        email: `${uuidv4()}@example.com`,
                        is_demo: box.is_demo,
                        preferred_box_id: box.id,
                     });
                  }}
               >
                  <span className="d-flex align-items-center">
                     <Icon path={mdiAccountPlus} size={0.85} />
                  </span>
               </Button>
               <UserSearchSelect onSelect={setSelectedUser} />
               <Button
                  variant="primary"
                  className="ms-2 d-flex align-items-center"
                  disabled={!selectedUser}
                  onClick={handleAddUserToBox}
               >
                  <span className="d-flex d-md-none align-items-center">
                     <Icon path={mdiAccountArrowRight} size={0.85} />
                  </span>
                  <span className="d-none d-md-inline">Hinzufügen</span>
               </Button>
            </Col>
         </Row>
         <ListGroup>
            {!coaches
               ? [...Array(6).keys()].map(i => (
                    <ListGroup.Item key={i}>
                       <SkeletonPlaceholder width="70%" />
                    </ListGroup.Item>
                 ))
               : coaches
                    .sort((u1, u2) => u1.first_name.localeCompare(u2.first_name))
                    .map(c => (
                       <ListGroup.Item
                          key={c.id}
                          className="d-flex justify-content-between align-items-center"
                       >
                          <MiniProfile type="normal" user={c} />
                          <ButtonGroup>
                             {process.env.NODE_ENV === 'development' && (
                                <Button
                                   variant="outline-warning"
                                   size="sm"
                                   className="d-flex align-items-center"
                                   onClick={async () => {
                                      await UserModel.impersonate(c.id);
                                      setImpersonateMode(true);
                                      window.location.reload();
                                   }}
                                >
                                   <Icon path={mdiLogin} size={0.75} />
                                </Button>
                             )}
                             <Button
                                variant="outline-secondary"
                                size="sm"
                                className="d-flex align-items-center"
                                onClick={() => setCoachToEdit(c)}
                             >
                                <Icon path={mdiPencilOutline} size={0.75} />
                             </Button>
                             <LoadingButton
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center"
                                tooltip="Benutzer aus der CrossFit-Box entfernen"
                                onClick={() => handleRemoveUserFromBox(c.id)}
                             >
                                <Icon path={mdiClose} size={0.75} />
                             </LoadingButton>
                          </ButtonGroup>
                       </ListGroup.Item>
                    ))}
            {coaches && coaches.length === 0 && (
               <ListGroup.Item className="d-flex justify-content-center">
                  <em>Keine Coaches vorhanden</em>
               </ListGroup.Item>
            )}
         </ListGroup>
         <EditUserDialog
            show={coachToEdit !== null}
            user={coachToEdit}
            afterSave={handleAfterSafe}
            onClose={() => setCoachToEdit(undefined)}
         />
      </>
   );
};
