import moment, { Moment } from 'moment';
import { AxiosError } from 'axios';
import { BaseModel, TimeBasedObjectModel } from './BaseModel';
import { ModelExtension } from './ModelExtension';
import ApiService from '../services/ApiService';
import { Absence } from '../types/api';
import { ErrorInfo } from '../types/ErrorInfo';
import { IApiResponse } from '../types/IApiResponse';

class AbsenceModelClass extends BaseModel<Absence> implements TimeBasedObjectModel<Absence> {
   public getName(): string {
      return 'absence';
   }

   public async listTimeRange(from?: Moment, to?: Moment): Promise<Absence[]> {
      return ModelExtension.listTimeRange<Absence>(this.getName(), from, to);
   }

   public async validate(absenceId: number, absence: Partial<Absence>): Promise<ErrorInfo[]> {
      try {
         const queryParam = absenceId !== 0 ? `?id=${absenceId}` : '';
         await ApiService.http.post(`validate/absence${queryParam}`, absence);
         return [];
      } catch (err) {
         const error = err as AxiosError<IApiResponse<ErrorInfo>>;
         if (error.response?.status === 409) {
            // 409 = Conflict = Validation response
            return error.response.data.data;
         }

         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async calculateVacationDays(
      start: Moment | Date | undefined,
      end: Moment | Date | undefined
   ): Promise<number> {
      if (!end || !start) return 0;

      const mStart = moment(start).format('YYYY-MM-DD');
      const mEnd = moment(end).format('YYYY-MM-DD');

      try {
         const result = await ApiService.http.get<IApiResponse<number>>(
            `data/${this.getName()}/calculateDays/${mStart}/${mEnd}`
         );
         return result.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   async submitForApproval(absence: Absence): Promise<Absence> {
      try {
         const response = await ApiService.http.put<IApiResponse<Absence>>(
            `data/${this.getName()}/${absence.id}/submitForApproval`
         );

         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
export const AbsenceModel = new AbsenceModelClass();
