import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, ListGroup, Modal } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import { Formik, FormikErrors } from 'formik';
import { setCache } from '../../store/actions/appActions';
import { Headline } from '../../molecules';
import { CreateUpdateInfo } from '../../organisms/CreateUpdateInfo';
import { useCache } from '../../hooks';
import { Room } from '../../types/api';
import { RoomModel } from '../../models';
import {
   FormFieldError,
   FormRow,
   FormUtils,
   GenericControl,
   SubmitButton,
   TextControl,
} from '../../forms';

export const ManageBoxRoomsTab = () => {
   const dispatch = useDispatch();
   const cachedData = useCache();
   const [showDialog, setShowDialog] = useState(false);
   const [roomToEdit, setRoomToEdit] = useState<Partial<Room>>({});
   const [usesBoxAddress, setUsesBoxAddress] = useState(false);

   useEffect(() => {
      setUsesBoxAddress(!roomToEdit.street_address || !roomToEdit.postal_code || !roomToEdit.city);
   }, [roomToEdit]);

   const handleSubmitForm = async (room: Partial<Room>) => {
      let rm: Room;
      if (room.id === 0) rm = await RoomModel.insert(room);
      else rm = await RoomModel.update(room);

      dispatch(
         setCache({
            ...cachedData,
            rooms: [...cachedData.rooms.filter(r => r.id !== rm.id), rm],
         })
      );
      setShowDialog(false);
   };

   const handleDelete = async (room: Room) => {
      await RoomModel.delete(room);
      dispatch(
         setCache({
            ...cachedData,
            rooms: [...cachedData.rooms.filter(t => t.id !== room.id)],
         })
      );
   };

   return (
      <>
         <Headline title="Räume" browserTitle="Box-Administration - Räume" />
         <div className="d-flex flex-fill">
            <Button
               variant="success"
               className="flex-fill flex-md-grow-0"
               onClick={() => {
                  setRoomToEdit({ id: 0 });
                  setShowDialog(true);
               }}
            >
               Raum hinzufügen
            </Button>
         </div>
         <ListGroup className="floating-panel">
            {cachedData.rooms
               .sort((r1, r2) => r1.name.localeCompare(r2.name))
               .map(r => (
                  <ListGroup.Item
                     key={r.id}
                     className="d-flex align-items-center justify-content-between"
                  >
                     <div>{r.name}</div>
                     <ButtonGroup>
                        <Button
                           variant="outline-secondary"
                           size="sm"
                           className="d-flex align-items-center"
                           onClick={() => {
                              setRoomToEdit(r);
                              setShowDialog(true);
                           }}
                        >
                           <Icon path={mdiPencilOutline} size={0.75} />
                        </Button>
                        <Button
                           variant="outline-danger"
                           size="sm"
                           className="d-flex align-items-center"
                           onClick={() => handleDelete(r)}
                        >
                           <Icon path={mdiTrashCanOutline} size={0.75} />
                        </Button>
                     </ButtonGroup>
                  </ListGroup.Item>
               ))}
         </ListGroup>

         <Modal show={showDialog} onHide={() => setShowDialog(false)}>
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={roomToEdit}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<Room>> = {};

                  if (!values.name) errors.name = 'Pflichtfeld';

                  if (values.street_address || values.postal_code || values.city) {
                     if (!values.street_address)
                        errors.street_address = 'Bitte gib die Straße inkl. Hausnummer an.';
                     if (!values.postal_code) errors.postal_code = 'Bitte gib die Postleitzahl an.';
                     if (!values.city) errors.city = 'Bitte gib die Stadt an.';
                  }

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>
                           {roomToEdit.id === 0 ? 'Neuer Raum' : 'Raum bearbeiten'}
                        </Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <TextControl
                           formik={formik}
                           name="name"
                           label="Name"
                           placeholder="Name des Raumes…"
                        />

                        <FormRow label="Adresse">
                           <Form.Check
                              className="mb-2"
                              type="switch"
                              id="cb-uses-box-address"
                              label="Adressdaten der Box verwenden"
                              checked={usesBoxAddress}
                              onChange={() => setUsesBoxAddress(v => !v)}
                           />
                           <GenericControl formik={formik} name="street_address">
                              <Form.Control
                                 type="text"
                                 name="street_address"
                                 placeholder="Straße & Hausnummer…"
                                 value={
                                    (usesBoxAddress
                                       ? cachedData.box?.street_address
                                       : formik.values.street_address) ?? ''
                                 }
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 isInvalid={FormUtils.isInvalid(formik, 'street_address')}
                                 disabled={formik.isSubmitting || usesBoxAddress}
                              />
                           </GenericControl>
                           <div className="d-flex align-items-center">
                              <Form.Control
                                 className="me-2"
                                 style={{ flex: 1 }}
                                 type="text"
                                 name="postal_code"
                                 placeholder="PLZ…"
                                 value={
                                    (usesBoxAddress
                                       ? cachedData.box?.postal_code
                                       : formik.values.postal_code) ?? ''
                                 }
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 isInvalid={FormUtils.isInvalid(formik, 'postal_code')}
                                 disabled={formik.isSubmitting || usesBoxAddress}
                              />
                              <Form.Control
                                 style={{ flex: 2 }}
                                 type="text"
                                 name="city"
                                 placeholder="Stadt…"
                                 value={
                                    (usesBoxAddress ? cachedData.box?.city : formik.values.city) ??
                                    ''
                                 }
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 isInvalid={FormUtils.isInvalid(formik, 'city')}
                                 disabled={formik.isSubmitting || usesBoxAddress}
                              />
                           </div>
                           <FormFieldError formik={formik} name="postal_code" />
                           <FormFieldError formik={formik} name="city" />
                        </FormRow>
                     </Modal.Body>
                     <Modal.Footer className="bg-light">
                        <CreateUpdateInfo hidden={roomToEdit.id === 0} obj={roomToEdit} />
                        <Button
                           variant="outline-link"
                           onClick={() => setShowDialog(false)}
                           disabled={formik.isSubmitting}
                        >
                           Schließen
                        </Button>
                        <SubmitButton formik={formik}>Speichern</SubmitButton>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
      </>
   );
};
