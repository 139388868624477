import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiArrowExpand,
   mdiCalendarOutline,
   mdiClockTimeFourOutline,
   mdiMapMarkerOutline,
} from '@mdi/js';
import moment from 'moment/moment';
import { Colors, formatRange } from '../../utils';
import { MiniProfile } from '../../molecules';
import EventPopover from '../../molecules/EventPopover';
import { EventEntryProps, PTEvent } from './EventTypes';
import { useSession } from '../../hooks';
import { EditPTClassDialog } from '../../dialogs/EditPTClassDialog';
import { PTClass } from '../../types/api';

export const PTClassEventEntry = ({ event }: EventEntryProps<PTEvent>) => {
   const { sessionUser } = useSession();
   const [showOverlay, setShowOverlay] = useState(false);
   const [ptClassToEdit, setPtClassToEdit] = useState<PTClass | null>(null);

   useEffect(() => {
      if (event.popupTarget) setShowOverlay(true);
   }, [event.popupTarget]);

   return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <div
         role="tooltip"
         onClick={e => {
            if (event.popupTarget) e.stopPropagation();
         }}
      >
         <strong className="small">{event.title}</strong>
         <EventPopover
            target={event.popupTarget}
            show={showOverlay}
            onHide={() => {
               setShowOverlay(false);
               event.popupTarget = undefined;
            }}
         >
            <Popover style={{ maxWidth: 350 }}>
               <Popover.Body className="p-0">
                  <div className="d-flex">
                     <div className="ps-3 pt-2 pe-2 flex-fill">
                        <div className="d-flex flex-row my-1">
                           <Icon
                              path={mdiCalendarOutline}
                              color={Colors.secondary}
                              size={1}
                              className="me-1 mt-1"
                           />
                           <h3>Personal Training</h3>
                        </div>
                        <div className="d-flex flex-row my-1">
                           <Icon
                              path={mdiClockTimeFourOutline}
                              color={Colors.secondary}
                              size={1}
                              className="me-1 mt-1"
                           />
                           <div>
                              <h5 className="mb-0">{moment(event.start).format('dddd, LL')}</h5>
                              <span>
                                 {formatRange(event.start, event.end, 'simple-date-with-time')}
                              </span>
                           </div>
                        </div>
                        <div className="d-flex flex-row my-1">
                           <Icon
                              path={mdiMapMarkerOutline}
                              color={Colors.secondary}
                              size={1}
                              className="me-1 mt-1"
                           />
                           <h5>{event.room?.name}</h5>
                        </div>
                     </div>
                     {event.ptClass.coach_id === sessionUser?.id && (
                        <div>
                           <Button
                              variant="link"
                              className="me-1 mt-1 btn p-0"
                              onClick={() => {
                                 setPtClassToEdit(event.ptClass);
                                 setShowOverlay(false);
                              }}
                           >
                              <Icon path={mdiArrowExpand} color={Colors.secondary} size={1} />
                           </Button>
                        </div>
                     )}
                  </div>
                  {event.coach && (
                     <>
                        <div
                           style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', height: 1 }}
                           className="mx-3 my-2"
                        >
                           &nbsp;
                        </div>
                        <div className="d-flex flex-column mx-3 mb-2">
                           <MiniProfile user={event.coach} type="large" className="my-1" />
                        </div>
                     </>
                  )}
               </Popover.Body>
            </Popover>
         </EventPopover>
         <EditPTClassDialog
            show={!!ptClassToEdit}
            onClose={() => setPtClassToEdit(null)}
            ptClass={ptClassToEdit ?? {}}
         />
      </div>
   );
};
