import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { SlotInfo, View } from 'react-big-calendar';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FloatingPanel } from '../../atoms';
import { Colors, setTitle } from '../../utils';
import { LoadingOverlay } from '../../organisms/LoadingOverlay';
import { RootState } from '../../store';
import { setPersonalTrainings, upsertPersonalTraining } from '../../store/actions/ptActions';
import { EventDropProps, EventResizeProps } from '../../utils/types';
import { EditPTClassDialog } from '../../dialogs/EditPTClassDialog';
import { BoxClosingTime, CoachingClass, PTClass } from '../../types/api';
import { CoachingClassModel, PTClassModel } from '../../models';
import { BoxClosingTimeModel } from '../../models/BoxClosingTime';
import { useCache, useSession } from '../../hooks';
import { GenericEvent, PTEvent } from '../../organisms/Calendar/EventTypes';
import { Calendar, DEFAULT_VIEW_WEB } from '../../organisms/Calendar';

export const PTCalenderTab = () => {
   const dispatch = useDispatch();
   const { sessionUser } = useSession();
   const cachedData = useCache();
   const { personalTrainings: ptClasses, clients: ptClients } = useSelector((s: RootState) => s.pt);
   const [urlParams] = useSearchParams();

   const [isLoading, setLoading] = useState(false);
   const [boxClosingTimes, setBoxClosingTimes] = useState<BoxClosingTime[]>([]);
   const [coachingClasses, setCoachingClasses] = useState<CoachingClass[]>([]);
   const [events, setEvents] = useState<GenericEvent[]>([]);
   const [eventToEdit, setEventToEdit] = useState<Partial<PTClass>>();

   useEffect(() => setTitle('Personal Trainings'), []);

   // PT-Sessions laden und in State packen
   const loadEvents = useCallback(
      async (start: Date, view: View) => {
         setLoading(true);
         try {
            // In der Anzeige wird der vorherige und nächste Monat/Woche/Tag ggf. angerissen, daher eine Woche
            //    vorher und nachher laden.
            const from = moment(start).add(-1, 'week');
            const to = moment(from).add(view === 'month' ? 6 : 2, 'week');

            dispatch(setPersonalTrainings(await PTClassModel.listTimeRange(from, to)));
            setBoxClosingTimes(await BoxClosingTimeModel.listTimeRange(from, to));
            setCoachingClasses(await CoachingClassModel.listTimeRange(from, to));
         } finally {
            setLoading(false);
         }
      },
      [dispatch]
   );

   useEffect(() => {
      (async () => {
         const start = urlParams.get('date')
            ? moment(urlParams.get('date'), 'YYYY-MM-DD')
            : moment();
         const view = urlParams.get('view') ?? DEFAULT_VIEW_WEB;
         await loadEvents(start.toDate(), view as View);
      })();
   }, [urlParams, loadEvents]);

   // PT-Sessions von State nehmen und in CalenderEvents umwandeln
   useEffect(() => {
      setEvents([
         ...ptClasses
            .filter(c => c.coach_id === sessionUser?.id)
            .map<PTEvent>(c => {
               const client = ptClients.find(u => u.id === c.client_id);
               return {
                  id: c.id,
                  type: 'PTClass',
                  title: client?.name,
                  color: client?.color,
                  isCanceled: c.canceled,
                  ptClass: c,
                  client: client,
                  room: cachedData.rooms.find(r => r.id === c.room_id),
                  coach: cachedData.users.find(u => u.id === c.coach_id),
                  start: c.start,
                  end: c.end,
               };
            }),
         ...ptClasses
            .filter(c => c.coach_id !== sessionUser?.id)
            .map<GenericEvent>(c => ({
               type: 'Other',
               color: Colors.secondary,
               id: c.id,
               title: `PT - ${cachedData.rooms.find(r => r.id === c.room_id)?.name}`,
               start: c.start,
               end: c.end,
            })),
         ...boxClosingTimes.map<GenericEvent>(c => ({
            type: 'Other',
            color: Colors.secondary,
            id: c.id,
            title: 'Kein Training möglich',
            start: c.start,
            end: c.end,
         })),
         ...coachingClasses.map<GenericEvent>(c => ({
            type: 'Other',
            color: Colors.secondary,
            id: c.id,
            title: `${cachedData.rooms.find(r => r.id === c.room_id)?.name}`,
            start: c.start,
            end: c.end,
         })),
      ]);
   }, [cachedData, ptClasses, boxClosingTimes, coachingClasses, sessionUser?.id, ptClients]);

   const moveEvent = async (args: EventDropProps<GenericEvent>) => {
      if (args.event.type !== 'PTClass') return;

      const ptEvent = args.event as PTEvent;

      if (args.isAllDay) {
         // Ablegen in Ganztägig wird nicht unterstützt
         // eslint-disable-next-line no-alert
         alert(
            'Das Verschieben eines Termines in die Zeile für ganztägige Termine wird nicht unterstützt.'
         );
         return;
      }

      ptEvent.ptClass.start = new Date(args.start);
      ptEvent.ptClass.end = new Date(args.end);
      // Damit die Ansicht nicht flackert, ändern wir das auch direkt an dem Event
      ptEvent.start = ptEvent.ptClass.start;
      ptEvent.end = ptEvent.ptClass.end;
      dispatch(upsertPersonalTraining(await PTClassModel.update(ptEvent.ptClass)));
   };

   const resizeEvent = async (args: EventResizeProps<GenericEvent>) => {
      if (args.event.type !== 'PTClass') return;

      const ptEvent = args.event as PTEvent;

      ptEvent.ptClass.start = new Date(args.start);
      ptEvent.ptClass.end = new Date(args.end);
      // Damit die Ansicht nicht flackert, ändern wir das auch direkt an dem Event
      ptEvent.start = ptEvent.ptClass.start;
      ptEvent.end = ptEvent.ptClass.end;
      dispatch(upsertPersonalTraining(await PTClassModel.update(ptEvent.ptClass)));
   };

   const newEvent = (slot: SlotInfo) => {
      if (moment(slot.end).diff(slot.start, 'minute') < 60) return;

      setEventToEdit({
         id: 0,
         start: new Date(slot.start),
         end: new Date(slot.end),
      });
   };

   return (
      <>
         <Button
            variant="success"
            className="floating-button me-2"
            onClick={() => setEventToEdit({ id: 0 })}
         >
            Hinzufügen
         </Button>
         <LoadingOverlay loading={isLoading} withBorderRadius>
            <FloatingPanel>
               <Calendar
                  events={events}
                  onEventDrop={moveEvent}
                  onEventResize={resizeEvent}
                  onSelectSlot={newEvent}
                  resizable
                  selectable
                  onNavigate={loadEvents}
               />
            </FloatingPanel>
         </LoadingOverlay>
         <EditPTClassDialog
            show={!!eventToEdit}
            onClose={() => setEventToEdit(undefined)}
            ptClass={eventToEdit ?? {}}
         />
      </>
   );
};
