import React, { CSSProperties, memo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type SkeletonPlaceholderProps = {
   /**
    * The width of the placeholder.
    */
   width?: number | string;
   /**
    * The height of the placeholder.
    */
   height?: number | string;
   /**
    * Additional classes.
    */
   className?: string;
   /**
    * Additional styling.
    */
   style?: CSSProperties;
};

/**
 * Renders an animated placeholder for a future component to be displayed.
 */
export const SkeletonPlaceholder = memo(
   ({ width = '6.25rem', height = '1.25rem', className, style }: SkeletonPlaceholderProps) => (
      <SkeletonTheme>
         <section className={className} style={style}>
            <Skeleton width={width} height={height} style={{ margin: 0 }} />
         </section>
      </SkeletonTheme>
   )
);
