import { Overlay } from 'react-bootstrap';
import React from 'react';

interface Props {
   children?: React.ReactElement;
   target?: HTMLElement;
   show: boolean;
   onHide: () => void;
}

const EventPopover = (props: Props) => {
   if (!props.children) return null;

   return (
      <Overlay
         target={props.target ?? null}
         show={props.show}
         placement="auto"
         flip
         rootClose
         onHide={props.onHide}
      >
         {props.children}
      </Overlay>
   );
};

const EventPopoverDivider = () => (
   <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', height: 1 }} className="mx-3 my-2">
      &nbsp;
   </div>
);

EventPopover.Divider = EventPopoverDivider;

// TODO: Weitere Elemente anlegen um Code zu reduzieren

export default EventPopover;
