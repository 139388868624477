import React, { useEffect, useState } from 'react';
import { Badge, Button, Form } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiPencilOutline } from '@mdi/js';
import moment from 'moment';
import { groupBy } from 'lodash';
import { CalendarDatum } from '@nivo/calendar/dist/types/types';
import { ResponsiveTimeRange } from '@nivo/calendar';
import { FloatingPanel } from '../../atoms';
import { UserTypes } from '../../types/api';
import { FormRow } from '../../forms';
import { Colors, setTitle } from '../../utils';
import { useCache, useSession } from '../../hooks';
import { UploadAvatarDialog } from '../../dialogs/UploadAvatarDialog';
import { Avatar } from '../../molecules/Avatar';
import { CoachingClassModel } from '../../models';

export const ProfileTab = () => {
   const { sessionUser } = useSession();
   const { roles } = useCache();

   const [showUploadAvatarDialog, setShowUploadAvatarDialog] = useState(false);
   const [myCoachingStats, setMyCoachingStats] = useState<CalendarDatum[]>([]);

   useEffect(() => setTitle('Mein Profil'), []);
   useEffect(() => {
      (async () => {
         const myCoachingDays = (
            await CoachingClassModel.listTimeRange(
               moment().set('month', 0).set('day', 1),
               moment().set('month', 11).set('day', 31)
            )
         )
            .filter(
               cc =>
                  cc.coach_id === sessionUser?.id ||
                  cc.coach_second_id === sessionUser?.id ||
                  cc.coach_other.includes(sessionUser?.id ?? 0)
            )
            .map(cc => moment(cc.start).format('YYYY-MM-DD'));

         setMyCoachingStats(
            Object.entries(groupBy(myCoachingDays)).map(([date, classes]) => ({
               day: date,
               value: classes.length,
            }))
         );
      })();
   }, [sessionUser?.id]);

   return (
      <>
         <div className="mt-3 d-flex justify-content-center">
            <div className="d-inline-block position-relative">
               <Avatar url={sessionUser?.profile_url} style={{ width: '7.5rem' }} />
               <div
                  className="position-absolute"
                  style={{
                     right: '-0.5rem',
                     bottom: '-0.5rem',
                     borderRadius: '50%',
                     borderWidth: '0.25rem',
                     borderStyle: 'solid',
                     borderColor: Colors.background,
                  }}
               >
                  <Button
                     className="p-1 text-white"
                     variant="secondary"
                     style={{
                        borderRadius: '50%',
                     }}
                     onClick={() => setShowUploadAvatarDialog(true)}
                  >
                     <Icon path={mdiPencilOutline} size={1} color={Colors.white} />
                  </Button>
               </div>
            </div>
         </div>

         <div className="mt-4 d-none d-lg-flex" style={{ height: 200 }}>
            <ResponsiveTimeRange
               data={myCoachingStats}
               from={moment().set('month', 0).set('day', 1).format('YYYY-MM-DD')}
               to={moment().set('month', 11).set('day', 31).format('YYYY-MM-DD')}
               emptyColor="rgba(0, 0, 0, 0.125)"
               colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
               margin={{ top: 20, right: 0, bottom: 0, left: 20 }}
               // monthBorderColor={Colors.background}
               dayBorderWidth={2}
               dayBorderColor={Colors.background}
               dayRadius={3}
               weekdayTicks={[]}
               weekdayLegendOffset={0}
               monthLegend={(_year, _month, date) => moment(date).format('MMM')}
               tooltip={({ day, value }) => (
                  <div
                     style={{
                        padding: 12,
                        background: Colors.white,
                        borderRadius: 4,
                     }}
                  >
                     <span>{moment(day).format('dddd, L')}: </span>
                     <strong>{value} Klassen</strong>
                  </div>
               )}
            />
         </div>

         <FloatingPanel>
            <FormRow label="E-Mail" type="responsive">
               <Form.Control type="text" value={sessionUser?.email ?? ''} readOnly />
            </FormRow>
            <FormRow label="Telefonnummer" type="responsive">
               <Form.Control type="text" value={sessionUser?.phone ?? ''} readOnly />
            </FormRow>
            <FormRow label="Vorname" type="responsive">
               <Form.Control type="text" value={sessionUser?.first_name ?? ''} readOnly />
            </FormRow>
            <FormRow label="Nachname" type="responsive">
               <Form.Control type="text" value={sessionUser?.last_name ?? ''} readOnly />
            </FormRow>
            <FormRow label="Nickname" type="responsive">
               <Form.Control type="text" value={sessionUser?.nickname ?? ''} readOnly />
            </FormRow>
            <FormRow label="Account-Typ" type="responsive">
               <Form.Control
                  type="text"
                  value={UserTypes.find(ut => ut.value === sessionUser?.type)?.label ?? ''}
                  readOnly
               />
            </FormRow>
            <FormRow label="Urlaubstage" type="responsive">
               <Form.Control
                  type="text"
                  value={sessionUser?.vacation_days_current_year ?? ''}
                  readOnly
               />
            </FormRow>
            <FormRow label="Resturlaub" type="responsive">
               <Form.Control
                  type="text"
                  value={sessionUser?.vacation_remaining_last_year ?? ''}
                  readOnly
               />
            </FormRow>
            <FormRow label="Rollen" type="responsive">
               <span>
                  {sessionUser?.roles.map(role => (
                     <Badge key={role} bg="secondary" className="me-1">
                        {roles.find(r => r.key === role)?.name}
                     </Badge>
                  ))}
               </span>
            </FormRow>
         </FloatingPanel>
         <UploadAvatarDialog
            show={showUploadAvatarDialog}
            onClose={() => setShowUploadAvatarDialog(false)}
         />
      </>
   );
};
