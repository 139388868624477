import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, FormikErrors } from 'formik';
import { LoadingButton } from '../molecules';
import { CoachingClass } from '../types/api';
import { CoachingClassModel } from '../models';
import { SubmitButton, TextareaControl } from '../forms';

interface Props {
   show: boolean;
   coachingClass?: Partial<CoachingClass>;
   onClose: () => void;
   afterSave?: (coachingClass: CoachingClass) => void;
}

export const CancelRestoreCoachingClassDialog = (props: Props) => {
   const [showCancelDialog, setShowCancelDialog] = useState(false);
   const [showRestoreDialog, setShowRestoreDialog] = useState(false);
   const [isRestoring, setRestoring] = useState(false);

   useEffect(() => {
      if (props.show) {
         if (props.coachingClass?.canceled) setShowRestoreDialog(true);
         else setShowCancelDialog(true);
      } else {
         setShowRestoreDialog(false);
         setShowCancelDialog(false);
      }
   }, [props.show, props.coachingClass]);

   const handleSubmitForm = async (values: Partial<CoachingClass>) => {
      const updatedClass = await CoachingClassModel.cancelClass(
         values.id ?? 0,
         values.cancel_reason ?? ''
      );
      setShowCancelDialog(false);
      props.afterSave?.(updatedClass);
      props.onClose();
   };

   const handleRestore = async () => {
      setRestoring(true);

      try {
         const updatedClass = await CoachingClassModel.restoreClass(props.coachingClass?.id ?? 0);
         setShowRestoreDialog(false);
         props.afterSave?.(updatedClass);
         props.onClose();
      } finally {
         setRestoring(false);
      }
   };

   if (!props.coachingClass || props.coachingClass.id === 0) return null;

   return (
      <>
         <Modal
            show={showCancelDialog}
            onHide={() => {
               setShowCancelDialog(false);
               props.onClose();
            }}
            centered
         >
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={props.coachingClass}
               enableReinitialize
               validate={values => {
                  const errors: FormikErrors<Partial<CoachingClass>> = {};

                  if (!values.cancel_reason) errors.cancel_reason = 'Bitte gib einen Grund an.';

                  return errors;
               }}
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>Klasse absagen</Modal.Title>
                     </Modal.Header>

                     <Modal.Body>
                        <TextareaControl
                           formik={formik}
                           name="cancel_reason"
                           placeholder="Grund der Absage…"
                        />
                     </Modal.Body>

                     <Modal.Footer className="bg-light">
                        <Button
                           variant="outline-link"
                           onClick={() => {
                              setShowCancelDialog(false);
                              props.onClose();
                           }}
                           disabled={formik.isSubmitting}
                        >
                           Abbrechen
                        </Button>
                        <SubmitButton formik={formik} variant="danger">
                           Absagen
                        </SubmitButton>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>

         <Modal
            show={showRestoreDialog}
            onHide={() => {
               setShowRestoreDialog(false);
               props.onClose();
            }}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title>Klasse reaktivieren</Modal.Title>
            </Modal.Header>

            <Modal.Body>Möchtest du die Klasse reaktivieren?</Modal.Body>

            <Modal.Footer className="bg-light">
               <Button
                  variant="outline-link"
                  onClick={() => {
                     setShowRestoreDialog(false);
                     props.onClose();
                  }}
                  disabled={isRestoring}
               >
                  Abbrechen
               </Button>
               <LoadingButton variant="success" onClick={handleRestore} isLoading={isRestoring}>
                  Reaktivieren
               </LoadingButton>
            </Modal.Footer>
         </Modal>
      </>
   );
};
